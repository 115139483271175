import React, { useState } from 'react';
import { View, Pressable, Text, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { ProfileResponse, ProfileResponse_Mini } from '@/api/zod';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { relativeDate } from '../RelativeDate';
import { colors } from '@/app/colors';

type ListItem_UserProps = {
  navigation: StackNavigationProp<ParamListBase>;
  pressedUser: ProfileResponse | ProfileResponse_Mini;
  callback?: () => void;
  subtitle?: number;
};

export function ListItem_User({
  navigation,
  pressedUser,
  callback,
  subtitle,
}: ListItem_UserProps) {
  const [hover, setHover] = useState(false);
  const handleTap = () => {
    if (callback) {
      callback();
    } else {
      navigation.push('UserProfile', {
        username: pressedUser.username,
      });
    }
  };
  return (
    <Pressable
      style={{
        ...styles.userContainer,
        backgroundColor: hover ? colors.primary_offset : colors.invisible,
      }}
      onPress={handleTap}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      {pressedUser.Profile_picture ? (
        <Image
          source={{
            uri: `${pressedUser.Profile_picture.url}?tpl=med`,
          }}
          transition={250}
          style={globalstyles.profileImageSmall}
        />
      ) : (
        <Image
          source={require('../../../assets/profile-placeholder.jpg')}
          style={globalstyles.profileImageSmall}
          transition={250}
        />
      )}
      <View
        style={{
          marginLeft: spacing.md,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={globalstyles.usernameText}>{pressedUser.username}</Text>
        {subtitle && (
          <Text
            style={{
              ...globalstyles.smallfade,
              opacity: 0.4,
              fontStyle: 'italic',
            }}
          >
            {` - ${relativeDate(subtitle)}`}
          </Text>
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing.md,
    padding: spacing.sm,
    borderRadius: radius.sm,
  },
});

import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Pressable,
  StyleSheet,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';
import { colors } from '@/app/colors';
import {
  globalstyles,
  heightWithHeader,
  maxWebContentWidth,
  radius,
  spacing,
} from '@/app/globalstyles';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { unauthenticatedRequest } from '@/modules/auth';
import { Image } from 'expo-image';
import { formattedDate } from '@/components/RelativeDate';
import { showToast } from '@/components/ErrorToast';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

type BadgeInfoResponse = {
  id: number;
  created_at: number;
  badge_type_id: number;
  users_id: number;
  posts_id: number;
  post: {
    id: number;
    long_id: number;
    images: [
      {
        images_id: number;
        image: {
          url: string;
        };
      },
    ];
  };
  daily_theme_events_id: number;
  daily_theme_event: {
    title: string;
    background_image: {
      url: string;
    };
  };
};

export function ViewBadgeInfoScreen() {
  const {
    params: { users_id, badge },
  } = useRoute<AuthenticatedNavigatorRouteProp<'ViewBadgeInfo'>>();
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'ViewBadgeInfo'>>();
  const [badgeInstances, setBadgeInstances] = useState<BadgeInfoResponse[]>([]);
  const fetchBadgeInstanceInfo = async () => {
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `badges/${badge.badge_instance_badge_type_id}/${users_id}`,
      );
      setBadgeInstances(response.data);
    } catch (error) {
      showToast('Error fetching badge info');
    }
  };

  useEffect(() => {
    fetchBadgeInstanceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderHeader = () => {
    return (
      <>
        <View style={{ marginVertical: spacing.lg, alignSelf: 'center' }}>
          <Image
            source={badge.icon.url}
            style={{ ...globalstyles.profileImage, borderRadius: 0 }}
            transition={250}
            contentFit={'contain'}
          />
        </View>
        <View style={{ marginBottom: spacing.md }}>
          <Text style={{ ...globalstyles.title, ...globalstyles._font_lg }}>
            {badge.title}
          </Text>
        </View>
        <View style={{ marginBottom: spacing.md, alignSelf: 'center' }}>
          <Text style={globalstyles.body}>{badge.description}</Text>
        </View>
      </>
    );
  };
  const renderBadgeInstance = ({ item }: { item: BadgeInfoResponse }) => {
    return (
      <Pressable
        onPress={() =>
          navigation.push('ViewSinglePost', {
            postId: item.post.long_id,
          })
        }
        disabled={item.post ? false : true}
        style={{ ...globalstyles.listItemContainer, width: '100%' }}
        key={item.id}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image
            source={{ uri: `${item.post.images[0].image.url}?tpl=med` }}
            style={{
              ...globalstyles.profileImage,
              borderRadius: radius.md,
              marginRight: spacing.lg,
            }}
            contentFit={'cover'}
            transition={250}
          />
          <View style={{ alignItems: 'flex-start' }}>
            <Text style={{ ...globalstyles.label, marginBottom: spacing.xs }}>
              Daily theme: {item.daily_theme_event.title}
            </Text>
            <Text style={globalstyles.smallfade}>
              Received {formattedDate(item.created_at)}
            </Text>
          </View>
        </View>
        <TouchableOpacity
          style={{ padding: spacing.md }}
          activeOpacity={0.9}
          onPress={() =>
            navigation.push('ViewSinglePost', { postId: item.post.long_id })
          }
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            color={colors.secondary}
            size={16}
          />
        </TouchableOpacity>
      </Pressable>
    );
  };

  return (
    <View style={globalstyles.container}>
      <FlatList
        data={badgeInstances}
        renderItem={renderBadgeInstance}
        ListHeaderComponent={renderHeader}
        style={styles.listContainer}
        contentContainerStyle={styles.contentContainer}
        keyExtractor={(item, index) => index.toString()}
      />
      <Toast />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    height: heightWithHeader,
    width: '100%',
    paddingTop: spacing.lg,
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignSelf: 'center',
    maxWidth: maxWebContentWidth,
  },
});

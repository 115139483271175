import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { border, globalstyles, radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { ParamListBase } from '@react-navigation/native';
import { Button_Countdown } from '../home/Button_Countdown';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { StackNavigationProp } from '@react-navigation/stack';

interface DailyThemeHeaderProps {
  activeRoute: string;
  navigation: StackNavigationProp<ParamListBase>;
  loggedInUserHasSubmitted: boolean;
}

export function WebNav_DailyThemeheader({
  activeRoute,
  navigation,
  loggedInUserHasSubmitted,
}: DailyThemeHeaderProps) {
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );
  return (
    <View style={styles.posContainer}>
      <View style={styles.bar}>
        <NavButton
          text={loggedInUserHasSubmitted ? 'Submitted ✓' : 'Submit post'}
          active={activeRoute === 'DailyTheme'}
          navCallback={
            activeRoute !== 'DailyTheme'
              ? () => navigation.navigate('DailyTheme')
              : () => null
          }
        />
        <NavButton
          text={'Vote'}
          active={
            activeRoute === 'DailyThemeRound1Vote' ||
            activeRoute === 'ViewPostsForVote'
          }
          navCallback={
            activeRoute !== 'DailyThemeRound1Vote'
              ? () => navigation.navigate('DailyThemeRound1Vote')
              : () => null
          }
        />
        <NavButton
          text={'Vote champ'}
          active={activeRoute === 'DailyThemePodium'}
          navCallback={
            activeRoute !== 'DailyThemePodium'
              ? () => navigation.navigate('DailyThemePodium')
              : () => null
          }
        />
        <NavButton
          text={'Next theme'}
          active={activeRoute === 'VoteNextTheme'}
          navCallback={
            activeRoute !== 'VoteNextTheme'
              ? () => navigation.navigate('VoteNextTheme')
              : () => null
          }
        />
      </View>
      <Button_Countdown themeInfo={submissionThemeInfo} />
    </View>
  );
}

interface NavButtonProps {
  text: string;
  active: boolean;
  navCallback: () => void;
}

function NavButton({ text, active, navCallback }: NavButtonProps) {
  const [hover, setHover] = useState(false);
  const bgColor = active
    ? colors.secondary
    : hover
    ? colors.primary_light
    : colors.primary_offset_2;
  return (
    <Pressable
      style={{
        ...globalstyles.roundedButtonWeb,
        backgroundColor: bgColor,
        margin: spacing.xs,
      }}
      onPress={navCallback}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <Text
        style={{
          ...globalstyles.body,
          color: active ? colors.primary : colors.secondary,
          opacity: active || hover ? 1 : 0.8,
          fontWeight: '500',
        }}
      >
        {text}
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  posContainer: {
    backgroundColor: colors.primary,
    borderTopColor: colors.primary_accent,
    borderTopWidth: border.sm,
    bottom: 0,
    position: 'absolute',
    zIndex: 4,
    paddingVertical: spacing.lg,
    width: '100%',
  },
  bar: {
    flexDirection: 'row',
    alignSelf: 'center',
    backgroundColor: colors.primary_offset,
    padding: spacing.xs,
    borderRadius: radius.xl,
  },
});

import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import { useCreateUser } from '@/api/useCreateUser';
import { Layout_OAuthStep1 } from './oauth/Layout_OAuthStep1';
import { Layout_OAuthStep2 } from './oauth/Layout_OAuthStep2';
import { Text_AuthHeader } from './Text_AuthHeader';
import { globalstyles, spacing } from '@/app/globalstyles';
import { Text_ForgotPassword } from './Text_ForgotPassword';
import { Text_PrivacyAndTerms } from './Text_PrivacyAndTerms';
import { login } from '@/modules/auth';
import { showErrorToastTop } from '../ErrorToast';
import Toast from 'react-native-toast-message';

type Props = {
  setScreen: (screen: string) => void;
  toggleModal: () => void;
  navigation: StackNavigationProp<ParamListBase>;
};

export function OAuth({ setScreen, toggleModal, navigation }: Props) {
  const [currentStep, setCurrentStep] = useState(1);
  const moveToStep = (s: number) => {
    setCurrentStep(s);
  };
  const {
    fetchDefaultUsername,
    defaultUsername,
    creatingUser,
    createOAuthUser,
  } = useCreateUser({
    email: '',
    password: '',
  });

  const renderSteps = () => {
    switch (currentStep) {
      case 1:
        return (
          <Layout_OAuthStep1 setScreen={setScreen} toggleModal={toggleModal} />
        );
      case 2:
        return (
          <Layout_OAuthStep2
            proceed={createOAuthUser}
            default_username={defaultUsername}
            creatingUser={creatingUser}
          />
        );
    }
  };

  // const authHeaderKey =
  //   currentStep === 1
  //     ? 'oauth-1'
  //     : currentStep === 2
  //     ? 'oauth-2'
  //     : 'oauth-1';

  useEffect(() => {
    const handleMessage = async (event: any) => {
      console.log('event data: ', event.data);
      if (
        event.origin !== 'http://localhost:19006' &&
        event.origin !== 'https://www.joinwhirl.com'
      ) {
        console.log('wrong origin.', event.origin);
        return;
      }
      if (event.data.status === 'duplicate-email') {
        console.log('duplicatee.');
        showErrorToastTop(
          'Duplicate email',
          'This email exists through another sign-in',
        );
      } else if (event.data.status === 'signup-success') {
        console.log('signup.');
        moveToStep(2);
        fetchDefaultUsername(event.data.token);
      } else if (event.data.status === 'login-success') {
        console.log('login.');
        await login(event.data.token);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [fetchDefaultUsername]);

  return (
    <View style={styles.wrapper}>
      <Text_AuthHeader text={'auth'} />
      {renderSteps()}
      <Text_ForgotPassword callback={() => setScreen('pw-reset')} />
      <Text_PrivacyAndTerms navigation={navigation} toggleModal={toggleModal} />
      <Toast />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    ...globalstyles.container,
    padding: spacing.xl,
  },
});

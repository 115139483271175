import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { globalstyles, font } from '@/app/globalstyles';
import { colors } from '@/app/colors';

type ThemeSubtitleProps = {
  pretitle: string;
  title: string;
  exclamation?: boolean;
};

export function ThemeSubtitle({
  title,
  pretitle,
  exclamation,
}: ThemeSubtitleProps) {
  return (
    <View style={globalstyles.subtitleContainer}>
      <Text style={globalstyles.title}>{pretitle}</Text>
      <Text style={{ ...globalstyles.title, ...styles.subtitleSpecialSauce }}>
        {title}
      </Text>
      <Text style={globalstyles.title}>{exclamation ? ' !' : null}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  subtitleSpecialSauce: {
    color: colors.accent,
    fontSize: font.lg,
    alignSelf: 'center',
  },
});

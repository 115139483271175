import React, { useCallback } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@/api/keys';
import { globalstyles } from '@/app/globalstyles';

export function Offline() {
  const queryClient = useQueryClient();

  const handleRetry = useCallback(() => {
    queryClient.resetQueries(queryKeys.all);
  }, [queryClient]);

  return (
    <View style={globalstyles.container}>
      <View style={styles.card}>
        <Text style={{ ...globalstyles.title, ...globalstyles._font_lg }}>
          App Offline
        </Text>
        <TouchableOpacity
          style={globalstyles.roundedButton}
          onPress={handleRetry}
        >
          <Text style={globalstyles.title}>Tap to retry</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

import { Dimensions, StyleSheet } from 'react-native';
import { colors } from './colors';

export const spacing = {
  xxs: 2,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
};

export const radius = {
  xxs: 2,
  xs: 6,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 108,
};

export const border = {
  xs: 1,
  sm: 2,
  md: 4,
};

export const font = {
  xs: 8,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
  xxl: 20,
  xxxl: 24,
};

const profPicSize = 144;
export const { width, height } = Dimensions.get('window');
export const maxWidth = 1264;
export const webContentWidth = 240;
export const headerHeight = 100;
export const HALF_WIDTH = width / 2;
export const longButtonWidth = width - spacing.md * 2;
export const heightWithHeader = height - headerHeight;
export const maxWebContentWidth = 1084;
export const maxWebContentHeight = 480;

export const globalstyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  webcontainer: {
    flex: 1,
    height: height,
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: spacing.md,
    paddingHorizontal: spacing.md,
    borderBottomColor: colors.primary_offset,
    borderBottomWidth: border.xs,
  },
  withBottomBorderSpacing: {
    paddingBottom: spacing.md,
    marginBottom: spacing.md,
    borderBottomWidth: 1,
    borderColor: colors.primary_accent,
  },
  centeringContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  endingContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  subtitleContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: spacing.md,
    marginTop: spacing.md,
  },
  promptInputContainer: {
    paddingVertical: spacing.md,
    backgroundColor: colors.primary,
    position: 'absolute',
    borderTopWidth: border.sm,
    borderTopColor: colors.primary_accent,
    bottom: 0,
    width: '100%',
  },
  footerIcons: {
    paddingBottom: spacing.lg,
    paddingTop: spacing.lg,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.md * 2,
    position: 'relative',
    zIndex: 4,
  },
  imageBackgroundFull: {
    justifyContent: 'center',
    height: height,
    width: '100%',
    flex: 1,
  },
  modalContent: {
    alignSelf: 'center',
    backgroundColor: colors.primary_accent,
    borderRadius: radius.lg,
    width: webContentWidth * 2,
    paddingBottom: spacing.lg,
  },
  header: {
    alignItems: 'center',
    marginTop: spacing.md * 2,
  },
  logoText: {
    fontFamily: 'Great_Vibes',
    fontSize: font.xxxl * 2,
    color: colors.secondary,
    alignSelf: 'center',
  },
  logoTextSmall: {
    fontFamily: 'Great_Vibes',
    fontSize: font.xxl * 2,
    color: colors.secondary,
    alignSelf: 'center',
  },
  title: {
    fontFamily: 'Manrope',
    fontSize: font.lg,
    color: colors.secondary,
    alignSelf: 'center',
  },
  body: {
    color: colors.secondary,
    fontSize: font.lg,
  },
  bodyfade: {
    color: colors.halfwhite,
    fontSize: font.lg,
  },
  smallbody: {
    color: colors.secondary,
    fontSize: font.md,
  },
  smallfade: {
    color: colors.halfwhite,
    fontSize: font.md,
  },
  tinybody: {
    color: colors.secondary,
    fontSize: font.sm,
  },
  tinyfade: {
    color: colors.halfwhite,
    fontSize: font.sm,
  },
  label: {
    color: colors.secondary,
    fontFamily: 'Manrope',
    fontSize: font.lg,
    fontWeight: '500',
  },
  headerText: {
    fontFamily: 'PressStart2P',
    color: colors.secondary,
    alignSelf: 'center',
    fontSize: 12,
  },
  usernameText: {
    fontSize: font.md,
    fontFamily: 'Manrope_Bold',
    fontWeight: '500',
    color: colors.secondary,
  },
  usernameTextLarge: {
    fontSize: font.xxxl,
    fontFamily: 'Manrope',
    color: colors.secondary,
  },
  _font_lg: {
    fontSize: 24,
  },
  _font_xl: {
    fontSize: 36,
  },
  _font_xxl: {
    fontSize: 48,
  },
  roundedButton: {
    padding: spacing.md,
    borderRadius: radius.xl,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary_accent,
  },
  roundedButtonWeb: {
    paddingVertical: spacing.md,
    paddingHorizontal: spacing.lg,
    borderRadius: radius.xl,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary_accent,
  },
  submitButton: {
    padding: spacing.md * 1.5,
    borderRadius: radius.lg,
    backgroundColor: colors.accent,
  },
  longButton: {
    width: longButtonWidth,
    maxWidth: webContentWidth * 2,
    backgroundColor: colors.primary_accent,
    alignItems: 'center',
    borderRadius: radius.md,
    padding: spacing.md + 4,
  },
  _bg_submit: {
    backgroundColor: colors.accent,
  },
  _bg_navigate: {
    backgroundColor: colors.secondary,
  },
  _row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  settingsButton: {
    width: '100%',
    maxWidth: webContentWidth * 2,
    marginBottom: spacing.sm,
    borderRadius: radius.sm,
    backgroundColor: colors.primary_accent,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: spacing.md,
    alignSelf: 'center',
  },
  chipContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  chip: {
    flexDirection: 'row',
    paddingVertical: spacing.sm,
    paddingHorizontal: spacing.sm + spacing.xs,
    borderRadius: radius.lg,
    backgroundColor: colors.primary,
    borderColor: colors.primary_accent,
    borderWidth: border.xs,
    margin: spacing.xs,
    alignItems: 'center',
  },
  chipText: {
    fontSize: font.md,
    textAlign: 'center',
    color: colors.halfwhite,
  },
  chipUsageCountText: {
    fontSize: font.xs,
    paddingLeft: spacing.xs,
    color: colors.halfwhite,
  },
  _selectedChip: {
    backgroundColor: colors.primary_accent,
  },
  _selectedChipText: {
    color: colors.secondary,
  },
  _margin_right: {
    marginRight: spacing.sm,
  },
  profileImageContainerSmall: {
    padding: 5,
    backgroundColor: colors.primary,
    borderRadius: radius.lg * 2,
  },
  profileImageSmall: {
    height: 50,
    width: 50,
    borderRadius: radius.lg * 2,
    backgroundColor: colors.primary,
  },
  profileImageXS: {
    height: 24,
    width: 24,
    borderRadius: radius.lg,
    backgroundColor: colors.primary,
  },
  profileImage: {
    height: profPicSize,
    width: profPicSize,
    backgroundColor: colors.primary,
    borderRadius: radius.xxl,
  },
  profileUploadImage: {
    height: profPicSize,
    width: profPicSize,
    backgroundColor: colors.primary,
  },
  gifImage: {
    width: webContentWidth * 2 - spacing.md * 2,
    alignSelf: 'center',
    height: webContentWidth / 2,
    borderRadius: radius.md,
  },
  rounded: {
    borderRadius: radius.xxl,
  },
  accented: {
    borderColor: colors.accent,
    borderWidth: border.sm,
  },
  profileImageContainer: {
    left: spacing.md,
    position: 'absolute',
    bottom: -spacing.md,
    borderWidth: 3,
  },
  logoVideo: {
    height: 500,
    aspectRatio: 1,
    borderRadius: radius.xl * 3,
  },
  logoImageLandingWeb: {
    height: spacing.xl * 3,
    aspectRatio: 1,
  },
  pinpointIcon: {
    height: 14,
    width: 14,
  },
  profileBannerImage: {
    width: '100%',
    aspectRatio: 4 / 1,
  },
  fs_background: {
    width: width,
    height: height,
    justifyContent: 'space-between',
  },
  thumbnailNative: {
    contentFit: 'cover',
    width: width / 3 - 0.5,
    height: height / 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  thumbnailWeb: {
    contentFit: 'cover',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: 1,
  },
  activeThumbnail: {
    borderRadius: radius.sm,
    borderWidth: 2,
    borderColor: colors.accent,
  },
  gradientOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
  },
  homeScreenGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    zIndex: 1,
  },
  homeScreenGradientFill: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    zIndex: 1,
  },
  toggle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginVertical: spacing.md,
  },
  negativeSpace: {
    flex: 1,
    backgroundColor: colors.primary,
    opacity: 0.4,
  },
  tabBar: {
    flexDirection: 'row',
    backgroundColor: colors.primary,
  },
  tabItems: {
    flex: 1,
    padding: 16,
    alignItems: 'center',
  },
  activeTabIndicator: {
    position: 'absolute',
    bottom: 0,
    width: '50%',
    height: 2,
    backgroundColor: colors.accent,
    borderRadius: 4,
  },
  profilePlaceholder: {
    width: 160,
    height: 160,
    marginTop: 24,
  },
});

import { ProfileResponse } from '@/api/zod';
import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest } from '@/modules/auth';
import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, FlatList, View } from 'react-native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { ProfileImage } from '@/components/ProfilePicture';
import { useMyProfileStore } from '@/app/store/store';
import { Text_ListHeader } from '@/components/Text_ListHeader';

export function Leaderboard_TotalExpScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'Leaderboard_DailyThemeStreak'>>();
  const [users, setUsers] = useState<ProfileResponse[]>([]);
  const [page, setPage] = useState(1);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);

  const navToUser = (userInfo: ProfileResponse) => {
    navigation.push('UserProfile', {
      username: userInfo.username,
    });
  };

  const renderItem = ({
    item,
    index,
  }: {
    item: ProfileResponse;
    index: number;
  }) => (
    <TouchableOpacity
      onPress={() => navToUser(item)}
      style={globalstyles.listItemContainer}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginRight: spacing.md }}>
          <Text style={globalstyles.label}>{index + 1}.</Text>
        </View>
        <View style={{ marginRight: spacing.md }}>
          <ProfileImage image_url={item.Profile_picture?.url} />
        </View>
        <Text style={globalstyles.usernameText}>@{item.username}</Text>
      </View>
      <View style={{ marginRight: spacing.md }}>
        <Text style={globalstyles.title}>{item.experience}</Text>
      </View>
    </TouchableOpacity>
  );

  const fetchCurrentExpLeaderboard = async () => {
    if (!hasMoreUsers) {
      return;
    }
    if (user) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `user_stats/total-exp/${user?.id}/${page}`,
        );
        if (response.data.length > 0) {
          setPage(page + 1);
          setUsers([...users, ...response.data]);
        } else {
          setHasMoreUsers(false);
        }
      } catch (error) {
        showToast('Error fetching exp leaderboard');
      }
    }
  };

  useEffect(() => {
    fetchCurrentExpLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listHeader = () => {
    return <Text_ListHeader text={'Total experience'} />;
  };

  return (
    <View style={globalstyles.container}>
      <FlatList
        data={users}
        renderItem={renderItem}
        ListHeaderComponent={listHeader}
        style={{ height: heightWithHeader }}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
}

import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  Pressable,
  Text,
  Animated,
  ActivityIndicator,
} from 'react-native';
import { Image } from 'expo-image';
import { Post, Comment } from '@/api/zod';
import { colors } from '@/app/colors';
import {
  globalstyles,
  radius,
  webContentWidth,
  spacing,
  headerHeight,
  heightWithHeader,
} from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Layout_Comment } from './comments/Layout_Comment';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import Buttons_PostActions from './Buttons_PostActions';

interface PostProps {
  post: Post;
  goToUser: () => void;
  like: () => void;
  save: () => void;
  toggleComment: () => void;
  commentsVisible: boolean;
  fetchComments: (post: Post, users_id: number) => void;
  likeCommentOrReply: (
    comment: Comment,
    users_id: number,
    isReply?: boolean,
  ) => void;
  navigation: StackNavigationProp<ParamListBase>;
  comments: Comment[];
  loading: boolean;
  hasMoreComments: boolean;
  createComment: (post: Post, users_id: number, commentInput: string) => void;
  deleteComment: (comment: Comment) => void;
  createReply: (
    comment: Comment,
    users_id: number,
    commentInput: string,
  ) => void;
  setPressedItem: (post: Post) => void;
  newCommentLoading: boolean;
  isAnonymous?: boolean;
  togglePostSettings: () => void;
  handleClose: () => void;
  fadeAnim: any;
}

export const Layout_Post = ({
  post,
  goToUser,
  like,
  save,
  toggleComment,
  commentsVisible,
  fetchComments,
  likeCommentOrReply,
  isAnonymous,
  navigation,
  comments,
  loading,
  createComment,
  deleteComment,
  createReply,
  newCommentLoading,
  hasMoreComments,
  setPressedItem,
  togglePostSettings,
  handleClose,
  fadeAnim,
}: PostProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [newImgLoading, setNewImgLoading] = useState(false);
  const postImage = post.images[0].image;
  const [aspectRatio, setAspectRatio] = useState(
    postImage.meta.width / postImage.meta.height,
  );
  const maxWidth = webContentWidth * 2 - spacing.md;
  const maxHeight = heightWithHeader - headerHeight * 2;
  const scaledHeight = maxWidth / aspectRatio;
  const theHeight = scaledHeight > maxHeight ? maxHeight : scaledHeight;
  const theWidth =
    scaledHeight > maxHeight ? maxHeight * aspectRatio : maxWidth;
  useEffect(() => {
    setAspectRatio(
      post.images[0].image.meta.width / post.images[0].image.meta.height,
    );
    setNewImgLoading(true);
    setImageLoaded(false);
  }, [post.images]);
  return (
    <Animated.View style={[globalstyles.container, { opacity: fadeAnim }]}>
      <View style={styles.leadContainer}>
        <Pressable>
          {!commentsVisible ? (
            <>
              {postImage.url ? (
                <>
                  {newImgLoading && !imageLoaded && (
                    <View
                      style={{
                        ...styles.loadingPlaceholder,
                        width: theWidth,
                        height: theHeight,
                      }}
                    >
                      <ActivityIndicator
                        size="small"
                        color={colors.secondary}
                      />
                    </View>
                  )}
                  <Image
                    key={post.id}
                    source={{ uri: `${postImage.url}?tpl=bigger` }}
                    style={{
                      ...styles.image,
                      width: theWidth,
                      height: theHeight,
                      opacity: imageLoaded ? 1 : 0,
                      aspectRatio: aspectRatio,
                      display: imageLoaded && !newImgLoading ? 'flex' : 'none',
                    }}
                    onLoad={() => {
                      setImageLoaded(true);
                      setNewImgLoading(false);
                    }}
                    transition={100}
                    contentFit={'cover'}
                  />
                </>
              ) : null}
              <Layout_PostFooter
                post={post}
                like={like}
                save={save}
                isAnonymous={isAnonymous}
                goToUser={goToUser}
                toggleComment={toggleComment}
                togglePostSettings={togglePostSettings}
              />
            </>
          ) : (
            <Layout_Comment
              post={post}
              navigation={navigation}
              isAnonymous={isAnonymous}
              comments={comments}
              loading={loading}
              hasMoreComments={hasMoreComments}
              fetchComments={fetchComments}
              likeCommentOrReply={likeCommentOrReply}
              setPressedItem={setPressedItem}
              toggleComment={toggleComment}
              createComment={createComment}
              deleteComment={deleteComment}
              createReply={createReply}
              newCommentLoading={newCommentLoading}
              handleClose={handleClose}
              theWidth={theWidth}
              theHeight={theHeight}
            />
          )}
        </Pressable>
      </View>
    </Animated.View>
  );
};

interface FooterProps {
  post: Post;
  isAnonymous?: boolean;
  like: () => void;
  save: () => void;
  toggleComment: () => void;
  goToUser: () => void;
  togglePostSettings: () => void;
}

const Layout_PostFooter = ({
  post,
  isAnonymous,
  like,
  save,
  goToUser,
  toggleComment,
  togglePostSettings,
}: FooterProps) => {
  return (
    <View style={styles.footerContainer}>
      <View style={styles.iconsAndUser}>
        <View style={globalstyles.container}>
          {!isAnonymous && <Layout_PostUser post={post} goToUser={goToUser} />}
          {post.title && (
            <View style={styles.titleContainer}>
              <Text style={globalstyles.smallbody} numberOfLines={2}>
                {post.title}
              </Text>
            </View>
          )}
        </View>
        <Pressable style={styles.actionButtonContainer} onPress={toggleComment}>
          <FontAwesomeIcon
            style={styles.actionButton}
            icon={faComment}
            color={colors.secondary}
            size={20}
          />
          <Text style={globalstyles.usernameText}>
            {post.commentCount > 0 ? post.commentCount : ''}
          </Text>
        </Pressable>
        <Buttons_PostActions
          like={like}
          save={save}
          togglePostSettings={togglePostSettings}
          post={post}
        />
      </View>
    </View>
  );
};

interface UserProps {
  post: Post;
  goToUser: () => void;
}

const Layout_PostUser = ({ post, goToUser }: UserProps) => {
  const imageSource = post.Profile_picture
    ? { uri: `${post.Profile_picture.url}?tpl=med` }
    : require('../../../assets/profile-placeholder.jpg');

  return (
    <Pressable style={styles.userContainer} onPress={goToUser}>
      <View style={styles.profImageContainer}>
        <Image
          source={imageSource}
          transition={250}
          style={globalstyles.profileImageXS}
        />
      </View>
      <Text style={globalstyles.usernameText} numberOfLines={1}>
        @{post.username}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  leadContainer: {
    ...globalstyles.container,
    backgroundColor: colors.primary_accent,
    padding: spacing.xxs / 2,
    borderRadius: radius.sm,
    maxHeight: heightWithHeader - headerHeight,
    // this is highly sus method. I don't like it at all.
    maxWidth: webContentWidth * 2 - spacing.sm - spacing.xs,
  },
  footerContainer: {
    ...globalstyles.container,
    padding: spacing.md,
    backgroundColor: colors.primary,
    // flexDirection: 'row',
    borderBottomRightRadius: radius.sm,
    borderBottomLeftRadius: radius.sm,
  },
  iconsAndUser: {
    ...globalstyles.container,
    flexDirection: 'row',
  },
  titleContainer: {
    ...globalstyles.container,
    flexWrap: 'wrap',
  },
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: spacing.sm,
  },
  profImageContainer: {
    paddingRight: spacing.sm,
    zIndex: 2,
  },
  actionButtonContainer: {
    alignItems: 'center',
  },
  actionButton: {
    marginHorizontal: spacing.sm,
    marginBottom: spacing.sm,
  },
  image: {
    maxWidth: webContentWidth * 2 - spacing.md,
    maxHeight: heightWithHeader - headerHeight * 2,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    borderTopRightRadius: radius.sm,
    borderTopLeftRadius: radius.sm,
    zIndex: 1,
  },
  userInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: -spacing.md + 6,
    zIndex: 5,
  },
  usernameContainer: {
    ...globalstyles.centeringContainer,
    flexDirection: 'row',
    paddingLeft: spacing.xs,
  },
  postFooterContainer: {
    flexDirection: 'row',
    maxWidth: webContentWidth * 2,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  textPreviewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: spacing.sm,
  },
  commentButtonContainer: {
    alignSelf: 'flex-start',
    paddingTop: spacing.xs,
  },
  loadingPlaceholder: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
  },
});

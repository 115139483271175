import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
} from 'react-native';
import { Image } from 'expo-image';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useUpdateSelf } from '@/api/me';
import { colors } from '@/app/colors';
import {
  globalstyles,
  heightWithHeader,
  webContentWidth,
  spacing,
  headerHeight,
} from '@/app/globalstyles';
import { authenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { useMyProfileStore } from '@/app/store/store';
import { queryKeys } from '@/api/keys';
import { Button_Submission } from '@/components/Button_Submission';
import { InputBox_WithLabel } from '@/components/InputBox_WithLabel';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { useOpenImagePicker } from '@/api/upload';
import { Button_Settings } from '@/components/shared/Button_Settings';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export function EditProfileScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'EditProfile'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const { mutate: updateProfile, isLoading } = useUpdateSelf();
  const [removing, setRemoving] = useState(false);
  const queryClient = useQueryClient();
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  const [modalVisible, setModalVisible] = useState(false);
  const updateModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  const [bio, setBio] = useState('');
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    if (user) {
      setBio(user.bio);
      setFullName(user.fullname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [profilePicFile, setProfilePicFile] = useState<Blob>();

  const handleRemoveProfilePicture = async () => {
    if (user?.Profile_picture?.url) {
      try {
        setRemoving(true);
        const authenticatedAxiosInstance = await authenticatedRequest();
        const response = await authenticatedAxiosInstance.post(
          'user/remove_profile_picture',
          {
            users_id: user?.id,
          },
        );
        setMyInfo(response.data);
        queryClient.setQueryData(queryKeys.auth.me(), response.data);
      } catch (error: any) {
        showToast(error.toString());
      } finally {
        setRemoving(false);
      }
    }
  };

  const handleEditSubmit = () => {
    if (user) {
      updateProfile(
        {
          bio: bio.toString(),
          fullname: fullName.toString(),
          users_id: user.id,
          Profile_picture: profilePicFile,
        },
        {
          onSuccess: () => {
            navigation.goBack();
          },
          onError: (error: any) => {
            console.error('Error:', error);
          },
        },
      );
    }
  };

  return (
    <ScrollView
      style={globalstyles.container}
      contentContainerStyle={styles.contentContainer}
    >
      <TouchableOpacity
        style={{
          ...globalstyles.profileImage,
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          marginBottom: spacing.md,
        }}
        activeOpacity={0.9}
        onPress={useOpenImagePicker(setProfilePicFile)}
      >
        {user?.Profile_picture?.url || profilePicFile ? (
          <Image
            source={
              !profilePicFile
                ? { uri: user?.Profile_picture?.url }
                : { uri: URL.createObjectURL(profilePicFile) }
            }
            style={globalstyles.profileImage}
            transition={250}
          />
        ) : (
          <View
            style={{
              ...globalstyles.profileImage,
              ...globalstyles.centeringContainer,
              backgroundColor: colors.primary_offset,
            }}
          >
            <FontAwesomeIcon
              icon={faImage}
              size={24}
              color={colors.secondary}
            />
          </View>
        )}
      </TouchableOpacity>
      <View style={{ marginLeft: spacing.md, marginBottom: spacing.md }}>
        <Text style={globalstyles.smallfade}> Aspect ratio: 1:1</Text>
      </View>
      <Button_Settings
        callback={useOpenImagePicker(setProfilePicFile)}
        title={'Select new profile image'}
        icon={faImage}
        bgColor={colors.primary_offset}
        marginBottom
      />
      {user?.Profile_picture?.url && (
        <Button_Settings
          callback={updateModalVisible}
          title={'Remove profile image'}
          icon={removing ? faSpinner : faTrash}
          iconColor={
            user?.Profile_picture?.url ? colors.alert : colors.secondary
          }
          bgColor={colors.primary_offset}
          marginBottom
        />
      )}
      <Button_Settings
        callback={() => navigation.navigate('EditBanner')}
        title={'Edit banner'}
        icon={faFlag}
        bgColor={colors.primary_offset}
        marginBottom
      />
      <Button_Settings
        callback={() => navigation.navigate('EditSocialLinks')}
        title={'Edit social media links'}
        icon={faLink}
        bgColor={colors.primary_offset}
        marginBottom
      />
      <View style={{ marginBottom: spacing.md }}>
        <Button_Settings
          callback={() => navigation.navigate('EditUsername')}
          title={'Edit username'}
          icon={faAt}
          bgColor={colors.primary_offset}
          marginBottom
        />
      </View>
      <InputBox_WithLabel
        placeholderText={'Your bio here'}
        handleInputChange={setBio}
        autocapitalize={'sentences'}
        maxLength={100}
        multiline
        value={bio}
        label={'Edit your bio'}
        bgColor={colors.primary_offset}
      />
      <InputBox_WithLabel
        placeholderText={'Full name'}
        handleInputChange={setFullName}
        autocapitalize={'sentences'}
        maxLength={50}
        value={fullName}
        label={'Edit your name'}
        bgColor={colors.primary_offset}
      />
      <View style={{ marginVertical: spacing.md, alignSelf: 'center' }}>
        <Button_Submission
          callback={handleEditSubmit}
          title={'Confirm'}
          loading={isLoading}
        />
      </View>
      <View style={{ height: headerHeight * 2, width: 1 }} />
      <Modal_TwoOptions
        cancel={updateModalVisible}
        isVisible={modalVisible}
        title={'Remove banner?'}
        callback1={handleRemoveProfilePicture}
        callback2={updateModalVisible}
        text1={'Confirm, remove'}
        icon1={faTrash}
        iconColor1={colors.alert}
        text2={'Nevermind'}
        icon2={faXmark}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    height: heightWithHeader,
    paddingTop: spacing.md,
    paddingBottom: 96,
    alignSelf: 'center',
    maxWidth: webContentWidth * 2,
  },
});

import React from 'react';
import { View, Pressable } from 'react-native';
import { Comment, Post } from '@/api/zod';
import { colors } from '@/app/colors';
import { ParamListBase } from '@react-navigation/native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import Modal from 'react-native-modal';
import { ModalHeader } from '@/components/ModalHeader';
import { Button_Settings } from '@/components/shared/Button_Settings';
import { StackNavigationProp } from '@react-navigation/stack';

interface CommentActionModalProps {
  users_id: number | undefined;
  comment: Comment;
  updateCommentActionModalVisible: () => void;
  updateCommentsModalVisible: () => void;
  handleDeleteComment: (comment: Comment) => void;
  commentActionModalVisible: boolean;
  navigation: StackNavigationProp<ParamListBase>;
  post: Post;
}

export const CommentActionModal = ({
  users_id,
  comment,
  updateCommentActionModalVisible,
  handleDeleteComment,
  commentActionModalVisible,
  updateCommentsModalVisible,
  navigation,
  post,
}: CommentActionModalProps) => {
  const handleReport = () => {
    navigation.navigate('Report', {
      comments_id: comment.id,
      reporting_user_id: users_id,
      reported_user_id: comment.users_id,
    });
    updateCommentActionModalVisible();
    updateCommentsModalVisible();
  };

  const handleNavigateToEdit = () => {
    navigation.navigate('EditComment', {
      comment: comment,
    });
    updateCommentActionModalVisible();
    updateCommentsModalVisible();
  };

  return (
    <Modal
      isVisible={commentActionModalVisible}
      avoidKeyboard={true}
      hideModalContentWhileAnimating={true}
      style={{ margin: 0 }}
    >
      <Pressable
        style={{ ...globalstyles.container, justifyContent: 'center' }}
        onPress={updateCommentActionModalVisible}
      >
        <View style={{ ...globalstyles.modalContent, marginTop: spacing.md }}>
          <ModalHeader
            callback={updateCommentActionModalVisible}
            title={'Comment action'}
          />
          {users_id === comment.users_id || users_id === post.users_id ? (
            <Button_Settings
              callback={() => handleDeleteComment(comment)}
              title={'Delete comment (no take backs)'}
              icon={faTrash}
              iconColor={colors.alert}
            />
          ) : null}
          {users_id === comment.users_id ? (
            <Button_Settings
              callback={handleNavigateToEdit}
              title={'Edit comment'}
              icon={faPen}
            />
          ) : null}
          <Button_Settings
            callback={handleReport}
            title={'Report'}
            icon={faFile}
          />
          <Button_Settings
            callback={updateCommentActionModalVisible}
            title={'Cancel'}
            icon={faTimes}
          />
        </View>
      </Pressable>
    </Modal>
  );
};

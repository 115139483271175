import { useMyProfileStore } from '@/app/store/store';
import Modal_SignIn from '@/components/unauthenticated/Modal_SignIn';
import React, { useState, ReactElement } from 'react';

function withAuthPopup<P extends object>(
  WrappedComponent: React.ComponentType<
    P & { isVisitor?: boolean; toggleModal?: () => void }
  >,
): React.FC<P> {
  return function (props): ReactElement {
    const [visible, setVisible] = useState<boolean>(false);
    const myInfo = useMyProfileStore((state) => state.myInfo);

    const toggleModal = () => {
      setVisible(!visible);
    };

    return (
      <>
        <WrappedComponent
          {...props}
          isVisitor={myInfo?.isVisitor}
          toggleModal={toggleModal}
        />
        <Modal_SignIn
          isVisible={visible}
          cancel={toggleModal}
          toggleModal={toggleModal}
        />
      </>
    );
  };
}

export default withAuthPopup;

import { colors } from '@/app/colors';
import { globalstyles, spacing } from '@/app/globalstyles';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useState } from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';

interface Props {
  navigation: StackNavigationProp<ParamListBase>;
}

export const Layout_PageNotFound = ({ navigation }: Props) => {
  const [hover, setHover] = useState(false);
  const headHome = () => {
    navigation.push('Explore', { passedTag: undefined });
  };
  return (
    <View style={styles.noUser}>
      <View style={globalstyles.centeringContainer}>
        <Text style={globalstyles.logoText}>Oops!</Text>
      </View>
      <Pressable
        style={styles.headHome}
        onPress={headHome}
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}
      >
        <Text style={{ ...globalstyles.title }}>
          Page not found.{' '}
          <Text style={{ color: hover ? colors.accent : colors.secondary }}>
            Head home.
          </Text>
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  noUser: {
    ...globalstyles.container,
    height: '100%',
    justifyContent: 'center',
  },
  headHome: {
    ...globalstyles.centeringContainer,
    paddingVertical: spacing.md,
  },
});

import React, { useState } from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';
import { colors } from '@/app/colors';
import { globalstyles, radius, spacing } from '@/app/globalstyles';

interface Props {
  title: string;
  bgColor?: string;
  bgHover?: string;
  bgHighlight?: string;
  brightText?: boolean;
  callback: () => void;
  roundie?: boolean;
}

export const Button_HotDog = ({
  title,
  callback,
  brightText,
  bgColor,
  bgHover,
  bgHighlight,
  roundie,
}: Props) => {
  const [hover, setHover] = useState(false);
  const [pressed, setPressed] = useState(false);
  const passedbgColor = bgColor ? bgColor : colors.primary;
  const passedbgHighlight = bgHighlight ? bgHighlight : colors.primary_light;
  const passedbgHover = bgHover ? bgHover : colors.primary_accent;
  const backgroundColor = hover
    ? passedbgHover
    : pressed
    ? passedbgHighlight
    : passedbgColor;

  return (
    <Pressable
      style={{
        ...styles.button,
        backgroundColor,
        borderRadius: !roundie ? radius.xs : radius.lg,
      }}
      onPress={callback}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
    >
      <Text
        style={{
          ...globalstyles.usernameText,
          opacity: hover || brightText ? 1 : 0.2,
        }}
      >
        {title}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.sm,
  },
});

import React, { useEffect, useState } from 'react';
import {
  // Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  SafeAreaView,
} from 'react-native';
import { colors } from '@/app/colors';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { authenticatedRequest } from '@/modules/auth';
import Toast from 'react-native-toast-message';
import { showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import { globalstyles, heightWithHeader } from '@/app/globalstyles';
import { BaseTheme, EMPTYSTATETHEMEINFO } from '@/api/zod';
import { JoinCard } from '@/components/JoinCard';

export function DailyThemeArchivesIndexScreen() {
  const { navigate } =
    useNavigation<AuthenticatedNavigatorProp<'DailyThemeArchivesIndex'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreThemes, setHasMoreThemes] = useState(true);
  const [themes, setThemes] = useState<BaseTheme[]>([EMPTYSTATETHEMEINFO]);

  const fetchThemes = async () => {
    if (!hasMoreThemes) {
      return;
    }

    setLoading(true);
    if (user) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `/daily_theme_event/list-all-finished-events/${user.id}/${page}`,
        );
        if (response.data.length > 0) {
          if (page === 1) {
            setPage(page + 1);
            setThemes(response.data);
          } else if (themes) {
            setPage(page + 1);
            setThemes([...themes, ...response.data]);
          }
        } else {
          setHasMoreThemes(false);
        }
      } catch (error) {
        showToast('Error fetching themes');
      }
    }
    setLoading(false);
  };

  const handleNavigateToTheme = (theme: BaseTheme) => {
    navigate('DailyThemeArchivesGallery', { daily_theme_event: theme });
  };

  useEffect(() => {
    fetchThemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTheme = ({ item }: { item: BaseTheme }) => (
    <TouchableOpacity>
      <JoinCard
        bgImageUrl={
          item.background_image_landscape
            ? item.background_image_landscape.url
            : item.background_image.url
        }
        title={item.title}
        date={item.submit_by}
        id={item.id}
        submitted={item.loggedInUserHasSubmitted}
        navigationCallback={() => handleNavigateToTheme(item)}
      />
    </TouchableOpacity>
  );

  const renderFooter = () => {
    return loading && hasMoreThemes ? (
      <View style={{ padding: 20 }}>
        <ActivityIndicator size="small" color={colors.secondary} />
      </View>
    ) : null;
  };

  const keyExtractor = (item: BaseTheme) => item.id.toString();

  return (
    <>
      <SafeAreaView style={globalstyles.container}>
        {themes[0]?.id !== 0 && (
          <FlatList
            data={themes}
            renderItem={renderTheme}
            keyExtractor={keyExtractor}
            style={{ height: heightWithHeader }}
            onEndReachedThreshold={0.8}
            onEndReached={fetchThemes}
            ListFooterComponent={renderFooter}
          />
        )}
      </SafeAreaView>
      <Toast />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator, FlatList, SafeAreaView } from 'react-native';
import { colors } from '@/app/colors';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { authenticatedRequest } from '@/modules/auth';
import Toast from 'react-native-toast-message';
import { EMPTYSTATEPOST, Post } from '@/api/zod';
import { showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import { globalstyles, heightWithHeader } from '@/app/globalstyles';
import { Modal_FullScreenImage } from '../Modal_FullScreenImage';
import { Thumbnail } from '@/components/shared/Thumbnail';

export function MySavedPostsScreen() {
  const { navigate } =
    useNavigation<AuthenticatedNavigatorProp<'MySavedPosts'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const [savedPosts, setSavedPosts] = useState<Post[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreSavedPosts, setHasMoreSavedPosts] = useState(true);

  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const [longPressedPost, setLongPressedPost] = useState<Post>(EMPTYSTATEPOST);

  const updateFullScreenModalVisible = (post: Post) => {
    setLongPressedPost(post);
    setFullScreenModalVisible(!fullScreenModalVisible);
  };

  const fetchSavedPosts = async () => {
    if (!hasMoreSavedPosts) {
      return;
    }

    setLoading(true);
    if (user) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `user_saved_posts/${user.id}/${page}`,
        );
        const filteredPosts = response.data.filter(
          (post: any) => post.posts_id,
        );
        if (filteredPosts.length > 0) {
          setPage(page + 1);
          setSavedPosts([...savedPosts, ...filteredPosts]);
        } else {
          setHasMoreSavedPosts(false);
        }
      } catch (error) {
        showToast('Error fetching saved posts');
      }
    }
    setLoading(false);
  };

  const handleSavedPostPress = (savedPost: Post) => {
    navigate('ViewSinglePost', {
      postId: savedPost.long_id,
      updateRootPostState: removePostFromList,
    });
  };

  const removePostFromList = (updatedPost: Post) => {
    const filteredPostsList = savedPosts.filter(
      (post) => post.id !== updatedPost.id,
    );

    setSavedPosts(filteredPostsList);
  };

  useEffect(() => {
    fetchSavedPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFooter = () => {
    return loading && hasMoreSavedPosts ? (
      <View style={{ padding: 20 }}>
        <ActivityIndicator size="small" color={colors.secondary} />
      </View>
    ) : null;
  };

  const addPlaceholders = (data: Post[]) => {
    if (data.length % 3 === 2) {
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
    } else if (data.length % 3 === 1) {
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
    }
    return data;
  };

  const postListWithPlaceholders = addPlaceholders(savedPosts);

  return (
    <>
      <SafeAreaView style={globalstyles.container}>
        <FlatList
          data={postListWithPlaceholders}
          keyExtractor={(post) => post.id.toString()}
          onEndReached={fetchSavedPosts}
          style={{ height: heightWithHeader }}
          onEndReachedThreshold={0.8}
          numColumns={3}
          ListFooterComponent={() => renderFooter()}
          renderItem={({ item }) => (
            <Thumbnail
              post={item}
              showCount={false}
              onPress={() => handleSavedPostPress(item)}
            />
          )}
        />
      </SafeAreaView>
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={() => updateFullScreenModalVisible(longPressedPost)}
        post={longPressedPost}
      />
      <Toast />
    </>
  );
}

import { useCallback } from 'react';
import * as ImagePicker from 'expo-image-picker';
import { authenticatedRequest } from '@/modules/auth';
import { OpenAIImageObject, Post, Technology } from './zod';
import { useMyProfileStore } from '@/app/store/store';
import { showToast } from '@/components/ErrorToast';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

export const useOpenImagePicker = (
  setFile: (file: Blob | undefined) => void,
) => {
  return useCallback(async () => {
    const pickerResult = await ImagePicker.launchImageLibraryAsync();

    if (pickerResult.canceled) {
      return;
    }

    const asset = pickerResult.assets[0];

    fetch(asset.uri)
      .then((res) => res.blob())
      .then((blob) => {
        setFile(blob);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const appendFileToFormData = (
  formData: FormData,
  key: string,
  file: Blob,
) => {
  formData.append(key, file, `${new Date().toISOString()}.png`);
};

export function useFileUpload() {
  const addNewPostToAllPostList = useMyProfileStore(
    (state) => state.addNewPostToAllPostList,
  );
  const addNewPostToDailyThemePostList = useMyProfileStore(
    (state) => state.addNewPostToDailyThemePostList,
  );
  // const [progress, setProgress] = useState(0); -- only relevant if we're using useMutate.

  const upload = useCallback(
    async (
      title: string,
      prompt: string,
      selectedTechnology: Technology,
      daily_theme_id: number | null,
      userID: number | undefined,
      allTags: string[],
      generated_image: OpenAIImageObject | undefined,
      file: Blob | undefined,
    ) => {
      if (!file && !generated_image) {
        throw new Error('No valid file selected');
      }

      if (userID !== undefined) {
        const formData = new FormData();
        if (file) {
          appendFileToFormData(formData, 'file', file);
        } else if (generated_image) {
          formData.append('images_id', generated_image.images_id.toString());
        }
        formData.append('users_id', userID.toString());
        formData.append('technology_type_id', selectedTechnology.id.toString());
        formData.append('title', title);
        formData.append('tags_json', JSON.stringify(allTags));
        formData.append('prompt', prompt);
        if (daily_theme_id) {
          formData.append('daily_theme_id', daily_theme_id.toString());
        }

        const response = await (
          await authenticatedRequest()
        ).post('post_2', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        });

        addNewPostToAllPostList(response.data);
        if (daily_theme_id) {
          addNewPostToDailyThemePostList(response.data);
        }

        return response;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = async (
    allTags: string[],
    isBoingle: boolean,
    submission_theme_id: number | null,
    title: string,
    prompt: string,
    selectedTechnology: Technology,
    my_id: number,
    username: string,
    generated_image: OpenAIImageObject | undefined,
    file: Blob | undefined,
    setLoading: (value: boolean) => void,
    setTitle: (value: string) => void,
    setPrompt: (value: string) => void,
    navigation: StackNavigationProp<ParamListBase>,
    updateSubmitted: (postId: number, long_id: number) => void,
    resetAfterSubmission: () => void,
  ) => {
    if (isBoingle) {
      allTags.push('boingle');
    }
    try {
      setLoading(true);
      const response = await upload(
        title,
        prompt,
        selectedTechnology,
        submission_theme_id,
        my_id,
        allTags,
        generated_image,
        file,
      );
      if (submission_theme_id) {
        updateSubmitted(response?.data.id, response?.data.long_id);
        // resetAfterSubmission();
      } else {
        resetAfterSubmission();
        navigation.push('UserProfile', { username: username });
      }
    } catch (error: any) {
      console.log('Error', error);
      showToast('Failed to post - try again');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (
    post: Post,
    title: string,
    prompt: string,
    selectedTechnology: Technology,
    allTags: string[],
    setLoading: (value: boolean) => void,
    navigation: StackNavigationProp<ParamListBase>,
    updatePostInGlobalLists: (post: Post) => void,
  ) => {
    try {
      setLoading(true);
      const authenticatedAxiosInstance = await authenticatedRequest();
      const updatedPost = await authenticatedAxiosInstance.post(
        `post_2/${post.id}`,
        {
          posts_id: post.id,
          title: title,
          prompt: prompt,
          technology_type_id: selectedTechnology.id,
          tags_json: JSON.stringify(allTags),
        },
      );
      updatePostInGlobalLists(updatedPost.data);
      navigation.goBack();
    } catch (error: any) {
      console.log(error);
      showToast(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return {
    upload,
    handleSubmit,
    handleEdit,
    // progress,
  };
}

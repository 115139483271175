import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  View,
  StyleSheet,
  TextInput,
  KeyboardTypeOptions,
  Text,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import { colors } from '@/app/colors';
import {
  font,
  radius,
  spacing,
  border,
  maxWebContentWidth,
} from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';

type Props = {
  setter: Dispatch<SetStateAction<string>>;
  value: string;
  placeholderText: string;
  handleInputChange?: (
    setter: Dispatch<SetStateAction<string>>,
  ) => (text: string) => void;
  keyboardType?: KeyboardTypeOptions;
  maxLength?: number;
  isPassword?: boolean;
  secureTextEntry?: boolean;
  updateSecureTextEntry?: () => void;
  invalid: boolean;
  invalidText: string;
  editable?: boolean;
  loading?: boolean;
  showLoading?: boolean;
};

export function InputBox_SignUp({
  handleInputChange,
  setter,
  value,
  placeholderText,
  keyboardType,
  maxLength,
  isPassword,
  secureTextEntry,
  updateSecureTextEntry,
  invalid,
  invalidText,
  editable,
  loading,
  showLoading,
}: Props) {
  const [hover, setHover] = useState(false);
  return (
    <>
      <Pressable
        style={{
          ...styles.inputWrapper,
          marginBottom: spacing.sm,
          backgroundColor: hover
            ? colors.primary_offset_2
            : colors.primary_offset,
          borderColor: hover ? colors.primary_light : colors.primary_offset_2,
        }}
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}
      >
        <TextInput
          placeholder={placeholderText}
          placeholderTextColor={colors.halfwhite}
          keyboardType={keyboardType ? keyboardType : 'default'}
          secureTextEntry={secureTextEntry}
          autoCapitalize="none"
          autoCorrect={false}
          style={styles.input}
          maxLength={maxLength ? maxLength : 254}
          value={value}
          editable={editable ? editable : true}
          onChangeText={handleInputChange ? handleInputChange(setter) : setter}
        />
        {isPassword ? (
          <Pressable
            style={{ marginRight: spacing.md }}
            onPress={updateSecureTextEntry}
          >
            <FontAwesomeIcon
              icon={faEye}
              color={secureTextEntry ? colors.halfwhite : colors.accent}
              size={16}
            />
          </Pressable>
        ) : null}
        {showLoading ? (
          <View
            style={{ paddingHorizontal: spacing.sm, opacity: loading ? 1 : 0 }}
          >
            <ActivityIndicator size={'small'} color={colors.secondary} />
          </View>
        ) : null}
      </Pressable>
      {invalid && invalidText ? (
        <View style={styles.invalidWrapper}>
          <Text style={styles.invalidText}>{invalidText}</Text>
        </View>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  inputWrapper: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: maxWebContentWidth / 3, // change?
    borderRadius: radius.sm,
    borderWidth: border.xs,
  },
  input: {
    color: colors.secondary,
    padding: spacing.md,
    width: '100%',
    fontSize: font.lg,
    borderRadius: radius.md,
  },
  invalidWrapper: {
    paddingBottom: spacing.sm,
    alignSelf: 'center',
  },
  invalidText: {
    color: colors.high_accent,
  },
});

import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { spacing } from '@/app/globalstyles';
import { InputBox_SignUp } from '../InputBox_SignUp';
import { colors } from '@/app/colors';
import { AuthButton } from '../AuthButton';
import { useCheckUsername } from '@/api/useCheckUsername';

interface Props {
  proceed: (username: string) => void;
  creatingUser: boolean;
}

export const Layout_SignUpStep2 = ({ proceed, creatingUser }: Props) => {
  const {
    handleInputChange,
    usernameTaken,
    validUsername,
    searchQuery,
    setSearchQuery,
    searching,
  } = useCheckUsername();

  const tryToProceed = () => {
    proceed(searchQuery);
  };

  // HELPERS.
  const readyToProceed = !usernameTaken && validUsername && searchQuery !== '';
  const invalidText = usernameTaken
    ? 'That username is already taken.'
    : !validUsername
    ? 'Invalid username'
    : 'Good to go!';

  return (
    <View>
      <InputBox_SignUp
        value={searchQuery}
        handleInputChange={handleInputChange}
        setter={setSearchQuery}
        placeholderText={'Create username'}
        maxLength={30}
        invalid={searchQuery !== ''}
        invalidText={invalidText}
        loading={searching}
        showLoading
      />
      <AuthButton
        logo={''}
        callback={readyToProceed ? tryToProceed : () => null}
        text={'Create account'}
        disabled={!readyToProceed}
        bgColor={readyToProceed ? colors.accent : colors.primary_accent}
        bgHover={
          readyToProceed ? colors.accent_highlight : colors.primary_accent
        }
        sideblanks
      />
      <View
        style={{
          ...styles.spinner,
          opacity: creatingUser ? 1 : 0,
        }}
      >
        <ActivityIndicator size={'small'} color={colors.secondary} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  spinner: {
    alignSelf: 'center',
    paddingHorizontal: spacing.sm,
    paddingVertical: spacing.lg,
  },
});

import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { globalstyles, maxWebContentWidth, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

type Props = {
  callback: () => void;
  title: string;
  icon: IconDefinition;
  bgColor?: string;
  iconColor?: string;
  textColor?: string;
  disabled?: boolean;
  marginBottom?: boolean;
};

export function Button_Settings({
  callback,
  title,
  icon,
  bgColor,
  iconColor,
  textColor,
  disabled,
  marginBottom,
}: Props) {
  return (
    <TouchableOpacity
      style={{
        ...globalstyles.settingsButton,
        marginBottom: marginBottom ? spacing.sm : undefined,
        backgroundColor: bgColor ? bgColor : undefined,
        width: maxWebContentWidth / 2,
      }}
      activeOpacity={0.9}
      disabled={disabled}
      onPress={callback}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text
          style={{
            ...globalstyles.label,
            color: textColor ? textColor : colors.secondary,
          }}
        >
          {title}
        </Text>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <FontAwesomeIcon
          icon={icon}
          color={iconColor ? iconColor : colors.secondary}
          size={20}
        />
      </View>
    </TouchableOpacity>
  );
}

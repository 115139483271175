import React from 'react';
import { Pressable, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import Button_NavLinkPost from './Button_NavLinkPost';
import { Button_HeaderNavLink } from './Button_HeaderNavLink';

interface HeaderProps {
  hoverInCallback: () => void;
}

export function WebHeaderLeft({ hoverInCallback }: HeaderProps) {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Home'>>();
  const goToPost = () => {
    navigation.navigate('CreatePost', {
      submission_theme_id: null,
      passedExistingTags: [],
    });
  };

  return (
    <Pressable
      style={{ alignItems: 'center', flexDirection: 'row', zIndex: 4 }}
      onHoverIn={hoverInCallback}
    >
      <Pressable
        onPress={() => navigation.navigate('Explore', { passedTag: undefined })}
        onHoverIn={hoverInCallback}
        style={{ margin: spacing.lg }}
      >
        <Text style={{ ...globalstyles.logoTextSmall }}>{'Whirl'}</Text>
      </Pressable>
      <Button_HeaderNavLink
        text={'Explore'}
        hoverInCallback={hoverInCallback}
        callback={() =>
          navigation.navigate('Explore', { passedTag: undefined })
        }
        activeRoute="Explore"
      />
      <Button_HeaderNavLink
        text={'Daily theme'}
        hoverInCallback={hoverInCallback}
        callback={() => navigation.navigate('DailyTheme')}
        activeRoute="DailyTheme"
      />
      <Button_NavLinkPost
        hoverInCallback={hoverInCallback}
        callback={goToPost}
      />
    </Pressable>
  );
}

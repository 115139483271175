import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Pressable, Animated } from 'react-native';
import Modal from 'react-native-modal';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faFlag,
  faPencil,
  faShare,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Post, ProfileResponse } from '@/api/zod';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import { Layout_Post } from './Layout_Post';
import { colors } from '@/app/colors';
import { useComment } from '@/api/useComment';
import Modal_TwoOptions from './Modal_TwoOptions';
import { Button_ModalClose } from './Button_ModalClose';
import Modal_SharePost from './Modal_SharePost';

interface PostModalProps {
  post: Post;
  setPressedItem: (post: Post) => void;
  myInfo: ProfileResponse | null;
  editable: boolean;
  isAnonymous?: boolean;
  onClose: () => void;
  like: () => void;
  save: () => void;
  next: () => void;
  previous: () => void;
  isVisible: boolean;
  imageFadeAnim: any;
  navigation: StackNavigationProp<ParamListBase>;
  commentsVisible: boolean;
  setCommentsVisible: (b: boolean) => void;
}

export const Modal_Post = ({
  post,
  setPressedItem,
  myInfo,
  isVisible,
  editable,
  isAnonymous,
  onClose,
  navigation,
  like,
  save,
  next,
  previous,
  imageFadeAnim,
  commentsVisible,
  setCommentsVisible,
}: PostModalProps) => {
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [shareHover, setShareHover] = useState(false);
  const goToUser = () => {
    handleClose();
    navigation.push('UserProfile', {
      username: post.username,
    });
  };
  const {
    fetchComments,
    comments,
    loading,
    newCommentLoading,
    hasMoreComments,
    reset,
    createComment,
    deleteComment,
    createReply,
    likeCommentOrReply,
  } = useComment();
  const toggleComment = () => {
    if (!commentsVisible) {
      myInfo && fetchComments(post, myInfo.id);
    }
    setCommentsVisible(!commentsVisible);
  };
  const handleClose = () => {
    onClose();
    setSettingsModalVisible(false);
    setTimeout(resetComment, 250);
  };
  const resetComment = () => {
    reset();
    setCommentsVisible(false);
  };
  const handleNext = () => {
    next();
    setTimeout(reset, 100);
  };
  const handlePrevious = () => {
    previous();
    setTimeout(reset, 100);
  };
  const togglePostSettings = () => {
    setSettingsModalVisible(!settingsModalVisible);
  };
  const toggleShare = () => {
    setShareModalVisible(!shareModalVisible);
  };
  const handlePressSettings = () => {
    setSettingsModalVisible(false);
    handleClose();
    if (!editable) {
      navigation.push('Report', {
        post: post,
        reporting_user_id: myInfo?.id,
        reported_user_id: post.users_id,
      });
    } else {
      navigation.navigate('EditPost', {
        post: post,
        updateLocalPostList: setPressedItem,
      });
    }
  };
  const fadeAnim = useState(new Animated.Value(0))[0];
  const translateY = useState(new Animated.Value(50))[0];

  useEffect(() => {
    if (isVisible) {
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
        Animated.timing(translateY, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      fadeAnim.setValue(0);
      translateY.setValue(50);
    }
  }, [isVisible, fadeAnim, translateY]);
  return (
    <Modal
      isVisible={isVisible}
      style={styles.modal}
      onBackdropPress={handleClose}
      backdropOpacity={0.9}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
    >
      <Button_ModalClose close={handleClose} />
      <View style={styles.modalContentWrap}>
        <Animated.View
          style={[
            styles.modalContentWrap,
            {
              opacity: fadeAnim,
              transform: [{ translateY }],
            },
          ]}
        >
          <View style={styles.contentContainer}>
            <Layout_Post
              post={post}
              goToUser={goToUser}
              like={like}
              save={save}
              isAnonymous={isAnonymous}
              navigation={navigation}
              toggleComment={toggleComment}
              fetchComments={fetchComments}
              likeCommentOrReply={likeCommentOrReply}
              commentsVisible={commentsVisible}
              comments={comments}
              loading={loading}
              hasMoreComments={hasMoreComments}
              createComment={createComment}
              deleteComment={deleteComment}
              createReply={createReply}
              setPressedItem={setPressedItem}
              newCommentLoading={newCommentLoading}
              togglePostSettings={togglePostSettings}
              handleClose={handleClose}
              fadeAnim={imageFadeAnim}
            />
          </View>
        </Animated.View>
        <View style={styles.toggleButtonContainer}>
          <Pressable
            style={{
              ...styles.shareButton,
              backgroundColor: shareHover
                ? colors.primary_offset_2
                : colors.primary_offset,
            }}
            onPress={toggleShare}
            onHoverIn={() => setShareHover(true)}
            onHoverOut={() => setShareHover(false)}
          >
            <FontAwesomeIcon
              icon={faShare}
              size={spacing.md}
              color={colors.secondary}
            />
          </Pressable>
          <Pressable style={styles.toggleButton} onPress={handlePrevious}>
            <FontAwesomeIcon
              icon={faChevronUp}
              size={spacing.md}
              color={colors.secondary}
            />
          </Pressable>
          <Pressable style={styles.toggleButton} onPress={handleNext}>
            <FontAwesomeIcon
              icon={faChevronDown}
              size={spacing.md}
              color={colors.secondary}
            />
          </Pressable>
        </View>
      </View>
      <Modal_SharePost
        isVisible={shareModalVisible}
        cancel={toggleShare}
        url_ending={post.long_id.toString()}
      />
      <Modal_TwoOptions
        cancel={() => setSettingsModalVisible(false)}
        isVisible={settingsModalVisible}
        callback1={handlePressSettings}
        text1={editable ? 'Edit post' : 'Report post'}
        icon1={editable ? faPencil : faFlag}
        iconColor1={colors.high_accent}
        callback2={() => setSettingsModalVisible(false)}
        text2={'Back'}
        icon2={faTimes}
      />
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    ...globalstyles.container,
    padding: spacing.md,
    borderRadius: radius.sm,
  },
  modalContentWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleButtonContainer: {
    margin: spacing.md,
  },
  toggleButton: {
    padding: spacing.md,
    marginVertical: spacing.sm,
    backgroundColor: colors.primary_accent,
    borderRadius: radius.xl,
  },
  shareButton: {
    padding: spacing.md,
    marginBottom: spacing.sm,
    borderRadius: radius.xl,
  },
});

import { colors } from '@/app/colors';
import { globalstyles, maxWebContentWidth, spacing } from '@/app/globalstyles';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

interface Props {
  navigation: StackNavigationProp<ParamListBase>;
  toggleModal: () => void;
}

export function Text_PrivacyAndTerms({ navigation, toggleModal }: Props) {
  const goTo = (text: string) => {
    navigation.push(text);
    toggleModal();
  };
  return (
    <View style={styles.baseText}>
      <Text style={{ ...globalstyles.tinyfade, textAlign: 'center' }}>
        By continuing you agree to Whirl's
        <Text onPress={() => goTo('TermsOfUse')} style={styles.link}>
          {' '}
          Terms of Use{' '}
        </Text>
        and confirm that you have read Whirl's
        <Text onPress={() => goTo('Privacy')} style={styles.link}>
          {' '}
          Privacy Policy
        </Text>
        .
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  baseText: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: maxWebContentWidth / 3 - spacing.xl,
    paddingTop: spacing.sm,
  },
  link: {
    color: colors.accent,
  },
});

import { EMPTYSTATEPOST, Post } from '@/api/zod';
import { globalstyles, heightWithHeader } from '@/app/globalstyles';
import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest } from '@/modules/auth';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { useMyProfileStore } from '@/app/store/store';
import { ThemeSubtitle } from '@/components/ThemeSubtitle';
import { Modal_FullScreenImage } from '../Modal_FullScreenImage';
import { colors } from '@/app/colors';
import { Thumbnail } from '@/components/shared/Thumbnail';

export function DailyThemeArchivesGalleryScreen() {
  const {
    params: { daily_theme_event },
  } = useRoute<AuthenticatedNavigatorRouteProp<'DailyThemeArchivesGallery'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'DailyThemeArchivesGallery'>>();
  const [postList, setPostList] = useState<Post[]>([]);
  const [pressedItem, setPressedItem] = useState<Post>(EMPTYSTATEPOST);
  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [loading, setLoading] = useState(false);

  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const [longPressedPost, setLongPressedPost] = useState<Post>(EMPTYSTATEPOST);

  const updateFullScreenModalVisible = (post: Post) => {
    setLongPressedPost(post);
    setFullScreenModalVisible(!fullScreenModalVisible);
  };

  const updateRootPostState = (updatedPost: Post) => {
    setPostList((prevState) => {
      const index = prevState.findIndex((p) => p.id === updatedPost.id);
      if (index !== -1) {
        return [
          ...prevState.slice(0, index),
          updatedPost,
          ...prevState.slice(index + 1),
        ];
      } else {
        return prevState;
      }
    });
  };

  async function fetchPosts() {
    if (!hasMorePosts) {
      return;
    }
    setLoading(true);
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `daily_theme_event_posts/finished-theme-posts/${daily_theme_event.id}/${user?.id}/${page}/0`,
      );
      if (response.data.length > 0) {
        setPage(page + 1);
        setPostList([...postList, ...response.data]);
      } else {
        setHasMorePosts(false);
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching posts');
    } finally {
      setLoading(false);
      if (!mounted) {
        setMounted(true);
      }
    }
  }

  useEffect(() => {
    if (mounted) {
      if (user) {
        const queryParams = [
          `daily_theme_event_posts/finished-theme-posts/${daily_theme_event.id}`,
          (postList.indexOf(pressedItem) + 1).toString(),
        ];
        navigation.push('ViewEphemeralPosts', {
          posts: [pressedItem],
          updateRootPostState: updateRootPostState,
          queryParams: queryParams,
        });
      }
    }
    setPressedItem(EMPTYSTATEPOST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedItem]);

  const handlePress = (item: Post) => {
    setPressedItem(item);
  };

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  const addPlaceholders = (data: Post[]) => {
    if (data.length % 3 === 2) {
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
    } else if (data.length % 3 === 1) {
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
    }
    return data;
  };

  const postListWithPlaceholders = addPlaceholders(postList);

  const renderHeader = () => {
    return (
      <>
        {daily_theme_event.background_image.url && (
          <ThemeSubtitle
            pretitle={`${daily_theme_event.total_posts} posts in the theme of `}
            title={daily_theme_event.title}
          />
        )}
      </>
    );
  };

  const renderFooter = () => {
    return loading && hasMorePosts ? (
      <View style={{ padding: 20 }}>
        <ActivityIndicator size="large" color={colors.halfwhite} />
      </View>
    ) : null;
  };

  return (
    <>
      <View style={globalstyles.container}>
        <FlatList
          data={postListWithPlaceholders}
          keyExtractor={(post) => post.id.toString()}
          numColumns={3}
          onEndReached={() => {
            fetchPosts();
          }}
          style={{ height: heightWithHeader }}
          onEndReachedThreshold={0.1}
          ListHeaderComponent={renderHeader}
          ListFooterComponent={renderFooter}
          renderItem={({ item }) => (
            <Thumbnail
              post={item}
              showCount={true}
              onPress={() => handlePress(item)}
            />
          )}
        />
      </View>
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={() => updateFullScreenModalVisible(longPressedPost)}
        post={longPressedPost}
      />
      <Toast />
    </>
  );
}

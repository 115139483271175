import React, { useState } from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import { ProfileResponse } from '@/api/zod';
import { globalstyles, spacing } from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import Modal_ShareProfile from './Modal_ShareProfile';
import { colors } from '@/app/colors';

interface ProfileUsernameBioProps {
  activeUser: ProfileResponse;
}

export const ProfileUsernameBio = ({ activeUser }: ProfileUsernameBioProps) => {
  const [visible, setVisible] = useState(false);
  const [hover, setHover] = useState(false);
  return (
    <View style={styles.headingContainer}>
      <View style={styles.usernameContainer}>
        <Text style={globalstyles.usernameTextLarge}>
          @{activeUser.username}
        </Text>
        <Pressable
          style={styles.shareButton}
          onPress={() => setVisible(true)}
          onHoverIn={() => setHover(true)}
          onHoverOut={() => setHover(false)}
        >
          <FontAwesomeIcon
            style={{ marginRight: spacing.md }}
            icon={faShare}
            size={16}
            color={hover ? colors.secondary : colors.halfwhite}
          />
        </Pressable>
      </View>
      {!!activeUser.bio && (
        <View style={styles.bioContainer}>
          <Text style={globalstyles.body}>{activeUser.bio}</Text>
        </View>
      )}
      <Modal_ShareProfile
        username={activeUser.username}
        isVisible={visible}
        cancel={() => setVisible(false)}
        showCancelButton
      />
    </View>
  );
};

const styles = StyleSheet.create({
  headingContainer: {
    paddingBottom: spacing.md,
    alignItems: 'center',
    width: '100%',
  },
  bioContainer: {
    paddingVertical: spacing.md,
  },
  usernameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  shareButton: {
    paddingLeft: spacing.md,
  },
});

import React, { useState } from 'react';
import { View, StyleSheet, Text, Pressable } from 'react-native';
import { border, globalstyles, radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import ModalHOC from '../shared/hoc/ModalHOC';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import * as Clipboard from 'expo-clipboard';
import { useMyProfileStore } from '@/app/store/store';

interface Props {
  username: string;
}

const Modal_Content = ({ username }: Props) => {
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const shareText =
    myInfo?.username === username ? 'Share my profile' : `Share @${username}`;
  const [hover, setHover] = useState(false);
  const full_url = `https://joinwhirl.com/${username}`;
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    Clipboard.setStringAsync(full_url);
    setCopied(true);
  };
  return (
    <Pressable style={styles.wrapper}>
      <View style={{ paddingBottom: spacing.md }}>
        <Text style={{ ...globalstyles.title }}>{shareText}</Text>
      </View>
      <View style={styles.copyContainer}>
        <Pressable
          style={{
            ...styles.button,
            backgroundColor: hover
              ? colors.primary_offset_2
              : colors.primary_offset,
          }}
          onPress={copyToClipboard}
          onHoverIn={() => setHover(true)}
          onHoverOut={() => setHover(false)}
        >
          <FontAwesomeIcon
            style={{ marginRight: spacing.md }}
            icon={copied ? faCheck : faCopy}
            size={16}
            color={colors.secondary}
          />
          <Text style={globalstyles.label}>
            {copied ? 'Copied!' : 'Copy link'}
          </Text>
        </Pressable>
        <View style={styles.textContainer}>
          <Text style={globalstyles.body}>{full_url}</Text>
        </View>
      </View>
    </Pressable>
  );
};

const Modal_ShareProfile = ModalHOC(Modal_Content);

export default Modal_ShareProfile;

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
  copyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderRadius: radius.sm,
    backgroundColor: colors.primary_offset,
    justifyContent: 'space-between',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing.md,
    borderRightColor: colors.primary_offset_2,
    borderRightWidth: border.sm,
    borderTopLeftRadius: radius.sm,
    borderBottomLeftRadius: radius.sm,
  },
  textContainer: {
    ...globalstyles.container,
    backgroundColor: colors.primary_offset,
    borderTopRightRadius: radius.sm,
    padding: spacing.md,
    borderBottomRightRadius: radius.sm,
  },
});

import React, { useEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import {
  globalstyles,
  heightWithHeader,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { OpenAIImageObject } from '@/api/zod';
import { useStudioStore } from '@/app/store/studioStore';
import { colors } from '@/app/colors';
import { debounce } from 'lodash';
import { Modal_FullScreenImage } from '../Modal_FullScreenImage';
import { addPlaceholders, toggleStarImage } from '@/api/studio';
import { RenderGalleryImage } from './RenderGalleryImage';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { useMyProfileStore } from '@/app/store/store';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { InputBox_WithSubmit } from '@/components/InputBox_WithSubmit';
import { ImageGenGalleryPromptHeader } from '@/components/studio/ImageGenGalleryPromptHeader';

const numbers = Array.from({ length: 4 }, (_, i) => i + 1);

export function PublicGalleryScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const [numColumns, setNumColumns] = useState(4);
  const [filterStarred, setFilterStarred] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [localQueryText, setLocalQueryText] = useState('');
  const [listKey, setListKey] = useState(Math.random().toString());
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const [fullScreenUrl, setFullScreenUrl] = useState('');

  const changeColumns = (newNumColumns: number) => {
    setNumColumns(newNumColumns);
    setListKey(Math.random().toString());
  };

  const publicGallery = useStudioStore((state) => state.publicGallery);
  const searchPublicImages = useStudioStore(
    (state) => state.searchPublicImages,
  );

  const updateImageInPublicGallery = useStudioStore(
    (state) => state.updateImageInPublicGallery,
  );
  const updateImageInMyGallery = useStudioStore(
    (state) => state.updateImageInMyGallery,
  );
  const loadingPublicGallery = useStudioStore(
    (state) => state.loadingPublicGallery,
  );
  const hasMorePublicGalleryImages = useStudioStore(
    (state) => state.hasMorePublicGalleryImages,
  );
  const fetchPublicGalleryImages = useStudioStore(
    (state) => state.fetchPublicGalleryImages,
  );
  const hasRemainingCredits = useStudioStore(
    (state) => state.hasRemainingCredits,
  );

  const handleTextChange = (text: string) => {
    setLocalQueryText(text);
  };

  const updateFullScreenModalVisible = async () => {
    setFullScreenModalVisible(!fullScreenModalVisible);
  };

  const handlePressStar = () => {
    setFilterStarred(!filterStarred);
    if (user) {
      if (localQueryText !== '') {
        try {
          setLoadingSearch(true);
          if (user) {
            searchPublicImages(localQueryText, !filterStarred, user?.id, true);
          }
          setLoadingSearch(false);
        } catch (error) {
          setLoadingSearch(false);
        }
      } else {
        fetchPublicGalleryImages(user.id, !filterStarred, true);
      }
    }
  };

  const toggleStar = (image: OpenAIImageObject) => {
    user && toggleStarImage(user.id, image);

    const updatedImage = {
      ...image,
      isStarred: !image.isStarred,
    };
    updateImageInPublicGallery(updatedImage);
    if (image.users_id === user?.id) {
      updateImageInMyGallery(updatedImage);
    }
  };

  const renderFooter = () => {
    return loadingPublicGallery && hasMorePublicGalleryImages ? (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="small" color={colors.halfwhite} />
      </View>
    ) : null;
  };

  const filter = debounce(async () => {
    if (localQueryText !== '') {
      try {
        setLoadingSearch(true);
        if (user) {
          searchPublicImages(localQueryText, filterStarred, user?.id, true);
        }
        setLoadingSearch(false);
      } catch (error) {
        setLoadingSearch(false);
      }
    } else {
      if (user) {
        fetchPublicGalleryImages(user.id, filterStarred, true);
      }
    }
  }, 500);

  useEffect(() => {
    filter();
    return () => {
      filter.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQueryText]);

  return (
    <>
      <KeyboardAwareScrollView
        style={globalstyles.container}
        keyboardShouldPersistTaps="handled"
        enableOnAndroid
      >
        {user && (
          <FlatList
            data={addPlaceholders(publicGallery, numColumns)}
            numColumns={numColumns}
            contentContainerStyle={{
              alignItems: 'center',
              paddingBottom: webContentWidth,
            }}
            style={{ height: heightWithHeader }}
            renderItem={({ index, item }) => (
              <RenderGalleryImage
                item={item}
                index={index}
                numColumns={numColumns}
                setFullScreenUrl={setFullScreenUrl}
                toggleStar={toggleStar}
                updateFullScreenModalVisible={updateFullScreenModalVisible}
                hasRemainingCredits={hasRemainingCredits}
              />
            )}
            onEndReached={() =>
              fetchPublicGalleryImages(user.id, filterStarred, false)
            }
            onEndReachedThreshold={0.8}
            key={listKey}
            keyExtractor={(item) => item.id.toString()}
            ListFooterComponent={renderFooter}
            showsVerticalScrollIndicator={false}
          />
        )}
        <View style={globalstyles.promptInputContainer}>
          <View style={{ alignItems: 'center', alignSelf: 'center' }}>
            <ImageGenGalleryPromptHeader
              columns={numbers}
              handlePressStar={handlePressStar}
              filterStarred={filterStarred}
              numColumns={numColumns}
              changeColumns={changeColumns}
            />
            <InputBox_WithSubmit
              placeholderText={'Search text prompt'}
              handleInputChange={handleTextChange}
              autocapitalize={'sentences'}
              multiline={false}
              isLoading={loadingSearch}
              maxLength={400}
              value={localQueryText}
              submitCallback={() => null}
              submitDisabled={true}
              submitIcon={faMagnifyingGlass}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={updateFullScreenModalVisible}
        bare_link={fullScreenUrl}
      />
    </>
  );
}

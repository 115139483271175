import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useCommentStore } from '@/app/store/commentStore';
import { Button_Submission } from '@/components/Button_Submission';
import { spacing } from '@/app/globalstyles';
import { InputBox_WithLabel } from '@/components/InputBox_WithLabel';

export function EditCommentScreen() {
  const {
    params: { comment },
  } = useRoute<AuthenticatedNavigatorRouteProp<'EditComment'>>();
  const navigation = useNavigation<AuthenticatedNavigatorProp<'EditComment'>>();
  const updateComment = useCommentStore((state) => state.updateComment);
  const loading = useCommentStore((state) => state.loading);
  const [editedComment, setEditedComment] = useState(comment.body);

  useCommentStore((state) => state.setLoading)();
  const isUnchanged = editedComment === comment.body;

  const handleSubmit = async () => {
    updateComment(comment, editedComment);
    setEditedComment('');
    navigation.goBack();
  };

  return (
    <KeyboardAwareScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      extraScrollHeight={96}
      enableOnAndroid
    >
      <View style={{ alignSelf: 'center' }}>
        <InputBox_WithLabel
          placeholderText={'comment'}
          handleInputChange={setEditedComment}
          autocapitalize={'none'}
          maxLength={480}
          value={editedComment}
          label={'Edit comment'}
        />
      </View>

      <Button_Submission
        callback={handleSubmit}
        title={'Update post'}
        loading={loading}
        disabled={isUnchanged || loading}
      />
      <Toast />
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
    marginTop: spacing.md,
  },
});

import React from 'react';
import {
  StyleSheet,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  View,
} from 'react-native';
import { colors } from '@/app/colors';
import {
  font,
  width,
  radius,
  spacing,
  webContentWidth,
} from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons/faWandMagicSparkles';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

type InputBox_WithSubmit = {
  placeholderText: string;
  handleInputChange: (text: string) => void;
  submitCallback: () => void;
  submitDisabled: boolean;
  autocapitalize: 'none' | 'sentences';
  editable?: boolean;
  value?: string;
  maxLength?: number;
  isLoading?: boolean;
  multiline?: boolean;
  numberOfLines?: number;
  submitIcon?: IconDefinition;
  secureTextEntry?: boolean;
  ref?: any;
  bgColor?: string;
};

export function InputBox_WithSubmit({
  handleInputChange,
  submitCallback,
  submitDisabled,
  placeholderText,
  maxLength,
  editable = true,
  isLoading,
  value,
  autocapitalize = 'none',
  multiline,
  numberOfLines,
  submitIcon,
  secureTextEntry,
  ref,
  bgColor,
}: InputBox_WithSubmit) {
  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: bgColor ? bgColor : colors.primary_accent,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: radius.sm,
        marginBottom: spacing.sm,
        width: width - spacing.md * 2,
        maxWidth: webContentWidth * 2,
      }}
    >
      <TextInput
        placeholder={placeholderText}
        placeholderTextColor={colors.halfwhite}
        onChangeText={handleInputChange}
        style={{
          ...styles.input,
          flex: 1,
        }}
        secureTextEntry={secureTextEntry ? secureTextEntry : false}
        autoCapitalize={autocapitalize}
        autoCorrect={true}
        editable={editable}
        value={value}
        maxLength={maxLength ? maxLength : undefined}
        multiline={multiline ? multiline : false}
        numberOfLines={numberOfLines ? numberOfLines : 1}
        ref={ref ? ref : null}
      />
      <TouchableOpacity
        style={{
          ...styles.input,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disabled={submitDisabled}
        onPress={submitCallback}
      >
        {!isLoading ? (
          <FontAwesomeIcon
            icon={submitIcon ? submitIcon : faWandMagicSparkles}
            color={colors.accent}
            size={20}
          />
        ) : (
          <ActivityIndicator size="small" color={colors.secondary} />
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    color: colors.secondary,
    borderRadius: radius.sm,
    padding: spacing.md,
    fontSize: font.lg,
  },
});

import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { globalstyles, spacing } from '@/app/globalstyles';
import { Text_AuthHeader } from '../Text_AuthHeader';
import { Text_PrivacyAndTerms } from '../Text_PrivacyAndTerms';
import { Button_ModalClose } from '../../shared/Button_ModalClose';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Text_ToggleNewUser } from '../Text_ToggleNewUser';
import { Layout_SignUpStep1 } from '../signup/Layout_SignUpStep1';
import { Layout_SignUpStep2 } from '../signup/Layout_SignUpStep2';
import { useCreateUser } from '@/api/useCreateUser';
import { Text_ForgotPassword } from '../Text_ForgotPassword';
import { colors } from '@/app/colors';

interface Props {
  setScreen: (screen: string) => void;
  toggleModal: () => void;
}

export const Layout_EmailSignUp = ({ setScreen, toggleModal }: Props) => {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Explore'>>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(1);
  const moveToStep = (s: number) => {
    setCurrentStep(s);
  };

  const { createUser, creatingUser } = useCreateUser({ email, password });

  const renderSteps = () => {
    switch (currentStep) {
      case 1:
        return (
          <Layout_SignUpStep1
            proceed={() => moveToStep(2)}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        );
      case 2:
        return (
          <Layout_SignUpStep2
            proceed={createUser}
            creatingUser={creatingUser}
          />
        );
    }
  };
  const authHeaderKey =
    currentStep === 1
      ? 'signup'
      : currentStep === 2
      ? 'signup-username'
      : 'signup';
  return (
    <View style={styles.wrapper}>
      <Text_AuthHeader text={authHeaderKey} />
      <Button_ModalClose
        close={() => setScreen('oauth')}
        overrideIcon={faChevronLeft}
        bgColor={colors.primary}
        bgHover={colors.primary_accent}
      />
      {renderSteps()}
      <Text_ToggleNewUser
        text1={'Already have an account?'}
        text2={'Log in'}
        toggleNewUser={() => setScreen('login')}
      />
      <Text_ForgotPassword callback={() => setScreen('pw-reset')} />
      <Text_PrivacyAndTerms navigation={navigation} toggleModal={toggleModal} />
      <Toast />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...globalstyles.container,
    padding: spacing.xl,
  },
});

import { colors } from '@/app/colors';
import React from 'react';
import withAuthPopup from '../shared/hoc/withAuthPopup';
import { Button_HotDog } from './Button_HotDog';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  fetchFollowing: () => void;
  activeTab: boolean;
}

const Button_FollowingFilter: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  fetchFollowing,
  activeTab,
}) => {
  const handlePress = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      fetchFollowing();
    }
  };
  return (
    <Button_HotDog
      title={'Following'}
      callback={handlePress}
      brightText={activeTab}
      bgColor={activeTab ? colors.primary_accent : colors.primary}
      roundie
    />
  );
};

export default withAuthPopup(Button_FollowingFilter);

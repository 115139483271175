import React, { useEffect, useState } from 'react';
import { Text, FlatList, View, SafeAreaView } from 'react-native';
import { Image } from 'expo-image';
import { globalstyles, heightWithHeader } from '@/app/globalstyles';
import { BadgeSchema, EMPTYSTATEBADGE } from '@/api/zod';
import { authenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import { Text_ListHeader } from '@/components/Text_ListHeader';

export function BadgesScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const [allBadges, setAllBadges] = useState<BadgeSchema[]>([EMPTYSTATEBADGE]);

  const renderItem = ({ item }: any) => (
    <View style={globalstyles.listItemContainer}>
      <View>
        <Text style={globalstyles.body}>{item.title}</Text>
        <Text style={globalstyles.tinyfade}>{item.description}</Text>
      </View>
      <Image
        source={item.icon.url}
        style={globalstyles.profileImageSmall}
        contentFit={'contain'}
        transition={250}
      />
    </View>
  );

  useEffect(() => {
    fetchBadges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBadges = async () => {
    if (user) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get('badges');
        setAllBadges(response.data);
      } catch (error) {
        showToast('Error fetching badges');
      }
    }
  };

  const listHeader = () => {
    return <Text_ListHeader text={'All available badges'} />;
  };

  return (
    <SafeAreaView style={globalstyles.container}>
      <FlatList
        data={allBadges}
        renderItem={renderItem}
        ListHeaderComponent={listHeader}
        style={{ height: heightWithHeader }}
        keyExtractor={(item, index) => index.toString()}
      />
    </SafeAreaView>
  );
}

export const REPORTTYPES = [
  {
    id: 1,
    type: 'Spam',
    description: 'Misleading or repetitive posts',
    selected: false,
  },
  {
    id: 2,
    type: 'Nudity or Pornography',
    description: 'Sexually explicit content',
  },
  {
    id: 3,
    type: 'Graphic violence',
    description: 'Violent images or promotion of violence',
  },
  {
    id: 4,
    type: 'Dangerous goods',
    description: 'Excessive drugs, weapons, regulated products',
  },
  {
    id: 5,
    type: 'Bigotry',
    description: 'Racism, sexism, etc.',
  },
];

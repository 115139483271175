import { Post } from '@/api/zod';
import { toggleSavePost, toggleLikePost } from '@/api/posts';
import { colors } from '@/app/colors';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { FlatList, ActivityIndicator, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { RenderPost } from '@/components/RenderPost';
import { useMyProfileStore } from '@/app/store/store';
import {
  globalstyles,
  heightWithHeader,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { WebNav_DailyThemeheader } from '@/components/daily theme/WebNav_DailyThemeHeader';

// This screen only has use in one area.
export function ViewPostsForVoteScreen() {
  const {
    params: { postId },
  } = useRoute<AuthenticatedNavigatorRouteProp<'ViewPostsForVote'>>();
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'ViewPostsForVote'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const [currentPostId] = useState(postId);
  const [activeDescriptionId, setActiveDescriptionId] = useState<number>();
  const updateActiveDescriptionId = (id: number | undefined) => {
    setActiveDescriptionId(id);
  };

  const firstRoundThemePosts = useDailyThemeStore(
    (state) => state.firstRoundThemePosts,
  );
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );
  const fetchMoreFirstRoundThemePosts = useDailyThemeStore(
    (state) => state.fetchMoreFirstRoundThemePosts,
  );
  const hasMorePosts = useDailyThemeStore((state) => state.hasMorePosts);
  const loading = useDailyThemeStore((state) => state.loading);
  const updatePostInFirstRoundThemePostList = useDailyThemeStore(
    (state) => state.updatePostInFirstRoundThemePostList,
  );
  const handleSavePost = (post: Post) => {
    user && toggleSavePost(user, post);

    // global update.
    const updatedPost = {
      ...post,
      isSaved: !post.isSaved,
      totalSaves: !post.isSaved ? post.totalSaves + 1 : post.totalSaves - 1,
    };
    updatePostInFirstRoundThemePostList(updatedPost);
  };

  const handleLikePost = (post: Post) => {
    user && toggleLikePost(user, post);

    // global update.
    const updatedPost = {
      ...post,
      likedOrVoted: !post.likedOrVoted,
      likeCount: !post.likedOrVoted ? post.likeCount + 1 : post.likeCount - 1,
    };
    updatePostInFirstRoundThemePostList(updatedPost);
  };

  const renderPost = ({ item }: { item: Post }) => {
    if (item.isPlaceholder) {
      return null;
    } else {
      return (
        <RenderPost
          post={item}
          user={user} // is this one wrong?
          navigation={navigation}
          handleLike={handleLikePost}
          handleSavePost={handleSavePost}
          voteable={false}
          editable={false}
          isAnonymous={true}
          updateActiveDescriptionId={updateActiveDescriptionId}
          activeDescriptionId={activeDescriptionId}
        />
      );
    }
  };

  const renderFooter = () => {
    return loading && hasMorePosts ? (
      <View style={{ padding: 20 }}>
        <ActivityIndicator size="large" color={colors.halfwhite} />
      </View>
    ) : null;
  };

  const handleLoadMore = () => {
    if (user) {
      fetchMoreFirstRoundThemePosts(user);
    }
  };

  const pressedPostIndex = firstRoundThemePosts.findIndex(
    (post) => post.id === currentPostId,
  );
  const slicedPostList = firstRoundThemePosts.slice(
    pressedPostIndex,
    firstRoundThemePosts.length,
  );

  return (
    <>
      <View style={globalstyles.container}>
        <FlatList
          data={slicedPostList}
          renderItem={renderPost}
          keyExtractor={(item) => String(item.id)}
          onEndReached={handleLoadMore}
          style={{
            height: heightWithHeader,
            paddingTop: spacing.md,
          }}
          onEndReachedThreshold={0.1}
          ListFooterComponent={renderFooter}
          contentContainerStyle={{
            alignSelf: 'center',
            maxWidth: webContentWidth * 2,
          }}
        />
        <WebNav_DailyThemeheader
          activeRoute={'ViewPostsForVote'}
          navigation={navigation}
          loggedInUserHasSubmitted={
            submissionThemeInfo.loggedInUserHasSubmitted
          }
        />
      </View>
      <Toast />
    </>
  );
}

import { EMPTYSTATEPOST, Post } from '@/api/zod';
import { colors } from '@/app/colors';
import {
  globalstyles,
  headerHeight,
  heightWithHeader,
  maxWebContentWidth,
} from '@/app/globalstyles';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useMyProfileStore } from '@/app/store/store';
import { WebNav_DailyThemeheader } from '@/components/daily theme/WebNav_DailyThemeHeader';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { FlatList, View, ActivityIndicator, StyleSheet } from 'react-native';
import { addPlaceholders } from '@/api/posts';
import { Thumbnail_Hover } from '@/components/shared/Thumbnail_Hover';
import { renderFooter } from '@/components/shared/Layout_FlatListFooter';
import { Modal_Post } from '@/components/shared/Modal_Post';
import usePostActions from '@/api/usePostActions';
import useFadeAnimation from '@/api/useFadeAnimation';
import { Layout_ThemeHeader } from '@/components/daily theme/Layout_ThemeHeader';

export function DailyThemeRound1VoteScreen() {
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'DailyThemeRound1Vote'>>();
  const route = useRoute();
  const routeName = route.name;
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );
  const firstRoundThemeInfo = useDailyThemeStore(
    (state) => state.firstRoundThemeInfo,
  );
  const fetchMoreFirstRoundThemePosts = useDailyThemeStore(
    (state) => state.fetchMoreFirstRoundThemePosts,
  );
  const firstRoundThemePosts = useDailyThemeStore(
    (state) => state.firstRoundThemePosts,
  );
  const hasMorePosts = useDailyThemeStore((state) => state.hasMorePosts);
  const loading = useDailyThemeStore((state) => state.loading);
  const [pressedItem, setPressedItem] = useState<Post>(EMPTYSTATEPOST);
  const [mounted, setMounted] = useState(false);

  const handlePress = (item: Post) => {
    const currentIndex = posts.findIndex((post) => post.id === item.id);
    setPressedItem(item);
    setCurrentIndex(currentIndex);
    togglePostModal();
  };
  const handleLoadMore = () => {
    if (myInfo) {
      fetchMoreFirstRoundThemePosts(myInfo);
    }
  };

  // handlers - action.
  const { handleLike, handleSave } = usePostActions();
  const like = () => {
    if (handleLike) {
      handleLike(pressedItem);
      const updatedPost = {
        ...pressedItem,
        likedOrVoted: !pressedItem.likedOrVoted,
        likeCount: !pressedItem.likedOrVoted
          ? pressedItem.likeCount + 1
          : pressedItem.likeCount - 1,
      };
      setPressedItem(updatedPost);
    }
  };
  const save = () => {
    handleSave(pressedItem);
    const updatedPost = {
      ...pressedItem,
      isSaved: !pressedItem.isSaved,
      totalSaves: !pressedItem.isSaved
        ? pressedItem.totalSaves + 1
        : pressedItem.totalSaves - 1,
    };
    setPressedItem(updatedPost);
  };

  const [postModalVisible, setPostModalVisible] = useState(false);
  const togglePostModal = () => {
    setPostModalVisible(!postModalVisible);
  };
  const posts = addPlaceholders(firstRoundThemePosts, 4);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { fadeAnim, previous, next, commentsVisible, setCommentsVisible } =
    useFadeAnimation(setCurrentIndex, setPressedItem, posts, currentIndex);

  const renderItem = ({ item }: { item: Post }) => {
    return (
      <Thumbnail_Hover
        item={item}
        callback={() => handlePress(item)}
        isAnonymous={true}
      />
    );
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <View style={globalstyles.container}>
      {mounted ? (
        <FlatList
          data={posts}
          renderItem={renderItem}
          keyExtractor={(post) => String(post.id)}
          numColumns={4}
          onEndReached={handleLoadMore}
          onEndReachedThreshold={0.8}
          ListFooterComponent={() => renderFooter(loading, hasMorePosts)}
          ListHeaderComponent={
            <Layout_ThemeHeader
              user={myInfo}
              themeInfo={firstRoundThemeInfo}
              navigation={navigation}
            />
          }
          style={styles.listContainer}
          contentContainerStyle={styles.contentContainer}
        />
      ) : (
        <ActivityIndicator size={'small'} color={colors.secondary} />
      )}
      <Modal_Post
        setPressedItem={setPressedItem}
        post={pressedItem}
        myInfo={myInfo}
        imageFadeAnim={fadeAnim}
        onClose={togglePostModal}
        isVisible={postModalVisible}
        navigation={navigation}
        isAnonymous={true}
        like={like}
        save={save}
        next={next}
        editable={pressedItem.users_id === myInfo?.id}
        previous={previous}
        commentsVisible={commentsVisible}
        setCommentsVisible={setCommentsVisible}
      />
      <WebNav_DailyThemeheader
        activeRoute={routeName}
        navigation={navigation}
        loggedInUserHasSubmitted={submissionThemeInfo.loggedInUserHasSubmitted}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    height: heightWithHeader,
  },
  contentContainer: {
    maxWidth: maxWebContentWidth,
    alignSelf: 'center',
    width: '90%',
    marginTop: headerHeight,
    paddingBottom: headerHeight * 2,
  },
});

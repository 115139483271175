import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { ProfileResponse, Tag } from './zod';
import { unauthenticatedRequest } from '@/modules/auth';
import { useMyProfileStore } from '@/app/store/store';

interface UseTagSearchProps {
  handleSearchNewTag: (tag: Tag) => void;
}

export const useTagSearch = ({ handleSearchNewTag }: UseTagSearchProps) => {
  const myInfo = useMyProfileStore((state) => state.myInfo);

  // GENERAL.
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const isUserSearch = () => {
    return searchQuery.startsWith('@');
  };
  const resetSearch = () => {
    setSearchLoading(false);
    setFetchedTags([]);
    setFetchedUsers([]);
    setSearchQuery('');
  };
  const handleInputChange = (text: string) => {
    setSearchQuery(text);
    if (text !== '@') {
      setSearchLoading(true);
    }
  };

  // TAG SEARCH.
  const [fetchedTags, setFetchedTags] = useState<Tag[]>([]);
  const fetchTags = debounce(async () => {
    if (searchQuery !== '') {
      setSearchLoading(true);
      try {
        const response = await unauthenticatedRequest().get(
          `tags/${searchQuery}`,
        );
        setFetchedTags(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setSearchLoading(false);
      }
    } else {
      setFetchedTags([]);
      setSearchLoading(false);
    }
  }, 100);

  useEffect(() => {
    if (isUserSearch()) {
      fetchUsers();
    } else {
      fetchTags();
    }
    return () => {
      fetchUsers.cancel();
      fetchTags.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleClickTag = (tag: Tag) => {
    resetSearch();
    handleSearchNewTag && handleSearchNewTag(tag);
  };

  // USER SEARCH.
  const [fetchedUsers, setFetchedUsers] = useState<ProfileResponse[]>([]);
  const fetchUsers = debounce(async () => {
    if (searchQuery !== '' && searchQuery !== '@') {
      try {
        setSearchLoading(true);
        const response = await unauthenticatedRequest().get(
          `search/users/${searchQuery.slice(1)}/${myInfo?.id}`,
        );
        setFetchedUsers(response.data);
        setSearchLoading(false);
      } catch (error) {
        console.log(error);
        setSearchLoading(false);
      }
    } else {
      setFetchedUsers([]);
    }
  }, 100);

  return {
    isUserSearch,
    resetSearch,
    handleInputChange,
    searchLoading,
    setSearchLoading,
    searchQuery,
    setSearchQuery,

    fetchedTags,
    handleClickTag,
    fetchedUsers,
  };
};

import React from 'react';
import { Text, View } from 'react-native';
import {
  requestAppleAuthWeb,
  requestDiscordAuthWeb,
  requestGoogleAuthWeb,
} from '@/modules/auth';
import { AuthButton } from '../AuthButton';
import { globalstyles, maxWebContentWidth, spacing } from '@/app/globalstyles';
import { StyleSheet } from 'react-native';
import { colors } from '@/app/colors';

type Props = {
  setScreen: (screen: string) => void;
  toggleModal: () => void;
};

export function Layout_OAuthStep1({ setScreen, toggleModal }: Props) {
  return (
    <View>
      <AuthButton
        logo={''}
        callback={() => setScreen('signup')}
        text={'Use Email'}
        sideblanks={false}
      />
      <AuthButton
        logo={require('../../../../assets/login-icons/discord-login-logo.png')}
        callback={requestDiscordAuthWeb}
        text={'Continue with Discord'}
        sideblanks={false}
      />
      <AuthButton
        logo={require('../../../../assets/login-icons/google-login-logo.png')}
        callback={requestGoogleAuthWeb}
        text={'Continue with Google'}
        sideblanks={false}
      />
      <AuthButton
        logo={require('../../../../assets/login-icons/apple-login-logo.png')}
        callback={requestAppleAuthWeb}
        text={'Continue with Apple'}
        sideblanks={false}
      />
      <View style={styles.divider}>
        <View style={styles.dividerLine} />
        <Text style={globalstyles.tinyfade}>OR</Text>
        <View style={styles.dividerLine} />
      </View>
      <AuthButton
        logo={''}
        callback={toggleModal}
        text={'Continue as Guest'}
        sideblanks={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    paddingBottom: spacing.xl,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    paddingBottom: spacing.lg,
    paddingTop: spacing.md,
    maxWidth: maxWebContentWidth / 3 - spacing.xl,
  },
  dividerLine: {
    ...globalstyles.container,
    height: 1,
    backgroundColor: colors.primary_accent,
    marginHorizontal: spacing.lg,
  },
});

import { unauthenticatedRequest } from '@/modules/auth';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { queryKeys } from './keys';

const HealthResponseParser = z.object({
  up: z.boolean(),
});
type HealthResponse = z.infer<typeof HealthResponseParser>;

async function check_health(): Promise<HealthResponse> {
  return unauthenticatedRequest()
    .get('health')
    .then(({ data }) => {
      return HealthResponseParser.parse(data);
    });
}

export function useHealthCheck() {
  const queryData = useQuery({
    queryKey: queryKeys.auth.health(),
    queryFn: check_health,
  });
  return queryData;
}

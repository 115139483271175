import { SearchPostsParams } from './zod';

export const queryKeys = {
  all: ['jackjack'] as const,
  auth: {
    all: () => [...queryKeys.all, 'auth'] as const,
    health: () => [...queryKeys.auth.all(), 'health'] as const,
    me: () => [...queryKeys.auth.all(), 'me'] as const,
  },
  posts: (params: SearchPostsParams = {}) =>
    [...queryKeys.all, 'posts', params] as const,
  rules: () => [...queryKeys.all, 'rules'] as const,
};

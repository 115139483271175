import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { globalstyles, spacing, webContentWidth } from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { colors } from '@/app/colors';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

interface Props {
  loading: boolean;
  allTags: string[];
  updateSearchModalVisible: () => void;
  handleRemoveTag: (tag: string) => void;
}

export const Layout_CreatePostTags = ({
  loading,
  allTags,
  updateSearchModalVisible,
  handleRemoveTag,
}: Props) => {
  return (
    <View style={{ marginBottom: spacing.md, maxWidth: webContentWidth * 2 }}>
      <View style={globalstyles.chipContainer}>
        <Text style={globalstyles.label}>Tags </Text>
        {allTags.length < 5 ? (
          <Pressable
            style={[globalstyles.chip, globalstyles._selectedChip]}
            onPress={updateSearchModalVisible}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faPlus} color={colors.secondary} size={16} />
          </Pressable>
        ) : null}
        {allTags.map((tag) => (
          <PostTag
            key={tag}
            tag={tag}
            loading={loading}
            removeTagCallback={() => handleRemoveTag(tag)}
          />
        ))}
      </View>
    </View>
  );
};

interface PostTagProps {
  tag: string;
  loading: boolean;
  removeTagCallback: (tag: string) => void;
}

const PostTag = ({ tag, loading, removeTagCallback }: PostTagProps) => {
  return (
    <Pressable
      key={tag}
      disabled={loading}
      style={{
        ...globalstyles.chip,
        ...globalstyles._selectedChip,
        marginRight: spacing.sm,
        alignItems: 'center',
      }}
      onPress={() => removeTagCallback(tag)}
    >
      <Text
        style={{
          ...globalstyles.smallbody,
          marginRight: spacing.sm,
        }}
      >
        {tag}
      </Text>
      <FontAwesomeIcon icon={faTimes} color={colors.halfwhite} size={10} />
    </Pressable>
  );
};

import { colors } from '@/app/colors';
import { globalstyles, spacing } from '@/app/globalstyles';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { faFire } from '@fortawesome/free-solid-svg-icons/faFire';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { Text_ListHeader } from '@/components/Text_ListHeader';
import { Button_Settings } from '@/components/shared/Button_Settings';

export function LeaderboardScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Leaderboard'>>();
  return (
    <View style={{ ...globalstyles.container, paddingHorizontal: spacing.md }}>
      <Text_ListHeader text={'Current & all-time records'} />
      <Button_Settings
        callback={() => navigation.push('Leaderboard_DailyThemeStreak')}
        title={'Daily theme streak'}
        icon={faFire}
        bgColor={colors.primary_accent}
      />
      <Button_Settings
        callback={() => navigation.push('Leaderboard_TotalExp')}
        title={'Total exp'}
        icon={faChartBar}
        bgColor={colors.primary_accent}
      />
    </View>
  );
}

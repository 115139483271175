import { create } from 'zustand';
import { OpenAIImageObject, Subscription } from '@/api/zod';
import { authenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';

const initialStudioStoreState = {
  myGallery: [],
  loadingMyGallery: false,
  myGalleryPage: 1,
  hasMoreMyGalleryImages: true,
  searchQuery: '',

  publicGallery: [],
  loadingPublicGallery: false,
  publicGalleryPage: 1,
  hasMorePublicGalleryImages: true,
  searchPublicQuery: '',

  loadingNewImage: false,
  textPrompt: '',

  hasRemainingCredits: true,
  activeProductId: '',
  activeProductName: '',
  isActiveCustomer: false,

  subscriptions: [],
  activeSubscription: '',
};

type StudioStore = {
  reset: () => void;

  subscriptions: Subscription[];
  activeSubscription: string;
  setActiveSubscription: (sub: string) => void;
  fetchSubscriptionHistory: (users_id: number) => void;

  myGallery: OpenAIImageObject[];
  loadingMyGallery: boolean;
  myGalleryPage: number;
  hasMoreMyGalleryImages: boolean;
  updateImageInMyGallery: (updatedImage: OpenAIImageObject) => void;
  setHasMoreMyGalleryImages: () => void;
  setLoadingMyGallery: () => void;
  fetchMyGalleryImages: (
    users_id: number,
    isStarred: boolean,
    reset?: boolean,
  ) => void;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
  searchImages: (
    searchQuery: string,
    filterStarred: boolean,
    users_id: number,
    reset: boolean,
  ) => void;

  publicGallery: OpenAIImageObject[];
  loadingPublicGallery: boolean;
  publicGalleryPage: number;
  hasMorePublicGalleryImages: boolean;
  updateImageInPublicGallery: (updatedImage: OpenAIImageObject) => void;
  setHasMorePublicGalleryImages: () => void;
  setLoadingPublicGallery: () => void;
  fetchPublicGalleryImages: (
    users_id: number,
    filterStarred: boolean,
    reset: boolean,
  ) => void;
  searchPublicQuery: string;
  setSearchPublicQuery: (searchQuery: string) => void;
  searchPublicImages: (
    searchQuery: string,
    filterStarred: boolean,
    users_id: number,
    reset: boolean,
  ) => void;

  fetchHasRemainingCredits: (users_id: number) => void;
  fetchCustomerInfo: (users_id: number) => void;
  hasRemainingCredits: boolean;
  activeProductId: string;
  activeProductName: string;
  isActiveCustomer: boolean;
  addNewImagesToGalleries: (newImages: OpenAIImageObject[]) => void;
  textPrompt: string;
  setTextPrompt: (prompt: string) => void;
  setLoadingNewImage: () => void;
  loadingNewImage: boolean;
  handleBlock: (blocked_user_id: number) => void;
};

export const useStudioStore = create<StudioStore>((set, get) => ({
  ...initialStudioStoreState,
  reset: () => set(() => ({ ...initialStudioStoreState })),
  setActiveSubscription: (text) => set({ activeSubscription: text }),
  fetchSubscriptionHistory: async (users_id: number) => {
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `subscriptions/history/${users_id}`,
      );
      if (response.data.length > 0) {
        set({ subscriptions: response.data });
      }
    } catch (error) {
      showToast('Error fetching subscriptions');
    }
  },
  setTextPrompt: (prompt) => set({ textPrompt: prompt }),
  fetchHasRemainingCredits: async (users_id: number) => {
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `image_generations/check_has_remaining_credits/${users_id}`,
      );
      set({ hasRemainingCredits: response.data });
    } catch (error) {
      showToast('Error checking remaining credits');
    }
  },
  fetchCustomerInfo: async (users_id: number) => {
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(`customers/${users_id}`);
      set({ hasRemainingCredits: response.data.hasRemainingCredits });
      if (response.data.active) {
        set({
          activeProductId: response.data.processor_api_id,
          activeProductName: response.data.name,
          isActiveCustomer: response.data.active,
        });
      }
    } catch (error) {
      showToast('Error checking customer info');
    }
  },

  updateImageInMyGallery: (updatedImage: OpenAIImageObject) =>
    set((state) => {
      const imgIndex = state.myGallery.findIndex(
        (img) => img.id === updatedImage.id,
      );

      if (imgIndex !== -1) {
        state.myGallery[imgIndex] = updatedImage;
      }
      return { myGallery: [...state.myGallery] };
    }),
  updateImageInPublicGallery: (updatedImage: OpenAIImageObject) =>
    set((state) => {
      const imgIndex = state.publicGallery.findIndex(
        (img) => img.id === updatedImage.id,
      );

      if (imgIndex !== -1) {
        state.publicGallery[imgIndex] = updatedImage;
      }
      return { publicGallery: [...state.publicGallery] };
    }),

  setHasMoreMyGalleryImages: () => set({ hasMoreMyGalleryImages: true }),
  setHasMorePublicGalleryImages: () =>
    set({ hasMorePublicGalleryImages: true }),

  setLoadingMyGallery: () => set({ loadingMyGallery: false }),
  setLoadingPublicGallery: () => set({ loadingPublicGallery: false }),
  setLoadingNewImage: () => set({ loadingNewImage: false }),

  fetchMyGalleryImages: async (users_id: number, isStarred, reset = false) => {
    const {
      myGalleryPage: currentPage,
      hasMoreMyGalleryImages,
      myGallery,
    } = get();
    if (!hasMoreMyGalleryImages && !reset) {
      return;
    }

    const page = reset ? 1 : currentPage;
    set({ loadingMyGallery: true });

    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `image_generations/${isStarred}/${users_id}/${page}`,
      );
      if (response.data.length > 0) {
        set({
          myGallery: reset
            ? [...response.data]
            : [...myGallery, ...response.data],
          hasMoreMyGalleryImages: true,
          loadingMyGallery: false,
          myGalleryPage: page + 1,
        });
      } else {
        if (page === 1) {
          set({ myGallery: [] });
        }
        set({ hasMoreMyGalleryImages: false });
      }
    } catch (error) {
      showToast('Error fetching images');
    }
    set({ loadingMyGallery: false });
  },

  setSearchQuery: (query) => set({ searchQuery: query }),
  searchImages: async (searchQuery, filterStarred, users_id, reset = false) => {
    const { loadingMyGallery, myGalleryPage: currentPage, myGallery } = get();
    if (loadingMyGallery) {
      return;
    }
    const page = reset ? 1 : currentPage;
    set({ loadingMyGallery: true });

    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `image_generations/search/${searchQuery}/${filterStarred}/${users_id}/${page}`,
      );

      if (response.data.length > 0) {
        set({
          myGallery: reset
            ? [...response.data]
            : [...myGallery, ...response.data],
          hasMoreMyGalleryImages: true,
          loadingMyGallery: false,
          myGalleryPage: page + 1,
        });
      } else {
        if (page === 1) {
          set({ myGallery: [] });
        }
        set({ hasMoreMyGalleryImages: false, loadingMyGallery: false });
      }
    } catch (error) {
      showToast('Error filtering images');
      set({ loadingMyGallery: false });
    }
  },

  fetchPublicGalleryImages: async (
    users_id: number,
    filterStarred,
    reset = false,
  ) => {
    const {
      publicGalleryPage: currentPage,
      hasMorePublicGalleryImages,
      publicGallery,
    } = get();
    if (!hasMorePublicGalleryImages && !reset) {
      return;
    }

    const page = reset ? 1 : currentPage;
    set({ loadingPublicGallery: true });

    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `image_generations/public/${filterStarred}/${users_id}/${page}`,
      );
      if (response.data.length > 0) {
        set({
          publicGallery: reset
            ? [...response.data]
            : [...publicGallery, ...response.data],
          hasMorePublicGalleryImages: true,
          loadingPublicGallery: false,
          publicGalleryPage: page + 1,
        });
      } else {
        if (page === 1) {
          set({ publicGallery: [] });
        }
        set({ hasMorePublicGalleryImages: false });
      }
    } catch (error) {
      showToast('Error fetching images');
    }
    set({ loadingPublicGallery: false });
  },
  setSearchPublicQuery: (query) => set({ searchPublicQuery: query }),
  searchPublicImages: async (
    searchQuery,
    filterStarred,
    users_id,
    reset = false,
  ) => {
    const {
      loadingPublicGallery,
      publicGalleryPage: currentPage,
      publicGallery,
    } = get();
    if (loadingPublicGallery) {
      return;
    }
    const page = reset ? 1 : currentPage;
    set({ loadingPublicGallery: true });

    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `image_generations/public/search/${searchQuery}/${filterStarred}/${users_id}/${page}`,
      );

      if (response.data.length > 0) {
        set({
          publicGallery: reset
            ? [...response.data]
            : [...publicGallery, ...response.data],
          hasMorePublicGalleryImages: true,
          loadingPublicGallery: false,
          publicGalleryPage: page + 1,
        });
      } else {
        if (page === 1) {
          set({ publicGallery: [] });
        }
        set({ hasMorePublicGalleryImages: false, loadingPublicGallery: false });
      }
    } catch (error) {
      showToast('Error filtering images');
      set({ loadingPublicGallery: false });
    }
  },
  addNewImagesToGalleries: (newImages: OpenAIImageObject[]) => {
    set((state) => {
      const updatedPublicGallery = [...state.publicGallery];
      const updatedMyGallery = [...state.myGallery];
      newImages.forEach((newImage) => {
        if (newImage.public) {
          updatedPublicGallery.unshift(newImage);
        }
        updatedMyGallery.unshift(newImage);
      });
      return {
        publicGallery: updatedPublicGallery,
        myGallery: updatedMyGallery,
      };
    });
  },
  handleBlock: (blocked_user_id: number) => {
    set((state) => ({
      publicGallery: state.publicGallery.filter(
        (post) => post.users_id !== blocked_user_id,
      ),
    }));
  },
}));

import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { spacing } from '@/app/globalstyles';
import { unauthenticatedRequest } from '@/modules/auth';
import { showToast } from './ErrorToast';
import { Button_SocialIcon } from './shared/Button_SocialIcon';

export const Layout_RenderSocials = () => {
  useEffect(() => {
    const fetchSocials = async () => {
      try {
        const authenticatedAxios = await unauthenticatedRequest();
        const response = await authenticatedAxios.get('community_social_stats');
        let fetchedSocials = [];
        fetchedSocials.push(response.data.instagram_stats);
        fetchedSocials.push(response.data.discord_stats);
        fetchedSocials.push(response.data.threads_stats);
        fetchedSocials.push(response.data.twitter_stats);
      } catch (error) {
        showToast('Error fetching socials');
      }
    };

    fetchSocials();
  }, []);
  return (
    <View style={{ ...styles.headerContent, flexDirection: 'row' }}>
      <Button_SocialIcon platformKey="instagram" url_ending={'joinwhirl'} />
      <Button_SocialIcon platformKey="discord" url_ending={'aiartcommunity'} />
      <Button_SocialIcon platformKey="threads" url_ending={'joinwhirl'} />
      <Button_SocialIcon platformKey="twitter" url_ending={'joinwhirl'} />
    </View>
  );
};

const styles = StyleSheet.create({
  headerContent: {
    marginBottom: spacing.lg,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

import React from 'react';
import { TouchableOpacity, Text, ActivityIndicator, View } from 'react-native';
import { globalstyles } from '@/app/globalstyles';
import { colors } from '@/app/colors';

type Button_SubmissionProps = {
  callback: () => void;
  title: string;
  loading: boolean;
  disabled?: boolean;
  bgColor?: string;
};

export function Button_Submission({
  callback,
  title,
  loading,
  disabled,
  bgColor,
}: Button_SubmissionProps) {
  return (
    <View style={{ alignItems: 'center', width: '100%' }}>
      <TouchableOpacity
        style={
          bgColor
            ? { ...globalstyles.roundedButton, backgroundColor: bgColor }
            : { ...globalstyles.roundedButton, ...globalstyles._bg_submit }
        }
        onPress={callback}
        disabled={disabled}
        activeOpacity={0.9}
      >
        {loading ? (
          <ActivityIndicator size={'small'} color={colors.secondary} />
        ) : (
          <Text style={globalstyles.title}>{title}</Text>
        )}
      </TouchableOpacity>
    </View>
  );
}

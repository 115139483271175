import React from 'react';
import { StyleSheet, Text, Pressable } from 'react-native';
import { colors } from '@/app/colors';
import { globalstyles, spacing } from '@/app/globalstyles';

interface ToggleTextProps {
  text1: string;
  text2: string;
  toggleNewUser: () => void;
}

export const Text_ToggleNewUser = ({
  text1,
  text2,
  toggleNewUser,
}: ToggleTextProps) => {
  return (
    <Pressable style={styles.toggleText} onPress={toggleNewUser}>
      <Text style={globalstyles.body}>{text1}</Text>
      <Text style={styles.accentText}> {text2}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  accentText: {
    ...globalstyles.label,
    color: colors.accent,
  },
  toggleText: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: spacing.md,
  },
});

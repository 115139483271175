import { z } from 'zod';

export const PostUserReactionSchema = z.object({
  id: z.number(),
  users_id: z.number(),
  posts_id: z.number(),
  reactions_id: z.number(),
});

export type PostUserReaction = z.infer<typeof PostUserReactionSchema>;

export const TagSchema = z.object({
  tags_id: z.optional(z.number()),
  id: z.optional(z.number()),
  title: z.string(),
  usage_count: z.number(),
});

export type Tag = z.infer<typeof TagSchema>;

export const EMPTYSTATETAG = {
  tags_id: 0,
  id: 0,
  title: '',
  usage_count: 42,
};

export type CalendarPost = {
  start: number;
  end: number;
  count: number;
  isPlaceholer?: boolean;
};

export const EMPTYCALENDARPOST = {
  isPlaceholer: true,
  start: 1,
  end: 1,
  count: 0,
};

export const PostSchema = z.object({
  id: z.number(),
  posts_id: z.optional(z.number()),
  long_id: z.number(),
  created_at: z.number(),
  title: z.string(),
  prompt: z.string(),
  technology_type_id: z.number(),
  technology_type: z.object({
    name: z.string(),
    emoji: z.string(),
  }),
  tags: z.array(TagSchema),
  images: z.array(
    z.object({
      images_id: z.number(),
      image: z.object({
        meta: z.object({
          width: z.number(),
          height: z.number(),
        }),
        url: z.string(),
      }),
    }),
  ),
  users_id: z.number(),
  username: z.string(),
  isChamp: z.boolean(),
  isDailyWinner: z.boolean(),
  isTopPost: z.boolean(),
  isVisible: z.boolean(),
  Profile_picture: z.object({
    url: z.string(),
  }),
  likedOrVoted: z.boolean(),
  isSaved: z.boolean(),
  likeCount: z.number(),
  voteCount: z.number(),
  commentCount: z.number(),
  totalFirstRoundVotes: z.number(),
  totalSaves: z.number(),
  isPlaceholder: z.boolean(),
  isDailyThemePost: z.boolean(),
  reactions: z.array(PostUserReactionSchema),
});

export type Post = z.infer<typeof PostSchema>;

export const EMPTYSTATEPOST = {
  id: 0,
  posts_id: 1,
  long_id: 0,
  created_at: 0,
  title: '',
  prompt: '',
  technology_type_id: 0,
  technology_type: {
    name: '',
    emoji: '',
  },
  tags: [
    {
      tags_id: 0,
      title: '',
      usage_count: 1,
    },
  ],
  images: [
    {
      images_id: 0,
      image: {
        meta: {
          width: 1,
          height: 1,
        },
        url: '',
      },
    },
  ],
  users_id: 0,
  username: '',
  Profile_picture: {
    url: 'https://link',
  },
  likeCount: 0,
  voteCount: 0,
  commentCount: 0,
  totalFirstRoundVotes: 0,
  totalSaves: 0,
  likedOrVoted: false,
  isSaved: false,
  isVisible: true,
  isPlaceholder: false,
  isDailyThemePost: false,
  isChamp: false,
  isDailyWinner: false,
  isTopPost: false,
  reactions: [],
};

export type MyStats = {
  total_posts: number;
  total_daily_theme_posts: number;
  total_likes_received: number;
  total_theme_votes: number;
  posts_this_month: CalendarPost[];
  daily_fav_streak: number;
};

export const EMPTYSTATS = {
  total_posts: 0,
  total_daily_theme_posts: 0,
  total_likes_received: 0,
  total_theme_votes: 0,
  posts_this_month: [],
  daily_fav_streak: 0,
};

export type Technology = {
  id: number;
  name: string;
  emoji: string;
};

export const EMPTYTECHNOLOGY = {
  id: 0,
  name: '',
  emoji: '',
};

const PostsResponseSchema = z.object({
  records: z.array(PostSchema),
  nextPage: z.number(),
});

export type PostsResponse = z.infer<typeof PostsResponseSchema>;

const baseCommentSchema = z.object({
  id: z.number(),
  body: z.string(),
  created_at: z.number(),
  posts_id: z.number(),
  comments_id: z.number(),
  users_id: z.number(),
  username: z.string(),
  Profile_picture: z.object({
    url: z.string(),
  }),
  likeCount: z.number(),
  isLiked: z.boolean(),
  isReply: z.boolean(),
});

export type Comment = z.infer<typeof baseCommentSchema> & {
  replies: Comment[];
};

const CommentSchema: z.ZodType<Comment> = baseCommentSchema.extend({
  replies: z.lazy(() => CommentSchema.array()),
});

export const EMPTYSTATECOMMENT = {
  id: 0,
  body: '',
  created_at: 0,
  posts_id: 1,
  comments_id: 0,
  users_id: 1,
  username: '',
  Profile_picture: {
    url: 'https://link',
  },
  likeCount: 0,
  isLiked: false,
  isReply: false,
  replies: [],
};

// The Partial type allows you to have the properties be optional.
export type SearchPostsParams = Partial<{
  eventType: 'daily_theme';
  /** ISO */
  eventDate: string;
  userId: number;
  limit: number;
  cursor?: number;
}>;

const BadgeSchemaParser = z.object({
  id: z.number(),
  badge_instance_badge_type_id: z.number(),
  count: z.number(),
  title: z.string(),
  icon: z.object({
    url: z.string(),
  }),
  description: z.string(),
  category: z.string(),
});
export type BadgeSchema = z.infer<typeof BadgeSchemaParser>;

export const EMPTYSTATEBADGE = {
  id: 0,
  badge_instance_badge_type_id: 0,
  count: 0,
  title: '',
  icon: {
    url: 'https://link',
  },
  description: '',
  category: '',
};

const ProfilePicture = z.object({
  url: z.string(),
});
const ProfileBanner = z.object({
  url: z.string(),
});

export const ProfileResponseParser = z.object({
  id: z.number(),
  created_at: z.number(),
  username: z.string(),
  fullname: z.string(),
  bio: z.string(),
  lastNotificationCheckTime: z.union([z.number(), z.null()]),
  experience: z.number(),
  level: z.number(),
  daily_theme_streak: z.number(),
  pr_daily_theme_streak: z.number(),
  vote_streak: z.number(),
  pr_vote_streak: z.number(),
  hasPosted: z.optional(z.boolean()),
  Profile_picture: z.optional(ProfilePicture.nullable()),
  Profile_banner: z.optional(ProfileBanner.nullable()),
  badges: z.optional(z.array(BadgeSchemaParser)),
  users_id: z.optional(z.number()),
  isFollowed: z.optional(z.boolean()),
  newsletterSubscriber: z.optional(z.boolean()),
  isVisitor: z.optional(z.boolean()),
});

export type ProfileResponse = z.infer<typeof ProfileResponseParser>;

export const ProfileResponse_MiniParser = z.object({
  id: z.number(),
  users_id: z.optional(z.number()),
  username: z.string(),
  Profile_picture: z.optional(ProfilePicture.nullable()),
});

export type ProfileResponse_Mini = z.infer<typeof ProfileResponse_MiniParser>;

export const EMPTYSTATEUSER = {
  id: 0,
  created_at: 0,
  username: '',
  fullname: '',
  bio: '',
  lastNotificationCheckTime: 0,
  experience: 0,
  level: 0,
  daily_theme_streak: 0,
  pr_daily_theme_streak: 0,
  vote_streak: 0,
  pr_vote_streak: 0,
  Profile_picture: {
    url: 'https://link',
  },
  Profile_banner: {
    url: 'https://link',
  },
  badges: [],
  hasPosted: false,
};

export const VISITOR = {
  ...EMPTYSTATEUSER,
  isVisitor: true,
};

const SocialMediaLinkParser = z.object({
  id: z.number(),
  social_media_type_id: z.number(),
  username: z.string(),
  name: z.string(),
  root_domain: z.string(),
  icon: z.object({
    meta: z.object({
      width: z.number(),
      height: z.number(),
    }),
    url: z.string(),
  }),
});

export type SocialMediaLink = z.infer<typeof SocialMediaLinkParser>;

export const EMPTYSOCIALLINK = {
  id: 0,
  social_media_type_id: 0,
  username: '',
  name: '',
  root_domain: 'https://test.gg',
  icon: {
    meta: {
      width: 0,
      height: 0,
    },
    url: 'https://link',
  },
};

export type Notification = {
  id: number;
  created_at: number;
  body: string;
  recipient_user_id: number;
  sender_user_id: number;
  posts_id: number;
  long_id: number;
  linkPost: boolean;
  linkUser: boolean;
  isDailyThemeRelated: boolean;
  type: string;
  username: string;
  Profile_picture?: {
    url: string;
  };
  isPopup: boolean;
  popupBody: string;
  badge_type_id: number;
};

export const EMPTYSTATENOTIFICATION = {
  id: 0,
  created_at: 0,
  body: '',
  recipient_user_id: 0,
  sender_user_id: 0,
  posts_id: 0,
  long_id: 0,
  linkPost: false,
  linkUser: false,
  isDailyThemeRelated: false,
  type: '',
  username: '',
  Profile_picture: {
    url: '',
  },
  isPopup: false,
  popupBody: '',
  badge_type_id: 0,
};

export type ReleaseNote = {
  id: number;
  title: string;
  version: string;
  release_date: number;
  notes: string;
  major_features: string;
  minor_features: string;
  isSubscriberTier: boolean;
  image: {
    meta: {
      width: number;
      height: number;
    };
    url: string;
  };
  shoutouts: [
    {
      id: number;
      users_id?: number;
      username: string;
      Profile_picture: {
        url: string;
      };
    },
  ];
};

export type OpenAIImageObject = {
  id: number;
  created_at: number;
  users_id: number;
  images_id: number;
  prompt: string;
  saved: boolean;
  generator: string;
  public: boolean;
  image: {
    url: string;
    meta: {
      height: number;
      width: number;
    };
  };
  isStarred: boolean;
  isPlaceholder?: boolean;
};

export type Subscription = {
  id: number;
  created_at: number;
  users_id: number;
  name: string;
  product_identifier: string;
  expiration_date: number;
  price: string;
  priceString: string;
  subscription_type: string;
  active: boolean;
  canceled: boolean;
};

export const EMPTYSTATE_OPENAIIMAGEOBJECT = {
  id: 0,
  created_at: 0,
  users_id: 0,
  images_id: 0,
  prompt: '',
  saved: true,
  isStarred: true,
  generator: 'openai',
  public: true,
  image: {
    url: 'https://roz.joinwhirl.com/vault/jxQB-77S/GIrVGN53bgx3iQYYMEKmGw0KA10/jbqXMg../img-1692870948094-12.png',
    meta: {
      height: 0,
      width: 0,
    },
  },
  isPlaceholder: true,
};

export type ContributorItem = {
  id: number;
  username: string;
  tier: string;
  image: {
    url: string;
  };
  users_id: number;
  user_info: {
    username: string;
    Profile_picture: {
      url: string;
    };
  };
};

export const EMPTYCONTRIBUTORITEM = {
  id: 0,
  username: '',
  tier: '',
  image: {
    url: 'https://link',
  },
  users_id: 0,
  user_info: {
    username: '',
    Profile_picture: {
      url: 'link',
    },
  },
};

export type TagList = {
  id: number;
  users_id: number;
  title: string;
  description: string;
  tags_id: number;
  active: boolean;
  image: {
    url: string;
  };
  tag: {
    title: string;
    usage_count: number;
  };
  username: string;
  Profile_picture: {
    url: string;
  };
  tags: TagListTag[];
  isFollowing: boolean;
  followerCount: number;
};

export type TagListTag = {
  id: number;
  created_at: number;
  tag_list_id: number;
  tags_id: number;
  title: string;
  usage_count: number;
  date: string;
};

export type SimpleTagListTag = {
  tag_str: string;
  day: number;
};

export type Product = {
  processor_api_id: string;
  name: string;
  price: number;
  discount: string | null;
  list_details: string[];
  billing_cycle: string;
};

// not sure this is actually needed right now.
export type Customer = {
  users_id: number;
};

const BaseThemeSchema = z.object({
  id: z.number(),
  title: z.string(),
  submit_by: z.number(),
  background_image: z.object({
    url: z.string(),
  }),
  background_image_landscape: z.object({
    url: z.string(),
  }),
  isAcceptingSubmissions: z.boolean(),
  isFirstRoundVoting: z.boolean(),
  isFinalRoundVoting: z.boolean(),
  loggedInUserHasSubmitted: z.boolean(),
  isFinished: z.boolean(),
  userHasVotedFor: z.boolean(),
  votes: z.number(),
  total_posts: z.optional(z.number()),
  total_votes_given: z.number(),
  background_gif: z.optional(z.string()),
  posts_id: z.optional(z.number()),
  long_id: z.optional(z.number()),
  tag_list_tags_id: z.optional(z.number()),
});

export type BaseTheme = z.infer<typeof BaseThemeSchema>;

export const EMPTYSTATETHEMEINFO = {
  id: 0,
  title: '',
  submit_by: 0,
  background_image: {
    url: 'https://link',
  },
  background_image_landscape: {
    url: 'https://link',
  },
  background_gif: 'https://link',
  isAcceptingSubmissions: false,
  isFirstRoundVoting: false,
  isFinalRoundVoting: false,
  isFinished: false,
  total_posts: 0,
  total_votes_given: 0,
  loggedInUserHasSubmitted: false,
  userHasVotedFor: false,
  votes: 0,
};

export type ExtendedBaseTheme = BaseTheme & {
  tag_list_info: {
    tag_list_tags_id?: number;
    tag_list_id?: number;
    tags_id?: number;
    date?: string;
    tag: {
      title: string;
      usage_count?: number;
    };
    tag_list: TagList;
  };
};

export const EMPTYSTATEEXTENDEDTHEMEINFO = {
  id: 0,
  title: '',
  submit_by: 0,
  background_image: {
    url: 'https://link',
  },
  background_image_landscape: {
    url: 'https://link',
  },
  background_gif: 'https://link',
  isAcceptingSubmissions: false,
  isFirstRoundVoting: false,
  isFinalRoundVoting: false,
  isFinished: false,
  total_posts: 0,
  total_votes_given: 0,
  loggedInUserHasSubmitted: false,
  userHasVotedFor: false,
  votes: 0,
  tag_list_info: {
    tag_list_tags_id: 0,
    tag_list_id: 0,
    tags_id: 0,
    date: '2023-10-11',
    tag: {
      title: '',
      usage_count: 0,
    },
    tag_list: {
      id: 0,
      users_id: 0,
      title: '',
      description: '',
      tags_id: 0,
      active: true,
      image: {
        url: 'https://link',
      },
      tag: {
        title: '',
        usage_count: 0,
      },
      username: '',
      Profile_picture: {
        url: 'https://link',
      },
      tags: [],
      isFollowing: false,
      followerCount: 0,
    },
  },
};

export type ProfileData = {
  user: ProfileResponse;
  badges: BadgeSchema[];
  tagList?: TagList;
  links: SocialMediaLink[];
  totalPosts: number;
  loading: boolean;
};

export const EMPTYPROFILEDATA = {
  user: EMPTYSTATEUSER,
  badges: [],
  tagList: undefined,
  links: [EMPTYSOCIALLINK],
  totalPosts: 0,
  loading: false,
};

export type GalleryData = {
  currentTab: string;
  allPostList: Post[];
  allPage: number;
  loadingAll: boolean;
  hasMoreAll: boolean;
  dailyThemePostList: Post[];
  dailyThemePage: number;
  loadingTheme: boolean;
  hasMoreTheme: boolean;
};

export const EMPTYGALLERYDATA = {
  currentTab: 'all',
  allPostList: [],
  allPage: 2,
  loadingAll: true,
  hasMoreAll: true,
  dailyThemePostList: [],
  dailyThemePage: 2,
  loadingTheme: true,
  hasMoreTheme: true,
};

import { colors } from '@/app/colors';
import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';

const LoadingText = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        return prevDots.length < 3 ? `${prevDots}.` : '';
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Text style={{ color: colors.secondary, fontFamily: 'PressStart2P' }}>
      {`Loading${dots}`}
    </Text>
  );
};

export default LoadingText;

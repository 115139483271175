import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Animated, Pressable } from 'react-native';
import { globalstyles, headerHeight } from '@/app/globalstyles';
import { WebHeaderLeft } from './shared/WebHeaderLeft';
import { LinearGradient } from 'expo-linear-gradient';
import { colors } from '@/app/colors';
import { Buttons_HomeTopRight } from './shared/Buttons_HomeTopRight';

export const Layout_WebHeader = () => {
  const [hover, setHover] = useState(false);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: hover ? 1 : 0,
      duration: 150,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hover]);

  const pathname = window.location.pathname;
  const isSuccessfulOAuthRoute = pathname.includes('auth/success');
  if (isSuccessfulOAuthRoute) {
    return null;
  }

  return (
    <Pressable
      style={styles.wrapper}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <WebHeaderLeft hoverInCallback={() => setHover(true)} />
      <Buttons_HomeTopRight hoverInCallback={() => setHover(true)} />
      <Animated.View
        style={[globalstyles.homeScreenGradientFill, { opacity: fadeAnim }]}
      >
        <LinearGradient
          colors={[colors.primary, colors.primary]}
          style={globalstyles.homeScreenGradientFill}
        />
      </Animated.View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: headerHeight,
  },
});

import React, { Dispatch, SetStateAction, useState } from 'react';
import { View } from 'react-native';
import { colors } from '@/app/colors';

import { unauthenticatedRequest, isValidEmail } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';
import { InputBox_SignUp } from '../InputBox_SignUp';
import { AuthButton } from '../AuthButton';

interface Props {
  moveToStep: (s: number) => void;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
}

export const Layout_PWResetStep1 = ({ moveToStep, email, setEmail }: Props) => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendCode = async () => {
    if (!isValidEmail(email)) {
      setInvalidEmail(true);
    } else {
      try {
        setLoading(true);
        const axios = unauthenticatedRequest();
        const response = await axios.post('/auth/password_reset/create_code', {
          email: email,
        });
        if (response.data === 'success') {
          moveToStep(2);
        } else if (response.data === 'no-email') {
          setNoEmail(true);
        } else {
          setInvalidEmail(true);
        }
      } catch (error: any) {
        showToast('Error creating reset code.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEmailInputChange = (setter: any) => (text: string) => {
    const newText = text.replace(/\s+/g, '');
    setter(newText.toLowerCase());
    setNoEmail(false);
  };

  const invalidEmailText = invalidEmail
    ? 'Invalid email address.'
    : noEmail
    ? 'No email found.'
    : '';
  const validEmail = isValidEmail(email);

  return (
    <View>
      <InputBox_SignUp
        value={email}
        handleInputChange={handleEmailInputChange}
        setter={setEmail}
        placeholderText={'Email'}
        keyboardType={'email-address'}
        invalid={invalidEmail || noEmail}
        invalidText={invalidEmailText}
      />
      <AuthButton
        logo={''}
        callback={sendCode}
        text={'Send code'}
        disabled={!validEmail}
        bgColor={!validEmail ? colors.primary_offset : colors.accent}
        bgHover={
          !validEmail ? colors.primary_offset_2 : colors.accent_highlight
        }
        loading={loading}
        sideblanks
      />
    </View>
  );
};

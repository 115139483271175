import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Animated,
  Pressable,
} from 'react-native';
import { Image } from 'expo-image';
import { ParamListBase } from '@react-navigation/native';
import { Post, Tag } from '@/api/zod';
import { ProfileResponse } from '@/api/zod';
import { toggleVoteForPost } from '@/api/posts';
import { relativeDate } from './RelativeDate';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { MetadataModal } from '@/screens/metadataModal';
import { CommentsModal } from '@/screens/commentsModal';
import { colors } from '@/app/colors';
import {
  globalstyles,
  radius,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { authenticatedRequest } from '@/modules/auth';
import { useCommentStore } from '@/app/store/commentStore';
import { Modal_FullScreenImage } from '@/screens/Modal_FullScreenImage';
import { showToast } from './ErrorToast';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { PostActionButtons } from './PostActionButtons';
import { StackNavigationProp } from '@react-navigation/stack';

interface PostProps {
  post: Post;
  user: ProfileResponse | null | undefined;
  navigation: StackNavigationProp<ParamListBase>;
  handleSavePost: (post: Post) => void;
  voteable: boolean;
  editable: boolean;
  updateActiveDescriptionId?: (id: number | undefined) => void;
  updateLocalPostList?: (updatedPost: Post) => void;
  activeDescriptionId?: number | undefined;
  isAnonymous?: boolean;
  handleLike?: (post: Post, user: ProfileResponse) => void;
  isPreview?: boolean;
}

export const RenderPost: React.FC<PostProps> = ({
  post,
  user,
  navigation,
  handleLike,
  handleSavePost,
  voteable,
  editable,
  isAnonymous,
  isPreview,
  updateActiveDescriptionId,
  activeDescriptionId,
  updateLocalPostList,
}) => {
  const [commentsModalVisible, setCommentsModalVisible] = useState(false);
  const updatePostInFinalRoundThemePostList = useDailyThemeStore(
    (state) => state.updatePostInFinalRoundThemePostList,
  );
  const reset = useCommentStore((state) => state.reset);
  const [metadataModalVisible, setMetadataModalVisible] = useState(false);
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const [promptIsSaved, setPromptIsSaved] = useState(false);
  const [totalPromptSaves, setTotalPromptSaves] = useState(0);
  const checkIfPromptIsSaved = async () => {
    if (user) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `user_saved_prompts/check_if_saved/${post.id}/${user.id}`,
        );
        setPromptIsSaved(response.data.promptIsSaved);
        setTotalPromptSaves(response.data.totalPromptSaves);
      } catch (error) {
        showToast('Error checking if prompt is saved');
      }
    }
  };
  const updatePromptInfo = () => {
    if (promptIsSaved) {
      setTotalPromptSaves(totalPromptSaves - 1);
    } else {
      setTotalPromptSaves(totalPromptSaves + 1);
    }
    setPromptIsSaved(!promptIsSaved);
  };
  const updateMetadataModalVisible = async () => {
    checkIfPromptIsSaved();
    setMetadataModalVisible(!metadataModalVisible);
  };
  const updateFullScreenModalVisible = async () => {
    setFullScreenModalVisible(!fullScreenModalVisible);
  };
  const updateCommentsModalVisible = () => {
    if (commentsModalVisible) {
      reset();
    }
    setCommentsModalVisible(!commentsModalVisible);
  };

  const handleNav = () => {
    navigation.push('UserProfile', {
      username: post.username,
    });
  };

  const handleLikeOrVote = () => {
    if (user) {
      if (voteable) {
        toggleVoteForPost(user, post);

        // global update.
        const updatedPost = {
          ...post,
          likedOrVoted: !post.likedOrVoted,
          voteCount: !post.likedOrVoted
            ? post.voteCount + 1
            : post.voteCount - 1,
        };
        updatePostInFinalRoundThemePostList(updatedPost);
      } else if (handleLike) {
        handleLike(post, user);
      } else {
        console.warn(
          'Neither handleVote nor handleLike functions are provided in props.',
        );
      }
    }
  };
  const maxTitleLength = 34;
  const titlePreview = (title: string) => {
    if (title.length > maxTitleLength) {
      return title.slice(0, maxTitleLength);
    } else {
      return title;
    }
  };

  const renderTag = (item: Tag) => {
    return (
      <TouchableOpacity
        key={item.title}
        style={globalstyles.chip}
        activeOpacity={0.9}
        disabled={item.id === 42 || isPreview}
        onPress={() =>
          navigation.push('Explore', {
            passedTag: item,
          })
        }
      >
        <Text style={globalstyles.chipText}>{`${item.title}`}</Text>
        <Text style={globalstyles.chipUsageCountText}>{item.usage_count}</Text>
      </TouchableOpacity>
    );
  };

  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: post.id === activeDescriptionId ? 1 : 0,
      duration: 600,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fadeAnim, activeDescriptionId]);

  return (
    <View style={globalstyles.withBottomBorderSpacing}>
      <View style={{ paddingHorizontal: spacing.sm }}>
        {!isAnonymous ? (
          <View style={styles.userInfoContainer}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
              {post.Profile_picture ? (
                <TouchableOpacity
                  style={globalstyles.profileImageContainerSmall}
                  activeOpacity={0.9}
                  disabled={isPreview}
                  onPress={handleNav}
                >
                  <Image
                    source={{
                      uri: post.Profile_picture.url,
                    }}
                    style={globalstyles.profileImageSmall}
                  />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={globalstyles.profileImageContainerSmall}
                  activeOpacity={0.9}
                  disabled={isPreview}
                  onPress={handleNav}
                >
                  <Image
                    source={require('../../assets/profile-placeholder.jpg')}
                    style={globalstyles.profileImageSmall}
                  />
                </TouchableOpacity>
              )}
              <TouchableOpacity
                style={styles.usernameContainer}
                activeOpacity={0.9}
                disabled={isPreview}
                onPress={handleNav}
              >
                <Text style={globalstyles.usernameText}>@{post.username} </Text>
                <Text style={globalstyles.tinyfade}>
                  · {relativeDate(post.created_at)}
                </Text>
              </TouchableOpacity>
            </View>
            {user?.id === post.users_id && editable ? (
              <TouchableOpacity
                style={{ marginRight: spacing.md }}
                disabled={isPreview}
                onPress={() => {
                  navigation.navigate('EditPost', {
                    post: post,
                    updateLocalPostList: updateLocalPostList,
                  });
                }}
              >
                <Text style={{ color: colors.secondary }}>edit</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={{ marginRight: spacing.md }}
                disabled={isPreview}
                onPress={
                  editable
                    ? () =>
                        navigation.navigate('Report', {
                          postId: post.id,
                          reporting_user_id: user?.id,
                          reported_user_id: post.users_id,
                        })
                    : () => null
                }
              >
                <Text style={globalstyles.body}>{editable ? '···' : ''}</Text>
              </TouchableOpacity>
            )}
          </View>
        ) : null}
        <Pressable disabled={isPreview} onPress={updateFullScreenModalVisible}>
          {post.images[0].image.url && (
            <Image
              source={{ uri: post.images[0].image.url }}
              style={{
                width: webContentWidth * 2 - spacing.md,
                height: undefined,
                marginBottom: spacing.sm,
                borderRadius: radius.lg,
                zIndex: 1,
                aspectRatio:
                  (post.images[0].image.meta.width || 1) /
                  (post.images[0].image.meta.height || 1),
              }}
              contentFit={'cover'}
            />
          )}
        </Pressable>

        <View style={styles.postFooterContainer}>
          <View style={styles.textPreviewContainer}>
            <TouchableOpacity
              disabled={isPreview}
              onPressIn={
                updateActiveDescriptionId
                  ? () => updateActiveDescriptionId(post.id)
                  : () => null
              }
            >
              <View style={styles.commentButtonContainer}>
                <View style={{ flexDirection: 'row' }}>
                  {post.commentCount > 0 ? (
                    <TouchableOpacity
                      disabled={isPreview}
                      onPress={updateCommentsModalVisible}
                    >
                      <Text style={{ color: colors.halfwhite }}>
                        {post.commentCount}
                        {post.commentCount > 1 ? ' comments' : ' comment'}
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                  {post.commentCount > 0 &&
                  (post.likeCount > 0 || post.voteCount > 0) ? (
                    <Text style={{ color: colors.halfwhite }}>{' · '}</Text>
                  ) : null}
                  {post.likeCount > 0 ? (
                    <TouchableOpacity
                      disabled={isPreview}
                      onPress={() =>
                        navigation.push('Likes', {
                          postId: post.id,
                          voteable: false,
                          isDailyThemeRelated: false,
                        })
                      }
                    >
                      <Text style={{ color: colors.halfwhite }}>
                        {post.likeCount}
                        {isAnonymous
                          ? `${post.likeCount > 1 ? ' votes' : ' vote'}`
                          : `${post.likeCount > 1 ? ' likes' : ' like'}`}
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                  {voteable && post.voteCount > 0 ? (
                    <TouchableOpacity
                      disabled={isPreview}
                      onPress={() =>
                        navigation.push('Likes', {
                          postId: post.id,
                          voteable: true,
                          isDailyThemeRelated: true,
                        })
                      }
                    >
                      <Text style={{ color: colors.halfwhite }}>
                        {post.voteCount}
                        {`${post.voteCount > 1 ? ' votes' : ' vote'}`}
                      </Text>
                    </TouchableOpacity>
                  ) : null}
                  {post.totalSaves > 0 &&
                  (post.likeCount > 0 || post.voteCount > 0) ? (
                    <Text style={{ color: colors.halfwhite }}>{' · '}</Text>
                  ) : null}
                  {post.totalSaves > 0 ? (
                    <View>
                      <Text style={{ color: colors.halfwhite }}>
                        {post.totalSaves}
                        {`${post.totalSaves > 1 ? ' saves' : ' save'}`}
                      </Text>
                    </View>
                  ) : null}
                </View>
                <Text style={{ color: colors.secondary, paddingTop: 4 }}>
                  {post.id !== activeDescriptionId
                    ? titlePreview(post.title)
                    : post.title}
                  {(post.title.length > maxTitleLength ||
                    post.tags.length > 0) &&
                  post.id !== activeDescriptionId
                    ? ' ...'
                    : null}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <PostActionButtons
            post={post}
            metadataCallback={updateMetadataModalVisible}
            commentCallback={updateCommentsModalVisible}
            saveCallback={() => handleSavePost(post)}
            likeCallback={() => handleLikeOrVote()}
            voteable={isAnonymous || voteable}
            isPreview={isPreview}
          />
        </View>
        {post.id === activeDescriptionId &&
        (post.title.length > 32 || post.tags.length > 0) ? (
          <Animated.View
            style={{
              opacity: fadeAnim,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingTop: spacing.sm,
                flexWrap: 'wrap',
              }}
            >
              {post.tags.length > 0 ? (
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                  {post.tags.map(renderTag)}
                </View>
              ) : null}
            </View>
            <TouchableOpacity
              style={{ alignItems: 'flex-end' }}
              disabled={isPreview}
              onPress={
                updateActiveDescriptionId
                  ? () => updateActiveDescriptionId(undefined)
                  : () => null
              }
            >
              <FontAwesomeIcon
                icon={faCaretUp}
                color={colors.secondary}
                style={{ paddingRight: spacing.sm, paddingTop: spacing.sm }}
                size={18}
              />
            </TouchableOpacity>
          </Animated.View>
        ) : null}
      </View>
      <MetadataModal
        metadataModalVisible={metadataModalVisible}
        updateMetadataModalVisible={updateMetadataModalVisible}
        post={post}
        userId={user?.id}
        promptIsSaved={promptIsSaved}
        totalPromptSaves={totalPromptSaves}
        updatePromptInfo={updatePromptInfo}
      />
      <CommentsModal
        commentsModalVisible={commentsModalVisible}
        updateCommentsModalVisible={updateCommentsModalVisible}
        post={post}
        users_id={user?.id}
        navigation={navigation}
        username={user?.username}
        updateLocalPostList={updateLocalPostList}
      />
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={updateFullScreenModalVisible}
        post={post}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  userInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: -spacing.md + 6,
    zIndex: 5,
  },
  usernameContainer: {
    ...globalstyles.centeringContainer,
    flexDirection: 'row',
    paddingLeft: spacing.xs,
  },
  postFooterContainer: {
    flexDirection: 'row',
    maxWidth: webContentWidth * 2,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  textPreviewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: spacing.sm,
  },
  actionButtonContainer: {
    backgroundColor: colors.primary_accent,
    borderRadius: radius.md,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing.md - 4,
  },
  commentButtonContainer: {
    alignSelf: 'flex-start',
    paddingTop: spacing.xs,
  },
});

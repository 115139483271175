import React, { useState, useEffect } from 'react';
import {
  Text,
  StyleSheet,
  Modal,
  FlatList,
  View,
  TouchableOpacity,
  SafeAreaView,
  Pressable,
} from 'react-native';
import { debounce } from 'lodash';
import { authenticatedRequest } from '@/modules/auth';
import { Tag } from '@/api/zod';
import { colors } from '@/app/colors';
import {
  globalstyles,
  webContentWidth,
  spacing,
  width,
} from '@/app/globalstyles';
import { SearchModalHeader } from '@/components/SearchModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { InputBox_WithSubmit } from '@/components/InputBox_WithSubmit';

interface SearchTagModalProps {
  searchModalVisible: boolean;
  updateSearchModalVisible: () => void;
  handleAddTag: (tag: Tag | string) => void;
  removeTag: (tag: string) => void;
  searchQuery: string;
  setSearchQuery: (tag: string) => void;
  allTags: string[];
  createNewTagButtonVisible: boolean;
  setCreateNewTagButtonVisible: (bool: boolean) => void;
}

export const SearchTagModal = ({
  searchModalVisible,
  updateSearchModalVisible,
  handleAddTag,
  searchQuery,
  setSearchQuery,
  allTags,
  removeTag,
  createNewTagButtonVisible,
  setCreateNewTagButtonVisible,
}: SearchTagModalProps) => {
  const [fetchedTags, setFetchedTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTags = debounce(async () => {
    if (searchQuery !== '') {
      try {
        setIsLoading(true);
        const response = await (
          await authenticatedRequest()
        ).get(`tags/${searchQuery}`);

        const exactMatchIndex = response.data.findIndex(
          (tag: Tag) => tag.title.toLowerCase() === searchQuery.toLowerCase(),
        );
        if (exactMatchIndex !== -1) {
          const exactMatch = response.data[exactMatchIndex];
          response.data.splice(exactMatchIndex, 1);
          response.data.unshift(exactMatch);
          setCreateNewTagButtonVisible(false);
        } else {
          setCreateNewTagButtonVisible(true);
        }

        setFetchedTags(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setFetchedTags([]);
    }
  }, 500);

  const filterFetchedTags = (
    UnfilteredAllTags: string[],
    UnfilteredFetchedTags: Tag[],
  ): Tag[] => {
    return UnfilteredFetchedTags.filter(
      (tag: Tag) => !UnfilteredAllTags.includes(tag.title.toLowerCase()),
    );
  };

  const filteredFetchedTags = filterFetchedTags(allTags, fetchedTags);

  useEffect(() => {
    fetchTags();
    return () => {
      fetchTags.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const renderItem = ({ item }: { item: Tag }) => (
    <TouchableOpacity
      style={styles.listItem}
      onPress={() => handleAddTag(item)}
    >
      <Text style={{ color: colors.secondary, fontSize: 16 }}>
        {item.title} ({item.usage_count}
        {item.usage_count === 1 ? ' post' : ' posts'})
      </Text>
    </TouchableOpacity>
  );

  const handleTextChange = (text: string) => {
    setSearchQuery(text);
    if (text === '') {
      setCreateNewTagButtonVisible(false);
    }
  };

  const renderHeader = () => {
    return createNewTagButtonVisible ? (
      <View>
        <TouchableOpacity
          onPress={() => handleAddTag(searchQuery)}
          style={styles.createTagContainer}
        >
          <FontAwesomeIcon icon={faPlus} color={colors.accent} size={18} />
          <Text
            style={{ color: colors.secondary, marginLeft: 6, fontSize: 16 }}
          >
            Create tag: {searchQuery}
          </Text>
        </TouchableOpacity>
      </View>
    ) : null;
  };

  function getUniqueKey(item: Tag) {
    if (item.id) {
      return item.id;
    } else if (item.tags_id) {
      return item.tags_id;
    } else {
      return 0;
    }
  }

  return (
    <Modal
      animationType="fade"
      transparent={false}
      visible={searchModalVisible}
      onRequestClose={updateSearchModalVisible}
    >
      <SafeAreaView style={styles.container}>
        <SearchModalHeader
          callback={updateSearchModalVisible}
          title={'Search tag'}
        />
        <InputBox_WithSubmit
          placeholderText={'Search for a tag (max 7)'}
          handleInputChange={handleTextChange}
          autocapitalize={'none'}
          isLoading={isLoading}
          value={searchQuery}
          maxLength={120}
          multiline={false}
          submitCallback={() => {
            setSearchQuery('');
            setFetchedTags([]);
            setCreateNewTagButtonVisible(false);
          }}
          submitDisabled={false}
          submitIcon={faTimes}
        />
        <View
          style={{
            ...globalstyles.chipContainer,
            paddingHorizontal: spacing.md * 2,
            marginBottom: spacing.md,
          }}
        >
          {allTags.map((tag, index) => (
            <Pressable
              key={index}
              disabled={isLoading}
              style={{
                ...globalstyles.chip,
                ...globalstyles._selectedChip,
                marginRight: spacing.sm,
                alignItems: 'center',
              }}
              onPress={() => removeTag(tag)}
            >
              <Text
                style={{ ...globalstyles.smallbody, marginRight: spacing.sm }}
              >
                {tag}
              </Text>
              <FontAwesomeIcon
                icon={faTimes}
                color={colors.halfwhite}
                size={10}
              />
            </Pressable>
          ))}
        </View>
        <FlatList
          data={filteredFetchedTags}
          keyboardShouldPersistTaps="handled"
          keyExtractor={(item) => getUniqueKey(item).toString()}
          renderItem={renderItem}
          ListHeaderComponent={renderHeader}
        />
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingHorizontal: spacing.md,
  },
  listItem: {
    width: width - spacing.lg * 2,
    maxWidth: webContentWidth * 2,
    padding: 16,
    backgroundColor: colors.primary_accent,
    marginTop: 4,
    borderRadius: 12,
  },
  createTagContainer: {
    backgroundColor: colors.primary_accent,
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: width - spacing.md * 3,
    maxWidth: webContentWidth * 2,
    borderRadius: 8,
    marginBottom: 8,
  },
});

import { unauthenticatedRequest } from '@/modules/auth';
import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  View,
  Pressable,
  Linking,
} from 'react-native';
import { Image } from 'expo-image';
import { colors } from '@/app/colors';
import {
  globalstyles,
  spacing,
  webContentWidth,
  heightWithHeader,
} from '@/app/globalstyles';
import { ContributorItem, EMPTYCONTRIBUTORITEM } from '@/api/zod';

const SUPPORTLEVELS = [
  {
    key: 'deity',
    name: 'Deity',
    emoji: '💎',
  },
  {
    key: 'queen',
    name: 'Queen',
    emoji: '👑',
  },
  {
    key: 'saint',
    name: 'Saint',
    emoji: '❤️‍🔥',
  },
  {
    key: 'patron',
    name: 'Patron',
    emoji: '❤️',
  },
  {
    key: 'lvl 2',
    name: 'Lvl 2',
    emoji: '✌️✨',
  },
];

export function AboutScreen() {
  const [selectedTier, setSelectedTier] = useState<string>('deity');
  const [contributorList, setContributorList] = useState<ContributorItem[]>();

  useEffect(() => {
    async function fetchContributors() {
      const axios = unauthenticatedRequest();
      const response = await axios.get('contributors_list');
      setContributorList(response.data);
    }
    fetchContributors();
  }, []);

  const addPlaceholders = (data: ContributorItem[]) => {
    if (data.length % 3 === 2) {
      data.push({
        ...contributorList,
        ...EMPTYCONTRIBUTORITEM,
      });
    } else if (data.length % 3 === 1) {
      data.push({
        ...contributorList,
        ...EMPTYCONTRIBUTORITEM,
      });
      data.push({
        ...contributorList,
        ...EMPTYCONTRIBUTORITEM,
      });
    }
    return data;
  };

  const renderContributors = () => {
    let contributors: ContributorItem[] | undefined;

    if (contributorList) {
      contributors = contributorList.filter(
        (contributor) => contributor.tier === selectedTier,
      );
    }

    const listHeader = () => {
      return (
        <View style={{ padding: spacing.md }}>
          <View style={{ marginBottom: spacing.xl }}>
            <Text style={globalstyles.body}>
              Hello! Welcome to Whirl 🌀
              {'\n\n'}
              We've been "AI Art Community" since summer 2022, hosting daily
              themes, weekly themes and collab tournaments on Discord &
              Instagram. To see where it all started, check out our first
              account on IG, which we're permanently locked out of:
              @ai.art.community.
              {'\n\n'}
              Glad you're here! We're having fun.
            </Text>
          </View>
          <View style={{ marginBottom: spacing.xl }}>
            <Text style={{ ...globalstyles.title, marginBottom: spacing.md }}>
              Supporters
            </Text>
            <Text style={globalstyles.body}>
              Massive appreciation for our 225+ community patrons.
            </Text>
          </View>
          <View style={styles.buttonsContainer}>
            {SUPPORTLEVELS.map((level) => (
              <TouchableOpacity
                key={level.name}
                style={
                  selectedTier === level.key
                    ? { ...globalstyles.roundedButton }
                    : {
                        ...globalstyles.roundedButton,
                        backgroundColor: colors.invisible,
                      }
                }
                activeOpacity={0.9}
                onPress={() => setSelectedTier(level.key)}
              >
                <Text
                  style={globalstyles.title}
                >{`${level.emoji} ${level.name}`}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      );
    };

    const handleNav = (item: ContributorItem) => {
      Linking.openURL(`https://instagram.com/${item.username}`);
    };

    return (
      contributors && (
        <FlatList
          data={addPlaceholders(contributors)}
          numColumns={3}
          style={{
            height: heightWithHeader,
            maxWidth: webContentWidth * 2,
          }}
          ListHeaderComponent={listHeader}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <Pressable
              onPress={() => handleNav(item)}
              style={styles.contributorContainer}
            >
              <Image
                style={{
                  ...globalstyles.profileImage,
                  marginBottom: spacing.md,
                }}
                source={{ uri: item.image.url }}
                transition={250}
              />
              <Text numberOfLines={1} style={globalstyles.usernameText}>
                {item.username}
              </Text>
            </Pressable>
          )}
          keyExtractor={(item) => item.id.toString()}
        />
      )
    );
  };

  return (
    <View style={{ ...globalstyles.container, alignItems: 'center' }}>
      {renderContributors()}
    </View>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  contributorContainer: {
    flex: 1,
    alignItems: 'center',
    marginVertical: spacing.md,
  },
});

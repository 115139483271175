import { showToast } from '@/components/ErrorToast';
import { login, unauthenticatedRequest } from '@/modules/auth';
import { useState } from 'react';

type Props = {
  email: string;
  password: string;
};

export const useCreateUser = ({ email, password }: Props) => {
  const [creatingUser, setCreatingUser] = useState(false);
  const [uuid, setUuid] = useState('');

  const createUser = async (username: string) => {
    try {
      setCreatingUser(true);
      const response = await unauthenticatedRequest().post('/auth_2/new_user', {
        email: email,
        password: password,
        username: username,
      });

      if (response.data) {
        await login(response.data);
      }
    } catch (error) {
      showToast(
        'There was an error during signup. Please try again! If the issue persists, start over.',
      );
    } finally {
      setCreatingUser(false);
    }
  };

  const createOAuthUser = async (username: string) => {
    if (uuid) {
      try {
        setCreatingUser(true);
        const response = await unauthenticatedRequest().post(
          '/auth_2/oauth/new_user',
          {
            uuid: uuid,
            username: username,
          },
        );

        if (response.data) {
          await login(response.data);
        }
      } catch (error) {
        showToast('There was an error during signup. Please try again!');
      } finally {
        setCreatingUser(false);
      }
    }
  };

  const [defaultUsername, setDefaultUsername] = useState('');
  const fetchDefaultUsername = async (uuid: string) => {
    try {
      setUuid(uuid);
      const response = await unauthenticatedRequest().get(
        `/auth_2/oauth/default_username/${uuid}`,
      );
      setDefaultUsername(response.data);
    } catch (error) {
      console.log('error');
    }
  };

  return {
    createUser,
    createOAuthUser,
    creatingUser,
    fetchDefaultUsername,
    defaultUsername,
  };
};

import { useCommentStore } from '@/app/store/commentStore';
import { useCommunityStore } from '@/app/store/communityStore';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useExploreStore } from '@/app/store/exploreStore';
import { useMyProfileStore } from '@/app/store/store';
import { useStudioStore } from '@/app/store/studioStore';

export const useBlock = (blocked_user_id: number) => {
  const user = useMyProfileStore((state) => state.myInfo);
  const blockInExplore = useExploreStore((state) => state.handleBlock);
  const blockInDailyTheme = useDailyThemeStore((state) => state.handleBlock);
  const blockInCommunity = useCommunityStore((state) => state.handleBlock);
  const blockInStudio = useStudioStore((state) => state.handleBlock);
  const resetComments = useCommentStore((state) => state.reset);
  const blockGlobally = () => {
    blockInExplore(blocked_user_id);
    blockInDailyTheme(blocked_user_id);
    blockInCommunity(blocked_user_id);
    blockInStudio(blocked_user_id);
    resetComments();
  };

  const resetDailyTheme = useDailyThemeStore((state) => state.reset);
  const fetchChamps = useDailyThemeStore((state) => state.fetchChamps);
  const fetchFinalRoundThemePosts = useDailyThemeStore(
    (state) => state.fetchFinalRoundThemePosts,
  );
  const fetchMoreFirstRoundThemePosts = useDailyThemeStore(
    (state) => state.fetchMoreFirstRoundThemePosts,
  );
  const unblockGlobally = () => {
    resetDailyTheme();
    if (user) {
      fetchChamps(user);
      fetchMoreFirstRoundThemePosts(user);
      fetchFinalRoundThemePosts(user);
    }
  };

  return { blockGlobally, unblockGlobally };
};

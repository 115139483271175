import React, { useEffect, useState } from 'react';
import { StyleSheet, View, FlatList, SafeAreaView } from 'react-native';
import { EMPTYSTATEPOST, Post, Tag, Technology } from '@/api/zod';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';
import Toast from 'react-native-toast-message';
import { useMyProfileStore } from '@/app/store/store';
import { colors } from '@/app/colors';
import {
  globalstyles,
  headerHeight,
  heightWithHeader,
  maxWebContentWidth,
  spacing,
} from '@/app/globalstyles';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { Thumbnail_Hover } from '@/components/shared/Thumbnail_Hover';
import { useTagSearch } from '@/api/search';
import { Modal_Post } from '@/components/shared/Modal_Post';
import { useExploreStore } from '@/app/store/exploreStore';
import usePostActions from '@/api/usePostActions';
import useFadeAnimation from '@/api/useFadeAnimation';
import { addPlaceholders } from '@/api/posts';
import { Layout_Header } from '@/components/explore/Layout_Header';
import Modal_Search from '@/components/explore/Modal_Search';
import { Button_WebFooter } from '@/components/Button_WebFooter';
import { showErrorToastTop } from '@/components/ErrorToast';
import { login } from '@/modules/auth';

export const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor);
  return check;
};

async function handleToken(token: string | undefined) {
  if (token) {
    if (token === 'duplicate-email') {
      showErrorToastTop(
        'Duplicate email',
        'This email exists through another sign-in',
      );
    } else {
      await login(token);
    }
  }
}

export function ExploreScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Explore'>>();

  // LOCAL.
  // const [mounted, setMounted] = useState(false);
  const [searchBarText, setSearchBarText] = useState('Search');
  const [pressedItem, setPressedItem] = useState<Post>(EMPTYSTATEPOST);

  // GLOBAL.
  const myInfo = useMyProfileStore((state) => state.myInfo);
  // -- explore fetch.
  const fetchRecentPosts = useExploreStore((state) => state.fetchRecentPosts);
  const fetchRandomPosts = useExploreStore((state) => state.fetchRandomPosts);
  const fetchFollowingPosts = useExploreStore(
    (state) => state.fetchFollowingPosts,
  );
  const fetchHasPromptPosts = useExploreStore(
    (state) => state.fetchHasPromptPosts,
  );
  const fetchSearchPosts = useExploreStore((state) => state.fetchSearchPosts);
  const fetchSearchTechPosts = useExploreStore(
    (state) => state.fetchSearchTechPosts,
  );
  const fetchTopPosts = useExploreStore((state) => state.fetchTopPosts);
  // -- explore toggle.
  const postList = useExploreStore((state) => state.postList);
  const hasMorePosts = useExploreStore((state) => state.hasMorePosts);
  const loading = useExploreStore((state) => state.loading);
  const activeTab = useExploreStore((state) => state.activeTab);
  const setActiveTab = useExploreStore((state) => state.setActiveTab);
  const searchedTag = useExploreStore((state) => state.searchedTag);
  const setSearchedTag = useExploreStore((state) => state.setSearchedTag);
  const selectedTech = useExploreStore((state) => state.selectedTech);
  const setSelectedTech = useExploreStore((state) => state.setSelectedTech);
  // -- other.
  const fetchVotingThemes = useDailyThemeStore(
    (state) => state.fetchVotingThemes,
  );
  const fetchAllThemeInfo = useDailyThemeStore(
    (state) => state.fetchAllThemeInfo,
  );

  // come back to this.
  // useEffect(() => {
  //   if (isFocused && myInfo) {
  //     checkForNewNotifications();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFocused]);

  // const checkForNewNotifications = async () => {
  //   try {
  //     const authenticatedAxios = await authenticatedRequest();
  //     const response = await authenticatedAxios.get(
  //       `notification/new/${myInfo?.id}`,
  //     );
  //     setIsNewNotifications(response.data.isNewNotifications);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      handleToken(token);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    const fetchData = async () => {
      if (myInfo) {
        await fetchAllThemeInfo(myInfo.id);
        fetchVotingThemes(myInfo, true);
        await fetchTopPosts(myInfo, true);
        // setMounted(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handlers - action.
  const { handleLike, handleSave } = usePostActions();
  const like = () => {
    if (handleLike) {
      handleLike(pressedItem);
      const updatedPost = {
        ...pressedItem,
        likedOrVoted: !pressedItem.likedOrVoted,
        likeCount: !pressedItem.likedOrVoted
          ? pressedItem.likeCount + 1
          : pressedItem.likeCount - 1,
      };
      setPressedItem(updatedPost);
    }
  };
  const save = () => {
    handleSave(pressedItem);
    const updatedPost = {
      ...pressedItem,
      isSaved: !pressedItem.isSaved,
      totalSaves: !pressedItem.isSaved
        ? pressedItem.totalSaves + 1
        : pressedItem.totalSaves - 1,
    };
    setPressedItem(updatedPost);
  };
  const handleLoadMore = () => {
    if (!loading && hasMorePosts && myInfo) {
      if (activeTab === 'recent') {
        fetchRecentPosts(myInfo, false);
      } else if (activeTab === 'tagsearch') {
        if (searchedTag) {
          fetchSearchPosts(searchedTag, myInfo, false);
        }
      } else if (activeTab === 'techsearch') {
        if (selectedTech) {
          fetchSearchTechPosts(selectedTech, myInfo, false);
        }
      } else if (activeTab === 'following') {
        fetchFollowingPosts(myInfo, false);
      } else if (activeTab === 'hasPrompt') {
        fetchHasPromptPosts(myInfo, false);
      } else if (activeTab === 'random') {
        fetchSearchPosts(searchedTag, myInfo, false);
      } else if (activeTab === 'boingle') {
        if (searchedTag) {
          fetchSearchPosts(searchedTag, myInfo, false);
        }
      } else if (activeTab === 'topweekly') {
        fetchTopPosts(myInfo, false);
      }
    }
  };
  const handleFetchTopWeekly = async () => {
    setSearchBarText('Search');
    setActiveTab('topweekly');
    if (myInfo) {
      await fetchTopPosts(myInfo, true);
    }
  };
  const handleFetchRecent = async () => {
    setSearchBarText('Search');
    setActiveTab('recent');
    if (myInfo) {
      await fetchRecentPosts(myInfo, true);
    }
  };
  const handleSearchFollowing = () => {
    setSearchBarText('Search');
    setActiveTab('following');
    if (myInfo) {
      fetchFollowingPosts(myInfo, true);
    }
  };
  const handleSearchNewTag = (tag: Tag) => {
    setSearchedTag(tag);
    setSearchBarText(`Search: #${tag.title}`);
    setActiveTab('tagsearch');
    toggleSearchModal();
    myInfo && fetchSearchPosts(tag, myInfo, true);
  };
  const handleSearchNewTech = (technology: Technology) => {
    setSelectedTech(technology);
    setSearchBarText(`Search: ${technology.name}`);
    setActiveTab('techsearch');
    toggleSearchModal();
    if (myInfo) {
      fetchSearchTechPosts(technology, myInfo, true);
    }
  };
  const handleSearchHasPrompt = () => {
    toggleSearchModal();
    setSearchBarText('Search: Has prompt');
    setActiveTab('hasPrompt');
    if (myInfo) {
      fetchHasPromptPosts(myInfo, true);
    }
  };
  const handleFetchRandom = async () => {
    toggleSearchModal();
    setSearchBarText('Search: Random');
    setActiveTab('random');
    if (myInfo) {
      await fetchRandomPosts(myInfo);
    }
  };
  // handlers - other.
  const handlePress = (item: Post) => {
    const currentIndex = posts.findIndex((post) => post.id === item.id);
    setPressedItem(item);
    setCurrentIndex(currentIndex);
    togglePostModal();
  };

  // search hook.
  const {
    fetchedTags,
    fetchedUsers,
    isUserSearch,
    searchLoading,
    handleClickTag,
    setSearchLoading,
    searchQuery,
    setSearchQuery,
    resetSearch,
  } = useTagSearch({ handleSearchNewTag });

  // render.
  const renderFooter = () => {
    return loading && hasMorePosts ? (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="large" color={colors.halfwhite} />
      </View>
    ) : null;
  };

  // helpers.
  const [currentIndex, setCurrentIndex] = useState(0);
  const posts = addPlaceholders(postList, 4);
  const { fadeAnim, previous, next, commentsVisible, setCommentsVisible } =
    useFadeAnimation(setCurrentIndex, setPressedItem, posts, currentIndex);

  const renderItem = ({ item }: { item: Post }) => {
    return <Thumbnail_Hover item={item} callback={() => handlePress(item)} />;
  };
  const handleTextChange = (text: string) => {
    setSearchLoading(true);
    setSearchQuery(text);
  };
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const toggleSearchModal = () => {
    setSearchModalVisible(!searchModalVisible);
  };
  const handleCancel = () => {
    setSearchModalVisible(false);
    setTimeout(delayedReset, 250);
  };
  const delayedReset = () => {
    setSearchBarText('Search');
    resetSearch();
  };
  const [postModalVisible, setPostModalVisible] = useState(false);
  const togglePostModal = () => {
    setPostModalVisible(!postModalVisible);
  };

  return (
    <SafeAreaView style={globalstyles.webcontainer}>
      <FlatList
        data={posts}
        keyExtractor={(post) => post.id.toString()}
        numColumns={4}
        onEndReached={handleLoadMore}
        onEndReachedThreshold={0.8}
        ListFooterComponent={renderFooter}
        ListHeaderComponent={
          <Layout_Header
            activeTab={activeTab}
            handleFetchTopWeekly={handleFetchTopWeekly}
            handleFetchRecent={handleFetchRecent}
            handleSearchFollowing={handleSearchFollowing}
            toggleModal={toggleSearchModal}
            searchBarText={searchBarText}
          />
        }
        style={styles.listContainer}
        contentContainerStyle={styles.contentContainer}
        renderItem={renderItem}
      />
      <Button_WebFooter navigation={navigation} />
      <Modal_Search
        visible={searchModalVisible}
        toggleVisible={toggleSearchModal}
        close={handleCancel}
        resetSearch={resetSearch}
        handleTextChange={handleTextChange}
        handleClickTag={handleClickTag}
        fetchedTags={fetchedTags}
        fetchedUsers={fetchedUsers}
        searchLoading={searchLoading}
        searchQuery={searchQuery}
        searchHasPrompts={handleSearchHasPrompt}
        searchRandom={handleFetchRandom}
        searchTech={handleSearchNewTech}
        isUserSearch={isUserSearch}
        navigation={navigation}
      />
      <Modal_Post
        setPressedItem={setPressedItem}
        post={pressedItem}
        imageFadeAnim={fadeAnim}
        myInfo={myInfo}
        onClose={togglePostModal}
        isVisible={postModalVisible}
        navigation={navigation}
        like={like}
        save={save}
        next={next}
        previous={previous}
        editable={pressedItem.users_id === myInfo?.id}
        commentsVisible={commentsVisible}
        setCommentsVisible={setCommentsVisible}
      />
      <Toast />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    height: heightWithHeader,
  },
  contentContainer: {
    maxWidth: maxWebContentWidth,
    alignSelf: 'center',
    width: '90%',
    marginTop: headerHeight,
  },
});

import React from 'react';
import { Layout_MobileWebStatic } from '@/components/home/Layout_MobileWebStatic';
import { globalstyles } from '@/app/globalstyles';
import { WhirlLogoImg } from '@/components/unauthenticated/WhirlLogoImg';
import { View } from 'react-native';
import { Icon_WhirlHeaderTitle } from '@/components/unauthenticated/Icon_WhirlHeaderTitle';

export function StaticMobileScreen() {
  return (
    <View
      style={{ ...globalstyles.container, ...globalstyles.centeringContainer }}
    >
      <WhirlLogoImg rounded={true} accented={false} />
      <Icon_WhirlHeaderTitle />
      <Layout_MobileWebStatic />
    </View>
  );
}

import React, { useRef, useState } from 'react';
import { View, Text, Pressable, Animated } from 'react-native';
import { border, font, globalstyles, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { Thumbnail } from './Thumbnail';
import { Post } from '@/api/zod';
import { LinearGradient } from 'expo-linear-gradient';
import { Image } from 'expo-image';
import { StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faComment, faHeart } from '@fortawesome/free-solid-svg-icons';

interface Props {
  item: Post;
  callback: () => void;
  isAnonymous?: boolean;
  showCount?: boolean;
}

export const Thumbnail_Hover = ({
  item,
  callback,
  isAnonymous,
  showCount = false,
}: Props) => {
  const [hover, setHover] = useState(false);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const onHoverIn = () => {
    setHover(true);
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  };

  const onHoverOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 0,
      useNativeDriver: true,
    }).start(() => setHover(false));
  };

  return (
    <Pressable
      style={{
        ...globalstyles.container,
        borderWidth: border.xs,
        borderColor: item.isPlaceholder
          ? colors.primary
          : hover
          ? colors.primary_accent
          : colors.primary,
      }}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      onPress={!item.isPlaceholder ? callback : () => null}
    >
      {item.images[0].image && (
        <Thumbnail post={item} showCount={showCount} onPress={() => null} />
      )}
      {hover && !item.isPlaceholder && (
        <HoverMaterial
          item={item}
          isAnonymous={isAnonymous}
          fadeAnim={fadeAnim}
        />
      )}
    </Pressable>
  );
};

interface HoverProps {
  item: Post;
  isAnonymous?: boolean;
  fadeAnim: any;
}

const HoverMaterial = ({ item, isAnonymous, fadeAnim }: HoverProps) => {
  return (
    <>
      <Animated.View
        style={{
          ...styles.hoverContainer,
          opacity: fadeAnim,
        }}
      >
        <View style={globalstyles.container}>
          {!isAnonymous && (
            <View style={styles.usernameContainer}>
              {item.Profile_picture && (
                <Image
                  source={{ uri: `${item.Profile_picture.url}?tpl=small` }}
                  transition={250}
                  style={{
                    ...globalstyles.profileImageXS,
                    marginRight: spacing.sm,
                  }}
                />
              )}
              <View style={globalstyles.container}>
                <Text style={globalstyles.usernameText} numberOfLines={1}>
                  @{item.username}
                </Text>
              </View>
            </View>
          )}
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {item.likeCount > 0 && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <FontAwesomeIcon
                  style={{ marginRight: spacing.xs }}
                  icon={faHeart}
                  color={colors.secondary}
                  size={font.md}
                />
                <Text style={globalstyles.usernameText}>
                  {item.likeCount}
                  {isAnonymous ? ' votes' : ''}
                </Text>
              </View>
            )}
            {item.likeCount > 0 && item.commentCount > 0 && (
              <View style={{ width: spacing.md }} />
            )}
            {item.commentCount > 0 && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <FontAwesomeIcon
                  style={{ marginRight: spacing.sm }}
                  icon={faComment}
                  color={colors.secondary}
                  size={font.md}
                />
                <Text style={globalstyles.usernameText}>
                  {item.commentCount}
                </Text>
              </View>
            )}
          </View>
        </View>
      </Animated.View>
      <LinearGradient
        colors={['transparent', colors.primary]}
        style={globalstyles.homeScreenGradientFill}
      />
    </>
  );
};

const styles = StyleSheet.create({
  usernameContainer: {
    ...globalstyles.container,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: spacing.sm,
  },
  hoverContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: spacing.md,
  },
});

import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import Toast from 'react-native-toast-message';
import { authenticatedRequest } from '@/modules/auth';
import { Post, Technology } from '@/api/zod';
import { useMyProfileStore } from '@/app/store/store';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { Text_WebPageHeader } from '@/components/Text_WebPageHeader';
import { Layout_WebUploadDetails } from '@/components/shared/Layout_WebUploadDetails';
import { useExploreStore } from '@/app/store/exploreStore';

export function EditPostScreen() {
  const {
    params: { post, updateLocalPostList },
  } = useRoute<AuthenticatedNavigatorRouteProp<'EditPost'>>();
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const navigation = useNavigation<AuthenticatedNavigatorProp<'EditPost'>>();
  const deletePostFromAllPostList = useMyProfileStore(
    (state) => state.deletePostFromAllPostList,
  );
  const deletePostFromDailyThemePostList = useMyProfileStore(
    (state) => state.deletePostFromDailyThemePostList,
  );
  const deletePostFromExplorePostList = useExploreStore(
    (state) => state.deletePostFromExplorePostList,
  );
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );
  const setSubmissionThemeInfo = useDailyThemeStore(
    (state) => state.setSubmissionThemeInfo,
  );

  useEffect(() => {
    async function fetchTechnologies() {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get('technology_type');
      setTechnologies(response.data);
    }
    fetchTechnologies();
  }, []);

  // MISC.
  const [loading, setLoading] = useState(false);

  // DELETE.
  // GIVE ALL THE INFO TO THE BELOW HERE. TOO MUCH ACTION HERE RIGHT NOW.
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };
  const handleDeletePost = async () => {
    try {
      setLoading(true);
      const authenticatedAxiosInstance = await authenticatedRequest();
      await authenticatedAxiosInstance.delete(`post/${post.id}`);

      deletePostFromExplorePostList(post.id);
      deletePostFromAllPostList(post.id);
      deletePostFromDailyThemePostList(post.id);
      // setEditedTitle('');
      const updateThemeInfo = {
        ...submissionThemeInfo,
        loggedInUserHasSubmitted: false,
      };
      setSubmissionThemeInfo(updateThemeInfo);
      // setEditedPrompt('');
      toggleDeleteModal();
      navigation.push('Explore', { passedTag: undefined });
      showSuccessToast('Deleted post');
    } catch (error) {
      console.log(error);
      showToast('Error deleting post');
    } finally {
      setLoading(false);
    }
  };

  // GLOBAL UPDATE.
  const updatePostInExplorePostList = useExploreStore(
    (state) => state.updatePostInExplorePostList,
  );
  const updatePostInAllPostList = useMyProfileStore(
    (state) => state.updatePostInAllPostList,
  );
  const updatePostInDailyThemePostList = useMyProfileStore(
    (state) => state.updatePostInDailyThemePostList,
  );
  const updatePostInGlobalLists = (updatedPost: Post) => {
    updatePostInDailyThemePostList(updatedPost);
    updatePostInAllPostList(updatedPost);
    updatePostInExplorePostList(updatedPost);
    if (updateLocalPostList) {
      updateLocalPostList(updatedPost);
    }
  };

  // TECHNOLOGIES.
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [selectedTechnology, setSelectedTechnology] = useState({
    id: post.technology_type_id,
    name: post.technology_type.name,
    emoji: post.technology_type.emoji,
  });
  useEffect(() => {
    async function fetchTechnologies() {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get('technology_type');
      setTechnologies(response.data);
    }
    fetchTechnologies();
  }, [setTechnologies]);

  // TAGS.
  const existingTagTitles = post.tags.map((tag) => tag.title);
  const [allTags, setAllTags] = useState<string[]>(existingTagTitles);
  const addTag = (tag: string) => {
    setAllTags([...allTags, tag.toLocaleLowerCase().trim()]);
  };
  const removeTag = (tagToRemove: string) => {
    setAllTags(allTags.filter((tag) => tag !== tagToRemove));
  };
  const resetTags = () => {
    setAllTags(existingTagTitles);
  };

  return (
    <KeyboardAwareScrollView
      style={globalstyles.container}
      contentContainerStyle={styles.contentContainer}
      extraScrollHeight={96}
      enableOnAndroid
    >
      <Text_WebPageHeader
        header={'Edit post'}
        subtitle={'Manage your post details'}
      />
      {myInfo && (
        <Layout_WebUploadDetails
          loading={loading}
          technologies={technologies}
          allTags={allTags}
          removeTag={removeTag}
          addTag={addTag}
          navigation={navigation}
          setLoading={setLoading}
          daily_theme_id={null}
          resetTags={resetTags}
          myInfo={myInfo}
          file={undefined}
          setFile={undefined} // make this optional.
          selectedTechnology={selectedTechnology}
          setSelectedTechnology={setSelectedTechnology}
          post={post}
          handleDeletePost={handleDeletePost}
          updatePostInGlobalLists={updatePostInGlobalLists}
          updateLocalPostList={updateLocalPostList}
        />
      )}
      <Toast />
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    height: heightWithHeader,
    width: '100%',
    alignItems: 'center',
    paddingBottom: 96,
    paddingHorizontal: spacing.md,
    alignSelf: 'center',
  },
});

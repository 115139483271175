import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import { colors } from '@/app/colors';
import {
  globalstyles,
  font,
  radius,
  spacing,
  width,
  webContentWidth,
} from '@/app/globalstyles';

type InputBox_WithLabelProps = {
  placeholderText: string;
  handleInputChange: (text: string) => void;
  autocapitalize: 'none' | 'sentences';
  label: string;
  value?: string;
  maxLength?: number;
  isLoading?: boolean;
  multiline?: boolean;
  numberOfLines?: number;
  editable?: boolean;
  bgColor?: string;
};

export function InputBox_WithLabel({
  placeholderText,
  handleInputChange,
  autocapitalize = 'none',
  label,
  maxLength,
  isLoading,
  value,
  multiline,
  numberOfLines,
  editable = true,
  bgColor,
}: InputBox_WithLabelProps) {
  return (
    <>
      <View style={{ alignSelf: 'flex-start' }}>
        <Text
          style={{
            ...globalstyles.label,
            marginBottom: spacing.md,
          }}
        >
          {label}
        </Text>
      </View>
      <View style={{ marginBottom: spacing.md }}>
        <TextInput
          placeholder={placeholderText}
          placeholderTextColor={colors.halfwhite}
          onChangeText={handleInputChange}
          style={{
            ...styles.input,
            backgroundColor: bgColor ? bgColor : colors.primary_offset,
          }}
          autoCapitalize={autocapitalize}
          autoCorrect={true}
          editable={editable}
          value={value}
          maxLength={maxLength ? maxLength : undefined}
          multiline={multiline ? multiline : false}
          numberOfLines={numberOfLines ? numberOfLines : 1}
        />
        {isLoading ? (
          <ActivityIndicator size="small" color={colors.halfwhite} />
        ) : null}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    color: colors.secondary,
    borderRadius: radius.sm,
    paddingTop: spacing.md,
    paddingLeft: spacing.md,
    paddingBottom: spacing.md,
    fontSize: font.lg,
    marginBottom: spacing.sm,
    width: width - spacing.md * 2,
    maxWidth: webContentWidth * 2,
  },
});

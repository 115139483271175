import React from 'react';
import { View } from 'react-native';
import { colors } from '@/app/colors';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import ModalHOC from './hoc/ModalHOC';
import { Button_Settings } from './Button_Settings';

interface Props {
  callback1: () => void;
  callback2: () => void;
  text1: string;
  text2: string;
  icon1: IconDefinition;
  iconColor1?: string;
  icon2: IconDefinition;
  iconColor2?: string;
}

const Modal_TwoOptionsContent = ({
  callback1,
  callback2,
  text1,
  text2,
  icon1,
  iconColor1,
  icon2,
  iconColor2,
}: Props) => {
  return (
    <View>
      <Button_Settings
        callback={callback1}
        title={text1}
        icon={icon1}
        iconColor={iconColor1 ? iconColor1 : colors.secondary}
        bgColor={colors.primary_offset}
        marginBottom={true}
      />
      <Button_Settings
        callback={callback2}
        title={text2}
        icon={icon2}
        iconColor={iconColor2 ? iconColor2 : colors.secondary}
        bgColor={colors.primary_offset}
      />
    </View>
  );
};

const Modal_TwoOptions = ModalHOC(Modal_TwoOptionsContent);

export default Modal_TwoOptions;

import { Post } from '@/api/zod';
import { toggleSavePost, toggleLikePost } from '@/api/posts';
import { globalstyles, spacing } from '@/app/globalstyles';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useMyProfileStore } from '@/app/store/store';
import { RenderPost } from '@/components/RenderPost';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ScrollView, SafeAreaView } from 'react-native';
import { Button_Navigation } from '@/components/Button_Navigation';
import { ThemeSubtitle } from '@/components/ThemeSubtitle';
import { useExploreStore } from '@/app/store/exploreStore';

export function ViewChampsScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'ViewChamps'>>();
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const champPostList = useDailyThemeStore((state) => state.champPostList);
  const champThemeInfo = useDailyThemeStore((state) => state.champThemeInfo);
  const [activeDescriptionId, setActiveDescriptionId] = useState<number>();
  const updatePostInAllPostList = useMyProfileStore(
    (state) => state.updatePostInAllPostList,
  );
  const updatePostInDailyThemePostList = useMyProfileStore(
    (state) => state.updatePostInDailyThemePostList,
  );
  const updatePostInExplorePostList = useExploreStore(
    (state) => state.updatePostInExplorePostList,
  );
  const updatePostInChampPostList = useDailyThemeStore(
    (state) => state.updatePostInChampPostList,
  );
  const fetchChamps = useDailyThemeStore((state) => state.fetchChamps);

  useEffect(() => {
    myInfo && fetchChamps(myInfo);
  }, [fetchChamps, myInfo]);

  const handleLike = (post: Post) => {
    myInfo && toggleLikePost(myInfo, post);

    const updatedPost = {
      ...post,
      likedOrVoted: !post.likedOrVoted,
      likeCount: !post.likedOrVoted ? post.likeCount + 1 : post.likeCount - 1,
    };

    updatePostInChampPostList(updatedPost);
    updatePostInDailyThemePostList(updatedPost);
    updatePostInAllPostList(updatedPost);
    updatePostInExplorePostList(updatedPost);
  };

  const handleSavePost = (post: Post) => {
    myInfo && toggleSavePost(myInfo, post);

    // global store update.
    const updatedPost = {
      ...post,
      isSaved: !post.isSaved,
      totalSaves: !post.isSaved ? post.totalSaves + 1 : post.totalSaves - 1,
    };
    updatePostInChampPostList(updatedPost);
    updatePostInExplorePostList(updatedPost);
    updatePostInDailyThemePostList(updatedPost);
    updatePostInAllPostList(updatedPost);
  };

  const updateActiveDescriptionId = (id: number | undefined) => {
    setActiveDescriptionId(id);
  };

  const handleArchiveNavigation = () => {
    navigation.push('DailyThemeArchivesGallery', {
      daily_theme_event: champThemeInfo,
    });
  };

  const renderChamps = () => {
    return champPostList.map((post) => {
      return (
        <RenderPost
          user={myInfo}
          key={post.id}
          navigation={navigation}
          post={post}
          handleLike={handleLike}
          handleSavePost={handleSavePost}
          voteable={false}
          editable={false}
          updateActiveDescriptionId={updateActiveDescriptionId}
          activeDescriptionId={activeDescriptionId}
        />
      );
    });
  };

  return (
    <SafeAreaView style={globalstyles.container}>
      <ThemeSubtitle
        title={champThemeInfo?.title}
        pretitle={'In the theme of: '}
      />
      <ScrollView style={styles.scrollView}>{renderChamps()}</ScrollView>
      <View
        style={{
          flexDirection: 'row',
          marginVertical: spacing.md,
          alignSelf: 'center',
        }}
      >
        <Button_Navigation
          title={'See all submissions'}
          callback={handleArchiveNavigation}
        />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    alignSelf: 'center',
  },
});

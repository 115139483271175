import React, { useState } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { colors } from '@/app/colors';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest } from '@/modules/auth';
import { EMPTYSTATE_OPENAIIMAGEOBJECT, OpenAIImageObject } from '@/api/zod';
import { useStudioStore } from '@/app/store/studioStore';
import { RenderGalleryImage } from './RenderGalleryImage';
import { Modal_FullScreenImage } from '../Modal_FullScreenImage';
import { InputBox_WithSubmit } from '@/components/InputBox_WithSubmit';
import { useMyProfileStore } from '@/app/store/store';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import Purchases from 'react-native-purchases';

export function StudioScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const [n, setN] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const setTextPrompt = useStudioStore((state) => state.setTextPrompt);
  const addNewImagesToGalleries = useStudioStore(
    (state) => state.addNewImagesToGalleries,
  );
  // const setActiveSubscription = useStudioStore(
  //   (state) => state.setActiveSubscription,
  // );
  const hasRemainingCredits = useStudioStore(
    (state) => state.hasRemainingCredits,
  );
  const fetchHasRemainingCredits = useStudioStore(
    (state) => state.fetchHasRemainingCredits,
  );
  const textPrompt = useStudioStore((state) => state.textPrompt);
  const [images, setImages] = useState<OpenAIImageObject[]>([
    EMPTYSTATE_OPENAIIMAGEOBJECT,
    EMPTYSTATE_OPENAIIMAGEOBJECT,
    EMPTYSTATE_OPENAIIMAGEOBJECT,
  ]);

  const numbers = Array.from({ length: 2 }, (_, i) => i + 1);

  const updateFullScreenModalVisible = async () => {
    setFullScreenModalVisible(!fullScreenModalVisible);
  };

  const createImage = async () => {
    setLoading(true);
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.post(
        '/image_generations/create',
        {
          users_id: user?.id,
          prompt: textPrompt,
          variations: n,
          size: '256x256',
        },
      );
      user && fetchHasRemainingCredits(user.id);
      setImages(response.data);
      addNewImagesToGalleries(response.data);
      setTextPrompt('');
    } catch (error) {
      showToast('Error creating image');
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const doTheThing = async () => {
  //     try {
  //       const customerInfo = await Purchases.getCustomerInfo();
  //       setActiveSubscription(customerInfo.activeSubscriptions[0]);
  //       // access latest customerInfo
  //     } catch (e) {
  //       // Error fetching customer info
  //     }
  //   };
  //   doTheThing();
  // }, [setActiveSubscription]);

  // const renderHeader = () => {
  //   return <Subtitle text={'Generate images with words'} />;
  // };

  return (
    <>
      <KeyboardAwareScrollView
        style={globalstyles.container}
        keyboardShouldPersistTaps="handled"
        enableOnAndroid
      >
        <FlatList
          data={images}
          numColumns={3}
          style={{
            height: heightWithHeader,
            paddingHorizontal: spacing.sm,
            paddingTop: spacing.md,
          }}
          renderItem={({ index, item }) => (
            <RenderGalleryImage
              item={item}
              index={index}
              numColumns={3} // for width.
              setFullScreenUrl={setFullScreenUrl}
              updateFullScreenModalVisible={updateFullScreenModalVisible}
              hasRemainingCredits={hasRemainingCredits}
            />
          )}
          keyExtractor={(index) => index.toString()}
        />
        <View style={globalstyles.promptInputContainer}>
          <View style={{ alignItems: 'center', alignSelf: 'center' }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Text
                style={{
                  ...globalstyles.label,
                  marginBottom: spacing.md,
                }}
              >
                Prompt an image below
              </Text>
              <View
                style={{
                  ...globalstyles.chipContainer,
                  marginBottom: spacing.md,
                }}
              >
                <View style={{ marginLeft: spacing.md }}>
                  <Text style={globalstyles.label}># </Text>
                </View>
                {numbers.map((num) => (
                  <TouchableOpacity
                    style={{
                      ...globalstyles.chip,
                      backgroundColor:
                        num === n ? colors.primary_accent : colors.primary,
                    }}
                    activeOpacity={0.9}
                    key={num}
                    onPress={() => setN(num)}
                  >
                    <Text
                      style={
                        num === n
                          ? {
                              ...globalstyles.chipText,
                              ...globalstyles._selectedChipText,
                            }
                          : {
                              ...globalstyles.chipText,
                            }
                      }
                    >
                      {num}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
            <InputBox_WithSubmit
              placeholderText={
                hasRemainingCredits
                  ? 'Describe something...'
                  : 'No test credits left'
              }
              handleInputChange={setTextPrompt}
              autocapitalize={'sentences'}
              editable={hasRemainingCredits ? true : false}
              multiline={false}
              isLoading={loading}
              value={textPrompt}
              submitCallback={createImage}
              submitDisabled={
                textPrompt === '' || !hasRemainingCredits ? true : false
              }
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={updateFullScreenModalVisible}
        bare_link={fullScreenUrl}
      />
    </>
  );
}

// const Placeholder = () => {
//   const animation = useRef(new Animated.Value(0)).current;

//   const startAnimation = () => {
//     Animated.loop(
//       Animated.sequence([
//         Animated.timing(animation, {
//           toValue: 1,
//           duration: 1000,
//           useNativeDriver: false,
//         }),
//         Animated.timing(animation, {
//           toValue: 0,
//           duration: 1000,
//           useNativeDriver: false,
//         }),
//       ]),
//     ).start();
//   };

//   useEffect(() => {
//     startAnimation();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const backgroundColor = animation.interpolate({
//     inputRange: [0, 1],
//     outputRange: [colors.primary, colors.primary_accent],
//   });

//   return (
//     <Animated.View style={{ ...globalstyles.thumbnailWeb, backgroundColor }} />
//   );
// };

import { showToast } from '@/components/ErrorToast';
import { login, unauthenticatedRequest } from '@/modules/auth';
import { useState } from 'react';

type Props = {
  email: string;
  password: string;
};

export const useLogin = ({ email, password }: Props) => {
  const [loading, setLoading] = useState(false);

  async function tryLogin() {
    try {
      setLoading(true);
      const response = await unauthenticatedRequest().post('/auth/login', {
        email: email,
        password: password,
      });

      if (response.data) {
        await login(response.data);
      }
    } catch (error) {
      showToast('Sign in error! Review your email and password.');
    } finally {
      setLoading(false);
    }
  }

  return { tryLogin, loading };
};

import React from 'react';
import { ScrollView } from 'react-native';
import { colors } from '@/app/colors';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import Toast from 'react-native-toast-message';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faShield } from '@fortawesome/free-solid-svg-icons/faShield';
import { Button_Settings } from '@/components/shared/Button_Settings';

export function AccountDetailsScreen() {
  const { navigate } =
    useNavigation<AuthenticatedNavigatorProp<'MySettings'>>();
  return (
    <ScrollView
      style={globalstyles.container}
      contentContainerStyle={{
        alignItems: 'center',
        paddingTop: spacing.md,
        height: heightWithHeader,
      }}
    >
      <Button_Settings
        callback={() => navigate('Privacy')}
        title={'Privacy'}
        icon={faLock}
        bgColor={colors.primary_offset}
        marginBottom
      />
      <Button_Settings
        callback={() => navigate('TermsOfUse')}
        title={'Terms of Use'}
        icon={faPen}
        bgColor={colors.primary_offset}
        marginBottom
      />
      <Button_Settings
        callback={() => navigate('MyBlockedUsers')}
        title={'Blocked users'}
        icon={faShield}
        bgColor={colors.primary_offset}
        marginBottom
      />
      <Button_Settings
        callback={() => navigate('DeleteAccount')}
        title={'Delete my account'}
        icon={faTimes}
        iconColor={colors.alert}
        textColor={colors.alert}
        bgColor={colors.primary_offset}
        marginBottom
      />
      <Toast />
    </ScrollView>
  );
}

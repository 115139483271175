import React from 'react';
import { View } from 'react-native';
import { Image } from 'expo-image';
import { globalstyles, spacing } from '@/app/globalstyles';

export const Icon_WhirlVideo = () => {
  return (
    <View style={{ paddingVertical: spacing.lg }}>
      <Image
        source={require('../../assets/nobg-icon.png')}
        transition={250}
        style={{
          ...globalstyles.profileImage,
          ...globalstyles.centeringContainer,
        }}
      />
    </View>
  );
};

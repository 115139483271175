import React from 'react';
import { View, Text } from 'react-native';
import { globalstyles, spacing, webContentWidth } from '@/app/globalstyles';
import { Technology } from '@/api/zod';
import { Button_TechnologyChip } from './Button_TechnologyChip';

interface Props {
  technologies: Technology[];
  loading: boolean;
  selectedTechnology: Technology;
  setSelectedTechnology: (technology: Technology) => void;
}

export const Layout_TechnologyChips = ({
  technologies,
  loading,
  selectedTechnology,
  setSelectedTechnology,
}: Props) => {
  return (
    <View
      style={{
        ...globalstyles.withBottomBorderSpacing,
        marginBottom: spacing.md,
        borderBottomWidth: 0,
        maxWidth: webContentWidth * 2,
      }}
    >
      <View style={globalstyles.chipContainer}>
        <Text style={{ ...globalstyles.label, marginRight: spacing.sm }}>
          Toolkit{' '}
        </Text>
        {technologies.map((t) => {
          return (
            <Button_TechnologyChip
              item={t}
              key={t.id}
              isSelected={selectedTechnology.id === t.id}
              loading={loading}
              callback={() => setSelectedTechnology(t)}
            />
          );
        })}
      </View>
    </View>
  );
};

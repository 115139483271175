import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { Technology } from '@/api/zod';
import { colors } from '@/app/colors';
import { DEFAULTTECH } from '@/api/posts';

interface LayoutProps {
  searchTech: (tech: Technology) => void;
}

export const Layout_TechnologyChips = ({ searchTech }: LayoutProps) => {
  return (
    <View style={globalstyles.chipContainer}>
      {DEFAULTTECH.map((t) => (
        <Button_Tech searchTech={searchTech} item={t} />
      ))}
    </View>
  );
};

interface Props {
  item: Technology;
  searchTech: (item: Technology) => void;
}

const Button_Tech = ({ item, searchTech }: Props) => {
  const [hover, setHover] = useState(false);
  const backgroundColor = hover ? colors.primary_accent : colors.primary_offset;

  return (
    <Pressable
      style={{
        ...styles.button,
        backgroundColor,
        borderRadius: radius.lg,
      }}
      onPress={() => searchTech(item)}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <Text
        style={{
          ...globalstyles.smallbody,
          // opacity: hover ? 1 : 0.8,
        }}
      >
        {`${item.emoji} ${item.name}`}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.sm,
    margin: spacing.xs,
  },
});

import React, { useState } from 'react';
import { Text, StyleSheet, View, FlatList, Pressable } from 'react-native';
import {
  Comment,
  EMPTYSTATECOMMENT,
  Post,
  ProfileResponse_Mini,
} from '@/api/zod';
import { colors } from '@/app/colors';
import {
  globalstyles,
  font,
  spacing,
  radius,
  webContentWidth,
  border,
} from '@/app/globalstyles';
import usePostActions from '@/api/usePostActions';
import { useMyProfileStore } from '@/app/store/store';
import { Layout_RenderComment } from './Layout_RenderComment';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import { useUserMentionSearch } from '@/api/useUserMentionSearch';
import { ListItem_User } from '../ListItem_User';
import { renderFooter } from '../Layout_FlatListFooter';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { InputBox_Comment } from './InputBox_Comment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal_CommentActions from './Modal_CommentActions';
import Button_CreateComment from './Button_CreateComment';

interface Props {
  post: Post;
  navigation: StackNavigationProp<ParamListBase>;
  comments: Comment[];
  loading: boolean;
  hasMoreComments: boolean;
  isAnonymous?: boolean;
  fetchComments: (post: Post, users_id: number) => void;
  likeCommentOrReply: (
    comment: Comment,
    users_id: number,
    isReply?: boolean,
  ) => void;
  toggleComment: () => void;
  handleClose: () => void;
  setPressedItem: (post: Post) => void;
  createComment: (post: Post, users_id: number, commentInput: string) => void;
  createReply: (
    comment: Comment,
    users_id: number,
    commentInput: string,
  ) => void;
  deleteComment: (comment: Comment) => void;
  newCommentLoading: boolean;
  theHeight: number;
  theWidth: number;
}

export const Layout_Comment = ({
  post,
  comments,
  navigation,
  loading,
  hasMoreComments,
  isAnonymous,
  fetchComments,
  likeCommentOrReply,
  toggleComment,
  handleClose,
  setPressedItem,
  createComment,
  createReply,
  deleteComment,
  newCommentLoading,
  theHeight,
  theWidth,
}: Props) => {
  // GLOBAL STORE.
  const myInfo = useMyProfileStore((state) => state.myInfo);

  // LOCAL STORE.
  const [commentActionModalVisible, setCommentActionModalVisible] =
    useState<boolean>(false);
  const [pressedComment, setPressedComment] =
    useState<Comment>(EMPTYSTATECOMMENT);
  const [commentInput, setCommentInput] = useState('');
  const [replying, setReplying] = useState(false);
  const [usernameReplyingTo, setUsernameReplyingTo] = useState('');

  // LAYOUT HANDLERS.
  const openCommentActions = (item: Comment) => {
    setPressedComment(item);
    setCommentActionModalVisible(true);
  };
  const updateCommentActionModalVisible = () => {
    setCommentActionModalVisible(!commentActionModalVisible);
  };
  const handleNav = (item: Comment) => {
    handleClose();
    navigation.push('UserProfile', {
      username: item.username,
    });
  };
  const leaveReplyMode = () => {
    setReplying(false);
    setUsernameReplyingTo('');
    setCommentReplyingTo(undefined);
    setCommentInput('');
  };
  const eraseText = () => {
    setCommentInput('');
    toggleComment();
  };

  // ACTION HANDLERS.
  const handleDeleteComment = (comment: Comment) => {
    deleteComment(comment);
    const newCount = post.commentCount - 1 - comment.replies.length;
    const updatedPost = {
      ...post,
      commentCount: newCount,
    };
    setCommentActionModalVisible(false);
    setPressedItem(updatedPost);
    updatePostListsGlobally(updatedPost);
  };
  const handleCreateComment = () => {
    if (myInfo) {
      if (!replying) {
        createComment(post, myInfo.id, commentInput);
      } else if (commentReplyingTo) {
        createReply(commentReplyingTo, myInfo.id, commentInput);
        leaveReplyMode();
      }
      const updatedPost = {
        ...post,
        commentCount: post.commentCount + 1,
      };
      setPressedItem(updatedPost);
      updatePostListsGlobally(updatedPost);
    }
    setCommentInput('');
  };
  const handleReply = (comment: Comment) => {
    if (isMineInDailyTheme) {
      return;
    }
    setReplying(true);
    if (comment.isReply && comment.comments_id) {
      const originalComment = comments.find(
        (c) => c.id === comment.comments_id,
      );
      if (originalComment) {
        setUsernameReplyingTo(comment.username);
        setCommentReplyingTo(originalComment);
      } else {
        console.log('Original comment not found');
      }
    } else {
      setUsernameReplyingTo(comment.username);
      setCommentReplyingTo(comment);
    }
    setCommentInput(`@${comment.username} `);
  };
  const handleLike = (item: Comment) => {
    if (myInfo) {
      if (item.isReply) {
        likeCommentOrReply(item, myInfo.id, true);
      } else {
        likeCommentOrReply(item, myInfo.id);
      }
    }
  };

  const handleNavToUser = (username: string) => {
    handleClose();
    navigation.push('UserProfile', {
      username:
        username === myInfo?.username ? myInfo?.username : username.slice(1),
    });
  };
  const [commentReplyingTo, setCommentReplyingTo] = useState<
    Comment | undefined
  >();

  // RENDERERS.
  const renderItem = ({ item }: { item: Comment }) => {
    return (
      <View key={item.id}>
        <Layout_RenderComment
          handleNav={handleNav}
          handleLike={handleLike}
          handleReply={handleReply}
          commentReplyingTo={commentReplyingTo}
          openCommentActions={openCommentActions}
          myInfo={myInfo}
          item={item}
          handleNavToUser={handleNavToUser}
        />
      </View>
    );
  };
  const renderUserMentionItem = ({ item }: { item: ProfileResponse_Mini }) => {
    return (
      <ListItem_User
        pressedUser={item}
        navigation={navigation}
        callback={() => addUserToMentions(item)}
      />
    );
  };
  const renderNullCommentSet = () => {
    if (!loading && comments.length === 0) {
      return (
        <View
          style={{
            ...styles.image,
            width: theWidth,
            height: theHeight,
            alignItems: 'center',
            paddingTop: spacing.md,
          }}
        >
          <Text style={globalstyles.usernameText}>No comments yet...</Text>
        </View>
      );
    }
  };

  // MENTIONS.
  const {
    fetchedUsers,
    resetSearch,
    handleInputChange: handleUserInputChange,
  } = useUserMentionSearch();
  const { updatePostListsGlobally } = usePostActions();
  const [isTypingMention, setIsTypingMention] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ start: 0, end: 0 });
  const handleSelectionChange = ({ nativeEvent: { selection } }: any) => {
    setCursorPosition(selection);
  };
  const handleInputChange = (text: string) => {
    setCommentInput(text);

    if (text.endsWith('@')) {
      setIsTypingMention(true);
      handleUserInputChange('@');
      return;
    }

    const currentWord = getCurrentWord(text);
    const wordStartsWithAt = currentWord.startsWith('@');
    const charBeforeCursorIsSpace = text[cursorPosition.start - 1] === ' ';

    setIsTypingMention(wordStartsWithAt && !charBeforeCursorIsSpace);

    if (wordStartsWithAt && !charBeforeCursorIsSpace) {
      handleUserInputChange(currentWord);
    } else if (charBeforeCursorIsSpace || text === '') {
      setIsTypingMention(false);
      resetSearch();
    }
  };
  const getCurrentWord = (text: string) => {
    if (text[cursorPosition.start] === ' ') {
      return ' ';
    }
    const start = text.lastIndexOf(' ', cursorPosition.start - 1) + 1;
    const end = text.indexOf(' ', cursorPosition.start);
    return text.substring(start, end !== -1 ? end : text.length);
  };
  const addUserToMentions = (item: ProfileResponse_Mini) => {
    const startOfCurrentMentionIdx = commentInput.lastIndexOf(
      '@',
      cursorPosition.start,
    );
    if (startOfCurrentMentionIdx !== -1) {
      const spaceAfterMentionIdx = commentInput.indexOf(
        ' ',
        startOfCurrentMentionIdx,
      );
      const endOfCurrentMentionIdx =
        spaceAfterMentionIdx !== -1
          ? spaceAfterMentionIdx
          : commentInput.length;
      const alreadyHasSpace = commentInput[endOfCurrentMentionIdx] === ' ';
      const updatedInput = `${commentInput.substring(
        0,
        startOfCurrentMentionIdx,
      )}@${item.username}${alreadyHasSpace ? '' : ' '}${commentInput.substring(
        endOfCurrentMentionIdx,
      )}`;
      setCommentInput(updatedInput);
    }
    resetSearch();
    setIsTypingMention(false);
  };

  // HELPERS.
  const keyExtractor = (item: Comment) => item.id.toString();
  const isMineInDailyTheme = isAnonymous && myInfo?.id === post.users_id;
  const isDisabled =
    commentInput === '' || isMineInDailyTheme || newCommentLoading;

  return (
    <View>
      <View style={styles.leadContainer}>
        {!loading && comments.length === 0 ? (
          <>{renderNullCommentSet()}</>
        ) : (
          <FlatList
            data={comments}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            style={{
              ...styles.image,
              height: theHeight,
              width: theWidth,
            }}
            contentContainerStyle={{ paddingBottom: spacing.md }}
            onEndReached={() => {
              myInfo && fetchComments(post, myInfo?.id);
            }}
            onEndReachedThreshold={0.8}
            ListFooterComponent={() => renderFooter(loading, hasMoreComments)}
          />
        )}
        {isTypingMention &&
          (fetchedUsers.length === 0 ? (
            <View style={styles.inputContainer}>
              <View
                style={{
                  ...styles.image,
                  width: theWidth,
                  height: theHeight,
                  justifyContent: 'flex-end',
                }}
              >
                <Text style={styles.emptyFooter}>Search users...</Text>
              </View>
            </View>
          ) : (
            <View style={styles.inputContainer}>
              <FlatList
                data={fetchedUsers}
                keyExtractor={(item) => item.id.toString()}
                renderItem={renderUserMentionItem}
                inverted
                style={{
                  ...styles.image,
                  width: theWidth,
                  height: theHeight,
                }}
                initialNumToRender={10}
              />
            </View>
          ))}
      </View>
      {replying && (
        <Pressable
          onPress={leaveReplyMode}
          style={styles.replyRemovalContainer}
        >
          <Text style={globalstyles.smallfade}>
            Replying to{' '}
            <Text
              style={{ ...globalstyles.usernameText, color: colors.accent }}
            >
              @{usernameReplyingTo}
            </Text>
          </Text>
          <FontAwesomeIcon icon={faTimes} color={colors.halfwhite} size={16} />
        </Pressable>
      )}
      <View style={styles.footerContainer}>
        <View style={styles.titleContainer}>
          <InputBox_Comment
            placeholderText={
              isMineInDailyTheme
                ? 'Comment on yours tomorrow!'
                : 'Add a comment'
            }
            handleInputChange={handleInputChange}
            value={commentInput}
            editable={!isMineInDailyTheme}
            onSelectionChange={handleSelectionChange}
          />
        </View>
        <Button_CreateComment
          handleCreateComment={handleCreateComment}
          disabled={isDisabled}
          newCommentLoading={newCommentLoading}
          isMineInDailyTheme={isMineInDailyTheme}
        />
        <Pressable
          style={styles.actionButtonContainer}
          onPress={eraseText}
          disabled={newCommentLoading}
        >
          <FontAwesomeIcon
            style={styles.actionButton}
            icon={faTimes}
            color={colors.secondary}
            size={20}
          />
        </Pressable>
      </View>
      <Modal_CommentActions
        cancel={updateCommentActionModalVisible}
        isVisible={commentActionModalVisible}
        toggleVisible={updateCommentActionModalVisible}
        comment={pressedComment}
        handleDeleteComment={handleDeleteComment}
        users_id={myInfo?.id}
        navigation={navigation}
        post={post}
        handleClose={handleClose}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  leadContainer: {
    ...globalstyles.container,
    backgroundColor: colors.primary,
    padding: spacing.xxs / 2,
    borderTopRightRadius: radius.sm,
    borderTopLeftRadius: radius.sm,
    // highly sus method.
    maxWidth: webContentWidth * 2 - spacing.sm - spacing.xxs,
  },
  image: {
    borderTopRightRadius: radius.sm,
    borderTopLeftRadius: radius.sm,
  },
  replyRemovalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing.md,
    backgroundColor: colors.darkshadow,
    borderTopColor: colors.primary_accent,
    borderTopWidth: spacing.xxs / 2,
  },
  input: {
    color: colors.secondary,
    borderRadius: radius.sm,
    padding: spacing.md,
    paddingTop: spacing.md, // declaring it helped.
    fontSize: font.lg,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    paddingHorizontal: spacing.sm,
    paddingVertical: spacing.sm,
    backgroundColor: colors.primary,
  },
  emptyFooter: {
    textAlign: 'center',
    color: colors.secondary,
    margin: spacing.md,
  },
  footerContainer: {
    ...globalstyles.container,
    borderTopWidth: border.sm,
    borderTopColor: colors.primary_accent,
    padding: spacing.md,
    backgroundColor: colors.darkshadow,
    flexDirection: 'row',
    borderBottomRightRadius: radius.sm,
    borderBottomLeftRadius: radius.sm,
  },
  titleContainer: {
    ...globalstyles.container,
    flexWrap: 'wrap',
  },
  actionButtonContainer: {
    alignItems: 'center',
  },
  actionButton: {
    marginHorizontal: spacing.sm,
    marginBottom: spacing.sm,
  },
});

import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import { globalstyles, spacing, width } from '@/app/globalstyles';
import { relativeDate } from './RelativeDate';
import { ProfileResponse, ProfileResponse_Mini } from '@/api/zod';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';

type ListItem_UserProps = {
  navigation: StackNavigationProp<ParamListBase>;
  pressedUser: ProfileResponse | ProfileResponse_Mini;
  subtitle?: number;
};

export function ListItem_User({
  navigation,
  pressedUser,
  subtitle,
}: ListItem_UserProps) {
  const goToUser = () => {
    navigation.push('UserProfile', {
      username: pressedUser.username,
    });
  };
  return (
    <TouchableOpacity
      style={styles.userContainer}
      activeOpacity={0.9}
      onPress={goToUser}
    >
      {pressedUser.Profile_picture ? (
        <Image
          source={{
            uri: `${pressedUser.Profile_picture.url}?tpl=med.jpg`,
          }}
          transition={250}
          style={globalstyles.profileImageSmall}
        />
      ) : (
        <Image
          source={require('../../assets/profile-placeholder.jpg')}
          style={globalstyles.profileImageSmall}
          transition={250}
        />
      )}
      <View
        style={{
          marginLeft: spacing.md,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={globalstyles.usernameText}>{pressedUser.username}</Text>
        {subtitle && (
          <Text
            style={{
              ...globalstyles.smallfade,
              opacity: 0.4,
              fontStyle: 'italic',
            }}
          >
            {` - ${relativeDate(subtitle)}`}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing.md,
    width: width,
  },
});

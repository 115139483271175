import axios, { AxiosInstance } from 'axios';
import { z } from 'zod';
import { AppConfig } from '@/app/config';
import { queryClient } from '@/app/store/queryClient';
import Cookies from 'js-cookie';
import { showErrorToastTop, showToast } from '@/components/ErrorToast';
import * as WebBrowser from 'expo-web-browser';
// ephem auth cache
let AUTH_CACHE = {};

/// STRIPE ACTION

const base_stripe_axios_config_url = {
  baseURL: 'https://roz.joinwhirl.com/api:UQuTJ3vx',
};

export async function authenticatedStripeRequest(): Promise<AxiosInstance> {
  const authToken = await demand_fresh_auth_token();
  return axios.create({
    ...base_stripe_axios_config_url,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
}

/// MAIN EVENT

const base_axios_config = {
  baseURL: AppConfig.baseURL,
};

// the base unauthenticated req. Pulls from ENV.
export function unauthenticatedRequest(): AxiosInstance {
  return axios.create(base_axios_config);
}

// authenticated req that demands token from cache. If none, no work.
export async function authenticatedRequest(): Promise<AxiosInstance> {
  const authToken = await demand_fresh_auth_token();
  return axios.create({
    ...base_axios_config,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
}

// Clear storage, ephem cache, and queryclient.
export async function logout() {
  AUTH_CACHE = {};
  Cookies.remove('authCache');
  queryClient.resetQueries();
}

// login takes a token, updates the cache, and resets queryclient.
export async function login(authToken: string) {
  const now = new Date().toISOString();
  const newAuthCache = { authToken: authToken, savedAt: now };
  await update_auth_cache(newAuthCache);
  queryClient.resetQueries();
}

// update the AUTH_CACHE once we have values.
async function update_auth_cache(newValues: AuthCache) {
  AUTH_CACHE = newValues;
  const keychainValues = JSON.stringify(newValues);
  Cookies.set('authCache', keychainValues, { expires: 7, secure: true });
}

// get a fresh auth token.
export async function getFreshAuthToken() {
  // return cache.
  const cache = await get_auth_cache();
  if (!cache) {
    return undefined;
  }

  // check if cache is expired.
  const cacheExpired = await cached_token_expired();
  if (!!cache && !cacheExpired) {
    return cache.authToken;
  }

  // if the cache has expired, refresh the (SINGULAR) token
  // if (cacheExpired) {
  //   const {
  //     data: { authToken },
  //   } = await refresh_token();
  //   return authToken;
  // }

  return undefined;
}

class ExpiredTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ExpiredTokenError';
  }
}

// used in the authenticatedRequest function - returns a string.
async function demand_fresh_auth_token(): Promise<string> {
  try {
    // get a fresh token.
    const authToken = await getFreshAuthToken();
    if (!authToken) {
      throw new ExpiredTokenError(
        'attempted to get auth token but found falsy value',
      );
    }
    return authToken;
  } catch (error) {
    if (error instanceof ExpiredTokenError) {
      console.warn(
        'expired auth token and expired refresh token: forcing logout',
      );
      await logout();
    } else {
      throw error;
    }
  }
  return ''; // never
}

// auth cache type.
const auth_cache_parser = z.object({
  authToken: z.string(),
  savedAt: z.string(),
  // refreshToken: z.string(),
});
type AuthCache = z.infer<typeof auth_cache_parser>;

// get auth cach from store. Set it to AUTH_CACHE. Return.
async function get_auth_cache(): Promise<AuthCache | null> {
  // if we already have the cache, just return that.
  const { success: cacheHit } = auth_cache_parser.safeParse(AUTH_CACHE);
  if (cacheHit) {
    return AUTH_CACHE as AuthCache;
  }

  // otherwise, async grab from storage / cookie tray. authCache object.
  const cookieValue = Cookies.get('authCache');
  if (cookieValue) {
    AUTH_CACHE = JSON.parse(cookieValue);
    return AUTH_CACHE as AuthCache;
  }

  return null;
}

/** 168 hours in milliseconds */
const AUTH_TOKEN_LIFESPAN = 1000 * 60 * 60 * 168;
/** 5 min in milliseconds */
const EXPIRED_TOKEN_GRACE_PERIOD = 1000 * 60 * 5;
async function cached_token_expired(): Promise<boolean> {
  const cache = await get_auth_cache();
  if (!cache) {
    return true;
  } else if (
    new Date().getTime() - new Date(cache.savedAt).getTime() >
    AUTH_TOKEN_LIFESPAN - EXPIRED_TOKEN_GRACE_PERIOD
  ) {
    return true;
  }
  return false;
}

// ----- GENERAL OAUTH

const framewidth = 600;
const frameheight = 500;
const left = window.screen.width / 2 - framewidth / 2;
const top = window.screen.height / 2 - frameheight / 2;
const windowFeatures = `width=${framewidth},height=${frameheight},left=${left},top=${top}`;

// ----- DISCORD OAUTH

export async function requestDiscordAuth() {
  const client_id = '1139639832793075712';
  const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${client_id}&redirect_uri=${AppConfig.baseURL}%2Fapi%2Fv1%2Fauth%2Foauth%2Fdiscord&response_type=code&scope=email%20identify`;
  if (AppConfig.secure) {
    const response = await WebBrowser.openAuthSessionAsync(authUrl);
    const token = (response as { url?: string })?.url?.split('token=')[1];
    if (token === 'duplicate-email') {
      showErrorToastTop(
        'Duplicate email',
        'This email exists through another sign-in',
      );
    } else if (token) {
      await login(token);
    }
    // TODO: error toasts
  } else {
    // local data in DEV mode.
    WebBrowser.openBrowserAsync(
      AppConfig.baseURL + 'init_endpoint' + 'redirect_uri',
    );
  }
}

export async function requestDiscordAuthWeb() {
  const client_id = '1139639832793075712';
  const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${client_id}&redirect_uri=${AppConfig.baseURL}%2Fapi%2Fv1%2Fauth%2Foauth%2Fweb_2%2Fdiscord&response_type=code&scope=email%20identify`;

  try {
    const authWindow = window.open(
      discordAuthUrl,
      'DiscordAuthPopup',
      windowFeatures,
    );
    if (authWindow) {
      authWindow.focus();
    } else {
      console.log(
        'Unable to open the authentication window. Please check your popup settings.',
      );
    }
  } catch (error) {
    console.log(error);
    showToast('boop beep!');
  }
}

export async function requestDiscordReauthentication() {
  const client_id = '1139639832793075712';
  const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${client_id}&redirect_uri=${AppConfig.baseURL}%2Fapi%2Fv1%2Fauth%2Foauth%2Fdiscord%2Freauthentication&response_type=code&scope=email%20identify`;
  if (AppConfig.secure) {
    const response = await WebBrowser.openAuthSessionAsync(authUrl);
    const token = (response as { url?: string })?.url?.split('token=')[1];
    return token;
  }
}

// -------------------- GOOGLE AUTH
const google_oauth_base_roz_url = {
  baseURL: 'https://roz.joinwhirl.com/api:U0aE1wpF',
};

export function googleOAuthRequest(): AxiosInstance {
  return axios.create(google_oauth_base_roz_url);
}

export async function requestGoogleAuth() {
  const googleInitUrl = `/oauth/google/init?redirect_uri=${google_oauth_base_roz_url.baseURL}/oauth/google/continue`;
  if (AppConfig.secure) {
    try {
      const googleAuthUrl = await googleOAuthRequest().get(googleInitUrl);
      const response = await WebBrowser.openAuthSessionAsync(
        googleAuthUrl.data.authUrl,
      );
      const token = (response as { url?: string })?.url?.split('token=')[1];
      if (token) {
        // remove this if possible.
        const tokenTrimmed = token.endsWith('#') ? token.slice(0, -1) : token;
        if (tokenTrimmed === 'duplicate-email') {
          showErrorToastTop(
            'Duplicate email',
            'This email exists through another sign-in',
          );
        } else {
          await login(tokenTrimmed);
        }
      }
    } catch (error) {
      showToast('boop beep!');
    }
    // TODO: error toasts
  } else {
    // if we were working with local data + in DEV mode.
    WebBrowser.openBrowserAsync(
      AppConfig.baseURL + 'init_endpoint' + 'redirect_uri',
    );
  }
}

export async function requestGoogleAuthWeb() {
  const googleInitUrl = `/oauth/google/init?redirect_uri=${google_oauth_base_roz_url.baseURL}/oauth/google/web_2/continue`;

  try {
    const googleAuthResponse = await googleOAuthRequest().get(googleInitUrl);
    const googleAuthUrl = googleAuthResponse.data.authUrl;
    const authWindow = window.open(
      googleAuthUrl,
      'GoogleAuthPopup',
      windowFeatures,
    );

    if (authWindow) {
      authWindow.focus();
    } else {
      console.log(
        'Unable to open the authentication window. Please check your popup settings.',
      );
    }
  } catch (error) {
    console.log(error);
    showToast('boop beep!');
  }
}

export async function requestGoogleReauthentication() {
  const googleInitUrl = `/oauth/google/init?redirect_uri=${google_oauth_base_roz_url.baseURL}/oauth/google/continue/reauthentication`;
  if (AppConfig.secure) {
    const googleAuthUrl = await googleOAuthRequest().get(googleInitUrl);
    const response = await WebBrowser.openAuthSessionAsync(
      googleAuthUrl.data.authUrl,
    );
    const token = (response as { url?: string })?.url?.split('token=')[1];
    return token;
  }
}

// -------------------- APPLE AUTH

const apple_oauth_base_roz_url = {
  baseURL: 'https://roz.joinwhirl.com/api:W8_0W67a',
};

export function appleOAuthRequest(): AxiosInstance {
  return axios.create(apple_oauth_base_roz_url);
}

export async function requestAppleAuth() {
  const appleInitUrl = `/oauth/apple/init?redirect_uri=${apple_oauth_base_roz_url.baseURL}/oauth/apple/continue`;
  if (AppConfig.secure) {
    try {
      const appleAuthUrl = await appleOAuthRequest().get(appleInitUrl);
      const response = await WebBrowser.openAuthSessionAsync(
        appleAuthUrl.data.url,
      );
      const token = (response as { url?: string })?.url?.split('token=')[1];
      if (token) {
        if (token === 'duplicate-email') {
          showErrorToastTop(
            'Duplicate email',
            'This email exists through another sign-in',
          );
        } else {
          await login(token);
        }
      }
    } catch (error) {
      console.log(error);
      showToast('boop beep!');
    }
    // TODO: error toasts
  } else {
    // if we were working with local data + in DEV mode.
    WebBrowser.openBrowserAsync(
      AppConfig.baseURL + 'init_endpoint' + 'redirect_uri',
    );
  }
}

export async function requestAppleAuthWeb() {
  const appleInitUrl = `/oauth/apple/init?redirect_uri=${apple_oauth_base_roz_url.baseURL}/oauth/apple/web_2/continue`;

  try {
    const appleAuthResponse = await appleOAuthRequest().get(appleInitUrl);
    const appleAuthUrl = appleAuthResponse.data.url;
    const authWindow = window.open(
      appleAuthUrl,
      'AppleAuthPopup',
      windowFeatures,
    );

    if (authWindow) {
      authWindow.focus();
    } else {
      console.log(
        'Unable to open the authentication window. Please check your popup settings.',
      );
    }
  } catch (error) {
    console.error('Error initiating Apple OAuth process:', error);
  }
}

export async function requestAppleReauthentication() {
  const appleInitUrl = `/oauth/apple/init?redirect_uri=${apple_oauth_base_roz_url.baseURL}/oauth/apple/continue/reauthentication`;
  if (AppConfig.secure) {
    const appleAuthUrl = await appleOAuthRequest().get(appleInitUrl);
    const response = await WebBrowser.openAuthSessionAsync(
      appleAuthUrl.data.url,
    );
    const token = (response as { url?: string })?.url?.split('token=')[1];
    return token;
  }
}

//// ---- VALIDATIONS

export const isValidEmail = (submittedEmail: string) => {
  const pattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
  return pattern.test(submittedEmail);
};

export const isValidPassword = (submittedPassword: string) => {
  const minLength = 8;
  const hasAlpha = /[a-zA-Z]/.test(submittedPassword);
  const hasDigit = /\d/.test(submittedPassword);

  return submittedPassword.length >= minLength && hasAlpha && hasDigit;
};

export const isValidUsername = (submittedUsername: string) => {
  const pattern = /^[a-zA-Z0-9._]{3,30}$/;
  return pattern.test(submittedUsername);
};

export const isValidWhirlUsername = (submittedUsername: string) => {
  const pattern = /^[a-zA-Z0-9._]{3,30}$/;
  const isTempUsername = submittedUsername.startsWith('temp_');
  return pattern.test(submittedUsername) && !isTempUsername;
};

export const isValidResetCode = (submittedCode: string) => {
  const pattern = /^\d{8}$/;
  return pattern.test(submittedCode);
};

export const isValidSocialUsername = (submittedUsername: string) => {
  const pattern = /^[a-zA-Z0-9._]+$/;
  return pattern.test(submittedUsername);
};

export const isValidVerificationCode = (submittedCode: string) => {
  const pattern = /^\d{6}$/;
  return pattern.test(submittedCode);
};

export const passwordsMatch = (password1: string, password2: string) => {
  return password1 === password2;
};

// -------------------------------------------------------
// IRRELEVANT FOR NOW - REFRESH TOKEN
// would send back two auth tokens... one long and one short.
// const refresh_response_parser = z.object({
//   data: z.object({
//     authToken: z.string(),
//   }),
// });
// type RefreshResponse = z.infer<typeof refresh_response_parser>;

// let refresh_token_promise: Promise<RefreshResponse> | null = null;

// // /** Directs all requests awaiting new credentials to a single promise */
// async function refresh_token(): Promise<RefreshResponse> {
//   // if promise has resolved, return it.
//   if (refresh_token_promise !== null) {
//     return refresh_token_promise;
//   }

//   // get the auth cache + set it to the AUTH_CACHE object.
//   const authCache = await get_auth_cache();
//   if (!authCache) {
//     throw new ExpiredTokenError('asked to refresh token with no token present');
//   }
//   // necessary?
//   const newPromise = sync_tokens(authCache.refreshToken);

//   refresh_token_promise = newPromise;
//   return refresh_token_promise;
// }

// // function that takes in a token and _____________
// async function sync_tokens(refreshToken: string): Promise<RefreshResponse> {
//   // unauthenticated request (no token provided)
//   // post with an object that's
//   return unauthenticatedRequest()
//     .post<RefreshResponse>('/api/v1/auth/refresh', {
//       refreshToken,
//     })
//     .then(({ data }) => refresh_response_parser.parse(data))
//     .then(async (newTokens) => {
//       const now = new Date().toISOString();

//       await update_auth_cache({
//         ...newTokens.data,
//         savedAt: now,
//       });

//       return newTokens;
//     })
//     .catch((error) => {
//       if (axios.isAxiosError(error) && error.response?.status === 401) {
//         throw new ExpiredTokenError('refresh token was expired');
//       }
//       throw error; // TODO handle offline mode and bad connections here
//     });
// }

import React from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { colors } from '@/app/colors';
import { font, globalstyles, radius } from '@/app/globalstyles';

type InputProps = {
  handleInputChange: (text: string) => void;
  placeholderText: string;
  value?: string;
  editable?: boolean;
  onSelectionChange: ({ nativeEvent: { selection } }: any) => void;
  focusCallback?: () => void;
  blurCallback?: () => void;
  // submitDisabled: boolean;
  // isLoading?: boolean;
  // submitCallback: () => void;
  // submitIconColor?: string;
};

// submitCallback,
// isLoading,
// submitIconColor,
// submitDisabled,
export const InputBox_Comment = ({
  handleInputChange,
  placeholderText,
  value,
  editable = true,
  onSelectionChange,
  blurCallback,
  focusCallback,
}: InputProps) => {
  return (
    <View style={styles.inputWrapper}>
      <TextInput
        placeholder={placeholderText}
        placeholderTextColor={colors.halfwhite}
        onChangeText={handleInputChange}
        style={{
          ...globalstyles.smallbody,
          // ...globalstyles.container,
        }}
        autoCapitalize={'sentences'}
        autoCorrect={true}
        editable={editable}
        value={value}
        onSelectionChange={onSelectionChange}
        maxLength={480}
        multiline={true}
        onFocus={focusCallback}
        onBlur={blurCallback}
        // ref={inputRef}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    color: colors.secondary,
    fontSize: font.md,
  },
  inputWrapper: {
    borderRadius: radius.sm,
    outline: 'none',
  },
});

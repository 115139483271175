import React from 'react';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useMyProfileStore } from '@/app/store/store';
import { DailyThemeWebComponent } from '@/components/daily theme/DailyThemeWebComponent';
import Toast from 'react-native-toast-message';

export function DailyThemeScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'DailyTheme'>>();
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const route = useRoute();
  const routeName = route.name;
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );

  return (
    <>
      <DailyThemeWebComponent
        routeName={routeName}
        navigation={navigation}
        submissionThemeInfo={submissionThemeInfo}
        myInfo={myInfo}
      />
      <Toast />
    </>
  );
}

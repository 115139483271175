import React, { useState } from 'react';
import { View, Text, Animated, Pressable } from 'react-native';
import {
  border,
  globalstyles,
  maxWebContentWidth,
  radius,
  spacing,
} from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { useOpenImagePicker } from '@/api/upload';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons/faCloudArrowUp';
import withAuthPopup from './shared/hoc/withAuthPopup';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  setFile: (newFile: Blob | undefined) => void;
}

const Button_WebUpload: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  setFile,
}) => {
  const [scale] = useState(new Animated.Value(1));
  const openImagePicker = useOpenImagePicker(setFile);
  const handlePress = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      openImagePicker();
    }
  };
  return (
    <Animated.View
      style={{
        padding: spacing.sm,
        borderRadius: radius.md,
        borderColor: colors.secondary,
        borderWidth: border.xs,
        width: maxWebContentWidth / 2,
        transform: [{ scale }],
      }}
    >
      <Pressable
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(43,38,48,0.6)',
          borderRadius: radius.md,
          paddingBottom: spacing.xl * 3,
          paddingTop: spacing.xl * 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={handlePress}
        onHoverIn={() => {
          Animated.timing(scale, {
            toValue: 1.01,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }}
        onHoverOut={() => {
          Animated.timing(scale, {
            toValue: 1,
            duration: 200,
            useNativeDriver: true,
          }).start();
        }}
      >
        <FontAwesomeIcon
          icon={faCloudArrowUp}
          size={64}
          color={colors.accent}
        />
        <View style={{ marginBottom: spacing.md }}>
          <Text style={{ ...globalstyles.title, ...globalstyles._font_lg }}>
            Select image to upload
          </Text>
        </View>
        <View style={{ marginBottom: spacing.sm }}>
          <Text style={globalstyles.body}>PNG or JPG</Text>
        </View>
      </Pressable>
    </Animated.View>
  );
};

export default withAuthPopup(Button_WebUpload);

import { colors } from '@/app/colors';
import { globalstyles, webContentWidth, spacing } from '@/app/globalstyles';
import { REPORTTYPES } from '@/app/store/reports';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import { Subtitle } from '@/components/Subtitle';
import { authenticatedRequest } from '@/modules/auth';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
} from 'react-native';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useBlock } from '@/api/useBlock';
import { useMyProfileStore } from '@/app/store/store';
import { useExploreStore } from '@/app/store/exploreStore';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';

const emptyReportState = {
  id: 0,
  type: '',
  description: '',
};

export function ReportScreen() {
  const {
    params: { postId, comments_id, reported_user_id, reporting_user_id },
  } = useRoute<AuthenticatedNavigatorRouteProp<'Report'>>();
  const { blockGlobally } = useBlock(reported_user_id);
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Report'>>();
  const [selectedReportReason, setSelectedReportReason] =
    useState(emptyReportState);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingBlock, setLoadingBlock] = useState(false);
  const [loadingPurge, setLoadingPurge] = useState(false);
  const [modalBlockVisible, setModalBlockVisible] = useState(false);
  const [modalPurgeVisible, setModalPurgeVisible] = useState(false);
  const [roleID, setRoleID] = useState(1);
  const deletePostFromAllPostList = useMyProfileStore(
    (state) => state.deletePostFromAllPostList,
  );
  const deletePostFromDailyThemePostList = useMyProfileStore(
    (state) => state.deletePostFromDailyThemePostList,
  );
  const deletePostFromExplorePostList = useExploreStore(
    (state) => state.deletePostFromExplorePostList,
  );

  useEffect(() => {
    const checkIfMod = async () => {
      try {
        const response = await (
          await authenticatedRequest()
        ).get(`user/role_check/${reporting_user_id}`);
        setRoleID(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    checkIfMod();
  }, [reporting_user_id]);

  const handleSubmit = async () => {
    setLoadingReport(true);
    const authenticatedAxios = await authenticatedRequest();
    if (comments_id) {
      await authenticatedAxios.post('report_instance', {
        report_type_id: selectedReportReason.id,
        comments_id: comments_id,
        reported_user_id: reported_user_id,
        reporting_user_id: reporting_user_id,
      });
    } else if (postId) {
      await authenticatedAxios.post('report_instance', {
        report_type_id: selectedReportReason.id,
        posts_id: postId,
        reported_user_id: reported_user_id,
        reporting_user_id: reporting_user_id,
      });
    } else {
      await authenticatedAxios.post('report_instance', {
        report_type_id: selectedReportReason.id,
        reported_user_id: reported_user_id,
        reporting_user_id: reporting_user_id,
      });
    }
    setLoadingReport(false);
    setSelectedReportReason(emptyReportState);
    navigation.goBack();
  };

  const handleBlock = async () => {
    setLoadingBlock(true);
    try {
      const response = await (
        await authenticatedRequest()
      ).post<string>('block_user', {
        blocking_users_id: reporting_user_id,
        blocked_users_id: reported_user_id,
      });
      if (response.data === 'success') {
        showSuccessToast('Successful block');
      }
      blockGlobally();
      setLoadingBlock(false);
      setSelectedReportReason(emptyReportState);
      setModalBlockVisible(false);
      navigation.navigate('Explore', { passedTag: undefined });
    } catch (error) {
      setLoadingBlock(false);
      setModalBlockVisible(false);
      showToast('Unable to block. Try again.');
      console.log(error);
    }
  };

  const handlePurge = async () => {
    setLoadingPurge(true);
    if (postId) {
      try {
        const response = await (
          await authenticatedRequest()
        ).delete(`post/admin_purge/${reporting_user_id}/${postId}`);
        deletePostFromDailyThemePostList(postId);
        deletePostFromExplorePostList(postId);
        deletePostFromAllPostList(postId);
        setLoadingPurge(false);
        setModalPurgeVisible(false);
        navigation.navigate('Explore', { passedTag: undefined });
        if (response.data === 'success') {
          showSuccessToast('Successful purge');
        }
      } catch (error) {
        setLoadingPurge(false);
        setModalPurgeVisible(false);
        showToast('Unable to purge. Try again.');
        console.log(error);
      }
    }
  };

  const renderButtons = () => {
    return REPORTTYPES.map((reason: any) => (
      <TouchableOpacity
        style={
          reason.id === selectedReportReason.id
            ? {
                ...styles.radioButtonContainer,
                borderLeftWidth: spacing.xs,
                backgroundColor: colors.primary_accent,
                // marginLeft: spacing.lg,
              }
            : {
                ...styles.radioButtonContainer,
              }
        }
        key={reason.type}
        activeOpacity={0.9}
        onPress={() => setSelectedReportReason(reason)}
      >
        <Text style={globalstyles.label}>{reason.type}</Text>
        <Text style={globalstyles.smallfade}>{reason.description}</Text>
      </TouchableOpacity>
    ));
  };

  return (
    <SafeAreaView style={{ ...globalstyles.container, alignItems: 'center' }}>
      <Subtitle text={'Select a reason to report.'} />
      <View style={styles.radioButtonListContainer}>{renderButtons()}</View>
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity
          style={{
            ...globalstyles.roundedButton,
            ...globalstyles._bg_submit,
            ...globalstyles.centeringContainer,
            marginRight: spacing.md,
          }}
          activeOpacity={0.9}
          onPress={handleSubmit}
        >
          {!loadingReport ? (
            <Text style={globalstyles.title}>Submit</Text>
          ) : (
            <ActivityIndicator size={'small'} color={colors.secondary} />
          )}
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            ...globalstyles.roundedButton,
            ...globalstyles.centeringContainer,
            backgroundColor: colors.alert,
            marginRight: roleID === 2 ? spacing.md : undefined,
          }}
          activeOpacity={0.9}
          onPress={() => setModalBlockVisible(true)}
        >
          {!loadingBlock ? (
            <Text style={globalstyles.title}>Block user</Text>
          ) : (
            <ActivityIndicator size={'small'} color={colors.secondary} />
          )}
        </TouchableOpacity>
        {roleID === 2 && postId && (
          <TouchableOpacity
            style={{
              ...globalstyles.roundedButton,
              ...globalstyles.centeringContainer,
              backgroundColor: colors.high_accent,
            }}
            activeOpacity={0.9}
            onPress={() => setModalPurgeVisible(true)}
          >
            {!loadingPurge ? (
              <Text style={globalstyles.title}>Purge post</Text>
            ) : (
              <ActivityIndicator size={'small'} color={colors.secondary} />
            )}
          </TouchableOpacity>
        )}
      </View>
      <View />
      <Modal_TwoOptions
        cancel={() => setModalBlockVisible(false)}
        isVisible={modalBlockVisible}
        title={'Block this user?'}
        callback1={handleBlock}
        callback2={() => setModalBlockVisible(false)}
        text1={'Yes'}
        icon1={faCheck}
        text2={'Nevermind'}
        icon2={faTimes}
      />
      <Modal_TwoOptions
        cancel={() => setModalPurgeVisible(false)}
        isVisible={modalPurgeVisible}
        title={'Purge this post for nudity, gore or spam?'}
        callback1={handlePurge}
        callback2={() => setModalPurgeVisible(false)}
        text1={'Yes'}
        icon1={faCheck}
        text2={'Nevermind'}
        icon2={faTimes}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  radioButtonListContainer: {
    width: '100%',
    maxWidth: webContentWidth * 2,
  },
  radioButtonContainer: {
    marginBottom: spacing.lg,
    padding: spacing.sm,
    paddingLeft: spacing.md,
    borderColor: colors.accent,
  },
});

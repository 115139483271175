import React from 'react';
import { Text, View, Pressable } from 'react-native';
import { colors } from '@/app/colors';
import { globalstyles, spacing } from '@/app/globalstyles';
import Modal from 'react-native-modal';
import { ParamListBase } from '@react-navigation/native';
import { Button_Submission } from './Button_Submission';
import { Image } from 'expo-image';
import { StackNavigationProp } from '@react-navigation/stack';

interface ModalProps {
  callbackCloseModal: () => void;
  visible: boolean;
  navigation: StackNavigationProp<ParamListBase>;
}

export const Modal_Subscribed = ({
  callbackCloseModal,
  visible,
  navigation,
}: ModalProps) => {
  const navToStudio = () => {
    callbackCloseModal();
    navigation.push('Studio');
  };
  return (
    <Modal
      isVisible={visible}
      style={{ margin: 0 }}
      hideModalContentWhileAnimating={true}
    >
      <Pressable
        style={{
          ...globalstyles.container,
          ...globalstyles.centeringContainer,
        }}
        onPress={callbackCloseModal}
      >
        <View style={{ ...globalstyles.modalContent, padding: spacing.md }}>
          <View style={{ marginBottom: spacing.md }}>
            <Image
              source={require('../../assets/snoop.gif')}
              style={globalstyles.gifImage}
              transition={250}
            />
          </View>
          <View style={{ marginBottom: spacing.md }}>
            <Text style={{ ...globalstyles.title, ...globalstyles._font_lg }}>
              You're subscribed!
            </Text>
          </View>
          <Button_Submission
            callback={navToStudio}
            title={'Start creating ->'}
            loading={false}
            bgColor={colors.accent}
          />
        </View>
      </Pressable>
    </Modal>
  );
};

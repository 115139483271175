import React from 'react';
import { Text, Pressable } from 'react-native';
import {
  globalstyles,
  radius,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { Image } from 'expo-image';
import ImageBackground from '../ImageBackground';
import { Button_CreatePost } from '../Button_CreatePost';
import { Subtitle } from '../Subtitle';
import { useOpenImagePicker } from '@/api/upload';

type Props = {
  file: Blob | undefined;
  setFile: (file: Blob | undefined) => void;
  loading: boolean;
  isBoingle: boolean | undefined;
  imageURL?: string;
};

export function Icon_ThumbnailPreview({
  file,
  setFile,
  imageURL,
  loading,
}: Props) {
  return (
    <Pressable
      disabled={loading}
      style={{ alignItems: 'center' }}
      onPress={useOpenImagePicker(setFile)}
    >
      {file ? (
        <>
          <Image
            style={getThumbnailStyle()}
            transition={250}
            source={{ uri: URL.createObjectURL(file) }}
          />
          <Subtitle text={'Select image to upload'} />
        </>
      ) : (
        <>
          {imageURL ? (
            <Image style={getThumbnailStyle()} source={{ uri: imageURL }} />
          ) : (
            <ImageBackground
              source={require('../../../assets/post-placeholder.png')}
              style={getThumbnailStyle()}
              imageStyle={{ opacity: 0.6, borderRadius: radius.sm }}
            >
              <Button_CreatePost />
              <Text style={globalstyles.smallfade}>PNG, JPEG</Text>
            </ImageBackground>
          )}
        </>
      )}
    </Pressable>
  );
}

export const getThumbnailStyle = () => {
  const commonStyles = {
    marginBottom: spacing.md,
    borderRadius: radius.sm,
  };
  return {
    ...globalstyles.thumbnailWeb,
    ...commonStyles,
    height: webContentWidth,
    width: webContentWidth,
  };
};

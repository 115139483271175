import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native';
import { Image } from 'expo-image';
import { Post } from '@/api/zod';
import { colors } from '@/app/colors';
import { globalstyles, font, spacing } from '@/app/globalstyles';

interface ThumbnailProps {
  post: Post;
  onPress: () => void;
  showCount: boolean;
}

const Thumbnail: React.FC<ThumbnailProps> = React.memo(
  ({ post, onPress, showCount }) => {
    const imageSource = post.images[0].image
      ? { uri: `${post.images[0].image.url}?tpl=big` }
      : require('../../../assets/bg-splotch.jpg');
    return (
      <TouchableOpacity
        style={{ ...globalstyles.container, margin: 0.5 }}
        activeOpacity={0.8}
        onPress={onPress}
        disabled={
          post.isPlaceholder || (!post.isVisible && post.isDailyThemePost)
        }
        key={post.id}
      >
        {post.isPlaceholder ? (
          <Placeholder />
        ) : (
          <Image
            style={{
              ...globalstyles.thumbnailWeb,
              opacity: !post.isVisible && post.isDailyThemePost ? 0.4 : 1,
            }}
            source={imageSource}
            transition={250}
          />
        )}

        {/* this needs to change to voteCount */}
        {showCount && (
          <View style={styles.allVotesContainer}>
            {post.isChamp && (
              <View style={styles.medalContainer}>
                <Text style={{ fontSize: font.lg, alignSelf: 'center' }}>
                  🏆
                </Text>
              </View>
            )}
            {post.isDailyWinner && (
              <View style={styles.medalContainer}>
                <Text style={{ fontSize: font.lg, alignSelf: 'center' }}>
                  🎖
                </Text>
              </View>
            )}
            {post.isTopPost && (
              <View style={styles.medalContainer}>
                <Text style={{ fontSize: font.lg, alignSelf: 'center' }}>
                  🎗
                </Text>
              </View>
            )}
          </View>
        )}
        {!post.isVisible && post.isDailyThemePost && (
          <View style={styles.allVotesContainer}>
            <Text style={{ ...globalstyles.tinybody, alignSelf: 'flex-end' }}>
              Daily theme
            </Text>
            <Text style={{ ...globalstyles.tinybody, alignSelf: 'flex-end' }}>
              Post preview
            </Text>
          </View>
        )}
      </TouchableOpacity>
    );
  },
);

const Placeholder = () => {
  return <View style={styles.placeholder} />;
};

export { Thumbnail };

const styles = StyleSheet.create({
  placeholder: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: colors.primary,
  },
  allVotesContainer: {
    position: 'absolute',
    alignItems: 'flex-start',
    top: spacing.sm,
    right: spacing.sm,
    zIndex: 2,
  },
  medalContainer: {
    marginBottom: 6,
    flexDirection: 'row',
  },
});

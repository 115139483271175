import React from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { colors } from '@/app/colors';
import { globalstyles, spacing } from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

type ModalHeaderProps = {
  callback: () => void;
  title: string;
};

export function ModalHeader({ callback, title }: ModalHeaderProps) {
  return (
    <View style={styles.headerContainer}>
      <Text style={{ ...styles.logoText, ...globalstyles.title }}>{title}</Text>
      <TouchableOpacity
        style={{
          ...globalstyles.chip,
          zIndex: 4,
        }}
        onPress={callback}
      >
        <FontAwesomeIcon icon={faTimes} color={colors.secondary} size={16} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    padding: spacing.md,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoText: {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

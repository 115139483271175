import { ParamListBase } from '@react-navigation/native';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  View,
  StyleSheet,
  Text,
} from 'react-native';
import { EMPTYSTATEPOST, GalleryData, Post, ProfileData } from '@/api/zod';
import { useMyProfileStore } from '@/app/store/store';
import {
  globalstyles,
  headerHeight,
  heightWithHeader,
  maxWebContentWidth,
  spacing,
} from '@/app/globalstyles';
import { ProfileHeader } from '@/components/profile/ProfileHeader';
import Modal_ProfileMoreInfo from '@/components/profile/Modal_ProfileMoreInfo';
import { Modal_Post } from '@/components/shared/Modal_Post';
import usePostActions from '@/api/usePostActions';
import useFadeAnimation from '@/api/useFadeAnimation';
import { colors } from '@/app/colors';
import { Thumbnail_Hover } from '@/components/shared/Thumbnail_Hover';
import { StackNavigationProp } from '@react-navigation/stack';
import { Modal_FullScreenImage } from '@/screens/Modal_FullScreenImage';
import { addPlaceholders } from '@/api/posts';

interface Props {
  galleryData: GalleryData;
  profileData: ProfileData;
  setCurrentTab: (tab: string) => void;
  fetchAllPosts: () => void;
  fetchThemePosts: () => void;
  handleFollow: () => void;
  myProfile: boolean;
  navigation: StackNavigationProp<ParamListBase>;
  updateRootPostState?: (updatedPost: Post) => void;
  mounted: boolean;
}

export const Layout_Profile = ({
  galleryData,
  profileData,
  setCurrentTab,
  fetchAllPosts,
  fetchThemePosts,
  handleFollow,
  myProfile,
  navigation,
  updateRootPostState,
  mounted,
}: Props) => {
  // BASE.
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const toggleFSImageModal = () => {
    setFullScreenModalVisible(!fullScreenModalVisible);
  };
  const allPosts = addPlaceholders(galleryData.allPostList, 4);
  const dailyThemePosts = addPlaceholders(galleryData.dailyThemePostList, 4);

  // ACTION HANDLERS.
  const { handleLike, handleSave } = usePostActions(updateRootPostState);
  const like = () => {
    if (handleLike) {
      handleLike(pressedItem);
      const updatedPost = {
        ...pressedItem,
        likedOrVoted: !pressedItem.likedOrVoted,
        likeCount: !pressedItem.likedOrVoted
          ? pressedItem.likeCount + 1
          : pressedItem.likeCount - 1,
      };
      setPressedItem(updatedPost);
    }
  };
  const save = () => {
    handleSave(pressedItem);
    const updatedPost = {
      ...pressedItem,
      isSaved: !pressedItem.isSaved,
      totalSaves: !pressedItem.isSaved
        ? pressedItem.totalSaves + 1
        : pressedItem.totalSaves - 1,
    };
    setPressedItem(updatedPost);
  };
  const navToEditProfile = () => {
    if (moreInfoModalVisible) {
      toggleMoreInfoModal();
    }
    myProfile && navigation.push('MySettings');
    // myProfile && navigation.push('EditProfile');
  };
  const navToLevels = () => {
    console.log('go to levels (soon!)');
    // if (moreInfoModalVisible) {
    //   toggleMoreInfoModal();
    // }
    // myProfile && navigation.push('Levels');
  };
  const navToTagList = () => {
    if (moreInfoModalVisible) {
      toggleMoreInfoModal();
    }
    if (profileData.tagList) {
      navigation.push('ViewTagList', {
        tag_list: profileData.tagList,
      });
    }
  };
  // const navToReport = () => {
  //   if (myInfo && !myProfile) {
  //     navigation.push('Report', {
  //       reported_user_id: relevantUser.id,
  //       reporting_user_id: myInfo?.id,
  //     });
  //   }
  // };

  // MODAL MOVEMENT.
  const [moreInfoModalVisible, setMoreInfoModalVisible] = useState(false);
  const toggleMoreInfoModal = () => {
    setMoreInfoModalVisible(!moreInfoModalVisible);
  };
  const [postModalVisible, setPostModalVisible] = useState(false);
  const togglePostModal = () => {
    setPostModalVisible(!postModalVisible);
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pressedItem, setPressedItem] = useState<Post>(EMPTYSTATEPOST);
  const posts = galleryData.currentTab === 'all' ? allPosts : dailyThemePosts;
  const { fadeAnim, previous, next, commentsVisible, setCommentsVisible } =
    useFadeAnimation(setCurrentIndex, setPressedItem, posts, currentIndex);
  const handlePress = (item: Post) => {
    let index = 0;
    if (galleryData.currentTab === 'all') {
      index = allPosts.findIndex((post) => post.id === item.id);
    } else {
      index = dailyThemePosts.findIndex((post) => post.id === item.id);
    }
    setPressedItem(item);
    setCurrentIndex(index);
    togglePostModal();
  };

  // GALLERY.
  const renderItem = ({ item }: { item: Post }) => {
    return (
      <>
        {galleryData.currentTab === 'all' ? (
          <Thumbnail_Hover item={item} callback={() => handlePress(item)} />
        ) : (
          <Thumbnail_Hover
            item={item}
            callback={() => handlePress(item)}
            showCount={true}
          />
        )}
      </>
    );
  };
  const data = galleryData.currentTab === 'all' ? allPosts : dailyThemePosts;
  const renderFooter = () => {
    if (galleryData.currentTab === 'all') {
      if (galleryData.loadingAll && galleryData.hasMoreAll) {
        return (
          <View style={{ padding: 20 }}>
            <ActivityIndicator size="small" color={colors.secondary} />
          </View>
        );
      } else if (
        !galleryData.loadingAll &&
        !galleryData.hasMoreAll &&
        data.length === 0
      ) {
        return (
          <View style={{ paddingTop: spacing.lg }}>
            <Text style={globalstyles.logoTextSmall}>No posts yet</Text>
          </View>
        );
      } else {
        return null;
      }
    } else {
      if (galleryData.loadingTheme && galleryData.hasMoreTheme) {
        return (
          <View style={{ padding: 20 }}>
            <ActivityIndicator size="small" color={colors.secondary} />
          </View>
        );
      } else if (
        !galleryData.loadingTheme &&
        !galleryData.hasMoreTheme &&
        data.length === 0
      ) {
        return (
          <View style={{ paddingTop: spacing.lg }}>
            <Text style={globalstyles.logoTextSmall}>No posts yet</Text>
          </View>
        );
      } else {
        return null;
      }
    }
  };
  const loadMore = () => {
    if (galleryData.currentTab === 'all') {
      fetchAllPosts();
    } else {
      fetchThemePosts();
    }
  };

  // HELPERS.
  // const levelBadge = badges.filter((badge) =>
  //   badge.title.includes(`Level ${relevantUser?.level}`),
  // );

  return (
    <View style={globalstyles.container}>
      {profileData.user && (
        <FlatList
          data={data}
          keyExtractor={(post) => post.id.toString()}
          onEndReached={loadMore}
          onEndReachedThreshold={0.8}
          numColumns={4}
          style={styles.listContainer}
          contentContainerStyle={styles.contentContainer}
          ListFooterComponent={() => renderFooter()}
          ListHeaderComponent={
            <ProfileHeader
              user={profileData.user}
              links={profileData.links}
              myProfile={myProfile}
              navToLevels={navToLevels}
              navToTagList={navToTagList}
              toggleModal={toggleMoreInfoModal}
              setCurrentTab={setCurrentTab}
              currentTab={galleryData.currentTab}
              tagList={profileData.tagList}
              // levelBadge={levelBadge[0]}
              totalPosts={profileData.totalPosts}
              navToEditProfile={navToEditProfile}
              handleFollow={handleFollow}
              toggleFSImageModal={toggleFSImageModal}
              mounted={mounted}
            />
          }
          renderItem={renderItem}
        />
      )}
      {profileData.user && (
        <Modal_ProfileMoreInfo
          cancel={toggleMoreInfoModal}
          isVisible={moreInfoModalVisible}
          title={`@${profileData.user.username}`}
          user={profileData.user}
          links={profileData.links}
          navigation={navigation}
          badges={profileData.badges}
          tagList={profileData.tagList}
          navToTagList={navToTagList}
          toggleMoreInfoModal={toggleMoreInfoModal}
        />
      )}
      <Modal_Post
        setPressedItem={setPressedItem}
        post={pressedItem}
        imageFadeAnim={fadeAnim}
        myInfo={myInfo}
        onClose={togglePostModal}
        isVisible={postModalVisible}
        navigation={navigation}
        like={like}
        save={save}
        next={next}
        previous={previous}
        editable={pressedItem.users_id === myInfo?.id}
        commentsVisible={commentsVisible}
        setCommentsVisible={setCommentsVisible}
        isAnonymous={false}
      />
      {profileData.user.Profile_picture && (
        <Modal_FullScreenImage
          visible={fullScreenModalVisible}
          toggleVisible={toggleFSImageModal}
          bare_link={profileData.user.Profile_picture?.url}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  listContainer: {
    height: heightWithHeader,
  },
  contentContainer: {
    maxWidth: maxWebContentWidth,
    alignSelf: 'center',
    width: '90%',
    marginTop: headerHeight,
  },
});

import React from 'react';
import { View, Text, Pressable } from 'react-native';
import {
  BadgeSchema,
  ProfileResponse,
  SocialMediaLink,
  TagList,
} from '@/api/zod';
import { globalstyles, maxWebContentWidth, spacing } from '@/app/globalstyles';
import { ParamListBase } from '@react-navigation/native';
import { colors } from '@/app/colors';
import { StackNavigationProp } from '@react-navigation/stack';
import ModalHOC from '../shared/hoc/ModalHOC';
import { formattedDate } from '../RelativeDate';
import { Layout_ProfileBadges } from './Layout_ProfileBadges';

interface Props {
  user: ProfileResponse;
  links: SocialMediaLink[];
  badges: BadgeSchema[];
  navigation: StackNavigationProp<ParamListBase>;
  toggleMoreInfoModal: () => void;
  tagList?: TagList;
  navToTagList?: () => void;
}

const Modal_Content = ({
  user,
  badges,
  navigation,
  tagList,
  toggleMoreInfoModal,
  navToTagList,
}: Props) => {
  return (
    <View style={{ maxWidth: maxWebContentWidth / 2 }}>
      <Layout_UserInfoAndStats
        user={user}
        tagList={tagList}
        navToTagList={navToTagList}
      />
      <Layout_ProfileBadges
        badges={badges}
        users_id={user?.id}
        navigation={navigation}
        callback={toggleMoreInfoModal}
      />
    </View>
  );
};

interface Layout_StatsListProps {
  user: ProfileResponse;
  tagList?: TagList;
  navToTagList?: () => void;
}

const Layout_UserInfoAndStats = ({
  user,
  tagList,
  navToTagList,
}: Layout_StatsListProps) => {
  return (
    <View style={globalstyles.centeringContainer}>
      <View style={{ marginBottom: spacing.md, alignItems: 'center' }}>
        {user.fullname && (
          <View style={{ marginBottom: spacing.sm }}>
            <Text style={globalstyles.body}>
              {user.fullname ? `${user.fullname}` : ''}
            </Text>
          </View>
        )}
        <Text style={globalstyles.smallfade}>
          Joined {formattedDate(user.created_at)}
        </Text>
      </View>
      {!!user.pr_daily_theme_streak && (
        <View
          style={{
            paddingBottom: spacing.sm,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text style={globalstyles.body}>
            Highest daily theme streak:{' '}
            <Text style={{ color: colors.accent }}>
              {user.pr_daily_theme_streak}
            </Text>
          </Text>
        </View>
      )}
      {!!user.daily_theme_streak && (
        <View style={{ paddingBottom: spacing.sm }}>
          <Text style={globalstyles.body}>
            Current streak:{' '}
            <Text style={{ color: colors.accent }}>
              {user.daily_theme_streak}
            </Text>
          </Text>
        </View>
      )}
      <View style={{ marginHorizontal: spacing.md, alignItems: 'center' }}>
        {tagList && (
          <Pressable
            style={{ paddingBottom: spacing.sm }}
            onPress={navToTagList}
          >
            <Text style={globalstyles.body}>
              Host:
              <Text
                style={{ color: colors.accent }}
              >{` #${tagList?.title} →`}</Text>
            </Text>
          </Pressable>
        )}
      </View>
    </View>
  );
};

const Modal_ProfileMoreInfo = ModalHOC(Modal_Content);

export default Modal_ProfileMoreInfo;

import { ProfileResponse } from '@/api/zod';
import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest } from '@/modules/auth';
import React, { useEffect, useState } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  FlatList,
  SafeAreaView,
} from 'react-native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import { colors } from '@/app/colors';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { Button_Navigation } from '@/components/Button_Navigation';
import { ProfileImage } from '@/components/ProfilePicture';
import { useMyProfileStore } from '@/app/store/store';
import { Text_ListHeader } from '@/components/Text_ListHeader';

export function Leaderboard_DailyThemeStreakScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'Leaderboard_DailyThemeStreak'>>();
  const [activeTab, setActiveTab] = useState('current');
  const handlePress = (tabName: string) => {
    setActiveTab(tabName);
  };
  const [currentStreakUsers, setCurrentStreakUsers] = useState<
    ProfileResponse[]
  >([]);
  const [allTimeStreakUsers, setAllTimeStreakUsers] = useState<
    ProfileResponse[]
  >([]);

  const navToUser = (userInfo: ProfileResponse) => {
    navigation.push('UserProfile', {
      username: userInfo.username,
    });
  };

  const renderItem = ({
    item,
    index,
  }: {
    item: ProfileResponse;
    index: number;
  }) => (
    <TouchableOpacity
      onPress={() => navToUser(item)}
      style={globalstyles.listItemContainer}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ marginRight: spacing.md }}>
          <Text style={globalstyles.label}>{index + 1}.</Text>
        </View>
        <View style={{ marginRight: spacing.md }}>
          <ProfileImage image_url={item.Profile_picture?.url} />
        </View>
        <Text style={globalstyles.usernameText}>@{item.username}</Text>
      </View>
      <View style={{ marginRight: spacing.md }}>
        <Text style={globalstyles.title}>
          {activeTab === 'current'
            ? item.daily_theme_streak
            : item.pr_daily_theme_streak}
        </Text>
      </View>
    </TouchableOpacity>
  );

  useEffect(() => {
    const fetchCurrentStreakLeaderboard = async () => {
      if (user) {
        try {
          const authenticatedAxios = await authenticatedRequest();
          const response = await authenticatedAxios.get(
            `user_stats/daily-theme-streak/${user?.id}/1`,
          );
          setCurrentStreakUsers([
            ...currentStreakUsers,
            ...response.data.current_streak_list,
          ]);

          setAllTimeStreakUsers([
            ...allTimeStreakUsers,
            ...response.data.all_time_streak_list,
          ]);
        } catch (error) {
          showToast('Error fetching streaks 🧐');
        }
      }
    };
    fetchCurrentStreakLeaderboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const listHeader = () => {
    return (
      <>
        <Text_ListHeader text={'Daily theme streaks'} />
        <View style={globalstyles.toggle}>
          <Button_Navigation
            title={'Current'}
            callback={() => handlePress('current')}
            bgColor={
              activeTab === 'current' ? colors.primary_accent : colors.invisible
            }
            textColor={colors.secondary}
          />
          <Button_Navigation
            title={'All-time'}
            callback={() => handlePress('all-time')}
            bgColor={
              activeTab === 'all-time'
                ? colors.primary_accent
                : colors.invisible
            }
            textColor={colors.secondary}
          />
        </View>
      </>
    );
  };

  return (
    <SafeAreaView style={globalstyles.container}>
      <FlatList
        data={activeTab === 'current' ? currentStreakUsers : allTimeStreakUsers}
        renderItem={renderItem}
        ListHeaderComponent={listHeader}
        style={{ height: heightWithHeader }}
        keyExtractor={(item, index) => index.toString()}
      />
    </SafeAreaView>
  );
}

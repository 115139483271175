import { authenticatedRequest } from '@/modules/auth';
import { EMPTYSTATE_OPENAIIMAGEOBJECT, OpenAIImageObject } from './zod';

export async function toggleStarImage(
  users_id: number,
  image: OpenAIImageObject,
) {
  const authenticatedAxios = await authenticatedRequest();

  const requestMethod = !image.isStarred ? 'post' : 'delete';
  const requestUrl = `image_generations/star${
    !image.isStarred ? '' : `/${users_id}/${image.id}`
  }`;

  const requestData = {
    users_id: users_id,
    image_generations_id: image.id,
  };

  const response = await authenticatedAxios[requestMethod](
    requestUrl,
    requestData,
  );

  return response;
}

export const addPlaceholders = (
  data: OpenAIImageObject[],
  columns: number,
): OpenAIImageObject[] => {
  const cleanedData = data.filter((post) => !post.isPlaceholder);

  const remainder = cleanedData.length % columns;
  const placeholdersToAdd = remainder > 0 ? columns - remainder : 0;

  const newDataWithPlaceholders = [...cleanedData];

  for (let i = 0; i < placeholdersToAdd; i++) {
    newDataWithPlaceholders.push({
      ...EMPTYSTATE_OPENAIIMAGEOBJECT,
      isPlaceholder: true,
    });
  }

  return newDataWithPlaceholders;
};

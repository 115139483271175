import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import React from 'react';
import { useMyProfileStore } from '@/app/store/store';
import { Layout_MyProfile } from '@/components/profile/Layout_MyProfile';
import { Layout_UserProfile } from '@/components/profile/Layout_UserProfile';

export function UserProfileScreen() {
  // BASE.
  const { params } = useRoute<AuthenticatedNavigatorRouteProp<'UserProfile'>>();
  const navigation = useNavigation<AuthenticatedNavigatorProp<'UserProfile'>>();
  const myInfo = useMyProfileStore((state) => state.myInfo);

  // DETERMINANT.
  const myProfile = params.username === myInfo?.username;
  const username = myProfile ? myInfo?.username : params.username;

  return (
    <>
      {myProfile ? (
        <Layout_MyProfile navigation={navigation} myInfo={myInfo} />
      ) : (
        <Layout_UserProfile
          navigation={navigation}
          username={username}
          myInfo={myInfo}
        />
      )}
    </>
  );
}

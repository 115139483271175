export const colors = {
  primary: '#0D0D0D',
  primary_accent: '#2B2630',
  primary_light: '#3A3440',
  primary_opacity: 'rgba(27,23,30,0.2)',
  primary_offset: '#151515',
  primary_offset_2: '#1F1F1F',
  secondary: '#FFF',
  accent: '#6184d8',
  accent_highlight: '#7EA4FE',
  high_accent: 'orange',
  invisible: 'rgba(0,0,0,0)',
  darkshadow: '#000',
  halfwhite: '#bababa',
  username_link: '#D4E0FF',
  alert: 'red',
  productTier1Accent: 'tomato',
  productTier2Accent: 'orange',
  productTier3Accent: '#6184d8',
  webHoverLight: 'rgba(196,196,196,0.2)',
};
// mobile_primary: '#1B171E',

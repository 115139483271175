import React, { useState } from 'react';
import {
  Text,
  StyleSheet,
  Pressable,
  TouchableOpacity,
  View,
} from 'react-native';
import { TagList } from '@/api/zod';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { Image } from 'expo-image';
import { ParamListBase } from '@react-navigation/native';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp';
import { StackNavigationProp } from '@react-navigation/stack';

interface Props {
  tagList: TagList;
  showDescription?: boolean;
  navigation: StackNavigationProp<ParamListBase>;
  navigationCallback: (id: number) => void;
  index: boolean;
}

export function TagListJoinCard({
  tagList,
  navigationCallback,
  navigation,
  showDescription,
  index,
}: Props) {
  const [open, setOpen] = useState(false);
  const handleNav = () => {
    navigation.push('UserProfile', {
      username: tagList.username,
    });
  };
  return (
    <Pressable
      onPress={() => navigationCallback(tagList.id)}
      style={styles.container}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TouchableOpacity
          style={styles.usernameContainer}
          activeOpacity={0.9}
          onPress={index ? () => navigationCallback(tagList.id) : handleNav}
        >
          {tagList.Profile_picture ? (
            <Image
              source={{
                uri: tagList.Profile_picture.url,
              }}
              transition={250}
              style={globalstyles.profileImageSmall}
            />
          ) : (
            <Image
              source={require('../../assets/profile-placeholder.jpg')}
              style={globalstyles.profileImageSmall}
              transition={250}
            />
          )}
          <View style={{ marginLeft: spacing.md }}>
            <Text style={globalstyles.usernameText}>@{tagList.username}</Text>
            <Text style={globalstyles.body}>{tagList.title}</Text>
          </View>
        </TouchableOpacity>
        {index && (
          <Pressable
            style={{ padding: spacing.md }}
            onPress={() => setOpen(!open)}
          >
            <FontAwesomeIcon
              size={18}
              icon={open ? faAngleUp : faAngleDown}
              color={colors.secondary}
            />
          </Pressable>
        )}
      </View>
      {open || !index ? (
        <Image
          source={{ uri: tagList.image.url }}
          transition={250}
          style={{
            width: '100%',
            aspectRatio: 1,
            borderBottomRightRadius: index ? radius.md : undefined,
            borderBottomLeftRadius: index ? radius.md : undefined,
          }}
        />
      ) : null}
      {showDescription && (
        <View style={{ padding: spacing.md }}>
          <Text style={{ ...globalstyles.body, marginLeft: spacing.sm }}>
            <Text style={{ fontWeight: 'bold' }}>About: </Text>
            {tagList.description}
          </Text>
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary_accent,
    marginHorizontal: spacing.md,
    marginVertical: spacing.sm,
    borderRadius: radius.md,
  },
  usernameContainer: {
    ...globalstyles.centeringContainer,
    flexDirection: 'row',
    padding: spacing.md,
  },
});

import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useExploreStore } from '@/app/store/exploreStore';
import { useSession } from '@/app/store/session';
import { useExpStore, useMyProfileStore } from '@/app/store/store';
import { useStudioStore } from '@/app/store/studioStore';

export const useLogout = () => {
  const { logout } = useSession();
  const resetExplore = useExploreStore((state) => state.reset);
  const resetMyProfile = useMyProfileStore((state) => state.reset);
  const resetStudio = useStudioStore((state) => state.reset);
  const resetDailyTheme = useDailyThemeStore((state) => state.reset);
  const resetExp = useExpStore((state) => state.reset);
  const handleLogout = () => {
    console.log('log out');
    resetExplore();
    resetMyProfile();
    resetDailyTheme();
    resetStudio();
    resetExp();
    logout();
  };

  return { handleLogout };
};

import React from 'react';
import { colors } from '@/app/colors';
import { radius } from '@/app/globalstyles';
import { TouchableOpacity } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';

type ButtonProps = {
  callback: () => void;
};

export function LeftBackButtonArrow_NoHome({ callback }: ButtonProps) {
  return (
    <TouchableOpacity
      onPress={callback}
      style={{
        backgroundColor: colors.primary_accent,
        paddingVertical: 8,
        paddingHorizontal: 10,
        borderRadius: radius.xl,
      }}
    >
      <FontAwesomeIcon icon={faArrowLeft} color={colors.secondary} size={16} />
    </TouchableOpacity>
  );
}

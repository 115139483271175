import {
  ContributorItem,
  // ContributorItem,
  // EMPTYCONTRIBUTORITEM,
  ProfileResponse,
} from '@/api/zod';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Linking,
  Pressable,
} from 'react-native';
import { colors } from '@/app/colors';
import {
  globalstyles,
  radius,
  spacing,
  webContentWidth,
  heightWithHeader,
} from '@/app/globalstyles';
// import { JoinCard } from '@/components/JoinCard';
import { useCommunityStore } from '@/app/store/communityStore';
import ImageBackground from '@/components/ImageBackground';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { Subtitle } from '@/components/Subtitle';
import { Icon_WhirlVideo } from '@/components/Icon_WhirlVideo';
import { Layout_RenderSocials } from '@/components/Layout_RenderSocials';

export type GlobalKeyStore = {
  key: string;
  value_string?: string;
  value_string_2?: string;
  value_int?: number;
  value_bool?: boolean;
  image?: {
    url: string;
  };
};

export function CommunityScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Community'>>();
  const fetchContributors = useCommunityStore(
    (state) => state.fetchContributors,
  );
  const users = useCommunityStore((state) => state.users);
  const contributors = useCommunityStore((state) => state.contributors);

  const handleNav = (contributor: ContributorItem) => {
    if (contributor.users_id > 0) {
      navigation.push('UserProfile', {
        username: contributor.user_info.username,
      });
    } else {
      Linking.openURL(`https://instagram.com/${contributor.username}`);
    }
  };

  useEffect(() => {
    fetchContributors();
  }, [fetchContributors]);

  const renderUser = ({ item }: { item: ProfileResponse }) => (
    <View>
      <Text>{item.username}</Text>
    </View>
  );

  const renderContributor = ({ item }: { item: ContributorItem }) => {
    const preferredUsername =
      item.users_id > 0 ? item.user_info.username : item.username;
    const preferredUrl =
      item.users_id > 0 && item.user_info.Profile_picture
        ? item.user_info.Profile_picture.url
        : item.image.url;
    return (
      <Pressable
        key={preferredUsername}
        style={{ borderRadius: radius.lg }}
        onPress={() => handleNav(item)}
      >
        <ImageBackground
          style={{
            width: webContentWidth - spacing.sm,
            height: webContentWidth - spacing.sm,
            margin: spacing.xs,
            justifyContent: 'flex-end',
          }}
          imageStyle={{ borderRadius: radius.md }}
          source={{ uri: preferredUrl }}
        >
          <View
            style={{
              alignSelf: 'center',
              paddingBottom: spacing.md,
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <Text style={globalstyles.label}>@{preferredUsername}</Text>
            {item.users_id === 0 && (
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                color={colors.secondary}
                style={{ padding: spacing.sm }}
                size={16}
              />
            )}
          </View>
        </ImageBackground>
      </Pressable>
    );
  };

  // const addPlaceholders = (data: ContributorItem[]) => {
  //   if (data.length % 3 === 2) {
  //     data.push({
  //       ...contributorList,
  //       ...EMPTYCONTRIBUTORITEM,
  //     });
  //   } else if (data.length % 3 === 1) {
  //     data.push({
  //       ...contributorList,
  //       ...EMPTYCONTRIBUTORITEM,
  //     });
  //     data.push({
  //       ...contributorList,
  //       ...EMPTYCONTRIBUTORITEM,
  //     });
  //   }
  //   return data;
  // };

  const filteredContributorList = (data: ContributorItem[]) => {
    let thatArray = data.filter(
      (c) => c.tier === 'deity' || c.tier === 'queen' || c.tier === 'saint',
    );
    return thatArray;
  };

  const renderHeader = () => {
    return (
      <>
        <View style={{ alignItems: 'center' }}>
          <Pressable style={{ margin: spacing.md, alignItems: 'center' }}>
            <Icon_WhirlVideo />
          </Pressable>
          <Layout_RenderSocials />
          <TouchableOpacity
            style={{
              ...globalstyles.roundedButton,
              ...globalstyles.centeringContainer,
              marginBottom: spacing.lg,
            }}
            activeOpacity={0.9}
            onPress={() => navigation.push('About')}
          >
            <Text style={globalstyles.title}>About us</Text>
          </TouchableOpacity>
        </View>
        <View style={{ alignItems: 'center' }}>
          <View style={{ marginBottom: spacing.md }}>
            <Text style={globalstyles.label}>Special thanks</Text>
          </View>
          <View style={{ marginHorizontal: spacing.md }}>
            <Subtitle
              text={
                'Special shoutout to our community Patron Saints, whose support made this app possible.'
              }
            />
          </View>
          <FlatList
            data={filteredContributorList(contributors)}
            numColumns={2}
            showsVerticalScrollIndicator={false}
            renderItem={renderContributor}
            keyExtractor={(item) => item.username}
          />
        </View>
      </>
    );
  };

  return (
    <View style={{ ...globalstyles.container, alignItems: 'center' }}>
      <FlatList
        data={users}
        ListHeaderComponent={renderHeader}
        contentContainerStyle={{
          maxWidth: webContentWidth * 2,
        }}
        style={{ height: heightWithHeader }}
        showsVerticalScrollIndicator={false}
        keyExtractor={(u) => u.id.toString()}
        renderItem={renderUser}
      />
    </View>
  );
}

import React from 'react';
import { View } from 'react-native';
import { radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

export function Button_CreatePost() {
  return (
    <View
      style={{
        padding: spacing.sm,
        backgroundColor: colors.accent,
        borderRadius: radius.lg,
        marginBottom: spacing.sm,
      }}
    >
      <FontAwesomeIcon
        icon={faPlus}
        color={colors.secondary}
        style={{
          backgroundColor: colors.accent,
          borderRadius: 100,
          zIndex: 2,
        }}
        size={24}
      />
    </View>
  );
}

import React from 'react';
import { View } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { Image } from 'expo-image';

type Props = {
  rounded: boolean;
  accented: boolean;
  size?: string;
};

export function WhirlLogoImg({ rounded, accented, size }: Props) {
  const imageStyles = [
    globalstyles.profileUploadImage,
    rounded && globalstyles.rounded,
    accented && globalstyles.accented,
  ];
  const smallImageStyle = [globalstyles.logoImageLandingWeb];
  return (
    <View style={{ marginBottom: spacing.md }}>
      <Image
        source={require('../../../assets/nobg-icon.png')}
        style={size === 'small' ? smallImageStyle : imageStyles}
        transition={250}
      />
    </View>
  );
}

import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  Modal,
  Image,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
} from 'react-native';
import { EMPTYSTATEPOST, ProfileResponse, Tag } from '@/api/zod';
import { colors } from '@/app/colors';
import { globalstyles, webContentWidth, spacing } from '@/app/globalstyles';
import { RenderPost } from './RenderPost';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

interface Props {
  user: ProfileResponse;
  navigation: StackNavigationProp<ParamListBase>;
  title: string;
  prompt: string;
  file: Blob;
  tags: string[];
  modalVisible: boolean;
  updateModalVisible: () => void;
}

export const Modal_PreviewPost = ({
  user,
  navigation,
  title,
  prompt,
  file,
  tags,
  modalVisible,
  updateModalVisible,
}: Props) => {
  const [imgWidth, setImgWidth] = useState(1);
  const [imgHeight, setImgHeight] = useState(1);
  let previewTags: Tag[] = [];
  tags.map((t) => {
    previewTags.push({
      tags_id: 1,
      title: t,
      usage_count: 42,
    });
  });
  useEffect(() => {
    (async () => {
      const uri = URL.createObjectURL(file);
      try {
        const { newWidth, newHeight } = await new Promise<{
          newWidth: number;
          newHeight: number;
        }>((resolve, reject) => {
          Image.getSize(
            uri,
            (width, height) => resolve({ newWidth: width, newHeight: height }),
            (error) => reject(error),
          );
        });
        setImgWidth(newWidth);
        setImgHeight(newHeight);
      } catch (error) {
        console.log('Error', error);
      }
    })();
  }, [file]);

  let previewPost = {
    ...EMPTYSTATEPOST,
    created_at: Date.now(),
    username: user.username,
    users_id: user.id,
    Profile_picture: {
      url: user.Profile_picture?.url || '',
    },
    title,
    prompt,
    tags: previewTags,
    images: [
      {
        images_id: 0,
        image: {
          meta: {
            width: imgWidth,
            height: imgHeight,
          },
          url: URL.createObjectURL(file),
        },
      },
    ],
  };

  return (
    <Modal
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        updateModalVisible();
      }}
    >
      <SafeAreaView
        style={{
          ...globalstyles.container,
          backgroundColor: colors.primary,
          alignItems: 'center',
        }}
      >
        <View style={{ marginVertical: spacing.md }}>
          <Text style={globalstyles.title}>Draft preview</Text>
        </View>
        <TouchableOpacity
          style={{
            ...globalstyles.chip,
            ...globalstyles._selectedChip,
            marginBottom: spacing.md,
            alignSelf: 'center',
          }}
          onPress={() => updateModalVisible()}
        >
          <Text style={globalstyles.smallbody}>Exit preview</Text>
        </TouchableOpacity>
        <ScrollView
          style={{ marginBottom: spacing.lg }}
          contentContainerStyle={{
            alignItems: 'center',
            maxWidth: webContentWidth * 2,
          }}
          showsVerticalScrollIndicator={false}
        >
          <RenderPost
            post={previewPost}
            user={user}
            navigation={navigation}
            handleSavePost={() => console.log(EMPTYSTATEPOST)}
            voteable={false}
            editable={false}
            isPreview={true}
            isAnonymous={false}
          />
        </ScrollView>
      </SafeAreaView>
    </Modal>
  );
};

import React, { useEffect, useState } from 'react';
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  Pressable,
} from 'react-native';
import { Comment, EMPTYSTATECOMMENT, Post } from '@/api/zod';
import { Image } from 'expo-image';
import { relativeDate } from '@/components/RelativeDate';
import { ParamListBase } from '@react-navigation/native';
import { CommentActionModal } from './commentActionModal';
import { useMyProfileStore } from '@/app/store/store';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useCommentStore } from '@/app/store/commentStore';
import { colors } from '@/app/colors';
import {
  globalstyles,
  border,
  font,
  radius,
  spacing,
  width,
} from '@/app/globalstyles';
import { ModalHeader } from '@/components/ModalHeader';
import Modal from 'react-native-modal';
import { InputBox_WithSubmit } from '@/components/InputBox_WithSubmit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { useExploreStore } from '@/app/store/exploreStore';
import { StackNavigationProp } from '@react-navigation/stack';

interface CommentsModalProps {
  commentsModalVisible: boolean;
  updateCommentsModalVisible: () => void;
  post: Post;
  users_id: number | undefined;
  navigation: StackNavigationProp<ParamListBase>;
  username: string | undefined;
  updateLocalPostList?: (updatedPost: Post) => void;
}

const gifLinks = [
  'https://media.giphy.com/media/l0HlBO7eyXzSZkJri/giphy.gif',
  'https://media.giphy.com/media/7SF5scGB2AFrgsXP63/giphy-downsized.gif',
  'https://media.giphy.com/media/g01ZnwAUvutuK8GIQn/giphy-downsized.gif',
  'https://media.giphy.com/media/ISOckXUybVfQ4/giphy.gif',
  'https://media.giphy.com/media/y4z1J3XEI5aGh4LMja/giphy.gif',
  'https://media.giphy.com/media/l0HlBO7eyXzSZkJri/giphy.gif',
  'https://media.giphy.com/media/7SF5scGB2AFrgsXP63/giphy-downsized.gif',
  'https://media.giphy.com/media/g01ZnwAUvutuK8GIQn/giphy-downsized.gif',
  'https://media.giphy.com/media/ISOckXUybVfQ4/giphy.gif',
  'https://media.giphy.com/media/y4z1J3XEI5aGh4LMja/giphy.gif',
];

export const CommentsModal = ({
  commentsModalVisible,
  updateCommentsModalVisible,
  post,
  users_id,
  navigation,
  updateLocalPostList,
}: CommentsModalProps) => {
  const updatePostInChampPostList = useDailyThemeStore(
    (state) => state.updatePostInChampPostList,
  );
  const updatePostInExplorePostList = useExploreStore(
    (state) => state.updatePostInExplorePostList,
  );
  const updatePostInAllPostList = useMyProfileStore(
    (state) => state.updatePostInAllPostList,
  );
  const updatePostInDailyThemePostList = useMyProfileStore(
    (state) => state.updatePostInDailyThemePostList,
  );
  const updatePostInFirstRoundThemePostList = useDailyThemeStore(
    (state) => state.updatePostInFirstRoundThemePostList,
  );
  const updatePostInFinalRoundThemePostList = useDailyThemeStore(
    (state) => state.updatePostInFinalRoundThemePostList,
  );
  const fetchComments = useCommentStore((state) => state.fetchComments);
  const comments = useCommentStore((state) => state.comments);
  const loading = useCommentStore((state) => state.loading);
  const newCommentLoading = useCommentStore((state) => state.newCommentLoading);
  const hasMoreComments = useCommentStore((state) => state.hasMoreComments);
  const likeComment = useCommentStore((state) => state.likeComment);
  const likeReply = useCommentStore((state) => state.likeReply);
  const createComment = useCommentStore((state) => state.createComment);
  const createReply = useCommentStore((state) => state.createReply);
  const deleteComment = useCommentStore((state) => state.deleteComment);
  const [commentActionModalVisible, setCommentActionModalVisible] =
    useState<boolean>(false);

  const [commentInput, setCommentInput] = useState<string>('');
  const [longPressComment, setLongPressComment] =
    useState<Comment>(EMPTYSTATECOMMENT);
  // const [isTypingComment, setIsTypingComment] = useState(false);

  const renderFooter = () => {
    return loading && hasMoreComments ? (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="large" color={colors.halfwhite} />
      </View>
    ) : null;
  };

  const updateCommentActionModalVisible = () => {
    setCommentActionModalVisible(!commentActionModalVisible);
  };

  const handleLongPressCommentAction = (item: Comment) => {
    setLongPressComment(item);
    setCommentActionModalVisible(true);
  };

  const updateGlobal = (updatedPost: Post) => {
    updatePostInExplorePostList(updatedPost);
    updatePostInAllPostList(updatedPost);
    updatePostInDailyThemePostList(updatedPost);
    updatePostInChampPostList(updatedPost);
    updatePostInFirstRoundThemePostList(updatedPost);
    updatePostInFinalRoundThemePostList(updatedPost);
  };

  const handleCreateComment = (commentReplyingTo: Comment | undefined) => {
    if (users_id) {
      setCommentInput('');
      if (!isWritingAReply) {
        createComment(post, users_id, commentInput);
      } else if (commentReplyingTo) {
        createReply(commentReplyingTo, users_id, commentInput);
        leaveReplyMode();
      }
      const updatedPost = {
        ...post,
        commentCount: post.commentCount + 1,
      };
      updateGlobal(updatedPost);
      if (updateLocalPostList) {
        updateLocalPostList(updatedPost);
      }
    }
  };

  const handleDeleteComment = (comment: Comment) => {
    deleteComment(comment);
    const updatedPost = {
      ...post,
      commentCount: post.commentCount - 1,
    };
    setCommentActionModalVisible(false);
    updateGlobal(updatedPost);
    if (updateLocalPostList) {
      updateLocalPostList(updatedPost);
    }
  };

  const currentUserComments = comments.filter(
    (comment) => comment.users_id === users_id,
  );

  const handleNav = (username: string | null) => {
    navigation.push('UserProfile', {
      username: username,
    });
    updateCommentsModalVisible();
  };

  const handleReply = (comment: Comment) => {
    setIsWritingAReply(true);
    setCommentReplyingTo(comment);
  };

  const lastDigit = parseInt(post.id.toString().slice(-1), 10);
  const randomGifLink = gifLinks[lastDigit];

  const renderProfilePicture = (item: Comment, isReply: boolean = false) => {
    const profileImageStyle = isReply
      ? globalstyles.profileImageXS
      : globalstyles.profileImageSmall;

    return (
      <TouchableOpacity
        onPress={() => handleNav(item.username)}
        style={{ flexDirection: 'row' }}
      >
        {item.Profile_picture ? (
          <Image
            source={{
              uri: item.Profile_picture.url,
            }}
            style={{ ...profileImageStyle, margin: spacing.md }}
            transition={250}
          />
        ) : (
          <Image
            source={require('../../assets/profile-placeholder.jpg')}
            style={{ ...profileImageStyle, margin: spacing.md }}
            transition={250}
          />
        )}
      </TouchableOpacity>
    );
  };

  const renderUsernameAndBody = (item: Comment) => {
    return (
      <View style={styles.commentTextHeaderContainer}>
        <View style={{ alignItems: 'center' }}>
          <Text style={globalstyles.usernameText}>@{item.username}</Text>
        </View>
        <View style={{ paddingVertical: spacing.sm }}>
          <Text style={globalstyles.smallbody}>{item.body}</Text>
        </View>
      </View>
    );
  };

  const handleLike = (item: Comment) => {
    if (users_id && item.isReply) {
      likeReply(item, users_id);
    } else if (users_id && !item.isReply) {
      likeComment(item, users_id);
    }
  };

  const renderCommentFooter = (item: Comment, isReply: boolean) => {
    return (
      <TouchableOpacity style={styles.commentFooter}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={globalstyles.tinyfade}>
            {`${relativeDate(item.created_at)} `}
          </Text>
          {!isReply && (
            <TouchableOpacity
              activeOpacity={0.9}
              onPress={() => handleReply(item)}
            >
              <Text
                style={
                  isReply && item.id === commentReplyingTo?.id
                    ? { ...globalstyles.tinybody, color: colors.accent }
                    : globalstyles.tinybody
                }
              >
                - Reply
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <TouchableOpacity
          style={styles.likeButtonContainer}
          onPress={() => handleLike(item)}
        >
          <View style={{ marginRight: spacing.sm }}>
            {item.likeCount > 0 ? (
              <Text style={globalstyles.tinybody}>{item.likeCount}</Text>
            ) : null}
          </View>
          <FontAwesomeIcon
            icon={faArrowUp}
            color={item.isLiked ? colors.accent : colors.halfwhite}
            size={18}
          />
        </TouchableOpacity>
      </TouchableOpacity>
    );
  };

  const renderComment = ({ item }: { item: Comment }) => {
    return (
      <View key={item.id}>
        <Pressable
          style={
            users_id === item.users_id
              ? {
                  ...styles.comment,
                  backgroundColor: colors.primary_accent,
                  marginLeft: item.isReply ? spacing.xl : 0,
                  borderLeftColor: item.isReply
                    ? colors.primary_accent
                    : colors.invisible,
                }
              : {
                  ...styles.comment,
                  marginLeft: item.isReply ? spacing.xl : 0,
                  borderLeftColor: item.isReply
                    ? colors.primary_accent
                    : colors.invisible,
                }
          }
          onLongPress={() => handleLongPressCommentAction(item)}
        >
          {renderProfilePicture(item, item.isReply)}
          <View style={{ flex: 1 }}>
            {renderUsernameAndBody(item)}
            {renderCommentFooter(item, item.isReply)}
          </View>
        </Pressable>
        {item.replies &&
          item.replies.map((reply) => renderComment({ item: reply }))}
      </View>
    );
  };

  const renderPlaceholderGif = () => {
    if (!loading && comments.length === 0) {
      return (
        <View style={styles.emptyCommentsPlaceholder}>
          <Text style={globalstyles.body}>No comments yet...</Text>
          <Image
            source={{
              uri: randomGifLink,
            }}
            style={{ height: 120, width: 120 }}
            transition={250}
            contentFit={'contain'}
          />
        </View>
      );
    }
    return <View style={styles.emptyCommentsPlaceholder} />;
  };

  useEffect(() => {
    if (commentsModalVisible && users_id) {
      fetchComments(post, users_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsModalVisible, post.id]);

  const keyExtractor = (item: Comment) => item.id.toString();

  const [isWritingAReply, setIsWritingAReply] = useState(false);
  const [commentReplyingTo, setCommentReplyingTo] = useState<
    Comment | undefined
  >();

  const leaveReplyMode = () => {
    setIsWritingAReply(false);
    setCommentReplyingTo(undefined);
  };

  return (
    <View>
      <Modal
        isVisible={commentsModalVisible}
        avoidKeyboard={true}
        hideModalContentWhileAnimating={true}
        style={{ margin: 0 }}
      >
        <View style={{ flex: 1 }}>
          <Pressable
            style={{ flex: 1, justifyContent: 'center' }}
            onPress={updateCommentsModalVisible}
          />

          <View style={styles.contentContainer}>
            <ModalHeader
              callback={updateCommentsModalVisible}
              title={'Comments'}
            />
            {comments.length > 0 && users_id ? (
              <FlatList
                data={comments}
                style={{ flex: 1 }}
                renderItem={renderComment}
                keyExtractor={keyExtractor}
                onEndReached={() => fetchComments(post, users_id)}
                onEndReachedThreshold={0.8}
                ListFooterComponent={renderFooter}
              />
            ) : (
              renderPlaceholderGif()
            )}
          </View>
          <View
            style={{
              paddingBottom: spacing.md,
              backgroundColor: colors.primary,
            }}
          >
            {isWritingAReply && (
              <Pressable
                onPress={leaveReplyMode}
                style={styles.replyRemovalContainer}
              >
                <Text
                  style={globalstyles.smallfade}
                >{`Replying to @${commentReplyingTo?.username}`}</Text>
                <FontAwesomeIcon
                  icon={faTimes}
                  color={colors.halfwhite}
                  size={16}
                />
              </Pressable>
            )}
            <View style={{ alignSelf: 'center' }}>
              <InputBox_WithSubmit
                placeholderText={
                  currentUserComments.length >= 5
                    ? 'Max 5 comments...'
                    : 'Add a comment...'
                }
                handleInputChange={setCommentInput}
                autocapitalize={'sentences'}
                editable={currentUserComments.length < 5}
                maxLength={480}
                multiline={false}
                isLoading={newCommentLoading}
                value={commentInput}
                submitCallback={() => handleCreateComment(commentReplyingTo)}
                submitDisabled={
                  currentUserComments.length >= 5 || commentInput.length === 0
                }
                submitIcon={
                  currentUserComments.length >= 5 ? faEllipsisH : faPaperPlane
                }
              />
            </View>
          </View>
        </View>
        <CommentActionModal
          commentActionModalVisible={commentActionModalVisible}
          updateCommentActionModalVisible={updateCommentActionModalVisible}
          comment={longPressComment}
          handleDeleteComment={handleDeleteComment}
          users_id={users_id}
          navigation={navigation}
          updateCommentsModalVisible={updateCommentsModalVisible}
          post={post}
        />
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollViewContainer: {
    flex: 1,
    backgroundColor: colors.primary,
  },
  contentContainer: {
    flex: 5,
    backgroundColor: colors.primary,
    borderTopRightRadius: radius.xl,
    borderTopLeftRadius: radius.xl,
  },
  container: {
    flex: 6,
    backgroundColor: colors.primary,
    borderTopRightRadius: radius.lg * 2,
    borderTopLeftRadius: radius.lg * 2,
  },
  keyboardContentContainer: {
    flexDirection: 'row',
    backgroundColor: colors.primary_accent,
    width: width - spacing.md * 2,
    marginHorizontal: spacing.md,
    marginBottom: spacing.xl,
    borderRadius: radius.md,
  },
  headerContainer: {
    marginTop: 24,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
  },
  comment: {
    flexDirection: 'row',
    borderLeftWidth: border.xs,
  },
  commentTextHeaderContainer: {
    paddingTop: spacing.lg,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  commentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: spacing.sm,
  },
  likeButtonContainer: {
    paddingRight: spacing.md,
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    color: colors.secondary,
    padding: spacing.lg,
    fontSize: font.lg,
    flex: 1,
  },
  emptyCommentsPlaceholder: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'center',
  },
  replyRemovalContainer: {
    padding: spacing.md,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopColor: colors.primary_accent,
    borderTopWidth: spacing.xs,
  },
});

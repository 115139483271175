import React, { Component } from 'react';
import {
  View,
  ImageStyle,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native';
import { Image } from 'expo-image';

interface ImageBackgroundProps {
  style?: StyleProp<ViewStyle>;
  imageStyle?: StyleProp<ImageStyle>;
  imageRef?: React.RefObject<typeof Image>;
  source: any;
  blurRadius?: number;
  contentFit?: 'contain' | 'cover';
  children?: React.ReactNode;
}

const extractDimensions = (
  style: StyleProp<ViewStyle>,
): { width?: number; height?: number } => {
  const flattenedStyle = StyleSheet.flatten(style);

  if (
    flattenedStyle &&
    typeof flattenedStyle.height === 'number' &&
    typeof flattenedStyle.width === 'number'
  ) {
    return { height: flattenedStyle.height, width: flattenedStyle.width };
  }

  return {};
};

export default class ImageBackground extends Component<ImageBackgroundProps> {
  render() {
    const {
      children,
      style = {},
      blurRadius,
      imageStyle,
      ...props
    } = this.props;
    const { width, height } = extractDimensions(style);

    if (style) {
      return (
        <View style={style}>
          <Image
            {...props}
            style={[
              StyleSheet.absoluteFill,
              {
                width: width,
                height: height,
              },
              imageStyle as any,
            ]}
            blurRadius={blurRadius}
            transition={250}
          />
          {children}
        </View>
      );
    }

    return null;
  }
}

import { colors } from '@/app/colors';
import { toggleSavePost, toggleVoteForPost } from '@/api/posts';
import {
  globalstyles,
  border,
  radius,
  spacing,
  height,
  webContentWidth,
  headerHeight,
} from '@/app/globalstyles';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Animated,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import { Image } from 'expo-image';
import { EMPTYSTATEPOST, Post } from '@/api/zod';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import { authenticatedRequest } from '@/modules/auth';
import { MetadataModal } from '../metadataModal';
import { CommentsModal } from '../commentsModal';
import { Modal_FullScreenImage } from '../Modal_FullScreenImage';
import { useCommentStore } from '@/app/store/commentStore';
import { showToast } from '@/components/ErrorToast';
import { LinearGradient } from 'expo-linear-gradient';
import { PostActionButtons } from '@/components/PostActionButtons';
import { useMyProfileStore } from '@/app/store/store';
import ImageBackground from '@/components/ImageBackground';
import { WebNav_DailyThemeheader } from '@/components/daily theme/WebNav_DailyThemeHeader';
import { Text_WebPageHeader } from '@/components/Text_WebPageHeader';
import { Button_PromptListChip } from '@/components/shared/Button_PromptListChip';

const SPACING = 10;
const ITEM_SIZE = webContentWidth * 2;
const EMPTY_ITEM_SIZE = (webContentWidth * 2 - ITEM_SIZE) / 2;

const Backdrop = () => {
  const finalRoundThemeInfo = useDailyThemeStore(
    (state) => state.finalRoundThemeInfo,
  );
  return (
    <View style={styles.backdropContainer}>
      <ImageBackground
        style={{
          height: '100%',
          width: '100%',
        }}
        source={{ uri: finalRoundThemeInfo.background_image_landscape.url }}
      />
      <LinearGradient
        colors={[colors.primary, 'rgba(43,38,48,0.8)']}
        style={globalstyles.gradientOverlay}
      />
    </View>
  );
};

export function DailyThemePodiumScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'DailyThemePodium'>>();
  const route = useRoute();
  const routeName = route.name;
  const finalRoundThemeInfo = useDailyThemeStore(
    (state) => state.finalRoundThemeInfo,
  );
  const finalRoundThemePosts = useDailyThemeStore(
    (state) => state.finalRoundThemePosts,
  );
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );
  const updatePostInFinalRoundThemePostList = useDailyThemeStore(
    (state) => state.updatePostInFinalRoundThemePostList,
  );
  const [commentsModalVisible, setCommentsModalVisible] = useState(false);
  const [metadataModalVisible, setMetadataModalVisible] = useState(false);
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const reset = useCommentStore((state) => state.reset);
  const [clickedPost, setClickedPost] = useState<Post>(EMPTYSTATEPOST);
  const [promptIsSaved, setPromptIsSaved] = useState(false);
  const [totalPromptSaves, setTotalPromptSaves] = useState(0);
  const updateCommentsModalVisible = () => {
    if (commentsModalVisible) {
      reset();
    }
    setCommentsModalVisible(!commentsModalVisible);
  };
  const updatePromptInfo = () => {
    if (promptIsSaved) {
      setTotalPromptSaves(totalPromptSaves - 1);
    } else {
      setTotalPromptSaves(totalPromptSaves + 1);
    }
    setPromptIsSaved(!promptIsSaved);
  };

  // const scrollX = React.useRef(new Animated.Value(0)).current;
  const postsWithSpacer = [
    EMPTYSTATEPOST,
    ...finalRoundThemePosts,
    { ...EMPTYSTATEPOST, id: -1 },
  ];

  const handleSavePost = (post: Post) => {
    user && toggleSavePost(user, post);

    // global update.
    const updatedPost = {
      ...post,
      isSaved: !post.isSaved,
      totalSaves: !post.isSaved ? post.totalSaves + 1 : post.totalSaves - 1,
    };
    updatePostInFinalRoundThemePostList(updatedPost);
  };

  // const setDebouncedIndex = debounce((index) => setCurrentIndex(index), 20);

  const renderHeader = () => {
    return (
      <View style={{ alignItems: 'center' }}>
        <Text_WebPageHeader
          header={'Champ vote'}
          subtitle={`Top 5 from the theme of "${finalRoundThemeInfo.title}"`}
        />
        <Button_PromptListChip
          navigation={navigation}
          theme={finalRoundThemeInfo}
        />
      </View>
    );
  };

  return (
    <View
      style={{
        ...globalstyles.container,
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Backdrop />
      <StatusBar hidden />
      <WebNav_DailyThemeheader
        activeRoute={routeName}
        navigation={navigation}
        loggedInUserHasSubmitted={submissionThemeInfo.loggedInUserHasSubmitted}
      />
      <Animated.FlatList
        showsHorizontalScrollIndicator={false}
        data={postsWithSpacer}
        contentContainerStyle={{
          alignItems: 'center',
          maxWidth: webContentWidth * 3,
          paddingBottom: height / 2,
        }}
        style={{
          height: height,
          paddingTop: headerHeight + spacing.lg,
        }}
        keyExtractor={(item) => item.id.toString()}
        bounces={false}
        ListHeaderComponent={renderHeader}
        renderToHardwareTextureAndroid
        snapToInterval={undefined}
        snapToAlignment="start"
        onScroll={undefined}
        scrollEventThrottle={16}
        decelerationRate={'fast'}
        renderItem={({ item }) => {
          const checkIfPromptIsSaved = async (post: Post) => {
            if (user) {
              try {
                const authenticatedAxios = await authenticatedRequest();
                const response = await authenticatedAxios.get(
                  `user_saved_prompts/check_if_saved/${post.id}/${user.id}`,
                );
                setPromptIsSaved(response.data.promptIsSaved);
                setTotalPromptSaves(response.data.totalPromptSaves);
              } catch (error) {
                showToast('Error checking if prompt is saved');
              }
            }
          };
          const updateMetadataModalVisible = async () => {
            checkIfPromptIsSaved(item);
            setClickedPost(item);
            setMetadataModalVisible(!metadataModalVisible);
          };

          const updateFullScreenModalVisible = () => {
            setClickedPost(item);
            setFullScreenModalVisible(!fullScreenModalVisible);
          };
          if (item.id < 1) {
            return <View style={{ width: EMPTY_ITEM_SIZE }} />;
          }

          const handleVote = () => {
            user && toggleVoteForPost(user, item);

            // global update.
            const updatedPost = {
              ...item,
              likedOrVoted: !item.likedOrVoted,
              voteCount: !item.likedOrVoted
                ? item.voteCount + 1
                : item.voteCount - 1,
            };
            updatePostInFinalRoundThemePostList(updatedPost);
          };

          // const inputRange = [
          //   (index - 2) * ITEM_SIZE,
          //   (index - 1) * ITEM_SIZE,
          //   index * ITEM_SIZE,
          // ];

          // const translateY = scrollX.interpolate({
          //   inputRange,
          //   outputRange: [20, 0, 20],
          //   extrapolate: 'clamp',
          // });

          return (
            <View style={{ width: ITEM_SIZE }}>
              <Animated.View
                key={item.id}
                style={{
                  marginHorizontal: undefined,
                  margin: spacing.md,
                  padding: SPACING,
                  transform: undefined,
                  backgroundColor: colors.primary,
                  borderWidth: border.sm,
                  borderColor: item.likedOrVoted
                    ? colors.accent
                    : colors.primary,
                  borderRadius: 24,
                }}
              >
                <Pressable onLongPress={updateFullScreenModalVisible}>
                  <Image
                    source={{ uri: item.images[0].image.url }}
                    style={{
                      width: '100%',
                      height: undefined,
                      marginBottom: spacing.sm,
                      borderRadius: radius.lg,
                      alignSelf: 'center',
                      maxHeight: height / 2,
                      aspectRatio:
                        (item.images[0].image.meta.width || 1) /
                        (item.images[0].image.meta.height || 1),
                    }}
                    contentFit={'cover'}
                    transition={250}
                  />
                </Pressable>
                <View style={styles.postInfoContainer}>
                  <View style={styles.textPreviewContainer}>
                    <View style={styles.commentButtonContainer}>
                      <View style={{ flexDirection: 'row' }}>
                        {item.commentCount > 0 ? (
                          <TouchableOpacity
                            onPress={updateCommentsModalVisible}
                          >
                            <Text style={{ color: colors.halfwhite }}>
                              {item.commentCount}
                              {item.commentCount > 1 ? ' comments' : ' comment'}
                            </Text>
                          </TouchableOpacity>
                        ) : null}
                        {item.commentCount > 0 && item.voteCount > 0 ? (
                          <Text style={{ color: colors.halfwhite }}>
                            {' · '}
                          </Text>
                        ) : null}
                        {item.voteCount > 0 ? (
                          <TouchableOpacity
                            onPress={() =>
                              navigation.push('Likes', {
                                postId: item.id,
                                voteable: true,
                                isDailyThemeRelated: true,
                              })
                            }
                          >
                            <Text style={{ color: colors.halfwhite }}>
                              {item.voteCount}
                              {`${
                                item.voteCount > 1
                                  ? ' champ votes'
                                  : ' champ vote'
                              }`}
                            </Text>
                          </TouchableOpacity>
                        ) : null}
                      </View>
                      <Text
                        style={{
                          ...globalstyles.body,
                          paddingTop: spacing.xs,
                          width: webContentWidth + spacing.md,
                        }}
                      >
                        {item.title}
                      </Text>
                    </View>
                  </View>
                  <PostActionButtons
                    post={item}
                    metadataCallback={updateMetadataModalVisible}
                    commentCallback={() => {
                      setClickedPost(item);
                      updateCommentsModalVisible();
                    }}
                    saveCallback={() => handleSavePost(item)}
                    likeCallback={handleVote}
                    voteable={true}
                  />
                </View>
                <TouchableOpacity
                  onPress={handleVote}
                  activeOpacity={0.9}
                  style={{
                    ...styles.actionButtonContainer,
                    backgroundColor: item.likedOrVoted
                      ? colors.accent
                      : colors.primary_accent,
                  }}
                >
                  <Text style={globalstyles.title}>
                    {item.likedOrVoted ? 'Voted!' : 'Vote'}
                  </Text>
                </TouchableOpacity>
                <MetadataModal
                  metadataModalVisible={metadataModalVisible}
                  updateMetadataModalVisible={updateMetadataModalVisible}
                  post={clickedPost}
                  userId={user?.id}
                  promptIsSaved={promptIsSaved}
                  totalPromptSaves={totalPromptSaves}
                  updatePromptInfo={updatePromptInfo}
                />
                <Modal_FullScreenImage
                  visible={fullScreenModalVisible}
                  toggleVisible={updateFullScreenModalVisible}
                  post={clickedPost}
                />
              </Animated.View>
            </View>
          );
        }}
      />
      <CommentsModal
        commentsModalVisible={commentsModalVisible}
        updateCommentsModalVisible={updateCommentsModalVisible}
        post={clickedPost}
        users_id={user?.id}
        navigation={navigation}
        username={user?.username}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  backdropContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  postInfoContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: spacing.md,
  },
  actionButtonContainer: {
    backgroundColor: colors.primary_accent,
    borderRadius: 12,
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textPreviewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: spacing.sm,
  },
  commentButtonContainer: {
    alignSelf: 'flex-start',
    paddingTop: spacing.xs,
  },
  dotContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: spacing.lg * 2,
    padding: spacing.md,
    backgroundColor: colors.primary,
    borderRadius: radius.xl,
  },
  dot: {
    width: spacing.sm,
    height: spacing.sm,
    borderRadius: spacing.sm,
    marginHorizontal: spacing.xs,
  },
});

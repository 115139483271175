import { create } from 'zustand';
import { ContributorItem, ProfileResponse } from '@/api/zod';
import { authenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';

const initialCommunityStoreState = {
  contributors: [],
  loading: true,
  users: [],
};

type CommunityStore = {
  reset: () => void;
  contributors: ContributorItem[];
  loading: boolean;
  setUsers: (users: ProfileResponse[]) => void;
  users: ProfileResponse[];

  fetchContributors: () => void;
  handleBlock: (blocked_user_id: number) => void;
};

export const useCommunityStore = create<CommunityStore>((set) => ({
  ...initialCommunityStoreState,
  setUsers: (users) => set({ users }),
  reset: () => set(() => ({ ...initialCommunityStoreState })),

  fetchContributors: async () => {
    set({ loading: true });
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get('contributors_list');
      set({
        contributors: response.data,
      });
    } catch (error) {
      showToast('Error saving fetching comments');
    }
    set({ loading: false });
  },
  handleBlock: (blocked_user_id: number) => {
    set((state) => ({
      users: state.users.filter((u) => u.id !== blocked_user_id),
    }));
    set((state) => ({
      contributors: state.contributors.filter(
        (c) => c.users_id !== blocked_user_id,
      ),
    }));
  },
}));

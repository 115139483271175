import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { EMPTYTECHNOLOGY, Technology } from '@/api/zod';
import { globalstyles } from '@/app/globalstyles';
import { useMyProfileStore } from '@/app/store/store';
import { Layout_WebUploadDetails } from '@/components/shared/Layout_WebUploadDetails';
import { Text_WebPageHeader } from '@/components/Text_WebPageHeader';
import { DEFAULTTECH } from '@/api/posts';
import Button_WebUpload from '@/components/Button_WebUpload';
import ImageBackground from '@/components/ImageBackground';

export function CreatePostScreen() {
  const {
    params: {
      // submission_theme_id,
      // updateSubmitted,
      generated_image,
      passedExistingTags,
    },
  } = useRoute<AuthenticatedNavigatorRouteProp<'CreatePost'>>();
  const navigation = useNavigation<AuthenticatedNavigatorProp<'CreatePost'>>();
  const [file, setFile] = useState<Blob | undefined>();
  const [loading, setLoading] = useState(false);
  const myInfo = useMyProfileStore((state) => state.myInfo);

  // TECHNOLOGIES.
  const [technologies] = useState<Technology[]>(
    generated_image ? [DEFAULTTECH[2]] : DEFAULTTECH,
  );
  const [selectedTechnology, setSelectedTechnology] = useState<Technology>(
    generated_image ? DEFAULTTECH[2] : EMPTYTECHNOLOGY,
  );

  // TAGS.
  const [allTags, setAllTags] = useState<string[]>(passedExistingTags);
  const addTag = (tag: string) => {
    setAllTags([...allTags, tag.toLocaleLowerCase().trim()]);
  };
  const removeTag = (tagToRemove: string) => {
    setAllTags(allTags.filter((tag) => tag !== tagToRemove));
  };
  const resetTags = () => {
    setAllTags(passedExistingTags);
  };

  return (
    <ImageBackground
      style={{
        ...globalstyles.container,
        justifyContent: 'center',
      }}
      imageStyle={{
        opacity: 0.4,
      }}
      blurRadius={50}
      source={require('../../assets/profile-placeholder.jpg')}
    >
      <View style={styles.contentContainer}>
        <Text_WebPageHeader header={'Draft'} subtitle={'Share a post'} />
        {file && myInfo ? (
          <Layout_WebUploadDetails
            file={file}
            loading={loading}
            setFile={setFile}
            technologies={technologies}
            selectedTechnology={selectedTechnology}
            setSelectedTechnology={setSelectedTechnology}
            allTags={allTags}
            addTag={addTag}
            removeTag={removeTag}
            navigation={navigation}
            setLoading={setLoading}
            myInfo={myInfo}
            post={undefined}
            daily_theme_id={null} // no need here.
            resetTags={resetTags}
          />
        ) : (
          <Button_WebUpload setFile={setFile} />
        )}
      </View>
      <Toast />
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    paddingBottom: 96,
    alignSelf: 'center',
    flexGrow: 1,
  },
});

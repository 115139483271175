import React from 'react';
import { View } from 'react-native';
import { Comment, Post } from '@/api/zod';
import { colors } from '@/app/colors';
import { ParamListBase } from '@react-navigation/native';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { StackNavigationProp } from '@react-navigation/stack';
import { Button_Settings } from '../Button_Settings';
import ModalHOC from '../hoc/ModalHOC';

interface Props {
  users_id: number | undefined;
  comment: Comment;
  toggleVisible: () => void;
  handleClose: () => void;
  handleDeleteComment: (comment: Comment) => void;
  navigation: StackNavigationProp<ParamListBase>;
  post: Post;
}

const Modal_CommentActionsContent = ({
  users_id,
  comment,
  toggleVisible,
  handleClose,
  handleDeleteComment,
  navigation,
  post,
}: Props) => {
  const handleReport = () => {
    handleClose();
    navigation.push('Report', {
      comment: comment,
      reporting_user_id: users_id,
      reported_user_id: comment.users_id,
    });
    toggleVisible();
  };

  const handleNavigateToEdit = () => {
    handleClose();
    navigation.navigate('EditComment', {
      comment: comment,
    });
    toggleVisible();
  };

  return (
    <View>
      {users_id === comment.users_id || users_id === post.users_id ? (
        <Button_Settings
          callback={() => handleDeleteComment(comment)}
          title={'Delete comment'}
          icon={faTrash}
          iconColor={colors.alert}
          bgColor={colors.primary_light}
          marginBottom={true}
        />
      ) : null}
      {users_id === comment.users_id ? (
        <Button_Settings
          callback={handleNavigateToEdit}
          title={'Edit comment'}
          icon={faPen}
          bgColor={colors.primary_light}
          marginBottom={true}
        />
      ) : null}
      <Button_Settings
        callback={handleReport}
        title={'Report'}
        icon={faFile}
        bgColor={colors.primary_light}
        marginBottom={true}
      />
    </View>
  );
};

const Modal_CommentActions = ModalHOC(Modal_CommentActionsContent);

export default Modal_CommentActions;

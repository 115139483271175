import React, { useState } from 'react';
import { Pressable, Text } from 'react-native';
import { border, globalstyles, spacing } from '@/app/globalstyles';
import { useRoute } from '@react-navigation/native';
import { colors } from '@/app/colors';

interface NavProps {
  text: string;
  hoverInCallback: () => void;
  callback: () => void;
  activeRoute: string;
}

export const Button_HeaderNavLink = ({
  text,
  hoverInCallback,
  callback,
  activeRoute,
}: NavProps) => {
  const [hover, setHover] = useState(false);
  const route = useRoute();

  const isDailyThemeRelated = () => {
    switch (route.name) {
      case 'VoteNextTheme':
      case 'DailyThemePodium':
      case 'DailyThemeRound1Vote':
      case 'ViewPostsForVote':
        return true;
      default:
        return false;
    }
  };
  const active =
    route.name === activeRoute ||
    (text === 'Daily theme' && isDailyThemeRelated());

  return (
    <Pressable
      style={{
        margin: spacing.md,
        paddingVertical: spacing.sm,
        borderBottomColor: active ? colors.secondary : colors.invisible,
        borderBottomWidth: border.sm,
      }}
      onPress={callback}
      onHoverIn={() => {
        setHover(true);
        hoverInCallback();
      }}
      onHoverOut={() => setHover(false)}
    >
      <Text
        style={
          active || hover
            ? { ...globalstyles.body, fontWeight: '500' }
            : { ...globalstyles.bodyfade, fontWeight: '500' }
        }
      >
        {text}
      </Text>
    </Pressable>
  );
};

import React, { useState } from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';
import { colors } from '@/app/colors';
import { border, font, globalstyles, spacing } from '@/app/globalstyles';

interface Props {
  title: string;
  active: boolean;
  callback: () => void;
}

export const Tab_Profile = ({ title, active, callback }: Props) => {
  const [hover, setHover] = useState(false);

  return (
    <Pressable
      style={{
        ...styles.button,
        borderBlockColor: active ? colors.accent : colors.invisible,
        borderBottomWidth: border.sm,
      }}
      onPress={callback}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <Text
        style={{
          ...globalstyles.body,
          fontWeight: active ? '600' : undefined,
          fontSize: font.xl,
          opacity: hover || active ? 1 : 0.6,
        }}
      >
        {title}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingVertical: spacing.md,
    // paddingHorizontal: spacing.md,
  },
});

import React, { useState } from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { colors } from '@/app/colors';
import { radius, spacing } from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface Props {
  close: () => void;
  overrideIcon?: IconDefinition;
  bgColor?: string;
  bgHover?: string;
}

export const Button_ModalClose = ({
  close,
  overrideIcon,
  bgColor,
  bgHover,
}: Props) => {
  const [hover, setHover] = useState(false);
  const calcBgColor = bgColor ? bgColor : colors.primary_accent;
  const calcBgHover = bgHover ? bgHover : colors.primary_light;
  return (
    <Pressable
      style={{
        ...styles.closeButton,
        backgroundColor: hover ? calcBgHover : calcBgColor,
      }}
      onPress={close}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <FontAwesomeIcon
        icon={overrideIcon ? overrideIcon : faTimes}
        size={spacing.md}
        color={colors.secondary}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    padding: spacing.sm,
    top: spacing.lg,
    left: spacing.lg,
    borderRadius: radius.xl,
    outlineStyle: 'none',
    zIndex: 2,
  },
});

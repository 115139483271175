import React from 'react';
import { View } from 'react-native';
import { colors } from '@/app/colors';
import { radius, spacing } from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';

export function LeftBackButtonArrow_OutsideApp() {
  return (
    <>
      <View
        style={{
          backgroundColor: colors.primary_accent,
          padding: spacing.sm,
          borderRadius: radius.xl,
        }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={colors.secondary}
          size={16}
        />
      </View>
    </>
  );
}

import React, { useState } from 'react';
import { View } from 'react-native';
import ModalHOC from '../shared/hoc/ModalHOC';
import { OAuth } from '../unauthenticated/OAuth';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { Layout_EmailSignUp } from './signup/Layout_EmailSignUp';
import { Layout_EmailSignIn } from './signup/Layout_EmailSignIn';
import { Layout_PasswordReset } from './pwreset/Layout_PasswordReset';

interface Props {
  toggleModal: () => void;
}

const Modal_SignInContent = ({ toggleModal }: Props) => {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Explore'>>();
  const [screen, setScreen] = useState('oauth');

  const renderScreen = () => {
    switch (screen) {
      case 'oauth':
        return (
          <OAuth
            setScreen={setScreen}
            toggleModal={toggleModal}
            navigation={navigation}
          />
        );
      case 'signup':
        return (
          <Layout_EmailSignUp setScreen={setScreen} toggleModal={toggleModal} />
        );
      case 'login':
        return (
          <Layout_EmailSignIn setScreen={setScreen} toggleModal={toggleModal} />
        );
      case 'pw-reset':
        return <Layout_PasswordReset setScreen={setScreen} />;
    }
  };
  return <View>{renderScreen()}</View>;
};

const Modal_SignIn = ModalHOC(Modal_SignInContent);

export default Modal_SignIn;

import {
  ProfileResponse,
  BaseTheme,
  EMPTYSTATETHEMEINFO,
  EMPTYSTATEEXTENDEDTHEMEINFO,
  ExtendedBaseTheme,
} from '@/api/zod';
import { Post } from '@/api/zod';
import { showToast } from '@/components/ErrorToast';
import { unauthenticatedRequest } from '@/modules/auth';
import { create } from 'zustand';

const initialDailyThemeStoreState = {
  votingComplete: false,
  dailyThemeTags: [],
  dailyThemesUpForVote: [
    EMPTYSTATEEXTENDEDTHEMEINFO,
    EMPTYSTATEEXTENDEDTHEMEINFO,
  ],
  submissionThemeInfo: EMPTYSTATEEXTENDEDTHEMEINFO,
  firstRoundThemeInfo: EMPTYSTATEEXTENDEDTHEMEINFO,
  finalRoundThemeInfo: EMPTYSTATEEXTENDEDTHEMEINFO,
  champPostList: [],
  champThemeInfo: EMPTYSTATETHEMEINFO,
  page: 1,
  seed: Math.random() * 2 - 1,
  loading: false,
  hasMorePosts: true,
  firstRoundThemePosts: [],
  finalRoundThemePosts: [],
};

type DailyThemeStore = {
  reset: () => void;
  resetRound1: () => void;
  resetPodium: () => void;
  resetVotingThemes: () => void;
  votingComplete: boolean;
  setSubmissionThemeInfo: (info: ExtendedBaseTheme) => void;
  dailyThemeTags: string[];

  hydrateDailyTheme: (userId: number | undefined) => Promise<void>;

  setVotingComplete: (votingStatus: boolean) => void;
  fetchVotingThemes: (user: ProfileResponse, reset: boolean) => void;
  dailyThemesUpForVote: ExtendedBaseTheme[];
  setDailyThemesUpForVote: (info: ExtendedBaseTheme[]) => void;

  submissionThemeInfo: ExtendedBaseTheme;
  firstRoundThemeInfo: ExtendedBaseTheme;
  finalRoundThemeInfo: ExtendedBaseTheme;
  setFinalRoundThemeInfo: (info: ExtendedBaseTheme) => void;
  fetchAllThemeInfo: (userId: number | undefined) => Promise<void>;
  updateSubmitted: (postId: number, long_id: number) => void;

  champPostList: Post[];
  champThemeInfo: BaseTheme;
  fetchChamps: (user: ProfileResponse) => Promise<void>;
  updatePostInChampPostList: (post: Post) => void;
  updatePostInFirstRoundThemePostList: (post: Post) => void;
  updatePostInFinalRoundThemePostList: (post: Post) => void;

  page: number;
  seed: number;
  loading: boolean;
  hasMorePosts: boolean;
  firstRoundThemePosts: Post[];
  setFirstRoundThemeInfo: (info: ExtendedBaseTheme) => void;
  setFirstRoundThemePosts: (posts: Post[]) => void;
  fetchMoreFirstRoundThemePosts: (user: ProfileResponse) => Promise<void>;

  finalRoundThemePosts: Post[];
  setFinalRoundThemePosts: (posts: Post[]) => void;
  fetchFinalRoundThemePosts: (user: ProfileResponse) => Promise<void>;

  handleBlock: (blocked_user_id: number) => void;
};

export const useDailyThemeStore = create<DailyThemeStore>((set, get) => ({
  ...initialDailyThemeStoreState,
  reset: () => set(() => ({ ...initialDailyThemeStoreState })),
  resetRound1: () =>
    set(() => ({
      firstRoundThemePosts: [],
      page: 1,
      hasMorePosts: true,
      seed: Math.random() * 2 - 1,
    })),
  resetPodium: () => set(() => ({ finalRoundThemePosts: [] })),
  resetVotingThemes: () =>
    set(() => ({
      dailyThemesUpForVote: [
        EMPTYSTATEEXTENDEDTHEMEINFO,
        EMPTYSTATEEXTENDEDTHEMEINFO,
      ],
      votingComplete: false,
    })),
  hydrateDailyTheme: async (userId: number | undefined) => {
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(`daily_theme_event/hydrate/${userId}`);
      console.log(
        'tag 1: ',
        response.data.daily_theme_info.submission_theme.tag_list_info.tag.title,
      );

      set({
        submissionThemeInfo: response.data.daily_theme_info.submission_theme,
        firstRoundThemeInfo:
          response.data.daily_theme_info.firstround_voting_theme,
        finalRoundThemeInfo:
          response.data.daily_theme_info.finalround_voting_theme,
        dailyThemeTags: [
          response.data.daily_theme_info.submission_theme.tag_list_info.tag
            .title,
          response.data.daily_theme_info.submission_theme.tag_list_info.tag_list
            .tag.title,
        ],
        dailyThemesUpForVote: response.data.themes_for_vote,
        champPostList: response.data.recent_champ_info.champs,
        champThemeInfo: response.data.recent_champ_info.event_info,
      });
      // Voting theme info.
      if (
        response.data.themes_for_vote[0].userHasVotedFor ||
        response.data.themes_for_vote[1].userHasVotedFor
      ) {
        set({ votingComplete: true });
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching daily theme info');
    }
  },

  setSubmissionThemeInfo: (info) => set({ submissionThemeInfo: info }),
  setVotingComplete: (votingStatus) => set({ votingComplete: votingStatus }),
  setDailyThemesUpForVote: (info) => set({ dailyThemesUpForVote: info }),
  fetchVotingThemes: async (user, reset) => {
    if (reset) {
      console.log('reset');
    }
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `daily_theme_event_votes_with_taglist/vote/${user?.id}`,
      );
      if (
        response.data[0].userHasVotedFor ||
        response.data[1].userHasVotedFor
      ) {
        set({ votingComplete: true });
      }
      set({ dailyThemesUpForVote: response.data });
    } catch (error) {
      console.log(error);
      showToast('There was an issue fetching themes for vote');
    }
  },
  setFinalRoundThemeInfo: (info) => set({ champThemeInfo: info }),
  setFirstRoundThemeInfo: (info) => set({ firstRoundThemeInfo: info }),
  fetchAllThemeInfo: async (userId: number | undefined) => {
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `daily_theme_event/all_theme_info_with_tags_2/${userId}`,
      );
      set({
        submissionThemeInfo: response.data.submission_theme,
        firstRoundThemeInfo: response.data.firstround_voting_theme,
        finalRoundThemeInfo: response.data.finalround_voting_theme,
        dailyThemeTags: [
          response.data.submission_theme.tag_list_info.tag.title,
          response.data.submission_theme.tag_list_info.tag_list.tag.title,
        ],
      });
    } catch (error) {
      console.log(error);
      showToast('Error fetching daily theme info');
    }
  },
  updateSubmitted: (postId, long_id) => {
    const submissionThemeInfo = get().submissionThemeInfo;
    const newDailyTheme = {
      ...submissionThemeInfo,
      loggedInUserHasSubmitted: true,
      postId: postId,
      long_id: long_id,
    };
    set({ submissionThemeInfo: newDailyTheme });
  },

  fetchChamps: async (user: ProfileResponse | undefined) => {
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(`daily_theme_event/champs/${user?.id}`);
      set({
        champPostList: response.data.champs,
        champThemeInfo: response.data.event_info,
      });
    } catch (error) {
      showToast('Error fetching champs');
    }
  },
  updatePostInChampPostList: (updatedPost) =>
    set((state) => {
      const postIndex = state.champPostList.findIndex(
        (post) => post.id === updatedPost.id,
      );

      if (postIndex !== -1) {
        state.champPostList[postIndex] = updatedPost;
      }
      return { champPostList: [...state.champPostList] };
    }),
  setFirstRoundThemePosts: (posts) => set({ firstRoundThemePosts: posts }),
  fetchMoreFirstRoundThemePosts: async (user: ProfileResponse | null) => {
    const { page, hasMorePosts, firstRoundThemePosts, seed } = get();
    if (!hasMorePosts) {
      return;
    }
    set({ loading: true });
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `daily_theme_event_posts/firstround_vote_random/${user?.id}/${page}/${seed}`,
      );
      if (response.data.length > 0) {
        set({
          page: page + 1,
          firstRoundThemePosts: [...firstRoundThemePosts, ...response.data],
        });
      } else {
        set({ hasMorePosts: false });
      }
    } catch (error) {
      showToast('Error fetching round 1 posts');
    }
    set({ loading: false });
  },
  updatePostInFirstRoundThemePostList: (updatedPost) =>
    set((state) => {
      const postIndex = state.firstRoundThemePosts.findIndex(
        (post) => post.id === updatedPost.id,
      );

      if (postIndex !== -1) {
        state.firstRoundThemePosts[postIndex] = updatedPost;
      }
      return { firstRoundThemePosts: [...state.firstRoundThemePosts] };
    }),

  setFinalRoundThemePosts: (posts) => set({ finalRoundThemePosts: posts }),
  fetchFinalRoundThemePosts: async (user) => {
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `daily_theme_event_posts/daily_winners/${user?.id}`,
      );
      set({ finalRoundThemePosts: response.data });
    } catch (error) {
      console.log(error);
      showToast('Error fetching top 5 posts');
    }
  },
  updatePostInFinalRoundThemePostList: (updatedPost) =>
    set((state) => {
      const postIndex = state.finalRoundThemePosts.findIndex(
        (post) => post.id === updatedPost.id,
      );

      if (postIndex !== -1) {
        state.finalRoundThemePosts[postIndex] = updatedPost;
      }
      return { finalRoundThemePosts: [...state.finalRoundThemePosts] };
    }),
  handleBlock: (blocked_user_id: number) => {
    set((state) => ({
      champPostList: state.champPostList.filter(
        (post) => post.users_id !== blocked_user_id,
      ),
    }));
    set((state) => ({
      firstRoundThemePosts: state.firstRoundThemePosts.filter(
        (post) => post.users_id !== blocked_user_id,
      ),
    }));
    set((state) => ({
      finalRoundThemePosts: state.finalRoundThemePosts.filter(
        (post) => post.users_id !== blocked_user_id,
      ),
    }));
  },
}));

import { useState } from 'react';
import { debounce } from 'lodash';
import { isValidWhirlUsername, unauthenticatedRequest } from '@/modules/auth';

export const useCheckUsername = () => {
  // STATES.
  const [searchQuery, setSearchQuery] = useState('');
  const [username, setUsername] = useState<string>('');
  const [searching, setSearching] = useState(false);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [validUsername, setValidUsername] = useState(false);

  // SEARCH.
  const checkUsername = debounce(async (text: string) => {
    if (text !== '') {
      try {
        setSearching(true);
        const response = await unauthenticatedRequest().get(
          `auth_2/username_exists/${text.toLowerCase()}`,
        );
        setUsernameTaken(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setSearching(false);
        setValidUsername(isValidWhirlUsername(text));
      }
    } else {
      setUsernameTaken(false);
    }
  }, 200);

  // HANDLERS.
  const resetSearch = () => {
    setSearching(false);
    setUsernameTaken(false);
    setUsername('');
    setSearchQuery('');
  };

  const handleInputChange = (setter: any) => (text: string) => {
    const newText = text.replace(/\s+/g, '').toLowerCase();
    setter(newText);
    checkUsername(newText);
  };

  return {
    resetSearch,
    handleInputChange,
    searchQuery,
    setSearchQuery,
    setValidUsername,
    username,
    usernameTaken,
    searching,
    validUsername,
  };
};

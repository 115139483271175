import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { LeftBackButtonArrow_OutsideApp } from '@/components/LeftBackArrow_OutsideApp';
import { MobileNavigatorParams } from '../types';
import { StaticMobileScreen } from '@/screens/mobile/StaticMobile';

const MobileStack = createStackNavigator<MobileNavigatorParams>();

export function MobileNavigator() {
  return (
    <MobileStack.Navigator
      screenOptions={{
        headerTransparent: true,
        headerTitle: '',
        headerBackTitleVisible: false,
        headerBackImage: LeftBackButtonArrow_OutsideApp,
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
      }}
    >
      <MobileStack.Screen name="StaticMobile" component={StaticMobileScreen} />
    </MobileStack.Navigator>
  );
}

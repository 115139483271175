import React from 'react';
import { View, Text } from 'react-native';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { ExtendedBaseTheme } from '@/api/zod';
import { countdown } from '../RelativeDate';
import { StyleSheet } from 'react-native';
import { colors } from '@/app/colors';

type Props = {
  themeInfo: ExtendedBaseTheme;
};

export function Button_Countdown({ themeInfo }: Props) {
  return (
    <View style={styles.wrapper}>
      <Text style={globalstyles.tinybody}>
        {countdown(themeInfo.submit_by)} left
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.primary_accent,
    borderRadius: radius.md,
    padding: spacing.sm,
    left: spacing.lg,
    top: -spacing.xl - spacing.lg,
    position: 'absolute',
  },
});

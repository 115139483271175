import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { ProfileResponse, SocialMediaLink, TagList } from '@/api/zod';
import { colors } from '@/app/colors';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import ImageBackground from '@/components/ImageBackground';
import { ProfileUsernameBio } from './ProfileUsernameBio';
import { ProfilePicture } from './ProfilePicture';
import { ProfileHeaderButtons } from './ProfileHeaderButtons';
import { LevelBadge, Total_Posts } from './ProfileHeaderExtras';
import { Tab_Profile } from '../shared/Tab_Profile';

export interface ProfileHeaderProps {
  user: ProfileResponse;
  myProfile: boolean;
  totalPosts: number;
  links: SocialMediaLink[];
  toggleModal: () => void;
  navToEditProfile: () => void;
  setCurrentTab: (tab: string) => void;
  currentTab: string;
  handleFollow: () => void;
  tagList?: TagList;
  // levelBadge?: BadgeSchema;
  navToTagList?: () => void;
  navToLevels?: () => void;
  toggleFSImageModal: () => void;
  mounted: boolean;
}

export function ProfileHeader({
  user,
  myProfile,
  // levelBadge,
  totalPosts,
  links,
  toggleModal,
  handleFollow,
  setCurrentTab,
  currentTab,
  tagList,
  navToTagList,
  navToLevels,
  navToEditProfile,
  toggleFSImageModal,
  mounted,
}: ProfileHeaderProps) {
  const bannerImageSource = user.Profile_banner
    ? { uri: `${user.Profile_banner.url}?tpl=large` }
    : require('../../../assets/bg-splotch.jpg');

  if (user) {
    return (
      <>
        {mounted ? (
          <View style={{ marginBottom: spacing.lg, alignItems: 'center' }}>
            <ImageBackground
              source={bannerImageSource}
              style={styles.banner}
              imageStyle={{
                borderRadius: radius.lg,
                backgroundColor: colors.primary,
              }}
            >
              <View style={styles.profPicContainer}>
                <Total_Posts totalPosts={totalPosts} />
                <ProfilePicture
                  handleFullScreen={toggleFSImageModal}
                  activeUser={user}
                />
                <LevelBadge
                  myProfile={myProfile}
                  activeUser={user}
                  navToLevels={navToLevels ? navToLevels : () => null}
                  toggleMoreInfoModal={toggleModal}
                  // badge={levelBadge}
                />
              </View>
            </ImageBackground>
          </View>
        ) : (
          <View style={{ marginBottom: spacing.lg, alignItems: 'center' }}>
            <View
              style={{
                ...styles.banner,
                justifyContent: 'center',
                borderRadius: radius.lg,
                backgroundColor: colors.primary_offset,
              }}
            >
              <View style={{ padding: spacing.md }}>
                <ActivityIndicator size="small" color={colors.secondary} />
              </View>
            </View>
          </View>
        )}
        <ProfileUsernameBio activeUser={user} />
        {mounted && (
          <>
            <ProfileHeaderButtons
              myProfile={myProfile}
              user={user}
              sociallinks={links}
              openMoreInfo={toggleModal}
              handleFollow={handleFollow}
              tagList={tagList}
              navToTagList={navToTagList}
              navToEditProfile={navToEditProfile}
            />
            <TabButtons setCurrentTab={setCurrentTab} currentTab={currentTab} />
          </>
        )}
      </>
    );
  } else {
    return (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="small" color={colors.secondary} />
      </View>
    );
  }
}

interface TabProps {
  setCurrentTab: (tab: string) => void;
  currentTab: string;
}

const TabButtons = ({ setCurrentTab, currentTab }: TabProps) => {
  return (
    <View style={styles.tabButtons}>
      <View style={styles.tabContainer}>
        <Tab_Profile
          title={'All'}
          callback={() => setCurrentTab('all')}
          active={currentTab === 'all'}
        />
      </View>
      <View style={styles.tabContainer}>
        <Tab_Profile
          title={'Daily theme'}
          callback={() => setCurrentTab('daily-theme')}
          active={currentTab === 'daily-theme'}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  profPicContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    bottom: -spacing.xl,
    zIndex: 2,
  },
  tabButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
  },
  tabContainer: {
    width: '25%',
  },
  banner: {
    ...globalstyles.profileBannerImage,
    backgroundColor: colors.primary,
    borderRadius: radius.lg,
    justifyContent: 'flex-end',
    marginBottom: spacing.md,
  },
});

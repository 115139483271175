import React, { useEffect, useState } from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  SafeAreaView,
} from 'react-native';
import { colors } from '@/app/colors';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { authenticatedRequest } from '@/modules/auth';
import { Image } from 'expo-image';
import Toast from 'react-native-toast-message';
import { showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import {
  globalstyles,
  heightWithHeader,
  radius,
  spacing,
} from '@/app/globalstyles';

type ImageProp = {
  images_id: number;
  image: {
    url: string;
  };
};

type SavedPrompt = {
  id: number;
  users_id: number;
  posts_id: number;
  long_id: number;
  prompt: string;
  images: ImageProp[];
};

const EMPTYSTATESAVEDPROMPT = {
  id: 0,
  users_id: 0,
  posts_id: 0,
  long_id: 0,
  prompt: '',
  images: [
    {
      images_id: 0,
      image: {
        url: 'https://link',
      },
    },
  ],
};

export function MySavedPromptsScreen() {
  const { navigate } =
    useNavigation<AuthenticatedNavigatorProp<'MySavedPrompts'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const [savedPrompts, setSavedPrompts] = useState<SavedPrompt[]>([
    EMPTYSTATESAVEDPROMPT,
  ]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreSavedPrompts, setHasMoreSavedPrompts] = useState(true);

  const fetchSavedPrompts = async () => {
    if (!hasMoreSavedPrompts) {
      return;
    }

    setLoading(true);
    if (user) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `user_saved_prompts/${user.id}/${page}`,
        );
        const filteredPrompts = response.data.filter(
          (prompt: any) => prompt.prompt,
        );
        if (filteredPrompts.length > 0) {
          if (page === 1) {
            setPage(page + 1);
            setSavedPrompts(filteredPrompts);
          } else {
            setPage(page + 1);
            setSavedPrompts([...savedPrompts, ...filteredPrompts]);
          }
        } else {
          setHasMoreSavedPrompts(false);
        }
      } catch (error) {
        showToast('Error fetching saved prompts');
      }
    }
    setLoading(false);
  };

  const handleSavedPromptPress = (savedPrompt: SavedPrompt) => {
    navigate('ViewSinglePost', { postId: savedPrompt.long_id });
  };

  useEffect(() => {
    fetchSavedPrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSavedPrompt = ({ item }: { item: SavedPrompt }) => (
    <TouchableOpacity
      activeOpacity={0.8}
      style={globalstyles.listItemContainer}
      onPress={() => handleSavedPromptPress(item)}
    >
      <Image
        style={{
          ...globalstyles.profileImage,
          borderRadius: radius.lg,
          marginRight: spacing.md,
        }}
        source={{ uri: `${item.images[0].image.url}?tpl=med.jpg` }}
        transition={250}
      />
      <View style={{ flex: 1 }}>
        <Text style={globalstyles.body}>{item.prompt}</Text>
      </View>
    </TouchableOpacity>
  );

  const renderFooter = () => {
    return loading && hasMoreSavedPrompts ? (
      <View style={{ padding: 20 }}>
        <ActivityIndicator size="small" color={colors.secondary} />
      </View>
    ) : null;
  };

  const keyExtractor = (item: SavedPrompt) => item.id.toString();

  return (
    <>
      <SafeAreaView style={globalstyles.container}>
        {savedPrompts[0]?.id !== 0 && (
          <FlatList
            data={savedPrompts}
            renderItem={renderSavedPrompt}
            keyExtractor={keyExtractor}
            onEndReached={fetchSavedPrompts}
            style={{ height: heightWithHeader }}
            contentContainerStyle={{ paddingTop: spacing.lg }}
            onEndReachedThreshold={0.8}
            ListFooterComponent={renderFooter}
          />
        )}
      </SafeAreaView>
      <Toast />
    </>
  );
}

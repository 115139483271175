import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest } from '@/modules/auth';
import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, FlatList, View } from 'react-native';
import { globalstyles, heightWithHeader } from '@/app/globalstyles';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useBlock } from '@/api/useBlock';
import { useMyProfileStore } from '@/app/store/store';
import { Text_ListHeader } from '@/components/Text_ListHeader';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';

type BlockedUser = {
  id: number;
  created_at: number;
  blocking_users_id: number;
  blocked_users_id: number;
  username: string;
};

const EMPTYBLOCKEDUSER = {
  id: 0,
  created_at: 1,
  blocking_users_id: 0,
  blocked_users_id: 0,
  username: '',
};

export function MyBlockedUsersScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const [blockedUsers, setBlockedUsers] = useState<BlockedUser[]>([]);
  const [, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [pressedItem, setPressedItem] = useState<BlockedUser>(EMPTYBLOCKEDUSER);
  const { unblockGlobally } = useBlock(pressedItem.blocked_users_id);

  const handleUnblock = async () => {
    setLoading(true);
    try {
      const response = await (
        await authenticatedRequest()
      ).delete<string>(`block_user/${pressedItem?.id}`);
      if (response.data === 'success') {
        console.log('success');
        const filtered = blockedUsers.filter((b) => b.id !== pressedItem?.id);
        setBlockedUsers(filtered);
        unblockGlobally();
      }
      setModalVisible(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setModalVisible(false);
      showToast('Unable to unblock. Try again.');
      console.log(error);
    }
  };

  const renderItem = ({ item }: { item: BlockedUser }) => (
    <TouchableOpacity
      style={globalstyles.listItemContainer}
      activeOpacity={0.9}
      onPress={() => {
        setPressedItem(item);
        setModalVisible(true);
      }}
    >
      <View>
        <Text style={globalstyles.usernameText}>@{item.username}</Text>
      </View>
      <View style={globalstyles.chip}>
        <Text style={globalstyles.chipText}>Unblock</Text>
      </View>
    </TouchableOpacity>
  );

  useEffect(() => {
    const fetchMyBlockedUsers = async () => {
      if (user) {
        try {
          const authenticatedAxios = await authenticatedRequest();
          const response = await authenticatedAxios.get(
            `block_user/list/${user?.id}`,
          );
          setBlockedUsers(response.data);
        } catch (error) {
          showToast('Error fetching blocked users');
        } finally {
          setMounted(true);
        }
      }
    };
    fetchMyBlockedUsers();
  }, [user]);

  const listHeader = () => {
    return (
      <Text_ListHeader
        text={blockedUsers.length === 0 ? 'No blocks' : 'Blocked users'}
      />
    );
  };

  return (
    <>
      <View style={globalstyles.container}>
        <FlatList
          data={blockedUsers}
          renderItem={renderItem}
          ListHeaderComponent={mounted ? listHeader : () => null}
          style={{ height: heightWithHeader }}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
      <Modal_TwoOptions
        cancel={() => setModalVisible(false)}
        isVisible={modalVisible}
        title={`Unblock @${pressedItem?.username}?`}
        callback1={handleUnblock}
        callback2={() => setModalVisible(false)}
        text1={'Yes'}
        text2={'Nevermind'}
        icon1={faCheck}
        icon2={faTimes}
      />
    </>
  );
}

import React, { useState } from 'react';
import { View, StyleSheet, Pressable, Linking } from 'react-native';
import { radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faDiscord,
  faInstagram,
  faTwitter,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { faThreads } from '@fortawesome/free-brands-svg-icons/faThreads';
import Modal_DiscordUsername from '../profile/Modal_DiscordUsername';

interface PlatformData {
  icon: IconDefinition;
  urlPrefix: string;
}

const platformManifest: Record<string, PlatformData> = {
  instagram: {
    icon: faInstagram,
    urlPrefix: 'https://instagram.com/',
  },
  discord: {
    icon: faDiscord,
    urlPrefix: 'https://discord.com/',
  },
  twitter: {
    icon: faTwitter,
    urlPrefix: 'https://twitter.com/',
  },
  threads: {
    icon: faThreads,
    urlPrefix: 'https://threads.net/',
  },
  linktree: {
    icon: faTree,
    urlPrefix: 'https://linktr.ee/',
  },
};

interface SocialProps {
  platformKey: keyof typeof platformManifest;
  url_ending: string;
}

export const Button_SocialIcon = ({ platformKey, url_ending }: SocialProps) => {
  const [hover, setHover] = useState(false);
  const platform = platformManifest[platformKey];
  const [dVisible, setDVisible] = useState(false);
  const handleIconPress = () => {
    if (platformKey === 'discord') {
      setDVisible(true);
    } else {
      const url = platform ? `${platform.urlPrefix}${url_ending}` : '';
      Linking.openURL(url);
    }
  };
  const renderCorrectIcon = () => {
    if (!platform) {
      return null;
    }
    return (
      <FontAwesomeIcon
        icon={platform.icon}
        size={18}
        color={hover ? colors.accent : colors.secondary}
      />
    );
  };

  if (!platform) {
    return null;
  }
  return (
    <Pressable
      style={styles.iconContainer}
      onPress={handleIconPress}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <View style={styles.iconBackground}>{renderCorrectIcon()}</View>
      <Modal_DiscordUsername
        username={url_ending}
        isVisible={dVisible}
        cancel={() => setDVisible(false)}
        showCancelButton
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  iconBackground: {
    backgroundColor: colors.primary_accent,
    borderRadius: radius.lg,
    padding: spacing.sm,
  },
  iconContainer: {
    alignItems: 'center',
    marginHorizontal: spacing.xs,
  },
});

import { globalstyles } from '@/app/globalstyles';
import React, { useEffect } from 'react';
import { View, Text, StyleSheet } from 'react-native';

async function handleParams(token: string, auth_status: string) {
  const dataToSendBack = { token: token, status: auth_status };
  if (window.opener && !window.opener.closed) {
    window.opener.postMessage(dataToSendBack, '*');
    window.close();
  }
}

const SuccessfulOAuthScreen = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const auth_status = urlParams.get('status');

    if (token && auth_status) {
      handleParams(token, auth_status);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  return (
    <View style={styles.container}>
      <Text style={globalstyles.title}>Success</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    ...globalstyles.container,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default SuccessfulOAuthScreen;

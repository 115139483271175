import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  View,
  ActivityIndicator,
  Text,
  Pressable,
  SectionList,
  StyleSheet,
} from 'react-native';
import { showToast } from '@/components/ErrorToast';
import Toast from 'react-native-toast-message';
import { unauthenticatedRequest } from '@/modules/auth';
import { colors } from '@/app/colors';
import {
  globalstyles,
  headerHeight,
  heightWithHeader,
  maxWebContentWidth,
  spacing,
} from '@/app/globalstyles';
import { TagListTag } from '@/api/zod';
import { TagListJoinCard } from '@/components/TagListJoinCard';
import { formattedDateFromString } from '@/components/RelativeDate';
import { useMyProfileStore } from '@/app/store/store';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';

export function ViewTagListScreen() {
  const {
    params: { tag_list },
  } = useRoute<AuthenticatedNavigatorRouteProp<'ViewTagList'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation = useNavigation<AuthenticatedNavigatorProp<'ViewTagList'>>();
  const [futureTags, setFutureTags] = useState<TagListTag[]>([]);
  const [pastTags, setPastTags] = useState<TagListTag[]>([]);
  const [loading, setLoading] = useState(false);
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );

  const sections = [
    { title: 'Next up...', data: futureTags },
    { title: 'Past', data: pastTags },
  ];

  const headerRight = useCallback(() => {
    return (
      <Pressable
        style={{
          ...globalstyles.chip,
          ...globalstyles._selectedChip,
          marginRight: spacing.md,
        }}
        onPress={() =>
          navigation.navigate('EditTagList', {
            tag_list: tag_list,
            tags: [...pastTags, ...futureTags],
          })
        }
      >
        <Text style={globalstyles.smallbody}>Edit</Text>
      </Pressable>
    );
  }, [navigation, tag_list, futureTags, pastTags]);

  useLayoutEffect(() => {
    const maxTitleLength = 18;
    let truncatedTitle = tag_list.title;
    if (truncatedTitle.length > maxTitleLength) {
      truncatedTitle = `${truncatedTitle.substring(0, maxTitleLength)}...`;
    }
    navigation.setOptions({
      title: truncatedTitle,
    });
    if (user?.id === tag_list.users_id) {
      navigation.setOptions({
        headerRight: headerRight,
      });
    }
  }, [navigation, user, headerRight, tag_list]);

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      try {
        const axios = unauthenticatedRequest();
        const response = await axios.get(
          `tag_lists/view/${tag_list.id}/${user?.id}`,
        );
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const todayString = today.toISOString().split('T')[0];

        const future: TagListTag[] = [];
        const past: TagListTag[] = [];
        response.data.tags.forEach((tag: TagListTag) => {
          if (tag.date >= todayString) {
            future.push(tag);
          } else {
            past.push(tag);
          }
        });
        setFutureTags(future);
        setPastTags(past);
      } catch (error) {
        console.log('Error: ', error);
        showToast('Error fetching tags for list');
      } finally {
        setLoading(false);
      }
    };
    fetchTags();
  }, [tag_list.id, user?.id]);

  const handleTagPress = (tagListTag: TagListTag) => {
    if (tagListTag.id !== submissionThemeInfo.tag_list_tags_id) {
      navigation.push('TagListGallery', {
        tagListTag: tagListTag,
        tag_list: tag_list,
      });
    } else {
      navigation.push('DailyTheme');
    }
  };

  const renderTag = ({ item }: { item: TagListTag }) => (
    <Pressable
      style={{
        ...globalstyles.listItemContainer,
        marginBottom: spacing.sm,
        paddingBottom: spacing.sm,
        flexWrap: 'wrap',
      }}
    >
      <Text style={globalstyles.smallbody}>
        {formattedDateFromString(item.date)}
      </Text>
      <View style={{ flexDirection: 'row' }}>
        {item.id === submissionThemeInfo.tag_list_tags_id && (
          <Text style={{ ...globalstyles.tinybody, color: colors.high_accent }}>
            Daily theme!
          </Text>
        )}
        <Pressable
          style={globalstyles.chip}
          onPress={() => handleTagPress(item)}
        >
          <Text style={globalstyles.smallbody}>{item.title}</Text>
        </Pressable>
      </View>
    </Pressable>
  );

  const renderHeader = () => {
    return (
      <View style={{ paddingBottom: spacing.md }}>
        <TagListJoinCard
          tagList={tag_list}
          navigation={navigation}
          showDescription={true}
          navigationCallback={() => console.log('go on')}
          index={false}
        />
      </View>
    );
  };

  return (
    <View style={globalstyles.container}>
      <SectionList
        sections={sections}
        keyExtractor={(item) => item.id?.toString() || item.title}
        renderItem={({ item }) => renderTag({ item })}
        style={styles.listContainer}
        contentContainerStyle={styles.contentContainer}
        renderSectionHeader={({ section: { title } }) => (
          <View style={{ padding: spacing.md }}>
            <Text style={globalstyles.label}>#{title}</Text>
          </View>
        )}
        ListHeaderComponent={renderHeader}
      />
      {loading && (
        <View style={{ padding: spacing.md }}>
          <ActivityIndicator size="large" color={colors.secondary} />
        </View>
      )}
      <Toast />
    </View>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    height: heightWithHeader,
  },
  contentContainer: {
    maxWidth: maxWebContentWidth / 2,
    alignSelf: 'center',
    width: '90%',
    marginTop: headerHeight,
  },
});

import React from 'react';
import { Pressable } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { useMyProfileStore } from '@/app/store/store';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons/faArrowsRotate';
import { colors } from '@/app/colors';
import { useRoute } from '@react-navigation/native';

export function Button_HeaderRightRefresh() {
  const route = useRoute();
  const routeName = route.name;
  const user = useMyProfileStore((state) => state.myInfo);
  const fetchPosts = useDailyThemeStore(
    (state) => state.fetchFinalRoundThemePosts,
  );
  const fetchVotingThemes = useDailyThemeStore(
    (state) => state.fetchVotingThemes,
  );
  const resetPodium = useDailyThemeStore((state) => state.resetPodium);
  const resetVotingThemes = useDailyThemeStore(
    (state) => state.resetVotingThemes,
  );
  const handleRefresh = () => {
    if (routeName === 'DailyThemePodium') {
      resetPodium();
      user && fetchPosts(user);
    } else if (routeName === 'VoteNextTheme') {
      resetVotingThemes();
      user && fetchVotingThemes(user, true);
    }
  };
  return (
    <Pressable
      style={{
        ...globalstyles.chip,
        ...globalstyles._selectedChip,
        marginRight: spacing.md,
      }}
      onPress={handleRefresh}
    >
      <FontAwesomeIcon
        icon={faArrowsRotate}
        color={colors.secondary}
        size={16}
      />
    </Pressable>
  );
}

import { Comment, Post } from '@/api/zod';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import { authenticatedRequest, unauthenticatedRequest } from '@/modules/auth';
import { useState } from 'react';

export const useComment = () => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreComments, setHasMoreComments] = useState(true);

  const reset = () => {
    setComments([]);
    setLoading(true);
    setNewCommentLoading(false);
    setPage(1);
    setHasMoreComments(true);
  };

  const fetchComments = async (post: Post, users_id: number) => {
    if (!hasMoreComments) {
      return;
    }
    try {
      setLoading(true);
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `comments/${post.id}/${users_id}/${page}`,
      );
      if (response.data.length > 0) {
        setPage(page + 1);
        setComments([...comments, ...response.data]);
      } else {
        setHasMoreComments(false);
      }
    } catch (error) {
      console.log('error fetching comments');
    } finally {
      setLoading(false);
    }
  };

  const likeCommentOrReply = async (
    comment: Comment,
    users_id: number,
    isReply = false,
  ) => {
    const url = comment.isLiked
      ? `comment_likes/${comment.id}/${users_id}`
      : 'comment_likes';
    const method = comment.isLiked ? 'delete' : 'post';
    const data = { users_id, comments_id: comment.id };

    setComments((prevComments) => {
      return prevComments.map((c) => {
        if (isReply) {
          if (c.id === comment.comments_id) {
            const updatedReplies = c.replies.map((reply) => {
              if (reply.id === comment.id) {
                return {
                  ...reply,
                  isLiked: !reply.isLiked,
                  likeCount: reply.likeCount + (!reply.isLiked ? 1 : -1),
                };
              }
              return reply;
            });
            return { ...c, replies: updatedReplies };
          }
        } else {
          if (c.id === comment.id) {
            return {
              ...c,
              isLiked: !c.isLiked,
              likeCount: c.likeCount + (!c.isLiked ? 1 : -1),
            };
          }
        }
        return c;
      });
    });

    try {
      await (await authenticatedRequest())[method](url, data);
    } catch (error) {
      console.error('Error updating like status:', error);
    }
  };

  const createComment = async (
    post: Post,
    users_id: number,
    commentInput: string,
  ) => {
    setNewCommentLoading(true);
    try {
      const response = await (
        await authenticatedRequest()
      ).post('comments_2', {
        users_id: users_id,
        posts_id: post.id,
        body: commentInput,
      });
      setComments([response.data, ...comments]);
    } catch (error) {
      showToast('Error creating comment 🧐');
    } finally {
      setNewCommentLoading(false);
    }
  };

  const createReply = async (
    comment: Comment,
    users_id: number,
    commentInput: string,
  ) => {
    setNewCommentLoading(true);
    try {
      const response = await (
        await authenticatedRequest()
      ).post(`comments_2/reply/${comment.id}`, {
        users_id: users_id,
        comments_id: comment.id,
        body: commentInput,
      });

      const updatedComments = comments.map((item) => {
        if (item.id === comment.id) {
          return {
            ...item,
            replies: [...(item.replies || []), response.data],
          };
        }
        return item;
      });
      setComments(updatedComments);
    } catch (error) {
      showToast('Error editing comment 🧐');
    } finally {
      setNewCommentLoading(false);
    }
  };

  const deleteComment = async (targetComment: Comment) => {
    try {
      await (
        await authenticatedRequest()
      ).delete(`comments/${targetComment.id}`);

      if (targetComment.isReply) {
        const parentCommentIndex = comments.findIndex(
          (comment) => comment.id === targetComment.comments_id,
        );
        const updatedComments = comments[parentCommentIndex].replies.filter(
          (c) => c.id !== targetComment.id,
        );
        setComments([...updatedComments]);
      } else {
        const updatedCommentsList = comments.filter(
          (comment) => comment.id !== targetComment.id,
        );
        setComments(updatedCommentsList);
      }
      showSuccessToast('Deleted!');
    } catch (error) {
      console.log('error fetching comments');
      showToast('Error deleting comment 🧐');
    }
  };

  const updateComment = async (targetComment: Comment, body: string) => {
    try {
      setLoading(true);
      const authenticatedAxiosInstance = await authenticatedRequest();
      const updatedComment = await authenticatedAxiosInstance.post(
        `comments_2/${targetComment.id}`,
        {
          comments_id: targetComment.id,
          body: body,
        },
      );

      if (targetComment.isReply) {
        const parentCommentIndex = comments.findIndex(
          (comment) => comment.id === targetComment.comments_id,
        );
        if (parentCommentIndex !== -1) {
          const replyIndex = comments[parentCommentIndex].replies.findIndex(
            (r) => r.id === targetComment.id,
          );
          if (replyIndex !== -1) {
            comments[parentCommentIndex].replies[replyIndex] =
              updatedComment.data;
          }
        }
      } else {
        const index = comments.findIndex((c) => c.id === targetComment.id);
        if (index !== -1) {
          comments[index] = updatedComment.data;
        }
      }

      setComments([...comments]);
    } catch (error: any) {
      console.log(error);
      showToast(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleBlock = (blocked_user_id: number) => {
    setComments((currentComments) => {
      return currentComments.filter((comment) => {
        const isNotBlockedUser = comment.users_id !== blocked_user_id;
        if (comment.replies) {
          comment.replies = comment.replies.filter(
            (reply) => reply.users_id !== blocked_user_id,
          );
        }
        return isNotBlockedUser;
      });
    });
  };

  return {
    reset,
    comments,
    loading,
    newCommentLoading,
    hasMoreComments,
    page,
    setLoading,
    fetchComments,
    likeCommentOrReply,
    createComment,
    createReply,
    deleteComment,
    updateComment,
    handleBlock,
  };
};

import React, { useState } from 'react';
import { Pressable, View, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { ExtendedBaseTheme } from '@/api/zod';
import { ParamListBase } from '@react-navigation/native';
import ImageBackground from '../ImageBackground';
import { Button_PromptListChip } from '../shared/Button_PromptListChip';
import { StackNavigationProp } from '@react-navigation/stack';
import withAuthPopup from '../shared/hoc/withAuthPopup';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  theme: ExtendedBaseTheme;
  index: number;
  percent: number;
  votingComplete: boolean;
  navigation: StackNavigationProp<ParamListBase>;
  callback: () => void;
}

const Poll_Web: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  theme,
  index,
  percent,
  votingComplete,
  navigation,
  callback,
}) => {
  const [hover, setHover] = useState(false);
  const handlePress = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      callback();
    }
  };

  return (
    <ImageBackground
      style={{
        height: '100%',
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        opacity: votingComplete && !theme.userHasVotedFor ? 0.4 : 1,
      }}
      source={{ uri: theme.background_image.url }}
      imageStyle={{ opacity: votingComplete ? 1 : hover ? 0.6 : 0.2 }}
    >
      <Pressable
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: index === 0 ? 'flex-end' : 'flex-start',
          padding: spacing.lg,
        }}
        disabled={votingComplete}
        onPress={handlePress}
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}
      >
        <View
          style={{
            justifyContent: 'flex-end',
            alignItems: index === 0 ? 'flex-end' : 'flex-start',
          }}
        >
          <Text
            style={{
              ...globalstyles.body,
              ...globalstyles._font_lg,
              marginBottom: spacing.sm,
            }}
          >
            {!votingComplete
              ? 'Vote for'
              : theme.userHasVotedFor
              ? 'You voted for'
              : '...'}
          </Text>
          <Text
            style={{
              ...globalstyles.label,
              ...globalstyles._font_xl,
              marginBottom: spacing.sm,
            }}
          >
            {theme.title}
          </Text>
          <Button_PromptListChip theme={theme} navigation={navigation} />
          {votingComplete && (
            <View>
              <Text
                style={{
                  ...globalstyles.label,
                  ...globalstyles._font_xxl,
                  marginBottom: spacing.xs,
                }}
              >
                {percent}%
              </Text>
              <View>
                <Text
                  style={{
                    ...globalstyles.smallbody,
                    textAlign: index === 0 ? 'right' : 'left',
                  }}
                >
                  {theme.votes} votes
                </Text>
              </View>
            </View>
          )}
        </View>
      </Pressable>
    </ImageBackground>
  );
};

export default withAuthPopup(Poll_Web);

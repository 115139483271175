import React, { ComponentType } from 'react';
import Modal, { ModalProps } from 'react-native-modal';
import { Text, StyleSheet, View, Pressable } from 'react-native';
import {
  border,
  globalstyles,
  maxWebContentWidth,
  radius,
  spacing,
} from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { Button_ModalClose } from '../Button_ModalClose';

interface ModalHOCProps extends Partial<ModalProps> {
  isVisible: boolean;
  title?: string;
  cancel: () => void;
  showCancelButton?: boolean;
}

const ModalHOC = <P extends object>(
  WrappedComponent: ComponentType<P & { children?: React.ReactNode }>,
): React.FC<P & ModalHOCProps> => {
  return ({
    isVisible,
    title,
    cancel,
    showCancelButton,
    style,
    ...wrappedComponentProps
  }) => (
    <Modal
      isVisible={isVisible}
      hideModalContentWhileAnimating={true}
      animationInTiming={250}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      style={[styles.modalStyle, style]}
    >
      {showCancelButton && <Button_ModalClose close={cancel} />}
      <Pressable style={styles.fullScreenContainer} onPress={cancel}>
        <Pressable style={styles.modalContent}>
          {title && (
            <View style={styles.titleContainer}>
              <Text style={globalstyles.title}>{title}</Text>
            </View>
          )}
          <WrappedComponent {...(wrappedComponentProps as P)} />
        </Pressable>
      </Pressable>
    </Modal>
  );
};

const styles = StyleSheet.create({
  fullScreenContainer: {
    ...globalstyles.container,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalStyle: {
    margin: 0,
  },
  titleContainer: {
    paddingBottom: spacing.md,
  },
  modalContent: {
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    borderColor: colors.primary_offset_2,
    borderWidth: border.xs,
    borderRadius: radius.md,
    padding: spacing.md,
    minWidth: maxWebContentWidth / 3,
    shadowColor: colors.primary_offset_2,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 20,
  },
});

export default ModalHOC;

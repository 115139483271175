import { globalstyles, spacing } from '@/app/globalstyles';
import React from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';

interface Props {
  callback: () => void;
}

export function Text_ForgotPassword({ callback }: Props) {
  return (
    <Pressable style={styles.wrapper} onPress={callback}>
      <Text style={globalstyles.tinyfade}>Forgot password?</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    alignSelf: 'center',
    marginBottom: spacing.md,
  },
});

import React from 'react';
import { View } from 'react-native';
import { webContentWidth, spacing } from '@/app/globalstyles';
import { Image } from 'expo-image';

export function Icon_WhirlHeaderTitle() {
  return (
    <View style={{ marginBottom: spacing.md }}>
      <Image
        source={require('../../../assets/transparent-logo.png')}
        style={{
          width: webContentWidth * 1.5,
          aspectRatio: 3029 / 1333, // sus to hard code, w/e.
        }}
        transition={250}
        contentFit={'contain'}
      />
    </View>
  );
}

import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import { Image } from 'expo-image';
import {
  globalstyles,
  radius,
  spacing,
  maxWebContentWidth,
  border,
} from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faUser } from '@fortawesome/free-solid-svg-icons';

type AuthButtonProps = {
  logo: any;
  callback: () => void;
  text: string;
  sideblanks: boolean;
  disabled?: boolean;
  bgColor?: string;
  bgHover?: string;
  loading?: boolean;
};

export function AuthButton({
  logo,
  callback,
  text,
  disabled,
  bgColor,
  bgHover,
  sideblanks,
  loading,
}: AuthButtonProps) {
  const [hover, setHover] = useState(false);
  const renderIcon = () => {
    if (text.includes('Email')) {
      return (
        <View style={styles.logoImage}>
          <FontAwesomeIcon
            icon={faEnvelope}
            color={colors.secondary}
            size={18}
          />
        </View>
      );
    } else if (text.includes('Guest')) {
      return (
        <View style={styles.logoImage}>
          <FontAwesomeIcon icon={faUser} color={colors.secondary} size={18} />
        </View>
      );
    } else if (loading) {
      return <ActivityIndicator size={'small'} color={colors.secondary} />;
    } else if (sideblanks) {
      return <View style={styles.logoImage} />;
    } else {
      return <Image style={styles.logoImage} source={logo} transition={250} />;
    }
  };
  const calculatedbgColor = bgColor ? bgColor : colors.primary;
  const calculatedbgHover = bgHover ? bgHover : colors.primary_accent;
  const backgroundColor = hover ? calculatedbgHover : calculatedbgColor;
  return (
    <Pressable
      style={{
        ...styles.authButton,
        backgroundColor: backgroundColor,
        borderColor: hover ? colors.primary_light : colors.primary_offset_2,
      }}
      disabled={disabled ? disabled : false}
      onPress={callback}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      {renderIcon()}
      <Text style={globalstyles.label}>{text}</Text>
      <View style={styles.logoImage} />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  authButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: maxWebContentWidth / 3, // change?
    borderRadius: radius.sm,
    borderWidth: border.xs,
    marginBottom: spacing.sm,
    padding: spacing.sm,
    paddingHorizontal: spacing.md,
  },
  logoImage: {
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

import React from 'react';
import {
  View,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  Text,
} from 'react-native';
import { Comment, ProfileResponse } from '@/api/zod';
import { colors } from '@/app/colors';
import {
  border,
  globalstyles,
  spacing,
  webContentWidth,
} from '@/app/globalstyles';
import { relativeDate } from '@/components/RelativeDate';
import { Image } from 'expo-image';
import Button_CommentReply from './Button_CommentReply';
import Buttons_CommentActions from './Buttons_CommentActions';

interface CommentProps {
  item: Comment;
  commentReplyingTo: Comment | undefined;
  myInfo: ProfileResponse | null;
  openCommentActions: (item: Comment) => void;
  handleNav: (item: Comment) => void;
  handleReply: (item: Comment) => void;
  handleLike: (item: Comment) => void;
  handleNavToUser: (username: string) => void;
}

export const Layout_RenderComment = ({
  item,
  commentReplyingTo,
  myInfo,
  openCommentActions,
  handleNav,
  handleReply,
  handleLike,
  handleNavToUser,
}: CommentProps) => {
  const mine = myInfo?.id === item.users_id;
  return (
    <View key={item.id}>
      <Pressable
        style={{
          ...styles.comment,
          backgroundColor: mine ? colors.primary_accent : undefined,
          marginLeft: item.isReply ? spacing.xl : 0,
          borderLeftColor: mine ? colors.primary_accent : colors.invisible,
        }}
        onLongPress={() => openCommentActions(item)}
      >
        <RenderProfilePicture
          item={item}
          isReply={item.isReply}
          handleNav={handleNav}
        />
        <View style={globalstyles.container}>
          <RenderUsernameAndBody
            item={item}
            handleNavToUser={handleNavToUser}
          />
          <RenderCommentFooter
            item={item}
            openCommentActions={openCommentActions}
            handleLike={handleLike}
            handleReply={handleReply}
            commentReplyingTo={commentReplyingTo}
          />
        </View>
      </Pressable>
      {item.replies &&
        item.replies.map((reply) => (
          <Layout_RenderComment
            key={reply.id}
            item={reply}
            commentReplyingTo={commentReplyingTo}
            myInfo={myInfo}
            openCommentActions={openCommentActions}
            handleNav={handleNav}
            handleReply={handleReply}
            handleLike={handleLike}
            handleNavToUser={handleNavToUser}
          />
        ))}
    </View>
  );
};

interface ProfPicProps {
  item: Comment;
  isReply: boolean;
  handleNav: (item: Comment) => void;
}

const RenderProfilePicture = ({
  item,
  handleNav,
  isReply = false,
}: ProfPicProps) => {
  const profileImageStyle = isReply
    ? globalstyles.profileImageXS
    : globalstyles.profileImageSmall;

  return (
    <TouchableOpacity
      onPress={() => handleNav(item)}
      style={{ flexDirection: 'row' }}
    >
      {item.Profile_picture ? (
        <Image
          source={{
            uri: item.Profile_picture.url,
          }}
          style={{ ...profileImageStyle, margin: spacing.md }}
          transition={250}
        />
      ) : (
        <Image
          source={require('../../../../assets/profile-placeholder.jpg')}
          style={{ ...profileImageStyle, margin: spacing.md }}
          transition={250}
        />
      )}
    </TouchableOpacity>
  );
};

interface BodyProps {
  item: Comment;
  handleNavToUser: (username: string) => void;
}

const RenderUsernameAndBody = ({ item, handleNavToUser }: BodyProps) => {
  const goToUser = (match: string) => {
    handleNavToUser(match);
  };
  const renderTextWithUsernames = (text: string) => {
    const usernameRegex = /@([a-zA-Z0-9._]{3,30})/g;
    let elements = [];
    let lastIndex = 0;
    const keyBase = `comment-${item.id}-`;

    text.replace(usernameRegex, (match, username, offset) => {
      elements.push(
        <Text style={globalstyles.smallbody} key={`${keyBase}${offset}-text`}>
          {text.substring(lastIndex, offset)}
        </Text>,
      );
      elements.push(
        <Text
          key={`${keyBase}${offset}-username`}
          onPress={() => goToUser(match)}
          style={{ ...globalstyles.smallbody, color: colors.username_link }}
        >
          {match}
        </Text>,
      );
      lastIndex = offset + match.length;
      return match;
    });
    if (lastIndex < text.length) {
      elements.push(
        <Text style={{ ...globalstyles.smallbody }} key={`${keyBase}end-text`}>
          {text.substring(lastIndex)}
        </Text>,
      );
    }

    return elements;
  };
  return (
    <View
      style={{
        ...styles.commentTextHeaderContainer,
        paddingTop: item.isReply ? spacing.md : spacing.lg,
        paddingRight: spacing.lg,
        marginRight: 100,
      }}
    >
      <View>
        <View style={{ alignItems: 'flex-start' }}>
          <Text style={globalstyles.usernameText}>@{item.username}</Text>
        </View>
        <View
          style={{ paddingVertical: spacing.sm, ...globalstyles.container }}
        >
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: webContentWidth * 1.5,
            }}
          >
            <Text>{renderTextWithUsernames(item.body)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

interface FooterProps {
  item: Comment;
  commentReplyingTo: Comment | undefined;
  openCommentActions: (item: Comment) => void;
  handleReply: (item: Comment) => void;
  handleLike: (item: Comment) => void;
}

const RenderCommentFooter = ({
  item,
  handleReply,
  openCommentActions,
  handleLike,
}: FooterProps) => {
  return (
    <TouchableOpacity style={styles.commentFooter}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={globalstyles.tinyfade}>
          {`${relativeDate(item.created_at)} `}
        </Text>
        <Button_CommentReply handleReply={() => handleReply(item)} />
      </View>
      <Buttons_CommentActions
        handleLike={() => handleLike(item)}
        handleOptions={() => openCommentActions(item)}
        item={item}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  comment: {
    flexDirection: 'row',
    borderLeftWidth: border.xs,
    marginBottom: spacing.xs / 2,
  },
  commentTextHeaderContainer: {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  commentFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: spacing.sm,
  },
  likeButtonContainer: {
    paddingRight: spacing.lg,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

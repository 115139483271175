import React, { useState } from 'react';
import { ScrollView } from 'react-native';
import { colors } from '@/app/colors';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import Toast from 'react-native-toast-message';
import { useLogout } from '@/api/useLogout';
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons/faUserAstronaut';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { Button_Settings } from '@/components/shared/Button_Settings';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';

export function MySettingsScreen() {
  const { handleLogout } = useLogout();

  const { navigate } =
    useNavigation<AuthenticatedNavigatorProp<'MySettings'>>();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const toggleLogoutModal = () => {
    setIsLogoutModalVisible(!isLogoutModalVisible);
  };

  return (
    <>
      <ScrollView
        style={globalstyles.container}
        contentContainerStyle={{
          alignItems: 'center',
          paddingTop: spacing.md,
          height: heightWithHeader,
        }}
      >
        {/* <Button_Settings
          callback={() => navigate('MySavedPrompts')}
          title={'Saved prompts'}
          icon={faBookmark}
          bgColor={colors.primary_accent}
          marginBottom
        />
        <Button_Settings
          callback={() => navigate('MySavedPosts')}
          title={'Saved posts'}
          icon={faThumbtack}
          bgColor={colors.primary_accent}
          marginBottom
        /> */}
        <Button_Settings
          callback={() => navigate('EditProfile')}
          title={'Edit profile'}
          icon={faUserAstronaut}
          bgColor={colors.primary_offset}
          marginBottom
        />
        {/* <Button_Settings
          callback={() => navigate('ReleaseNotes')}
          title={'Release notes'}
          icon={faBolt}
          bgColor={colors.primary_accent}
          marginBottom
        /> */}
        {/* <Button_Settings
          callback={() => navigate('SubscriptionsHistory')}
          title={'Subscriptions History'}
          icon={'magic'}
        /> */}
        <Button_Settings
          callback={() => navigate('AccountDetails')}
          title={'Account details'}
          icon={faListAlt}
          bgColor={colors.primary_offset}
          marginBottom
        />
        <Button_Settings
          callback={toggleLogoutModal}
          title={'Logout'}
          icon={faSignOutAlt}
          bgColor={colors.primary_offset}
          marginBottom
        />
        <Toast />
      </ScrollView>
      <Modal_TwoOptions
        cancel={toggleLogoutModal}
        isVisible={isLogoutModalVisible}
        title={'Logout of Whirl?'}
        callback1={handleLogout}
        callback2={toggleLogoutModal}
        text1={'Yes log me out'}
        icon1={faCheck}
        iconColor1={colors.alert}
        text2={'Nevermind'}
        icon2={faTimes}
      />
    </>
  );
}

import { create } from 'zustand';
import { Comment, Post } from '@/api/zod';
import { authenticatedRequest } from '@/modules/auth';
import { showSuccessToast, showToast } from '@/components/ErrorToast';

const initialCommentStoreState = {
  comments: [],
  loading: true,
  newCommentLoading: false,
  page: 1,
  hasMoreComments: true,
};

type CommentStore = {
  reset: () => void;
  comments: Comment[];
  loading: boolean;
  newCommentLoading: boolean;
  hasMoreComments: boolean;
  page: number;
  setHasMoreComments: () => void;
  setLoading: () => void;
  fetchComments: (post: Post, users_id: number) => void;
  likeComment: (comment: Comment, users_id: number) => void;
  likeReply: (comment: Comment, users_id: number) => void;
  createComment: (post: Post, users_id: number, commentInput: string) => void;
  createReply: (
    comment: Comment,
    users_id: number,
    commentInput: string,
  ) => void;
  deleteComment: (comment: Comment) => void;
  updateComment: (comment: Comment, body: string) => void;
  handleBlock: (blocked_user_id: number) => void;
};

export const useCommentStore = create<CommentStore>((set, get) => ({
  ...initialCommentStoreState,
  reset: () => set(() => ({ ...initialCommentStoreState })),
  setHasMoreComments: () => set({ hasMoreComments: true }),
  setLoading: () => set({ loading: false }),
  fetchComments: async (post: Post, users_id: number) => {
    const { page, hasMoreComments, comments } = get();
    if (!hasMoreComments) {
      return;
    }

    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `comments/${post.id}/${users_id}/${page}`,
      );
      if (response.data.length > 0) {
        set({
          page: page + 1,
          comments: [...comments, ...response.data],
        });
      } else {
        set({ hasMoreComments: false });
      }
    } catch (error) {
      showToast('Error saving fetching comments');
    }
    set({ loading: false });
  },
  likeComment: async (comment: Comment, users_id: number) => {
    const comments = get().comments;
    const index = comments?.findIndex((c) => c.id === comment.id);
    if (index === undefined || index === -1) {
      return;
    }

    const currentComment = comments[index];
    const isLiked = !currentComment.isLiked;

    const updatedComments = comments.map((c) => {
      if (c.id === comment.id) {
        return {
          ...c,
          isLiked,
          likeCount: c.likeCount + (isLiked ? 1 : -1),
        };
      } else {
        return c;
      }
    });

    set({ comments: updatedComments });

    const url = comment.isLiked
      ? `comment_likes/${comment.id}/${users_id}`
      : 'comment_likes';
    const method = comment.isLiked ? 'delete' : 'post';
    const data = { users_id: users_id, comments_id: comment.id };
    await (await authenticatedRequest())[method](url, data);
  },
  likeReply: async (likedComment: Comment, users_id: number) => {
    const comments = get().comments;
    let parentIndex = -1;
    let replyIndex = -1;
    for (let i = 0; i < comments.length; i++) {
      replyIndex = comments[i].replies.findIndex(
        (r) => r.id === likedComment.id,
      );
      if (replyIndex !== -1) {
        parentIndex = i;
        break;
      }
    }

    if (parentIndex === -1 || replyIndex === -1) {
      return;
    }

    const isLiked = !likedComment.isLiked;
    comments[parentIndex].replies[replyIndex] = {
      ...likedComment,
      isLiked,
      likeCount: likedComment.likeCount + (isLiked ? 1 : -1),
    };
    set({ comments: [...comments] });
    const url = likedComment.isLiked
      ? `comment_likes/${likedComment.id}/${users_id}`
      : 'comment_likes';
    const method = likedComment.isLiked ? 'delete' : 'post';
    const data = { users_id: users_id, comments_id: likedComment.id };
    await (await authenticatedRequest())[method](url, data);
  },
  createComment: async (post: Post, users_id: number, commentInput: string) => {
    set({ newCommentLoading: true });
    const comments = get().comments;
    try {
      const response = await (
        await authenticatedRequest()
      ).post('comments', {
        users_id: users_id,
        posts_id: post.id,
        body: commentInput,
      });

      set({
        comments: [response.data, ...comments],
      });
    } catch (error) {
      showToast('Error creating comment 🧐');
    }
    set({ newCommentLoading: false });
  },
  createReply: async (
    comment: Comment,
    users_id: number,
    commentInput: string,
  ) => {
    set({ newCommentLoading: true });
    const comments = get().comments;
    try {
      const response = await (
        await authenticatedRequest()
      ).post(`comments/reply/${comment.id}`, {
        users_id: users_id,
        comments_id: comment.id,
        body: commentInput,
      });

      const updatedComments = comments.map((item) => {
        if (item.id === comment.id) {
          return {
            ...item,
            replies: [response.data, ...(item.replies || [])],
          };
        }
        return item;
      });

      set({
        comments: updatedComments,
      });
    } catch (error) {
      showToast('Error creating reply 🧐');
    }
    set({ newCommentLoading: false });
  },
  deleteComment: async (targetComment: Comment) => {
    try {
      await (
        await authenticatedRequest()
      ).delete(`comments/${targetComment.id}`);
      const comments = get().comments;
      let found = false;

      if (targetComment.isReply) {
        const parentCommentIndex = comments.findIndex(
          (comment) => comment.id === targetComment.comments_id,
        );
        if (parentCommentIndex !== -1) {
          const replyIndex = comments[parentCommentIndex].replies.findIndex(
            (r) => r.id === targetComment.id,
          );
          if (replyIndex !== -1) {
            comments[parentCommentIndex].replies.splice(replyIndex, 1);
            found = true;
          }
        }
        if (found) {
          set({ comments: [...comments] });
        } else {
          console.log('Comment not found');
        }
      } else {
        const updatedCommentsList = comments.filter((comment) => {
          if (comment.id !== targetComment.id) {
            return true;
          }

          found = true;
          return false;
        });
        if (found) {
          set({ comments: updatedCommentsList });
        }
      }

      if (!found) {
        console.log('Comment not found');
        return;
      }

      showSuccessToast('Comment deleted. 🫡');
    } catch (error) {
      showToast('Error deleting comment 🧐');
    }
  },
  updateComment: async (targetComment: Comment, body: string) => {
    try {
      set({ loading: true });
      const authenticatedAxiosInstance = await authenticatedRequest();
      const updatedComment = await authenticatedAxiosInstance.post(
        `comments/${targetComment.id}`,
        {
          comments_id: targetComment.id,
          body: body,
        },
      );

      const comments = get().comments;

      if (targetComment.isReply) {
        const parentCommentIndex = comments.findIndex(
          (comment) => comment.id === targetComment.comments_id,
        );
        if (parentCommentIndex !== -1) {
          const replyIndex = comments[parentCommentIndex].replies.findIndex(
            (r) => r.id === targetComment.id,
          );
          if (replyIndex !== -1) {
            comments[parentCommentIndex].replies[replyIndex] =
              updatedComment.data;
          }
        }
      } else {
        const index = comments.findIndex((c) => c.id === targetComment.id);
        if (index !== -1) {
          comments[index] = updatedComment.data;
        }
      }

      set({ comments: [...comments] });
    } catch (error: any) {
      console.log(error);
      showToast(error.toString());
    } finally {
      set({ loading: false });
    }
  },
  handleBlock: (blocked_user_id: number) => {
    set((state) => ({
      comments: state.comments.filter(
        (post) => post.users_id !== blocked_user_id,
      ),
    }));
  },
}));

import React from 'react';
import { View } from 'react-native';
import { globalstyles } from '@/app/globalstyles';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { Button_WebFooter } from '../Button_WebFooter';

export const HomeWebComponent = () => {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Home'>>();
  return (
    <View
      style={{
        ...globalstyles.container,
        ...globalstyles.imageBackgroundFull,
        justifyContent: 'flex-end',
      }}
    >
      <Button_WebFooter navigation={navigation} />
    </View>
  );
};

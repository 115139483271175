import { EMPTYSTATEPOST, Post } from '@/api/zod';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest } from '@/modules/auth';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList, View, Text, TouchableOpacity } from 'react-native';
import Toast from 'react-native-toast-message';
import { useMyProfileStore } from '@/app/store/store';
import { ThemeSubtitle } from '@/components/ThemeSubtitle';
import { Modal_FullScreenImage } from '../Modal_FullScreenImage';
import { formattedDateFromString } from '@/components/RelativeDate';
import { colors } from '@/app/colors';
import { Subtitle } from '@/components/Subtitle';
import { Thumbnail } from '@/components/shared/Thumbnail';

export function TagListGalleryScreen() {
  const {
    params: { tagListTag, tag_list },
  } = useRoute<AuthenticatedNavigatorRouteProp<'TagListGallery'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'TagListGallery'>>();
  const [postList, setPostList] = useState<Post[]>([]);
  const [pressedItem, setPressedItem] = useState<Post>(EMPTYSTATEPOST);
  const [mounted, setMounted] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [page, setPage] = useState(1);

  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const [longPressedPost, setLongPressedPost] = useState<Post>(EMPTYSTATEPOST);

  const updateFullScreenModalVisible = (post: Post) => {
    setLongPressedPost(post);
    setFullScreenModalVisible(!fullScreenModalVisible);
  };

  const updateRootPostState = (updatedPost: Post) => {
    setPostList((prevState) => {
      const index = prevState.findIndex((p) => p.id === updatedPost.id);
      if (index !== -1) {
        return [
          ...prevState.slice(0, index),
          updatedPost,
          ...prevState.slice(index + 1),
        ];
      } else {
        return prevState;
      }
    });
  };

  async function fetchPosts() {
    if (hasMorePosts) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `tag_lists/posts/${tagListTag.id}/${user?.id}/${page}/0`,
        );
        if (response.data.length > 0) {
          setPage(page + 1);
          setPostList([...postList, ...response.data]);
        } else {
          setHasMorePosts(false);
        }
      } catch (error) {
        console.log(error);
        showToast('Error fetching posts');
      } finally {
        if (!mounted) {
          setMounted(true);
        }
      }
    }
  }

  useEffect(() => {
    if (mounted) {
      if (user) {
        const queryParams = [
          `tag_lists/posts/${tagListTag.id}`,
          (postList.indexOf(pressedItem) + 1).toString(),
        ];
        navigation.push('ViewEphemeralPosts', {
          posts: [pressedItem],
          updateRootPostState: updateRootPostState,
          queryParams: queryParams,
        });
      }
    }
    setPressedItem(EMPTYSTATEPOST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressedItem]);

  const handlePress = (item: Post) => {
    setPressedItem(item);
  };

  const handleCreatePost = () => {
    navigation.navigate('CreatePost', {
      submission_theme_id: null,
      passedExistingTags: [tag_list.tag.title, tagListTag.title],
    });
  };

  useEffect(() => {
    navigation.setOptions({
      title: formattedDateFromString(tagListTag.date),
    });
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, user, tagListTag]);

  const addPlaceholders = (data: Post[]) => {
    if (data.length % 3 === 2) {
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
    } else if (data.length % 3 === 1) {
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
      data.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
    }
    return data;
  };

  const postListWithPlaceholders = addPlaceholders(postList);

  const renderHeader = () => {
    return (
      <>
        <ThemeSubtitle
          pretitle={'Posts in the theme of '}
          title={tagListTag.title}
        />
        {mounted && (
          <>
            {postListWithPlaceholders.length === 0 && (
              <Subtitle text={'No posts - be the first!'} />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <View style={globalstyles.container}>
        <FlatList
          data={postListWithPlaceholders}
          keyExtractor={(post) => post.id.toString()}
          numColumns={3}
          style={{ height: heightWithHeader }}
          onEndReached={fetchPosts}
          onEndReachedThreshold={0.8}
          ListHeaderComponent={renderHeader}
          renderItem={({ item }) => (
            <Thumbnail
              post={item}
              showCount={true}
              onPress={() => handlePress(item)}
            />
          )}
        />
        <TouchableOpacity
          activeOpacity={0.9}
          style={{
            ...globalstyles.centeringContainer,
            ...globalstyles.roundedButton,
            ...globalstyles._bg_navigate,
            position: 'absolute',
            bottom: spacing.lg * 2,
          }}
          onPress={handleCreatePost}
        >
          <Text style={{ ...globalstyles.title, color: colors.primary }}>
            {'Create post for this theme'}
          </Text>
        </TouchableOpacity>
      </View>
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={() => updateFullScreenModalVisible(longPressedPost)}
        post={longPressedPost}
      />
      <Toast />
    </>
  );
}

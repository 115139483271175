import React from 'react';
import '@expo/metro-runtime';
import { StatusBar } from 'expo-status-bar';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { LoadAssets } from '@/app/LoadAssets';
import { NavigationRoot } from '@/navigators/NavigationRoot';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@/app/store/queryClient';
import { colors } from './colors';
import { View, StyleSheet } from 'react-native';
import { globalstyles } from './globalstyles';

const linking = {
  prefixes: ['whirl://', 'https://joinwhirl.com', 'https://whirlai.com'],
  config: {
    screens: {
      Explore: '',
      SuccessfulOAuth: 'oauth/success',
      DailyTheme: 'daily/submit',
      DailyThemePodium: 'daily/vote-champ',
      DailyThemeRound1Vote: 'daily/vote-new',
      VoteNextTheme: 'daily/vote-theme',
      Privacy: 'legal/privacy',
      TermsOfUse: 'legal/terms',
      Help: 'account/help',
      StaticMobile: 'mobile/static',
      Subscriptions: 'account/subscriptions',
      MyBlockedUsers: 'account/blocks',
      AccountDetails: 'account/details',
      DeleteAccount: 'account/delete',
      EditBanner: 'account/edit/banner',
      EditProfile: 'account/edit/profile',
      EditSocialLinks: 'account/edit/links',
      EditUsername: 'account/edit/username',
      MySettings: 'account/settings',
      ViewSinglePost: 'posts/:postId',
      UserProfile: ':username',
    },
  },
};

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: colors.primary,
  },
};

export function App() {
  return (
    <LoadAssets>
      <QueryClientProvider client={queryClient}>
        <View style={rootStyle.root}>
          <NavigationContainer linking={linking} theme={MyTheme}>
            <NavigationRoot />
            <StatusBar style={'light'} />
          </NavigationContainer>
        </View>
      </QueryClientProvider>
    </LoadAssets>
  );
}

const rootStyle = StyleSheet.create({
  root: {
    ...globalstyles.container,
    backgroundColor: colors.primary,
  },
});

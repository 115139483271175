import { Post } from '@/api/zod';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useMyProfileStore } from '@/app/store/store';
import { toggleSavePost, toggleLikePost, toggleVoteForPost } from '@/api/posts';
import { useExploreStore } from '@/app/store/exploreStore';

const usePostActions = (
  updateRootPostState?: (updatedPost: Post) => void,
  updateLocalPostList?: (updatedPost: Post) => void,
) => {
  const user = useMyProfileStore((state) => state.myInfo);
  const updatePostInExplorePostList = useExploreStore(
    (state) => state.updatePostInExplorePostList,
  );
  const updatePostInChampPostList = useDailyThemeStore(
    (state) => state.updatePostInChampPostList,
  );
  const updatePostInAllPostList = useMyProfileStore(
    (state) => state.updatePostInAllPostList,
  );
  const updatePostInDailyThemePostList = useMyProfileStore(
    (state) => state.updatePostInDailyThemePostList,
  );
  const updatePostInFirstRoundThemePostList = useDailyThemeStore(
    (state) => state.updatePostInFirstRoundThemePostList,
  );
  const updatePostInFinalRoundThemePostList = useDailyThemeStore(
    (state) => state.updatePostInFinalRoundThemePostList,
  );
  const setFinalRoundThemeInfo = useDailyThemeStore(
    (state) => state.setFinalRoundThemeInfo,
  );
  const finalRoundThemeInfo = useDailyThemeStore(
    (state) => state.finalRoundThemeInfo,
  );
  const setFirstRoundThemeInfo = useDailyThemeStore(
    (state) => state.setFirstRoundThemeInfo,
  );
  const firstRoundThemeInfo = useDailyThemeStore(
    (state) => state.firstRoundThemeInfo,
  );

  const updatePostListsGlobally = (updatedPost: Post) => {
    updatePostInDailyThemePostList(updatedPost);
    updatePostInAllPostList(updatedPost);
    updatePostInExplorePostList(updatedPost);
    updatePostInChampPostList(updatedPost);
    updatePostInFirstRoundThemePostList(updatedPost);
    updatePostInFinalRoundThemePostList(updatedPost);
    updateRootPostState && updateRootPostState(updatedPost);
    updateLocalPostList && updateLocalPostList(updatedPost);
  };

  const handleLike = async (post: Post, isDailyThemeRd1: boolean = false) => {
    user && toggleLikePost(user, post);
    const updatedPost = {
      ...post,
      likedOrVoted: !post.likedOrVoted,
      likeCount: !post.likedOrVoted ? post.likeCount + 1 : post.likeCount - 1,
    };
    updatePostListsGlobally(updatedPost);

    if (isDailyThemeRd1) {
      const updatedThemeInfo = {
        ...firstRoundThemeInfo,
        total_votes_given: !post.likedOrVoted
          ? firstRoundThemeInfo.total_votes_given + 1
          : firstRoundThemeInfo.total_votes_given - 1,
      };
      setFirstRoundThemeInfo(updatedThemeInfo);
    }
  };

  const handleVote = (post: Post) => {
    user && toggleVoteForPost(user, post);

    const updatedPost = {
      ...post,
      likedOrVoted: !post.likedOrVoted,
      voteCount: !post.likedOrVoted ? post.voteCount + 1 : post.voteCount - 1,
    };
    updatePostListsGlobally(updatedPost);

    const updatedThemeInfoVoteCount = {
      ...finalRoundThemeInfo,
      total_votes_given: !post.likedOrVoted
        ? finalRoundThemeInfo.total_votes_given + 1
        : finalRoundThemeInfo.total_votes_given - 1,
    };
    setFinalRoundThemeInfo(updatedThemeInfoVoteCount);
  };

  const handleSave = async (post: Post) => {
    user && toggleSavePost(user, post);
    const updatedPost = {
      ...post,
      isSaved: !post.isSaved,
      totalSaves: !post.isSaved ? post.totalSaves + 1 : post.totalSaves - 1,
    };
    updatePostListsGlobally(updatedPost);
  };

  return { handleLike, handleSave, handleVote, updatePostListsGlobally };
};

export default usePostActions;

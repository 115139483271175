import { authenticatedRequest } from '@/modules/auth';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import {
  globalstyles,
  heightWithHeader,
  radius,
  webContentWidth,
  spacing,
  width,
} from '@/app/globalstyles';
import { ReleaseNote } from '@/api/zod';
import { Image } from 'expo-image';
import { colors } from '@/app/colors';
import { FlatList } from 'react-native-gesture-handler';
import { ListItem_User } from '@/components/ListItem_User';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import { Modal_FullScreenImage } from './Modal_FullScreenImage';
// import { useMyProfileStore } from '@/app/store/store';

export function ReleaseNotesScreen() {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>();
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'ReleaseNotes'>>();
  // const user = useMyProfileStore((state) => state.myInfo);
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const [fullScreenUrl, setFullScreenUrl] = useState('');

  useEffect(() => {
    async function fetchNotes() {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get('release_notes');
      setReleaseNotes(response.data);
    }
    fetchNotes();
  }, []);

  const updateFullScreenModalVisible = async () => {
    setFullScreenModalVisible(!fullScreenModalVisible);
  };

  const renderReleaseNote = ({ item }: { item: ReleaseNote }) => (
    <View key={item.id} style={styles.noteContainer}>
      <View style={{ marginBottom: spacing.sm }}>
        <Text style={globalstyles.title}>{item.title}</Text>
      </View>
      <View style={{ marginBottom: spacing.md, alignSelf: 'center' }}>
        <Text style={globalstyles.smallfade}>
          {`Version ${item.version} - ${item.release_date}`}
        </Text>
      </View>
      <View style={{ marginBottom: spacing.md }}>
        <View style={{ marginBottom: spacing.sm }}>
          <Text style={globalstyles.label}>Notes</Text>
        </View>
        <Text style={globalstyles.smallbody}>{item.notes}</Text>
      </View>
      <View style={{ marginBottom: spacing.md }}>
        <View style={{ marginBottom: spacing.sm }}>
          <Text style={globalstyles.label}>Main adds</Text>
        </View>
        <Text style={globalstyles.smallbody}>{item.major_features}</Text>
      </View>
      {item.minor_features && (
        <View style={{ marginBottom: spacing.md }}>
          <View style={{ marginBottom: spacing.sm }}>
            <Text style={globalstyles.label}>Mini adds</Text>
          </View>
          <Text style={globalstyles.smallbody}>{item.minor_features}</Text>
        </View>
      )}
      {item.image && (
        <Pressable
          style={{ marginBottom: spacing.md, alignItems: 'center' }}
          onPress={() => {
            setFullScreenUrl(item.image.url);
            updateFullScreenModalVisible();
          }}
        >
          <Image
            source={{ uri: item.image.url }}
            style={globalstyles.profileImage}
            transition={250}
          />
        </Pressable>
      )}
      {item.shoutouts.length > 0 && (
        <View>
          <View style={{ marginBottom: spacing.sm }}>
            <Text style={globalstyles.label}>Shoutouts</Text>
          </View>
          {item.shoutouts.map((mentionedUser) => (
            <ListItem_User
              navigation={navigation}
              pressedUser={mentionedUser}
            />
          ))}
        </View>
      )}
    </View>
  );

  return (
    <View style={{ ...globalstyles.container, alignItems: 'center' }}>
      <FlatList
        data={releaseNotes}
        renderItem={renderReleaseNote}
        style={{
          maxWidth: webContentWidth * 2,
          height: heightWithHeader,
        }}
        initialNumToRender={releaseNotes ? releaseNotes.length : 10}
        contentContainerStyle={{ alignItems: 'center' }}
        keyExtractor={(item) => item.id.toString()}
        showsVerticalScrollIndicator={false}
      />
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={updateFullScreenModalVisible}
        bare_link={fullScreenUrl}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  noteContainer: {
    width: width - spacing.md * 2,
    maxWidth: webContentWidth * 2,
    backgroundColor: colors.primary_accent,
    padding: spacing.md,
    margin: spacing.md,
    borderRadius: radius.lg,
  },
});

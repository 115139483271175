export const relativeDate = (timestamp: number) => {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - timestamp) / 1000);

  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  let relativeText = '';

  if (weeks > 0) {
    relativeText = `${weeks}wk${weeks > 1 ? 's' : ''}`;
  } else if (days > 0) {
    relativeText = `${days}d${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    relativeText = `${hours}hr${hours > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    relativeText = `${minutes}min${minutes > 1 ? 's' : ''}`;
  } else {
    relativeText = 'just now';
  }

  return relativeText;
};

export const countdown = (timestamp: number) => {
  const now = new Date();
  const diffInSeconds = Math.floor((timestamp - now.getTime()) / 1000);

  if (diffInSeconds <= 0) {
    return "Time's up!";
  }

  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  let countdownText = '';

  if (weeks > 0) {
    countdownText = `${weeks}wk${weeks > 1 ? 's' : ''}`;
  } else if (days > 0) {
    countdownText = `${days}d${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    countdownText = `${hours}hr${hours > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    countdownText = `${minutes}min${minutes > 1 ? 's' : ''}`;
  } else {
    countdownText = `${diffInSeconds}sec${diffInSeconds > 1 ? 's' : ''}`;
  }

  return countdownText;
};

export const formattedDate = (timestamp: number) => {
  const date = new Date(timestamp);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

export const formattedDateFromString = (dateStr: string) => {
  const [year, monthIndex, day] = dateStr.split('-').map(Number);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = monthNames[monthIndex - 1];

  return `${month} ${day}, ${year}`;
};

export const shortFormDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}.${day}.${year}`;
};

export const getFormattedDateThreeInputs = (
  year: number,
  month: number,
  day: number,
): string => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let suffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    suffix = 'st';
  } else if (day === 2 || day === 22) {
    suffix = 'nd';
  } else if (day === 3 || day === 23) {
    suffix = 'rd';
  }

  return `${monthNames[month - 1]} ${day}${suffix}, ${year}`;
};

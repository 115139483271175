import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';

type Props = {
  numColumns: number;
  filterStarred: boolean;
  columns: number[];
  handlePressStar: () => void;
  changeColumns: (n: number) => void;
};

export function ImageGenGalleryPromptHeader({
  numColumns,
  filterStarred,
  columns,
  handlePressStar,
  changeColumns,
}: Props) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: spacing.md,
        }}
      >
        <Text style={globalstyles.label}>Columns: </Text>
        <View
          style={{
            ...globalstyles.chipContainer,
          }}
        >
          {columns.map((num) => (
            <TouchableOpacity
              style={{
                ...globalstyles.chip,
                backgroundColor:
                  num === numColumns ? colors.primary_accent : colors.primary,
              }}
              activeOpacity={0.9}
              key={num}
              onPress={() => changeColumns(num)}
            >
              <Text
                style={
                  num === numColumns
                    ? {
                        ...globalstyles.chipText,
                        ...globalstyles._selectedChipText,
                      }
                    : {
                        ...globalstyles.chipText,
                      }
                }
              >
                {num}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
      <TouchableOpacity
        activeOpacity={0.9}
        style={{
          ...globalstyles.chip,
          borderColor: colors.primary,
        }}
        onPress={handlePressStar}
      >
        <FontAwesomeIcon
          icon={faStar}
          size={20}
          color={filterStarred ? colors.accent : colors.secondary}
        />
      </TouchableOpacity>
    </View>
  );
}

import { globalstyles } from '@/app/globalstyles';
import React from 'react';
import { Pressable, Text } from 'react-native';
import withAuthPopup from '../../shared/hoc/withAuthPopup';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  handleReply: () => void;
}

const Button_CommentReply: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  handleReply,
}) => {
  const handlePress = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      handleReply();
    }
  };
  return (
    <Pressable onPress={handlePress}>
      <Text style={globalstyles.tinybody}>- Reply</Text>
    </Pressable>
  );
};

export default withAuthPopup(Button_CommentReply);

import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  globalstyles,
  spacing,
  maxWebContentWidth,
  radius,
  border,
} from '@/app/globalstyles';
import { Button_ModalClose } from '../../shared/Button_ModalClose';
import { Text_AuthHeader } from '../Text_AuthHeader';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Layout_PWResetStep1 } from './Layout_PWResetStep1';
import { Layout_PWResetStep2 } from './Layout_PWResetStep2';
import { Layout_PWResetStep3 } from './Layout_PWResetStep3';
import { colors } from '@/app/colors';

interface Props {
  setScreen: (screen: string) => void;
}

export const Layout_PasswordReset = ({ setScreen }: Props) => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const [currentStep, setCurrentStep] = useState(1);
  const moveToStep = (s: number) => {
    setCurrentStep(s);
  };

  const renderSteps = () => {
    switch (currentStep) {
      case 1:
        return (
          <Layout_PWResetStep1
            moveToStep={moveToStep}
            email={email}
            setEmail={setEmail}
          />
        );
      case 2:
        return (
          <Layout_PWResetStep2
            moveToStep={moveToStep}
            code={code}
            email={email}
            setCode={setCode}
          />
        );
      case 3:
        return <Layout_PWResetStep3 email={email} />;
    }
  };

  const authHeaderKey =
    currentStep === 1
      ? 'pw-reset-1'
      : currentStep === 2
      ? 'pw-reset-2'
      : currentStep === 3
      ? 'pw-reset-3'
      : 'pw-reset-1';

  return (
    <View style={styles.wrapper}>
      <Text_AuthHeader text={authHeaderKey} pw_email={email} />
      <Button_ModalClose
        close={() => setScreen('oauth')}
        overrideIcon={faChevronLeft}
        bgColor={colors.primary}
        bgHover={colors.primary_accent}
      />
      {renderSteps()}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...globalstyles.container,
    padding: spacing.xl,
  },
  submitButton: {
    alignItems: 'center',
    width: maxWebContentWidth / 3, // change?
    borderRadius: radius.sm,
    borderWidth: border.xs,
    marginBottom: spacing.lg,
    padding: spacing.sm,
    paddingHorizontal: spacing.md,
  },
});

import { authenticatedRequest, unauthenticatedRequest } from '@/modules/auth';
import { useMutation } from '@tanstack/react-query';
import {
  EMPTYSTATEPOST,
  GalleryData,
  Post,
  ProfileData,
  ProfileResponse,
} from './zod';
import { appendFileToFormData } from './upload';

export interface CreatePostArgs {
  users_id: number;
  file: any;
  title?: string;
  technology_type_id?: number;
  daily_theme_id?: number;
  prompt?: string;
}

export async function createPost(newPost: CreatePostArgs) {
  return (await authenticatedRequest()).post<{ data: Post }>('post', newPost);
}

export function useCreatePost() {
  return useMutation({
    mutationFn: createPost,
  });
}

export async function toggleSavePost(user: ProfileResponse, post: Post) {
  const authenticatedAxios = await authenticatedRequest();
  const requestMethod = !post.isSaved ? 'post' : 'delete';
  const requestUrl = `user_saved_posts${
    !post.isSaved ? '' : `/${user?.id}/${post.id}`
  }`;

  const requestData = {
    users_id: user?.id,
    posts_id: post.id,
  };

  const response = await authenticatedAxios[requestMethod](
    requestUrl,
    requestData,
  );

  return response;
}

export async function toggleLikePost(user: ProfileResponse, post: Post) {
  const authenticatedAxios = await authenticatedRequest();
  const requestMethod = !post.likedOrVoted ? 'post' : 'delete';
  const requestUrl = `likes${
    !post.likedOrVoted ? '' : `/${user.id}/${post.id}`
  }`;

  const requestData = {
    posts_id: post.id,
    users_id: user.id,
    username: user.username,
  };

  const response = await authenticatedAxios[requestMethod](
    requestUrl,
    requestData,
  );

  return response;
}

export async function toggleVoteForPost(user: ProfileResponse, post: Post) {
  const authenticatedAxios = await authenticatedRequest();
  const requestMethod = !post.likedOrVoted ? 'post' : 'delete';
  const requestUrl = `votes${
    !post.likedOrVoted ? '' : `/${post.id}/${user.id}`
  }`;

  const requestData = {
    posts_id: post.id,
    users_id: user?.id,
  };

  const response = await authenticatedAxios[requestMethod](
    requestUrl,
    requestData,
  );

  return response;
}

export async function updateImage(file: Blob, posts_id: number) {
  const formData = new FormData();

  appendFileToFormData(formData, 'file', file);

  const response = await (
    await authenticatedRequest()
  ).post<Post>(`post/image/${posts_id}`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return response.data;
}

export function addPlaceholders(data: Post[], numColumns: number): Post[] {
  const dataCopy = [...data.filter((post) => !post.isPlaceholder)];
  const remainder = dataCopy.length % numColumns;
  const placeholdersToAdd = remainder === 0 ? 0 : numColumns - remainder;
  for (let i = 0; i < placeholdersToAdd; i++) {
    dataCopy.push({ ...EMPTYSTATEPOST, isPlaceholder: true });
  }
  return dataCopy;
}

export async function fetchProfileData(
  username: string,
  loggedInUserId: number,
): Promise<ProfileData> {
  const response = await unauthenticatedRequest().get(
    `/user_profile/info/${username}/${loggedInUserId}`,
  );
  return response.data;
}

export async function fetchGalleryData(
  username: string,
  loggedInUserId: number,
): Promise<GalleryData> {
  const response = await unauthenticatedRequest().get(
    `user_profile/posts/${username}/${loggedInUserId}`,
  );
  return response.data;
}

export const DEFAULTTECH = [
  {
    id: 1,
    name: 'Midjourney',
    emoji: '⛵️',
  },
  {
    id: 2,
    name: 'Stable Diffusion',
    emoji: '🪜',
  },
  {
    id: 14,
    name: 'DALL-E 3',
    emoji: '☘️',
  },
  {
    id: 3,
    name: 'DALL-E 2',
    emoji: '🧸',
  },
  {
    id: 5,
    name: 'Wombo',
    emoji: '🌈',
  },
  {
    id: 12,
    name: 'monAI',
    emoji: '🎨',
  },
  {
    id: 13,
    name: 'LeonardoAI',
    emoji: '🖼️',
  },
  {
    id: 7,
    name: 'NightCafe',
    emoji: '☕️',
  },
  {
    id: 10,
    name: 'Lexica',
    emoji: '📚',
  },
  {
    id: 11,
    name: 'Playground',
    emoji: '▶️',
  },
  {
    id: 6,
    name: 'Starry AI',
    emoji: '✨',
  },
  {
    id: 4,
    name: 'Disco Diffusion',
    emoji: '🌐',
  },
  {
    id: 8,
    name: 'Custom model',
    emoji: '🛠',
  },
  {
    id: 9,
    name: 'Other',
    emoji: '🔮',
  },
];

import { colors } from '@/app/colors';
import React from 'react';
import withAuthPopup from '../shared/hoc/withAuthPopup';
import { Button_HotDog } from './Button_HotDog';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  handleFollow: () => void;
  isFollowed?: boolean;
}

const Button_Follow: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  handleFollow,
  isFollowed,
}) => {
  const handlePress = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      handleFollow();
    }
  };
  return (
    <Button_HotDog
      title={isFollowed ? 'Unfollow' : 'Follow'}
      callback={handlePress}
      bgColor={isFollowed ? colors.primary_accent : colors.accent}
      bgHover={isFollowed ? colors.primary_light : colors.accent_highlight}
      bgHighlight={isFollowed ? colors.primary_light : colors.accent_highlight}
      brightText
    />
  );
};

export default withAuthPopup(Button_Follow);

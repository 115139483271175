import { useRef, useState } from 'react';
import { Animated } from 'react-native';
import { Post } from './zod';

const useFadeAnimation = (
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>,
  setPressedItem: (post: Post) => void,
  posts: Post[],
  currentIndex: number,
) => {
  const [commentsVisible, setCommentsVisible] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const fadeOut = (callback: () => void) => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true,
    }).start(callback);
  };

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  };

  const previous = () => {
    if (currentIndex > 0) {
      fadeOut(() => {
        if (commentsVisible) {
          setCommentsVisible(false);
        }
        setPressedItem(posts[currentIndex - 1]);
        setCurrentIndex((prev) => prev - 1);
        setTimeout(fadeIn, 400);
      });
    }
  };

  const next = () => {
    if (currentIndex < posts.length - 1) {
      fadeOut(() => {
        if (commentsVisible) {
          setCommentsVisible(false);
        }
        setPressedItem(posts[currentIndex + 1]);
        setCurrentIndex((prev) => prev + 1);
        setTimeout(fadeIn, 400);
      });
    }
  };

  return { fadeAnim, previous, next, commentsVisible, setCommentsVisible };
};

export default useFadeAnimation;

import { showToast } from '@/components/ErrorToast';
import {
  isValidEmail,
  isValidPassword,
  isValidVerificationCode,
} from '@/modules/auth';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { View } from 'react-native';
import { spacing } from '@/app/globalstyles';
import { InputBox_SignUp } from '../InputBox_SignUp';
import { InputBox_SendCode } from '../InputBox_SendCode';
import { colors } from '@/app/colors';
import { AuthButton } from '../AuthButton';
import { useEmailVerification } from '@/api/useEmailVerification';

interface Props {
  proceed: () => void;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
}

export const Layout_SignUpStep1 = ({
  proceed,
  email,
  password,
  setEmail,
  setPassword,
}: Props) => {
  // STATE.
  const [code, setCode] = useState<string>('');
  const [secureTextEntry1, setSecureTextEntry1] = useState(true);
  const {
    resetValidationState,
    sendCode,
    verifyCode,
    setEmailTaken,
    setExpiredCode,
    setInvalidCode,
    setCanSendNewCode,
    setTimeUntilCanSendCode,
    expiredCode,
    invalidCode,
    emailTaken,
    canSendNewCode,
    timeUntilCanSendCode,
    sendingCode,
    verifyingDetails,
  } = useEmailVerification({ email });

  // HANDLERS.
  const updateSecureTextEntry1 = () => {
    setSecureTextEntry1(!secureTextEntry1);
  };
  const handleEmailInputChange = (setter: any) => (text: string) => {
    const newText = text.replace(/\s+/g, '');
    setter(newText.toLowerCase());
  };
  const tryToProceed = async () => {
    resetValidationState();
    const verification_response = await verifyCode(code, email);
    if (verification_response === 'email-taken') {
      setEmailTaken(true);
    } else if (verification_response === 'expired') {
      setCanSendNewCode(true);
      setExpiredCode(true);
    } else if (verification_response === 'denied') {
      setInvalidCode(true);
    } else if (verification_response === 'success') {
      proceed();
    } else if (verification_response === 'no-code-found') {
      showToast('No code found. Click "Send code" to send another!');
    } else if (verification_response === 'error') {
      showToast('Unknown error.');
    }
  };

  useEffect(() => {
    let interval: any = null;
    if (timeUntilCanSendCode > 0) {
      interval = setInterval(() => {
        setTimeUntilCanSendCode((currentTime) => currentTime - 1);
      }, 1000);
    } else if (timeUntilCanSendCode === 0) {
      setCanSendNewCode(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeUntilCanSendCode, setCanSendNewCode, setTimeUntilCanSendCode]);

  // HELPERS.
  const readyToProceed =
    isValidPassword(password) &&
    isValidVerificationCode(code) &&
    isValidEmail(email);
  const invalidCodeText = expiredCode
    ? 'Code is expired. Send a new code.'
    : invalidCode
    ? 'Code is invalid. Try again.'
    : '';
  const isInvalid = invalidCode || expiredCode;

  return (
    <View>
      <InputBox_SignUp
        value={email}
        handleInputChange={handleEmailInputChange}
        setter={setEmail}
        placeholderText={'Email'}
        keyboardType={'email-address'}
        invalid={emailTaken}
        invalidText={'An account with that email already exists.'}
      />
      <InputBox_SignUp
        isPassword={true}
        placeholderText={'Password'}
        value={password}
        setter={setPassword}
        secureTextEntry={secureTextEntry1}
        updateSecureTextEntry={updateSecureTextEntry1}
        maxLength={128}
        invalid={!isValidPassword(password) && password !== ''}
        invalidText={'8+ characters and at least one number.'}
      />
      <View style={{ marginBottom: spacing.lg }}>
        <InputBox_SendCode
          value={code}
          setter={setCode}
          canSendNewCode={canSendNewCode}
          sendCode={sendCode}
          sendCodeText={
            !canSendNewCode
              ? `Resend code ${timeUntilCanSendCode}s`
              : 'Send code'
          }
          validEmail={isValidEmail(email)}
          invalid={isInvalid}
          invalidText={invalidCodeText}
          loading={sendingCode}
        />
      </View>
      <AuthButton
        logo={''}
        callback={readyToProceed ? tryToProceed : () => null}
        text={'Next'}
        disabled={!readyToProceed}
        bgColor={readyToProceed ? colors.accent : colors.primary_accent}
        bgHover={
          readyToProceed ? colors.accent_highlight : colors.primary_accent
        }
        loading={verifyingDetails}
        sideblanks
      />
    </View>
  );
};

import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { Text, StyleSheet, View, Pressable, ScrollView } from 'react-native';
import { Image } from 'expo-image';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useUpdateSelf } from '@/api/me';
import { colors } from '@/app/colors';
import {
  globalstyles,
  heightWithHeader,
  radius,
  webContentWidth,
  spacing,
  maxWebContentWidth,
} from '@/app/globalstyles';
import { authenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { useMyProfileStore } from '@/app/store/store';
import { queryKeys } from '@/api/keys';
import { Button_Submission } from '@/components/Button_Submission';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { useOpenImagePicker } from '@/api/upload';
import { Button_Settings } from '@/components/shared/Button_Settings';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export function EditBannerScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'EditBanner'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const { mutate: updateProfile, isLoading } = useUpdateSelf();
  const [removing, setRemoving] = useState(false);
  const queryClient = useQueryClient();
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  const [banner_file, setBannerFile] = useState<Blob>();
  const [modalVisible, setModalVisible] = useState(false);
  const updateModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  const removeBanner = async () => {
    updateModalVisible();
    if (user?.Profile_banner?.url) {
      try {
        setRemoving(true);
        const authenticatedAxiosInstance = await authenticatedRequest();
        const response = await authenticatedAxiosInstance.post(
          'user/remove_banner',
          {
            users_id: user?.id,
          },
        );
        setMyInfo(response.data);
        queryClient.setQueryData(queryKeys.auth.me(), response.data);
      } catch (error: any) {
        console.log(error);
        showToast(error.toString());
      } finally {
        setRemoving(false);
      }
    }
  };

  const handleEditSubmit = () => {
    if (user) {
      updateProfile(
        {
          bio: user.bio,
          fullname: user.fullname,
          users_id: user.id,
          Profile_banner: banner_file,
        },
        {
          onSuccess: () => {
            navigation.goBack();
          },
          onError: (error: any) => {
            console.error('Error:', error);
          },
        },
      );
    }
  };

  return (
    <ScrollView
      style={globalstyles.container}
      contentContainerStyle={{
        height: heightWithHeader,
        alignItems: 'center',
      }}
    >
      <Pressable
        onPress={useOpenImagePicker(setBannerFile)}
        style={styles.imagePickerSection}
      >
        <>
          {user?.Profile_banner?.url || banner_file ? (
            <View style={{ width: maxWebContentWidth }}>
              <Image
                source={
                  !banner_file
                    ? { uri: user?.Profile_banner?.url }
                    : { uri: URL.createObjectURL(banner_file) }
                }
                style={{
                  ...globalstyles.profileBannerImage,
                  backgroundColor: colors.primary,
                  borderRadius: radius.lg,
                  justifyContent: 'flex-end',
                  marginBottom: spacing.md,
                }}
                transition={250}
              />
            </View>
          ) : (
            <View style={{ width: maxWebContentWidth }}>
              <View
                style={{
                  ...globalstyles.profileBannerImage,
                  backgroundColor: colors.primary_offset,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: spacing.md,
                  borderRadius: radius.lg,
                }}
              >
                <FontAwesomeIcon
                  icon={faImage}
                  size={24}
                  color={colors.secondary}
                />
              </View>
            </View>
          )}
        </>
      </Pressable>
      <View
        style={{
          width: webContentWidth * 2,
          paddingHorizontal: spacing.md * 2,
        }}
      >
        <View style={{ marginBottom: spacing.md }}>
          <Text style={globalstyles.smallfade}>Aspect ratio: ~4:1</Text>
        </View>
        <Button_Settings
          callback={useOpenImagePicker(setBannerFile)}
          title={'Select new image'}
          icon={faImage}
          bgColor={colors.primary_offset}
          marginBottom
        />
        {user?.Profile_banner?.url && (
          <Button_Settings
            callback={updateModalVisible}
            title={'Remove banner image'}
            icon={removing ? faSpinner : faTrash}
            iconColor={
              user?.Profile_banner?.url ? colors.alert : colors.secondary
            }
            bgColor={colors.primary_offset}
            marginBottom
          />
        )}
        <View style={{ marginTop: spacing.md, alignSelf: 'center' }}>
          <Button_Submission
            callback={handleEditSubmit}
            title={'Confirm'}
            bgColor={banner_file ? colors.accent : colors.primary_offset}
            loading={isLoading}
            disabled={!banner_file}
          />
        </View>
      </View>
      <Modal_TwoOptions
        cancel={updateModalVisible}
        isVisible={modalVisible}
        title={'Remove banner?'}
        callback1={removeBanner}
        callback2={updateModalVisible}
        text1={'Confirm, remove'}
        icon1={faTrash}
        iconColor1={colors.alert}
        text2={'Nevermind'}
        icon2={faXmark}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  imagePickerSection: {
    width: '100%',
    marginBottom: spacing.md,
    alignItems: 'center',
  },
  imagePicker: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary_accent,
    height: 60,
    width: 60,
    borderRadius: radius.xxl,
    marginRight: spacing.md,
    marginLeft: spacing.md,
  },
});

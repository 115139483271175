import React, { useState, useEffect } from 'react';
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import { colors } from '@/app/colors';
import { useLogout } from '@/api/useLogout';

export function FullScreenLoading() {
  const [showError, setShowError] = useState(false);
  const { handleLogout } = useLogout();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowError(true);
    }, 10000); // 10 seconds

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <View style={styles.container}>
      <Text style={{ color: colors.secondary, fontFamily: 'PressStart2P' }}>
        Loading
      </Text>
      {showError && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorMessage}>
            Taking a long time to load - this is either an internet connectivity
            issue or a little bug in the system.
          </Text>
          <TouchableOpacity onPress={handleLogout}>
            <Text style={styles.errorButton}>
              Click here to return to login
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  errorMessage: {
    color: colors.secondary,
    textAlign: 'center',
  },
  errorButton: {
    color: colors.secondary,
    textDecorationLine: 'underline',
    marginTop: 10,
  },
});

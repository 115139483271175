import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { ProfileResponse } from './zod';
import { unauthenticatedRequest } from '@/modules/auth';
import { useMyProfileStore } from '@/app/store/store';

export const useUserMentionSearch = () => {
  const myInfo = useMyProfileStore((state) => state.myInfo);

  // GENERAL.
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const resetSearch = () => {
    setSearchLoading(false);
    setFetchedUsers([]);
    setSearchQuery('');
  };
  const handleInputChange = (text: string) => {
    setSearchQuery(text);
    if (text !== '@') {
      setSearchLoading(true);
    }
  };

  useEffect(() => {
    fetchUsers();
    return () => {
      fetchUsers.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  // USER SEARCH.
  const [fetchedUsers, setFetchedUsers] = useState<ProfileResponse[]>([]);
  const fetchUsers = debounce(async () => {
    if (searchQuery !== '' && searchQuery !== '@') {
      try {
        setSearchLoading(true);
        const response = await unauthenticatedRequest().get(
          `search/users/${searchQuery.slice(1)}/${myInfo?.id}`,
        );
        setFetchedUsers(response.data);
        setSearchLoading(false);
      } catch (error) {
        console.log(error);
        setSearchLoading(false);
      }
    } else {
      setFetchedUsers([]);
    }
  }, 100);

  return {
    resetSearch,
    handleInputChange,
    searchLoading,
    setSearchLoading,
    searchQuery,
    setSearchQuery,
    fetchedUsers,
  };
};

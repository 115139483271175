import { ProfileResponse } from '@/api/zod';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { Image } from 'expo-image';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { authenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';

export function LikesScreen() {
  const {
    params: { postId, voteable, isDailyThemeRelated },
  } = useRoute<AuthenticatedNavigatorRouteProp<'Likes'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Likes'>>();

  const [users, setUsers] = useState<ProfileResponse[]>();
  const fetchUsers = async () => {
    if (!voteable) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `likes/${postId}/${user?.id}`,
        );
        setUsers(response.data);
      } catch (error) {
        showToast('Error fetching users');
      }
    } else {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `votes/${postId}/${user?.id}`,
        );
        setUsers(response.data);
      } catch (error) {
        showToast('Error fetching users');
      }
    }
  };

  useEffect(() => {
    fetchUsers();
    navigation.setOptions({
      title: isDailyThemeRelated ? 'Votes' : 'Likes',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  const handleNav = (pressedUser: ProfileResponse) => {
    navigation.push('UserProfile', {
      username: pressedUser.username,
    });
  };

  const renderUser = ({ item }: { item: ProfileResponse }) => (
    <TouchableOpacity
      style={{
        ...globalstyles.listItemContainer,
        justifyContent: 'flex-start',
      }}
      onPress={() => handleNav(item)}
    >
      {item.Profile_picture ? (
        <Image
          source={{
            uri: `${item.Profile_picture.url}?tpl=med.jpg`,
          }}
          style={globalstyles.profileImageSmall}
          transition={250}
        />
      ) : (
        <Image
          source={require('../../assets/profile-placeholder.jpg')}
          style={globalstyles.profileImageSmall}
          transition={250}
        />
      )}
      <View style={{ marginLeft: spacing.lg }}>
        <Text style={globalstyles.usernameText}>@{item.username}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={globalstyles.container}>
      <FlatList
        data={users}
        showsVerticalScrollIndicator={false}
        keyExtractor={(u) => u.username}
        style={{ height: heightWithHeader, paddingTop: spacing.lg }}
        renderItem={renderUser}
      />
    </View>
  );
}

import React from 'react';
import { Text, View, StyleSheet, Pressable, FlatList } from 'react-native';
import { BadgeSchema } from '@/api/zod';
import { globalstyles, spacing } from '@/app/globalstyles';
import { ParamListBase } from '@react-navigation/native';
import { Image } from 'expo-image';
import { colors } from '@/app/colors';
import { StackNavigationProp } from '@react-navigation/stack';

type BadgesWidget = {
  callback: () => void;
  badges: BadgeSchema[];
  navigation: StackNavigationProp<ParamListBase>;
  users_id: number | undefined;
};

export const Layout_ProfileBadges = ({
  callback,
  badges,
  users_id,
  navigation,
}: BadgesWidget) => {
  const transformBadges = (badgeData: BadgeSchema[]): BadgeSchema[] => {
    const getHighestIdItem = (category: string) => {
      let highestBadge = null;
      for (const badge of badgeData) {
        if (badge.category === category) {
          if (
            !highestBadge ||
            badge.badge_instance_badge_type_id >
              highestBadge.badge_instance_badge_type_id
          ) {
            highestBadge = badge;
          }
        }
      }
      return highestBadge;
    };

    const dailytheme = badgeData.filter(
      (badge) => badge.category === 'dailytheme',
    );
    const community = badgeData.filter(
      (badge) => badge.category === 'community',
    );
    const likes = getHighestIdItem('total-likes');
    const posts = getHighestIdItem('total-posts');
    const streaks = getHighestIdItem('daily-theme-streak');
    return [...dailytheme, streaks, likes, posts, ...community].filter(
      Boolean,
    ) as BadgeSchema[];
  };

  const filteredBadges = transformBadges(badges);

  const handlePress = (item: BadgeSchema) => {
    navigation.push('ViewBadgeInfo', {
      badge: item,
      users_id: users_id,
    });
    callback();
  };

  return (
    <View style={{ padding: spacing.md }}>
      <View style={{ marginVertical: spacing.md }}>
        <Text style={globalstyles.label}>Badges</Text>
      </View>
      {filteredBadges.length > 0 && (
        <BadgeSection handlePress={handlePress} badges={filteredBadges} />
      )}
    </View>
  );
};

interface BadgesSectionProps {
  badges: BadgeSchema[];
  handlePress: (item: BadgeSchema) => void;
}

const BadgeSection = ({ badges, handlePress }: BadgesSectionProps) => {
  const renderItem = ({ item }: { item: BadgeSchema }) => {
    return <Badge badge={item} handlePress={handlePress} />;
  };
  return (
    <FlatList
      data={badges}
      numColumns={4}
      renderItem={renderItem}
      keyExtractor={(item: BadgeSchema) =>
        item.badge_instance_badge_type_id.toString()
      }
    />
  );
};

interface BadgeProps {
  badge: BadgeSchema;
  handlePress: (item: BadgeSchema) => void;
}

const Badge = ({ badge, handlePress }: BadgeProps) => (
  <Pressable
    disabled={
      badge.badge_instance_badge_type_id === 2 ||
      badge.badge_instance_badge_type_id === 3 ||
      badge.badge_instance_badge_type_id === 4
        ? false
        : true
    }
    style={styles.badge}
    onPress={() => handlePress(badge)}
  >
    <Image
      style={styles.badgeImage}
      contentFit={'contain'}
      transition={100}
      source={{ uri: `${badge.icon.url}?tpl=med` }}
    />
    <Text style={globalstyles.tinybody} numberOfLines={1}>
      {`${badge.count > 1 ? `(${badge.count}) ` : ''}${badge.title}`}
    </Text>
  </Pressable>
);

const styles = StyleSheet.create({
  badge: {
    ...globalstyles.container,
    alignItems: 'center',
    marginBottom: spacing.sm,
    paddingHorizontal: spacing.md,
  },
  badgeImage: {
    ...globalstyles.profileImageSmall,
    backgroundColor: colors.invisible,
    borderRadius: 0,
    marginBottom: spacing.sm,
  },
});

import React from 'react';
import { View, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { Buttons_DownloadApp } from '../unauthenticated/Buttons_DownloadApp';

export function Layout_MobileWebStatic() {
  return (
    <View style={{ marginBottom: spacing.md, alignItems: 'center' }}>
      <View style={{ marginBottom: spacing.lg, alignSelf: 'center' }}>
        <Buttons_DownloadApp />
      </View>
      <Text
        style={{
          ...globalstyles.body,
          textAlign: 'center',
          alignSelf: 'center',
        }}
      >
        Not available on mobile browser, yet.{'\n\n'}Join Whirl on Desktop, or
        through App/Play Store!
      </Text>
    </View>
  );
}

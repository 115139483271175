import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Image } from 'expo-image';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { colors } from '@/app/colors';
import {
  globalstyles,
  heightWithHeader,
  longButtonWidth,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { Button_Submission } from '@/components/Button_Submission';
import { InputBox_WithLabel } from '@/components/InputBox_WithLabel';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { appendFileToFormData, useOpenImagePicker } from '@/api/upload';
import { Subtitle } from '@/components/Subtitle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { PromptListTagModal } from './promptListTagModal';
import { SimpleTagListTag } from '@/api/zod';
import { authenticatedRequest } from '@/modules/auth';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import { Button_Settings } from '@/components/shared/Button_Settings';

export function CreateTagListScreen() {
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'CreateTagList'>>();
  const user = useMyProfileStore((state) => state.myInfo);

  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createNewTagButton, setCreateNewTagButton] = useState(false);
  const [pressedDate, setPressedDate] = useState<number>(0);
  const [allTags, setAllTags] = useState<SimpleTagListTag[]>([]);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(2023);

  const [imageFile, setImageFile] = useState<Blob>();

  useEffect(() => {
    const fetchRelevantYearAndMonth = async () => {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          'global_keys/tag_list_meta',
        );
        setMonth(response.data.month);
        setYear(response.data.year);
      } catch (error) {
        showToast('Error fetching date...');
      }
    };

    fetchRelevantYearAndMonth();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (imageFile) {
      appendFileToFormData(formData, 'file', imageFile);
    }
    const tags_json = JSON.stringify(allTags);
    formData.append('tags_json', tags_json);
    user && formData.append('users_id', user.id.toString());

    try {
      await (
        await authenticatedRequest()
      ).post('tag_lists', formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });
      navigation.navigate('Explore', {
        passedTag: undefined,
      });
      showSuccessToast('Success! Prompt list created.');
    } catch (error) {
      console.error('Error creating tag list:', error);
      showToast('Error creating prompt list');
    } finally {
      setLoading(false);
    }
  };

  const addTag = (tag: SimpleTagListTag) => {
    const updatedTags = allTags.filter(
      (existingTag) => existingTag.day !== tag.day,
    );
    updatedTags.push(tag);
    setSearchQuery('');
    setAllTags(updatedTags);
    setCreateNewTagButton(false);
    updateModalVisible();
  };

  const updateModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  // const removeTag = (day: number) => {
  //   const updatedTags = allTags.filter(
  //     (existingTag) => existingTag.day !== day,
  //   );
  //   setAllTags(updatedTags);
  // };

  const detailsComplete = (): boolean => {
    return !!imageFile && !!title && !!description && allTags.length > 3;
  };

  return (
    <KeyboardAwareScrollView
      style={globalstyles.container}
      contentContainerStyle={styles.contentContainer}
      extraScrollHeight={48}
      enableOnAndroid
    >
      <Subtitle text={'Host a series of daily themes that anyone can join!'} />
      <InputBox_WithLabel
        placeholderText={'Enter title'}
        handleInputChange={setTitle}
        autocapitalize={'sentences'}
        maxLength={50}
        value={title}
        label={'Event title'}
      />
      <InputBox_WithLabel
        placeholderText={'Prompt list description...'}
        handleInputChange={setDescription}
        autocapitalize={'sentences'}
        maxLength={280}
        multiline
        value={description}
        label={'Description'}
      />
      <TouchableOpacity
        style={{
          ...styles.image,
          ...globalstyles.centeringContainer,
          marginBottom: spacing.md,
        }}
        activeOpacity={0.9}
      >
        {imageFile ? (
          <Image
            source={{ uri: URL.createObjectURL(imageFile) }}
            transition={250}
            style={styles.image}
          />
        ) : (
          <View
            style={{
              ...styles.image,
              ...globalstyles.centeringContainer,
              backgroundColor: colors.primary_accent,
            }}
          >
            <FontAwesomeIcon
              icon={faImage}
              size={24}
              color={colors.secondary}
            />
          </View>
        )}
      </TouchableOpacity>
      <View style={{ marginBottom: spacing.md }}>
        <Button_Settings
          callback={useOpenImagePicker(setImageFile)}
          title={'Select your banner image'}
          icon={imageFile ? faCheck : faImage}
          iconColor={imageFile ? colors.accent : colors.secondary}
        />
      </View>
      <Text
        style={{
          ...globalstyles.label,
          marginBottom: spacing.md,
        }}
      >
        Add your daily themes (minimum 4)
      </Text>
      <RenderButtonsForMonth
        month={month}
        year={year}
        tagsForMonth={allTags}
        // removeTag={removeTag}
        handlePress={(day: number) => {
          setPressedDate(day);
          setModalVisible(true);
        }}
      />
      <View style={{ marginVertical: spacing.md, alignSelf: 'center' }}>
        <Button_Submission
          callback={handleSubmit}
          title={'Create prompt list'}
          loading={loading}
          disabled={!detailsComplete()}
          bgColor={detailsComplete() ? colors.accent : colors.primary_accent}
        />
      </View>
      <PromptListTagModal
        visible={modalVisible}
        updateVisible={updateModalVisible}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        year={year}
        month={month}
        day={pressedDate}
        addTag={addTag}
        allTags={allTags}
        createNewTagButton={createNewTagButton}
        setCreateNewTagButton={(bool) => setCreateNewTagButton(bool)}
      />
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    height: heightWithHeader,
    paddingTop: spacing.md,
    paddingBottom: 96,
    marginHorizontal: spacing.md,
    alignSelf: 'center',
    maxWidth: webContentWidth * 2,
  },
  image: {
    height: longButtonWidth,
    width: longButtonWidth,
    backgroundColor: colors.primary_accent,
  },
});

interface DateProps {
  month: number;
  year: number;
  handlePress: (day: number) => void;
  // removeTag: (day: number) => void;
  tagsForMonth: Array<{ day: number; tag_str: string }>;
}

const RenderButtonsForMonth: React.FC<DateProps> = ({
  month,
  year,
  handlePress,
  // removeTag,
  tagsForMonth,
}) => {
  const daysInMonth = getDaysInMonth(month, year);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;

  const isFutureDay = (day: number) =>
    month === currentMonth && day > currentDay;

  const buttons = Array.from({ length: daysInMonth }, (_, i) => i + 1)
    .filter(isFutureDay)
    .map((day) => {
      const tagForDay = tagsForMonth.find((item) => item.day === day);
      const buttonText = tagForDay
        ? `${monthNames[month - 1]} ${day} -> ${tagForDay.tag_str}`
        : `${monthNames[month - 1]} ${day} ->`;

      const iconAndTextColor = tagForDay
        ? colors.high_accent
        : colors.secondary;

      return (
        <Button_Settings
          key={day}
          callback={() => handlePress(day)}
          // cancelCallback={tagForDay ? () => removeTag(day) : undefined}
          title={buttonText}
          icon={tagForDay ? faCheck : faPlus}
          iconColor={iconAndTextColor}
          textColor={iconAndTextColor}
        />
      );
    });

  return <>{buttons}</>;
};

const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import {
  Text,
  StyleSheet,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Pressable,
} from 'react-native';
import { Image } from 'expo-image';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { colors } from '@/app/colors';
import {
  font,
  globalstyles,
  heightWithHeader,
  radius,
  webContentWidth,
  spacing,
  width,
} from '@/app/globalstyles';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { countdown } from '@/components/RelativeDate';
import { updateImage } from '@/api/posts';
import { useMyProfileStore } from '@/app/store/store';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { Subtitle } from '@/components/Subtitle';
import { useOpenImagePicker } from '@/api/upload';
import { StackActions } from '@react-navigation/native';
import { showSuccessToast } from '@/components/ErrorToast';
import { useExploreStore } from '@/app/store/exploreStore';

export function EditPostImageScreen() {
  const {
    params: { post, updateLocalPostList, updateSuperLocalPostList },
  } = useRoute<AuthenticatedNavigatorRouteProp<'EditPostImage'>>();
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'EditPostImage'>>();
  const [loading, setLoading] = useState(false);
  const updatePostInExplorePostList = useExploreStore(
    (state) => state.updatePostInExplorePostList,
  );

  const updatePostInAllPostList = useMyProfileStore(
    (state) => state.updatePostInAllPostList,
  );
  const updatePostInDailyThemePostList = useMyProfileStore(
    (state) => state.updatePostInDailyThemePostList,
  );

  const [file, setFile] = useState<Blob>();
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );

  const handleEditImageSubmit = async () => {
    setLoading(true);
    if (file) {
      const updatedPost = await updateImage(file, post.id);

      // check to see that myprof pic thumbnail is changed.
      updatePostInExplorePostList(updatedPost);
      updatePostInAllPostList(updatedPost);
      updatePostInDailyThemePostList(updatedPost);
      if (updateLocalPostList) {
        updateLocalPostList(updatedPost);
        if (updateSuperLocalPostList) {
          updateSuperLocalPostList(updatedPost.images[0].image.url);
        }
      }
    }

    setLoading(false);
    const pop = StackActions.pop(3);
    navigation.dispatch(pop);
    showSuccessToast('Success!');
  };

  return (
    <KeyboardAwareScrollView
      style={globalstyles.container}
      contentContainerStyle={styles.contentContainer}
      extraScrollHeight={96}
      enableOnAndroid
    >
      <View
        style={{ paddingHorizontal: 24, alignItems: 'center', width: '100%' }}
      >
        <Subtitle text={'Select a new image'} />
        <View style={styles.countdownContainer}>
          <Text style={styles.countdown}>
            {countdown(submissionThemeInfo.submit_by - 3600000)} left to edit...
          </Text>
        </View>
      </View>
      <View style={styles.imagePickerContainer}>
        <Pressable onPress={useOpenImagePicker(setFile)}>
          <Image
            source={
              !file
                ? { uri: post.images[0].image.url }
                : { uri: URL.createObjectURL(file) }
            }
            style={{
              width: width - spacing.lg,
              maxWidth: webContentWidth * 2,
              height: undefined,
              marginBottom: spacing.md,
              borderRadius: 24,
              zIndex: 1,
              aspectRatio:
                (post.images[0].image.meta.width || 1) /
                (post.images[0].image.meta.height || 1),
            }}
            transition={250}
            contentFit={'cover'}
          />
        </Pressable>
        <View>
          <TouchableOpacity
            style={styles.imagePicker}
            onPress={useOpenImagePicker(setFile)}
          >
            <FontAwesomeIcon
              icon={faImage}
              size={24}
              color={colors.secondary}
            />
          </TouchableOpacity>
        </View>
      </View>

      <View
        style={{
          ...globalstyles.centeringContainer,
          width: '100%',
          marginTop: spacing.md,
        }}
      >
        <TouchableOpacity
          style={{
            ...globalstyles.roundedButton,
            ...globalstyles._bg_submit,
            marginBottom: spacing.xl * 2,
          }}
          onPress={handleEditImageSubmit}
          disabled={loading}
        >
          {loading ? (
            <ActivityIndicator size={'small'} color={colors.secondary} />
          ) : (
            <Text style={globalstyles.title}>Confirm edit</Text>
          )}
        </TouchableOpacity>
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    height: heightWithHeader,
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'center',
    maxWidth: webContentWidth * 2,
  },
  imagePickerContainer: {
    width: '100%',
    alignItems: 'center',
  },
  imagePicker: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary_accent,
    height: 60,
    width: 60,
    borderRadius: 36,
  },
  countdownContainer: {
    paddingVertical: spacing.sm,
    paddingHorizontal: spacing.md,
    marginVertical: spacing.md,
    backgroundColor: colors.secondary,
    borderRadius: radius.lg,
    alignSelf: 'center',
  },
  countdown: {
    fontSize: font.lg,
    alignSelf: 'center',
    color: colors.primary,
  },
});

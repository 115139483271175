import React, { useEffect, useState } from 'react';
import {
  View,
  ActivityIndicator,
  FlatList,
  SafeAreaView,
  TouchableOpacity,
  Pressable,
  Text,
} from 'react-native';
import { colors } from '@/app/colors';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { authenticatedRequest } from '@/modules/auth';
import Toast from 'react-native-toast-message';
import { showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { TagList } from '@/api/zod';
import { TagListJoinCard } from '@/components/TagListJoinCard';

export function TagListIndexScreen() {
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'TagListIndex'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [hasMoreTagLists, setHasMoreTagLists] = useState(true);
  const [tagLists, setTagLists] = useState<TagList[]>([]);

  const fetchTagLists = async () => {
    if (!hasMoreTagLists) {
      return;
    }

    setLoading(true);
    if (user) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `tag_lists/index/${page}`,
        );
        if (response.data.length > 0) {
          if (page === 1) {
            setPage(page + 1);
            setTagLists(response.data);
          } else if (tagLists) {
            setPage(page + 1);
            setTagLists([...tagLists, ...response.data]);
          }
        } else {
          setHasMoreTagLists(false);
        }
      } catch (error) {
        showToast('Error fetching Prompt Lists');
      }
    }
    setLoading(false);
    setMounted(true);
  };

  const handleNavigateToTagList = (tagList: TagList) => {
    navigation.push('ViewTagList', { tag_list: tagList });
  };

  const handleCreateNewList = () => {
    if (myPromptListIsIncluded()) {
      const myTagList = tagLists.find(
        (tagList) => tagList.users_id === user?.id,
      );
      if (myTagList) {
        navigation.push('ViewTagList', { tag_list: myTagList });
      }
    } else {
      navigation.navigate('CreateTagList');
    }
  };

  useEffect(() => {
    fetchTagLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTagList = ({ item }: { item: TagList }) => (
    <Pressable>
      <TagListJoinCard
        tagList={item}
        navigation={navigation}
        navigationCallback={() => handleNavigateToTagList(item)}
        index={true}
      />
    </Pressable>
  );

  const renderFooter = () => {
    return loading && hasMoreTagLists ? (
      <View style={{ padding: 20 }}>
        <ActivityIndicator size="small" color={colors.secondary} />
      </View>
    ) : null;
  };

  const keyExtractor = (item: TagList) => item.id.toString();

  const myPromptListIsIncluded = (): boolean => {
    return tagLists.some((tagList) => tagList.users_id === user?.id);
  };

  return (
    <>
      <SafeAreaView style={globalstyles.container}>
        {tagLists.length > 0 && (
          <FlatList
            data={tagLists}
            renderItem={renderTagList}
            keyExtractor={keyExtractor}
            style={{ height: heightWithHeader }}
            contentContainerStyle={{
              paddingTop: spacing.md,
              paddingBottom: spacing.xl * 3,
            }}
            onEndReachedThreshold={0.8}
            onEndReached={fetchTagLists}
            ListFooterComponent={renderFooter}
          />
        )}
        {mounted && (
          <TouchableOpacity
            activeOpacity={0.9}
            style={{
              ...globalstyles.centeringContainer,
              ...globalstyles.roundedButton,
              ...globalstyles._bg_navigate,
              position: 'absolute',
              bottom: spacing.lg * 2,
            }}
            disabled={loading}
            onPress={handleCreateNewList}
          >
            <Text style={{ ...globalstyles.title, color: colors.primary }}>
              {myPromptListIsIncluded()
                ? 'My list'
                : '+ Create new prompt list'}
            </Text>
          </TouchableOpacity>
        )}
      </SafeAreaView>
      <Toast />
    </>
  );
}

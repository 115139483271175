import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import { globalstyles, spacing, width } from '@/app/globalstyles';
import { LeftBackButtonArrow_NoHome } from './LeftBackButtonArrow_NoHome';

type SearchModalHeaderProps = {
  callback: () => void;
  title: string;
};

export function SearchModalHeader({ callback, title }: SearchModalHeaderProps) {
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: width,
        marginVertical: spacing.md,
        marginLeft: spacing.lg,
      }}
    >
      <LeftBackButtonArrow_NoHome callback={callback} />
      <Text
        style={{
          ...globalstyles.title,
          marginLeft: spacing.lg,
        }}
      >
        {title}
      </Text>
      <View style={{ height: 30, width: 36, marginRight: spacing.lg }} />
    </TouchableOpacity>
  );
}

import {
  EMPTYSOCIALLINK,
  EMPTYSTATEUSER,
  GalleryData,
  ProfileData,
  Post,
  ProfileResponse,
} from '@/api/zod';
import { ParamListBase } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { authenticatedRequest, unauthenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';
import { Layout_Profile } from '@/components/profile/Layout_Profile';
import { fetchGalleryData, fetchProfileData } from '@/api/posts';
import { StackNavigationProp } from '@react-navigation/stack';
import { Layout_PageNotFound } from '../shared/Layout_PageNotFound';

interface Props {
  myInfo: ProfileResponse | null;
  username: string;
  navigation: StackNavigationProp<ParamListBase>;
}

export const Layout_UserProfile = ({ myInfo, username, navigation }: Props) => {
  // STATE.
  const [mounted, setMounted] = useState(false);
  const [noUserFound, setNoUserFound] = useState(false);
  const PRELOADUSER = {
    ...EMPTYSTATEUSER,
    username: username,
  };
  const [profileData, setProfileData] = useState<ProfileData>({
    user: PRELOADUSER,
    badges: [],
    tagList: undefined,
    links: [EMPTYSOCIALLINK],
    totalPosts: 0,
    loading: false,
  });

  // GALLERY STATE.
  const [galleryData, setGalleryData] = useState<GalleryData>({
    currentTab: 'all',
    allPostList: [],
    allPage: 2,
    loadingAll: true,
    hasMoreAll: true,
    dailyThemePostList: [],
    dailyThemePage: 2,
    loadingTheme: true,
    hasMoreTheme: true,
  });

  const updateRootPostState = (updatedPost: Post) => {
    setGalleryData((prevGalleryData) => {
      const allPostListIndex = prevGalleryData.allPostList.findIndex(
        (p) => p.id === updatedPost.id,
      );
      const updatedAllPostList =
        allPostListIndex !== -1
          ? [
              ...prevGalleryData.allPostList.slice(0, allPostListIndex),
              updatedPost,
              ...prevGalleryData.allPostList.slice(allPostListIndex + 1),
            ]
          : prevGalleryData.allPostList;
      const dailyThemePostListIndex =
        prevGalleryData.dailyThemePostList.findIndex(
          (p) => p.id === updatedPost.id,
        );
      const updatedDailyThemePostList =
        dailyThemePostListIndex !== -1
          ? [
              ...prevGalleryData.dailyThemePostList.slice(
                0,
                dailyThemePostListIndex,
              ),
              updatedPost,
              ...prevGalleryData.dailyThemePostList.slice(
                dailyThemePostListIndex + 1,
              ),
            ]
          : prevGalleryData.dailyThemePostList;
      return {
        ...prevGalleryData,
        allPostList: updatedAllPostList,
        dailyThemePostList: updatedDailyThemePostList,
      };
    });
  };

  // ACTION HANDLERS.
  const handleFollow = async () => {
    if (profileData.user.isFollowed) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        await authenticatedAxios.delete(
          `follows/${profileData.user?.id}/${myInfo?.id}`,
        );
        setProfileData((prev) => ({
          ...prev,
          user: { ...prev.user, isFollowed: !prev.user.isFollowed },
        }));
      } catch (error) {
        showToast('Error unfollowing');
      }
    } else {
      try {
        const authenticatedAxios = await authenticatedRequest();
        await authenticatedAxios.post('follows', {
          following_users_id: myInfo?.id,
          followed_users_id: profileData.user.id,
          username: profileData.user.username,
        });
        setProfileData((prev) => ({
          ...prev,
          user: { ...prev.user, isFollowed: !prev.user.isFollowed },
        }));
      } catch (error) {
        showToast('Error fetching notifications');
      }
    }
  };

  const moreAllPosts = async () => {
    if (!galleryData.hasMoreAll || galleryData.loadingAll) {
      return;
    }
    setGalleryData((prev) => ({ ...prev, loadingAll: true }));
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `post/user/all/${username}/${myInfo?.id}/${galleryData.allPage}/0`,
      );
      if (response.data.length > 0) {
        setGalleryData((prev) => ({ ...prev, allPage: prev.allPage + 1 }));
        setGalleryData((prev) => ({
          ...prev,
          allPostList: [...prev.allPostList, ...response.data],
        }));
      } else {
        setGalleryData((prev) => ({ ...prev, hasMoreAll: false }));
      }
    } finally {
      setGalleryData((prev) => ({ ...prev, loadingAll: false }));
    }
  };

  const moreThemePosts = async () => {
    if (!galleryData.hasMoreTheme || galleryData.loadingTheme) {
      return;
    }
    setGalleryData((prev) => ({ ...prev, loadingTheme: true }));
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `post/user/daily-theme/${username}/${myInfo?.id}/${galleryData.dailyThemePage}/0`,
      );
      if (response.data.length > 0) {
        setGalleryData((prev) => ({
          ...prev,
          dailyThemePage: prev.dailyThemePage + 1,
        }));
        setGalleryData((prev) => ({
          ...prev,
          dailyThemePostList: [...prev.dailyThemePostList, ...response.data],
        }));
      } else {
        setGalleryData((prev) => ({ ...prev, hasMoreTheme: false }));
      }
    } finally {
      setGalleryData((prev) => ({ ...prev, loadingTheme: false }));
    }
  };

  const hydrateProfilePosts = useCallback(async () => {
    try {
      if (myInfo) {
        const response = await fetchGalleryData(username, myInfo.id);
        setGalleryData(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [myInfo, username]);

  const hydrateProfileData = useCallback(async () => {
    setProfileData((prev) => ({ ...prev, loading: true }));
    try {
      if (myInfo) {
        const response = await fetchProfileData(username, myInfo.id);
        setProfileData({ ...response, loading: false });
      }
    } catch {
      setNoUserFound(true);
      setProfileData((prev) => ({ ...prev, loading: false }));
    } finally {
      setMounted(true);
    }
  }, [myInfo, username]);

  useEffect(() => {
    hydrateProfileData();
    hydrateProfilePosts();
  }, [hydrateProfileData, hydrateProfilePosts]);

  // HELPERS.
  const setCurrentTab = (tab: string) => {
    setGalleryData((prev) => ({ ...prev, currentTab: tab }));
  };

  return (
    <>
      {!noUserFound ? (
        <Layout_Profile
          profileData={profileData}
          galleryData={galleryData}
          setCurrentTab={setCurrentTab}
          fetchAllPosts={moreAllPosts}
          fetchThemePosts={moreThemePosts}
          handleFollow={handleFollow}
          myProfile={false}
          navigation={navigation}
          updateRootPostState={updateRootPostState}
          mounted={mounted}
        />
      ) : (
        <Layout_PageNotFound navigation={navigation} />
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import {
  Text,
  StyleSheet,
  Modal,
  FlatList,
  View,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { debounce } from 'lodash';
import { authenticatedRequest } from '@/modules/auth';
import { SimpleTagListTag, Tag } from '@/api/zod';
import { colors } from '@/app/colors';
import { webContentWidth, spacing, width } from '@/app/globalstyles';
import { SearchModalHeader } from '@/components/SearchModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { InputBox_WithSubmit } from '@/components/InputBox_WithSubmit';
import { Subtitle } from '@/components/Subtitle';
import { getFormattedDateThreeInputs } from '@/components/RelativeDate';

interface Props {
  visible: boolean;
  updateVisible: () => void;
  searchQuery: string;
  setSearchQuery: (tag: string) => void;
  addTag: (tag: SimpleTagListTag) => void;
  allTags: SimpleTagListTag[];
  createNewTagButton: boolean;
  setCreateNewTagButton: (bool: boolean) => void;
  year: number;
  month: number;
  day: number;
  eventTag?: boolean;
}

export const PromptListTagModal = ({
  visible,
  updateVisible,
  searchQuery,
  setSearchQuery,
  year,
  month,
  day,
  addTag,
  allTags,
  createNewTagButton,
  setCreateNewTagButton,
  eventTag,
}: Props) => {
  const [fetchedTags, setFetchedTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTags = debounce(async () => {
    if (searchQuery !== '') {
      try {
        setIsLoading(true);
        const response = await (
          await authenticatedRequest()
        ).get(`tags/${searchQuery}`);

        const exactMatchIndex = response.data.findIndex(
          (tag: Tag) => tag.title.toLowerCase() === searchQuery.toLowerCase(),
        );
        if (exactMatchIndex !== -1) {
          const exactMatch = response.data[exactMatchIndex];
          response.data.splice(exactMatchIndex, 1);
          response.data.unshift(exactMatch);
          setCreateNewTagButton(false);
        } else {
          setCreateNewTagButton(true);
        }

        setFetchedTags(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setFetchedTags([]);
    }
  }, 500);

  const filterFetchedTags = (
    UnfilteredAllTags: SimpleTagListTag[],
    UnfilteredFetchedTags: Tag[],
  ): Tag[] => {
    // no duplicates.
    let allExistingTags = [...UnfilteredAllTags.map((tag) => tag.tag_str)];
    let filteredFetchedTags = UnfilteredFetchedTags.filter(
      (tag: Tag) => !allExistingTags.includes(tag.title.toLowerCase()),
    );
    return filteredFetchedTags;
  };

  const updatedFetchedTags = filterFetchedTags(allTags, fetchedTags);

  useEffect(() => {
    fetchTags();
    return () => {
      fetchTags.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const renderItem = ({ item }: { item: Tag }) => (
    <TouchableOpacity
      style={styles.listItem}
      onPress={() =>
        addTag({ day: day, tag_str: item.title.toLocaleLowerCase().trim() })
      }
    >
      <Text style={{ color: colors.secondary, fontSize: 16 }}>
        {item.title} ({item.usage_count}
        {item.usage_count === 1 ? ' post' : ' posts'})
      </Text>
    </TouchableOpacity>
  );

  const handleTextChange = (text: string) => {
    setSearchQuery(text);
    if (text === '') {
      setCreateNewTagButton(false);
    }
  };

  const renderHeader = () => {
    return createNewTagButton && addTag ? (
      <View>
        <TouchableOpacity
          onPress={() =>
            addTag({
              day: day,
              tag_str: searchQuery.toLocaleLowerCase().trim(),
            })
          }
          style={styles.createTagContainer}
        >
          <FontAwesomeIcon icon={faPlus} color={colors.accent} size={18} />
          <Text
            style={{ color: colors.secondary, marginLeft: 6, fontSize: 16 }}
          >
            Create tag: {searchQuery}
          </Text>
        </TouchableOpacity>
      </View>
    ) : null;
  };

  function getUniqueKey(item: Tag) {
    if (item.id) {
      return item.id;
    } else if (item.tags_id) {
      return item.tags_id;
    } else {
      return 0;
    }
  }

  return (
    <Modal
      animationType="fade"
      transparent={false}
      visible={visible}
      onRequestClose={updateVisible}
    >
      <SafeAreaView style={styles.container}>
        <SearchModalHeader
          callback={updateVisible}
          title={
            eventTag
              ? 'Event tag'
              : getFormattedDateThreeInputs(year, month, day)
          }
        />
        <View style={{ padding: spacing.md }}>
          <Subtitle
            text={
              eventTag
                ? 'Select or create an event tag for your list.'
                : `Select or create a theme for October ${day}.`
            }
          />
        </View>
        <InputBox_WithSubmit
          placeholderText={'Search tag'}
          handleInputChange={handleTextChange}
          autocapitalize={'none'}
          isLoading={isLoading}
          value={searchQuery}
          maxLength={120}
          multiline={false}
          submitCallback={() => {
            setSearchQuery('');
            setFetchedTags([]);
            setCreateNewTagButton(false);
          }}
          submitDisabled={false}
          submitIcon={faTimes}
        />
        {/* <View
          style={{
            ...globalstyles.chipContainer,
            paddingHorizontal: spacing.md * 2,
            marginBottom: spacing.md,
          }}
        >
          {allTags.map((tag, index) => (
            <Pressable
              key={index}
              disabled={isLoading}
              style={{
                ...globalstyles.chip,
                ...globalstyles._selectedChip,
                marginRight: spacing.sm,
                alignItems: 'center',
              }}
              onPress={() => handleRemoveTag(tag)}
            >
              <Text
                style={{ ...globalstyles.smallbody, marginRight: spacing.sm }}
              >
                {tag}
              </Text>
              <FontAwesomeIcon
                icon={faTimes}
                color={colors.halfwhite}
                size={10}
              />
            </Pressable>
          ))}
        </View> */}
        <FlatList
          data={updatedFetchedTags}
          keyboardShouldPersistTaps="handled"
          keyExtractor={(item) => getUniqueKey(item).toString()}
          renderItem={renderItem}
          ListHeaderComponent={renderHeader}
        />
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingHorizontal: spacing.md,
  },
  listItem: {
    width: width - spacing.lg * 2,
    maxWidth: webContentWidth * 2,
    padding: 16,
    backgroundColor: colors.primary_accent,
    marginTop: 4,
    borderRadius: 12,
  },
  createTagContainer: {
    backgroundColor: colors.primary_accent,
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: width - spacing.md * 3,
    maxWidth: webContentWidth * 2,
    borderRadius: 8,
    marginBottom: 8,
  },
});

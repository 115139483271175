import React from 'react';
import { StyleSheet, View, FlatList, Text, Pressable } from 'react-native';
import { ProfileResponse, Tag, Technology } from '@/api/zod';
import { colors } from '@/app/colors';
import {
  globalstyles,
  maxWebContentWidth,
  radius,
  spacing,
} from '@/app/globalstyles';
import {
  Input_WebSearch,
  RenderListItem,
} from '@/components/explore/Layout_WebExploreSearch';
import Modal from 'react-native-modal';
import { Layout_TechnologyChips } from './Layout_TechnologyChips';
import { ListItem_User } from '../shared/ListItem_User';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import { Button_HotDog } from '../shared/Button_HotDog';
import { Button_ModalClose } from '../shared/Button_ModalClose';

interface Props {
  visible: boolean;
  toggleVisible: () => void;
  close: () => void;
  handleClickTag: (tag: Tag) => void;
  fetchedTags: Tag[];
  fetchedUsers: ProfileResponse[];
  handleTextChange: (text: string) => void;
  searchTech: (tech: Technology) => void;
  isUserSearch: () => boolean;
  searchLoading: boolean;
  searchQuery: string;
  navigation: StackNavigationProp<ParamListBase>;
  searchHasPrompts: () => void;
  searchRandom: () => void;
  resetSearch: () => void;
}

const Modal_Search = ({
  visible,
  toggleVisible,
  close,
  handleClickTag,
  fetchedTags,
  fetchedUsers,
  handleTextChange,
  searchTech,
  isUserSearch,
  searchLoading,
  searchQuery,
  navigation,
  searchHasPrompts,
  searchRandom,
  resetSearch,
}: Props) => {
  const renderTag = ({ item }: { item: Tag }) => {
    return <RenderListItem item={item} handleClickTag={handleClickTag} />;
  };
  const goToUser = (item: ProfileResponse) => {
    toggleVisible();
    setTimeout(() => {
      navigation.push('UserProfile', {
        username: item.username,
      });
      resetSearch();
    }, 250);
  };
  const renderUser = ({ item }: { item: ProfileResponse }) => (
    <ListItem_User
      callback={() => goToUser(item)}
      navigation={navigation}
      pressedUser={item}
      subtitle={item.created_at}
    />
  );
  const renderNoResultsFooter = () => {
    return (
      <Pressable>
        <Text style={globalstyles.usernameText}>No results..</Text>
      </Pressable>
    );
  };
  const returnedTags = fetchedTags.filter((tag) => tag.usage_count > 0);
  return (
    <Modal
      isVisible={visible}
      style={styles.modal}
      hideModalContentWhileAnimating={true}
      onBackdropPress={close}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
    >
      <Button_ModalClose close={close} />
      <View style={styles.modalContent}>
        <Input_WebSearch
          placeholderText={'Search tags and @users...'}
          cancelCallback={resetSearch}
          editable={true}
          loading={searchLoading}
          handleInputChange={handleTextChange}
          value={searchQuery}
        />
        {searchQuery === '' || searchQuery === '@' ? (
          <>
            <Layout_TechnologyChips searchTech={searchTech} />
            <Layout_ExtraFilters
              searchHasPrompts={searchHasPrompts}
              searchRandom={searchRandom}
            />
          </>
        ) : (
          <>
            {isUserSearch() ? (
              <FlatList
                data={fetchedUsers}
                keyExtractor={(item) => item.id.toString()}
                renderItem={renderUser}
                style={{ padding: spacing.md }}
                ListFooterComponent={
                  searchQuery !== '' &&
                  !searchLoading &&
                  fetchedUsers.length === 0
                    ? renderNoResultsFooter
                    : () => null
                }
              />
            ) : (
              <FlatList
                data={returnedTags}
                keyExtractor={(item) => item.title.toString()}
                renderItem={renderTag}
                style={{ padding: spacing.md }}
                ListFooterComponent={
                  !searchLoading &&
                  searchQuery !== '' &&
                  fetchedTags.length === 0
                    ? renderNoResultsFooter
                    : null
                }
              />
            )}
          </>
        )}
      </View>
    </Modal>
  );
};

interface ExtraProps {
  searchHasPrompts: () => void;
  searchRandom: () => void;
}

const Layout_ExtraFilters = ({
  searchHasPrompts,
  searchRandom,
}: ExtraProps) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: spacing.sm,
      }}
    >
      <View style={{ marginRight: spacing.sm }}>
        <Button_HotDog
          callback={searchHasPrompts}
          title={'Posts with prompts'}
          brightText
          roundie
        />
      </View>
      <Button_HotDog
        callback={searchRandom}
        title={'Random'}
        brightText
        roundie
      />
    </View>
  );
};

const styles = StyleSheet.create({
  searchButtonWrapper: {
    borderRadius: radius.sm,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modal: {
    justifyContent: 'center',
    margin: 0,
    width: '100%',
    alignSelf: 'center',
  },
  modalContent: {
    backgroundColor: colors.primary,
    borderColor: colors.primary_accent,
    borderWidth: 2,
    padding: spacing.md,
    borderRadius: radius.md,
    maxHeight: '60%',
    width: '50%',
    minWidth: maxWebContentWidth / 2,
    maxWidth: maxWebContentWidth,
    alignSelf: 'center',
  },
});

export default Modal_Search;

import React from 'react';
import { View } from 'react-native';
import { globalstyles } from '@/app/globalstyles';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { authenticatedRequest } from '@/modules/auth';
import { showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import { WebNav_DailyThemeheader } from '@/components/daily theme/WebNav_DailyThemeHeader';
import Poll_Web from '@/components/daily theme/Poll_Web';

export function VoteNextThemeScreen() {
  const setVotingComplete = useDailyThemeStore(
    (state) => state.setVotingComplete,
  );
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'VoteNextTheme'>>();
  const route = useRoute();
  const routeName = route.name;
  const votingComplete = useDailyThemeStore((state) => state.votingComplete);
  const dailyThemesUpForVote = useDailyThemeStore(
    (state) => state.dailyThemesUpForVote,
  );
  const setDailyThemesUpForVote = useDailyThemeStore(
    (state) => state.setDailyThemesUpForVote,
  );
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );

  const handleVoteForTomorrowTheme = async (id: number) => {
    const updatedDailyThemes = [...dailyThemesUpForVote];
    updatedDailyThemes[id].userHasVotedFor = true;
    updatedDailyThemes[id].votes = updatedDailyThemes[id].votes + 1;

    try {
      await (
        await authenticatedRequest()
      ).post('daily_theme_event_votes', {
        users_id: myInfo?.id,
        daily_theme_event_id: updatedDailyThemes[id].id,
      });
      setDailyThemesUpForVote(updatedDailyThemes);
      setVotingComplete(true);
    } catch (error) {
      showToast('Error voting');
    }
  };

  const totalVotes =
    dailyThemesUpForVote[0].votes + dailyThemesUpForVote[1].votes;
  const image1percent = Math.round(
    (dailyThemesUpForVote[0].votes / totalVotes) * 100,
  );
  const image2percent = Math.round(
    (dailyThemesUpForVote[1].votes / totalVotes) * 100,
  );

  // const votedThemeTitle = dailyThemesUpForVote.filter(
  //   (theme) => theme.userHasVotedFor === true,
  // );

  return (
    <>
      <View style={{ ...globalstyles.container, flexDirection: 'row' }}>
        <Poll_Web
          theme={dailyThemesUpForVote[0]}
          callback={() => handleVoteForTomorrowTheme(0)}
          index={0}
          navigation={navigation}
          percent={image1percent}
          votingComplete={votingComplete}
        />
        <Poll_Web
          theme={dailyThemesUpForVote[1]}
          callback={() => handleVoteForTomorrowTheme(1)}
          index={1}
          navigation={navigation}
          percent={image2percent}
          votingComplete={votingComplete}
        />
      </View>
      <WebNav_DailyThemeheader
        activeRoute={routeName}
        navigation={navigation}
        loggedInUserHasSubmitted={submissionThemeInfo.loggedInUserHasSubmitted}
      />
    </>
  );
}

import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { Button_HotDog } from '@/components/shared/Button_HotDog';
import { Button_SearchBar } from '@/components/explore/Button_SearchBar';
import { formattedDate } from '../RelativeDate';
import { colors } from '@/app/colors';
import Button_FollowingFilter from '../shared/Button_FollowingFilter';

interface RenderHeaderProps {
  activeTab: string;
  handleFetchTopWeekly: () => void;
  handleFetchRecent: () => void;
  handleSearchFollowing: () => void;
  toggleModal: () => void;
  searchBarText: string;
}

export const Layout_Header = ({
  activeTab,
  handleFetchTopWeekly,
  handleFetchRecent,
  handleSearchFollowing,
  toggleModal,
  searchBarText,
}: RenderHeaderProps) => {
  const now = new Date().getTime();
  return (
    <View
      style={{
        ...globalstyles.container,
        width: '100%',
        marginBottom: spacing.md,
        alignSelf: 'center',
      }}
    >
      <View
        style={{
          margin: spacing.xl,
          alignSelf: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Text style={globalstyles.logoText}>Welcome!</Text>
        <Text style={globalstyles.usernameText}>{formattedDate(now)}</Text>
      </View>
      <View style={styles.exploreHeaderContainer}>
        <Filters
          activeTab={activeTab}
          fetchTop={handleFetchTopWeekly}
          fetchRecent={handleFetchRecent}
          fetchFollowing={handleSearchFollowing}
        />
        <Button_SearchBar
          toggleModal={toggleModal}
          searchBarText={searchBarText}
        />
      </View>
    </View>
  );
};

interface FilterProps {
  activeTab: string;
  fetchTop: () => void;
  fetchRecent: () => void;
  fetchFollowing: () => void;
}

const Filters = ({
  activeTab,
  fetchTop,
  fetchRecent,
  fetchFollowing,
}: FilterProps) => {
  return (
    <View style={styles.filterContainer}>
      <View style={{ marginRight: spacing.sm }}>
        <Button_HotDog
          title={'Featured'}
          callback={fetchTop}
          bgColor={
            activeTab === 'topweekly' ? colors.primary_accent : colors.primary
          }
          brightText={activeTab === 'topweekly'}
          roundie
        />
      </View>
      <View style={{ marginRight: spacing.sm }}>
        <Button_HotDog
          title={'Recent'}
          callback={fetchRecent}
          brightText={activeTab === 'recent'}
          bgColor={
            activeTab === 'recent' ? colors.primary_accent : colors.primary
          }
          roundie
        />
      </View>
      <Button_FollowingFilter
        fetchFollowing={fetchFollowing}
        activeTab={activeTab === 'following'}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  filterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  exploreHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

import { ENV } from '@env';

export type AppEnvironment = 'DEV' | 'PROD';
const APP_ENV = (ENV || 'DEV') as AppEnvironment;

export type AppConfiguration = {
  baseURL: string;
  secure: boolean;
};

const configurations: Record<AppEnvironment, AppConfiguration> = {
  DEV: {
    baseURL: 'https://roz.joinwhirl.com/api:Wh7gWrFD',
    secure: true,
  },
  PROD: {
    baseURL: 'https://roz.joinwhirl.com/api:Wh7gWrFD',
    secure: true,
  },
};

export const AppConfig = configurations[APP_ENV];

import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { globalstyles } from '@/app/globalstyles';

type NavigationButtonProps = {
  callback: () => void;
  title: string;
  textColor?: string;
  bgColor?: string;
};

export function Button_Navigation({
  callback,
  title,
  bgColor,
  textColor,
}: NavigationButtonProps) {
  return (
    <TouchableOpacity
      style={
        !bgColor
          ? globalstyles.roundedButton
          : { ...globalstyles.roundedButton, backgroundColor: bgColor }
      }
      onPress={callback}
      activeOpacity={0.9}
    >
      <Text
        style={
          !textColor
            ? globalstyles.title
            : { ...globalstyles.title, color: textColor }
        }
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
}

import { colors } from '@/app/colors';
import {
  font,
  globalstyles,
  heightWithHeader,
  spacing,
} from '@/app/globalstyles';
import React from 'react';
import { StyleSheet, Text, ScrollView } from 'react-native';
import { termsOfUseStore } from '../app/store/termsOfUseStore';

export function TermsOfUseScreen() {
  return (
    <ScrollView
      style={{ padding: spacing.lg, ...globalstyles.container }}
      contentContainerStyle={{ height: heightWithHeader }}
    >
      <Text
        style={{
          ...globalstyles.title,
          ...globalstyles._font_lg,
          alignSelf: 'flex-start',
        }}
      >
        Terms of Use
      </Text>
      <Text
        style={{
          ...globalstyles.title,
          alignSelf: 'flex-start',
          marginBottom: spacing.md,
        }}
      >
        Effective September 1, 2023
      </Text>
      {termsOfUseStore.map((item, index) => (
        <React.Fragment key={index}>
          <Text style={styles.headerText}>{item.header}</Text>
          <Text style={{ ...globalstyles.body, marginBottom: spacing.lg }}>
            {item.body}
          </Text>
        </React.Fragment>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  headerText: {
    fontSize: font.xl,
    fontWeight: 'bold',
    marginBottom: spacing.md,
    color: colors.secondary,
  },
});

import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Image, ImageSource } from 'expo-image';
import { webContentWidth, spacing } from '@/app/globalstyles';

type Props = {
  logo:
    | string
    | number
    | ImageSource
    | ImageSource[]
    | string[]
    | null
    | undefined;
  callback: () => void;
};

export function Button_DownloadApp({ logo, callback }: Props) {
  return (
    <TouchableOpacity onPress={callback}>
      <Image
        style={styles.logoImage}
        source={logo}
        contentFit={'contain'}
        transition={250}
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  logoImage: {
    width: webContentWidth - spacing.md * 2, // change?
    height: 60,
  },
});

import { useMyProfileStore } from '@/app/store/store';
import { authenticatedRequest } from '@/modules/auth';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';

type UpdateUsernameArgs = {
  users_id: number;
  username: string;
};

export const useEditUsername = () => {
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'EditUsername'>>();

  async function updateUsername(args: UpdateUsernameArgs) {
    const formData = new FormData();
    formData.append('users_id', args.users_id.toString());
    formData.append('username', args.username);

    const response = await (
      await authenticatedRequest()
    ).post('user/edit/username', formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });

    if (response.data === 'success') {
      const newMyInfo: any = {
        ...myInfo,
        username: args.username,
      };
      setMyInfo(newMyInfo);
      navigation.goBack();
    }

    return response.data;
  }

  return { updateUsername };
};

import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, SafeAreaView, ScrollView } from 'react-native';
import { Image } from 'expo-image';
import { useExpStore, useMyProfileStore } from '@/app/store/store';
import { colors } from '@/app/colors';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import {
  globalstyles,
  font,
  radius,
  spacing,
  webContentWidth,
  heightWithHeader,
} from '@/app/globalstyles';
import { authenticatedRequest } from '@/modules/auth';
import { BadgeSchema } from '@/api/zod';
import { showToast } from '@/components/ErrorToast';
import { LVLS } from '@/api/levels';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faSignal } from '@fortawesome/free-solid-svg-icons/faSignal';
import { faTrophy } from '@fortawesome/free-solid-svg-icons/faTrophy';
import { faMedal } from '@fortawesome/free-solid-svg-icons/faMedal';
import { Button_Settings } from '@/components/shared/Button_Settings';

export function LevelsScreen() {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Levels'>>();
  const [totalSubmissions, setTotalSubmissons] = useState<number>();
  const myProfileData = useMyProfileStore((state) => state.myProfileData);
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const fetchExpRecords = useExpStore((state) => state.fetchExpRecords);

  const fetchStats = async () => {
    if (myInfo) {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `user_stats/total-daily-theme-submissions/${myInfo.id}`,
        );
        setTotalSubmissons(response.data.total_posts);
        if (response.data.level && myInfo) {
          const myInfoWithNewLevel = { ...myInfo, level: response.data.level };
          setMyInfo(myInfoWithNewLevel);
        }
      } catch (error) {
        showToast('Error fetching total submissions');
      }
    }
  };

  useEffect(() => {
    fetchStats();
    fetchExpRecords(myInfo);
    // myInfo && fetchBadges(myInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // calculate that sweet sweet exp.
  const currentLevel = myInfo?.level || 1;
  const currentLevelInfo = LVLS.find((lvl) => lvl.level === currentLevel);
  const currentLevelExp = currentLevelInfo ? currentLevelInfo.exp : 0;
  const nextLevelInfo = LVLS.find((lvl) => lvl.level === currentLevel + 1);
  const nextLevelExp = nextLevelInfo ? nextLevelInfo.exp : 0;
  const progressWithinLevel = (myInfo?.experience || 0) - currentLevelExp;
  const expToNextLevel = nextLevelExp - currentLevelExp;
  const progressPercentage = progressWithinLevel / expToNextLevel;
  const progressBarStyle = StyleSheet.create({
    progressBar: {
      height: 20,
      marginHorizontal: spacing.xs,
      width: webContentWidth * 2 * progressPercentage - spacing.md,
      minWidth: webContentWidth * 2 * 0.1,
      zIndex: 4,
      backgroundColor: 'orange',
      borderRadius: radius.xl,
    },
  });

  const calculateExpString = () => {
    return (
      <View style={{ alignSelf: 'center', marginBottom: spacing.md }}>
        <Text style={globalstyles.body}>
          {myInfo?.experience} / {nextLevelExp} EXP
        </Text>
      </View>
    );
  };

  const renderExpItem = (title: string, amount: number | undefined) => {
    return (
      <View style={styles.itemContainer}>
        <Text style={globalstyles.body}>{title}</Text>
        <Text style={globalstyles.label}>{amount}</Text>
      </View>
    );
  };

  const renderBadge = (item: BadgeSchema, index: number) => {
    return (
      <View style={styles.itemContainer} key={index}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Image
            source={{ uri: item.icon.url }}
            contentFit={'contain'}
            style={styles.icon}
            transition={250}
          />
          <Text style={globalstyles.body}>{item.title}</Text>
        </View>
        <Text style={globalstyles.label}>{item.count}</Text>
      </View>
    );
  };

  return (
    <SafeAreaView
      style={{
        ...globalstyles.container,
        paddingHorizontal: spacing.md,
        alignItems: 'center',
      }}
    >
      <ScrollView
        contentContainerStyle={{
          paddingVertical: spacing.xl,
          maxWidth: webContentWidth * 2,
        }}
        style={{ height: heightWithHeader }}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.sectionContainer}>
          <Text
            style={{
              ...globalstyles.title,
              fontSize: font.xxxl,
              marginBottom: spacing.md,
            }}
          >
            Level {myInfo?.level}
          </Text>
          <View style={styles.progressBarContainer}>
            {calculateExpString()}
            <View style={{ justifyContent: 'center' }}>
              <View style={styles.progressBarBackground} />
              <View style={progressBarStyle.progressBar} />
            </View>
          </View>
          <Button_Settings
            callback={() => navigation.push('ExpLog')}
            title={'Exp. log'}
            icon={faTasks}
            bgColor={colors.primary_accent}
          />
          <Button_Settings
            callback={() => navigation.push('ExpList')}
            title={'Exp. list'}
            icon={faBars}
            bgColor={colors.primary_accent}
          />
          <Button_Settings
            callback={() => navigation.push('LevelsList')}
            title={'Levels list'}
            icon={faSignal}
            bgColor={colors.primary_accent}
          />
        </View>

        <View style={styles.sectionContainer}>
          <Text style={{ ...globalstyles.title, marginBottom: spacing.lg }}>
            Daily theme
          </Text>
          <View style={{ width: '100%' }}>
            {renderExpItem('Total daily theme submissions', totalSubmissions)}
            {renderExpItem('Current streak', myInfo?.daily_theme_streak)}
            {renderExpItem(
              'Highest all-time streak',
              myInfo?.pr_daily_theme_streak,
            )}
          </View>
          <Button_Settings
            callback={() => navigation.push('Leaderboard')}
            title={'Global Leaderboard'}
            icon={faTrophy}
            bgColor={colors.primary_accent}
          />
        </View>
        <View>
          <View style={{ marginBottom: spacing.lg }}>
            <Text style={globalstyles.title}>My Badges</Text>
          </View>
          <View style={{ width: '100%' }}>
            {myProfileData.badges.map(renderBadge)}
          </View>
          <Button_Settings
            callback={() => navigation.push('Badges')}
            title={'All Badges'}
            icon={faMedal}
            bgColor={colors.primary_accent}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  sectionContainer: {
    borderBottomColor: colors.primary_accent,
    borderBottomWidth: 2,
    paddingBottom: spacing.lg,
    marginBottom: spacing.lg,
  },
  progressBarContainer: {
    width: webContentWidth * 2 - spacing.md * 2,
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: spacing.md,
  },
  progressBarBackground: {
    height: 28,
    width: webContentWidth * 2 - spacing.md * 2,
    position: 'absolute',
    zIndex: 4,
    backgroundColor: colors.accent,
    borderRadius: radius.lg,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing.md,
  },
  icon: {
    width: 36,
    height: 36,
    marginRight: 6,
  },
});

import Toast from 'react-native-toast-message';

export const showToast = (textMsg: string) => {
  Toast.show({
    type: 'error',
    text1: 'Error',
    text2: textMsg,
    position: 'bottom',
  });
};

export const showSuccessToast = (textMsg: string) => {
  Toast.show({
    type: 'success',
    text1: 'Success',
    text2: textMsg,
    position: 'top',
  });
};

export const showErrorToastTop = (header: string, body: string) => {
  Toast.show({
    type: 'error',
    text1: header,
    text2: body,
    position: 'top',
  });
};

import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
  Text,
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { Image } from 'expo-image';
import { relativeDate } from '@/components/RelativeDate';
import { showToast } from '@/components/ErrorToast';
import Toast from 'react-native-toast-message';
import { authenticatedRequest } from '@/modules/auth';
import { EMPTYSTATENOTIFICATION, Notification } from '@/api/zod';
import { useMyProfileStore } from '@/app/store/store';
import { colors } from '@/app/colors';
// import { PopupNotificationModal } from './popupNotificationModal';
import {
  globalstyles,
  heightWithHeader,
  maxWebContentWidth,
  radius,
  spacing,
} from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { Text_WebPageHeader } from '@/components/Text_WebPageHeader';

export function NotificationsScreen() {
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'Notifications'>>();
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const [notifications, setNotifications] = useState<Notification[]>([
    EMPTYSTATENOTIFICATION,
  ]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMoreNotifications, setHasMoreNotifications] = useState(true);
  const [checkTime] = useState(myInfo?.lastNotificationCheckTime);

  // const [popupVisible, setPopupVisible] = useState(false);
  // const [popupBody, setPopupBody] = useState('');
  // const updatePopupVisible = (body: string) => {
  //   setPopupBody(body);
  //   setPopupVisible(!popupVisible);
  // };

  const fetchNotifications = async () => {
    if (!hasMoreNotifications) {
      return;
    }
    setLoading(true);
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `notification/user/${myInfo?.id}/${page}`,
      );
      if (response.data.length > 0) {
        setPage(page + 1);
        if (page === 1) {
          setNotifications(response.data);
        } else {
          setNotifications([...notifications, ...response.data]);
        }
      } else {
        setHasMoreNotifications(false);
      }
      if (myInfo) {
        const updatedInfo = {
          ...myInfo,
          lastNotificationCheckTime: Date.now(),
        };
        setMyInfo(updatedInfo);
      }
    } catch (error) {
      console.log('Error: ', error);
      showToast('Error fetching notifications');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNotificationPress = (notification: Notification) => {
    if (notification.linkPost) {
      navigation.push('ViewSinglePost', { postId: notification.long_id });
    }
  };

  const renderNotification = ({ item }: { item: Notification }) => (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{ ...globalstyles.listItemContainer, width: '100%' }}
      onPress={() => handleNotificationPress(item)}
    >
      {checkTime && checkTime > item.created_at ? (
        <View
          style={{
            ...styles.notificationIndicator,
            backgroundColor: colors.invisible,
          }}
        />
      ) : (
        <View
          style={{
            ...styles.notificationIndicator,
            backgroundColor: colors.accent,
          }}
        />
      )}
      {item.linkUser ? (
        <TouchableOpacity
          onPress={() =>
            navigation.push('UserProfile', {
              username: item.username,
            })
          }
        >
          {item.Profile_picture?.url ? (
            <Image
              style={{
                ...globalstyles.profileImageSmall,
                marginRight: spacing.md,
              }}
              source={{ uri: `${item.Profile_picture?.url}?tpl=med.jpg` }}
              transition={250}
            />
          ) : (
            <Image
              style={{
                ...globalstyles.profileImageSmall,
                marginRight: spacing.md,
              }}
              source={require('../../assets/profile-placeholder.jpg')}
              transition={250}
            />
          )}
        </TouchableOpacity>
      ) : (
        <Image
          style={{ ...globalstyles.profileImageSmall, marginRight: spacing.md }}
          source={require('../../assets/ultron.jpg')}
          transition={250}
        />
      )}
      <View style={{ flex: 1 }}>
        <Text style={{ ...globalstyles.body, marginBottom: spacing.xs }}>
          {item.body}
        </Text>
        <Text style={globalstyles.smallfade}>
          {relativeDate(item.created_at)}
        </Text>
      </View>
      {item.linkPost && (
        <TouchableOpacity
          style={{ padding: spacing.md }}
          onPress={() =>
            navigation.push('ViewSinglePost', { postId: item.long_id })
          }
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            color={colors.secondary}
            size={16}
          />
        </TouchableOpacity>
      )}
    </TouchableOpacity>
  );

  const renderFooter = () => {
    return loading && hasMoreNotifications ? (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="large" color={colors.secondary} />
      </View>
    ) : null;
  };

  const keyExtractor = (item: Notification) => item.id.toString();

  return (
    <>
      <View style={globalstyles.container}>
        {notifications[0].id !== 0 && (
          <FlatList
            data={notifications}
            style={styles.listContainer}
            contentContainerStyle={styles.contentContainer}
            renderItem={renderNotification}
            keyExtractor={keyExtractor}
            onEndReached={fetchNotifications}
            onEndReachedThreshold={0.8}
            ListFooterComponent={renderFooter}
            ListHeaderComponent={
              <Text_WebPageHeader header={'Notifications'} subtitle={''} />
            }
          />
        )}
      </View>
      <Toast />
    </>
  );
}

const styles = StyleSheet.create({
  notificationIndicator: {
    width: spacing.sm,
    height: spacing.sm,
    borderRadius: radius.lg,
    marginRight: spacing.md,
  },
  listContainer: {
    height: heightWithHeader,
    width: '100%',
    paddingTop: spacing.lg,
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignSelf: 'center',
    maxWidth: maxWebContentWidth / 2,
  },
});

import { LVLS } from '@/api/levels';
import { globalstyles, heightWithHeader } from '@/app/globalstyles';
import { useMyProfileStore } from '@/app/store/store';
import { Text_ListHeader } from '@/components/Text_ListHeader';
import React from 'react';
import { Text, View, FlatList, SafeAreaView } from 'react-native';

export function LevelsListScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const renderItem = ({ item }: { item: any }) => (
    <View style={globalstyles.listItemContainer}>
      <View>
        <Text style={globalstyles.body}>Level {item.level}</Text>
      </View>
      <Text style={globalstyles.label}>+{item.exp}</Text>
    </View>
  );

  const listHeader = () => {
    return <Text_ListHeader text={'All the levels'} />;
  };

  return (
    <SafeAreaView style={globalstyles.container}>
      {user && (
        <FlatList
          data={LVLS}
          renderItem={renderItem}
          ListHeaderComponent={listHeader}
          style={{ height: heightWithHeader }}
          initialNumToRender={LVLS.length}
          keyExtractor={(item, index) => index.toString()}
        />
      )}
    </SafeAreaView>
  );
}

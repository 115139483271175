import React from 'react';
import { StyleSheet, View } from 'react-native';
import { spacing } from '@/app/globalstyles';
import withAuthPopup from '../shared/hoc/withAuthPopup';
import { Button_HotDog } from '../shared/Button_HotDog';
import { colors } from '@/app/colors';

interface Buttons_SignupCTAProps {
  toggleModal?: () => void;
  isVisitor?: boolean;
}

const Buttons_SignupCTA: React.FC<Buttons_SignupCTAProps> = ({
  toggleModal,
  isVisitor,
}) => {
  return (
    <View style={styles.wrapper}>
      <Button_HotDog
        title={'Sign in'}
        callback={isVisitor && toggleModal ? toggleModal : () => null}
        bgColor={colors.primary_offset_2}
        bgHover={colors.primary_accent}
        bgHighlight={colors.primary_light}
        brightText
        roundie
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: spacing.lg,
  },
});

export default withAuthPopup(Buttons_SignupCTA);

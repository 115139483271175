import React, { useState } from 'react';
import { View } from 'react-native';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import {
  EMPTYTECHNOLOGY,
  Post,
  ProfileResponse,
  Tag,
  Technology,
} from '@/api/zod';
import { ParamListBase } from '@react-navigation/native';
import { useFileUpload } from '@/api/upload';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { Icon_ThumbnailPreview } from '../shared/Icon_ThumbnailPreview';
import { InputBox_WithLabel } from '../InputBox_WithLabel';
import { Layout_TechnologyChips } from '../Layout_TechnologyChips';
import { Layout_CreatePostTags } from '../Layout_CreatePostTags';
import { SearchTagModal } from '@/screens/searchTagsModal';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Modal_PreviewPost } from '../Modal_PreviewPost';
import { Button_WebBasic } from '../Button_WebBasic';
import Modal_TwoOptions from '../shared/Modal_TwoOptions';
import { StackNavigationProp } from '@react-navigation/stack';
import Toast from 'react-native-toast-message';
import { showSuccessToast } from '../ErrorToast';

interface UploadDetailsProps {
  file: Blob | undefined;
  setFile?: (newFile: Blob | undefined) => void;
  post: Post | undefined;
  loading: boolean;
  technologies: Technology[];
  selectedTechnology: Technology;
  setSelectedTechnology: (technology: Technology) => void;
  allTags: string[];
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
  navigation: StackNavigationProp<ParamListBase>;
  setLoading: (value: boolean) => void;
  myInfo: ProfileResponse;
  daily_theme_id: number | null;
  resetTags: () => void;
  handleDeletePost?: () => void;
  updatePostInGlobalLists?: (post: Post) => void;
  updateLocalPostList?: (post: Post) => void;
}

export const Layout_WebUploadDetails = ({
  file,
  setFile,
  post,
  loading,
  technologies,
  selectedTechnology,
  setSelectedTechnology,
  allTags,
  addTag,
  removeTag,
  navigation,
  setLoading,
  myInfo,
  daily_theme_id,
  resetTags,
  handleDeletePost,
  updatePostInGlobalLists,
}: // updateLocalPostList,
UploadDetailsProps) => {
  const [title, setTitle] = useState(post ? post.title : '');
  const [prompt, setPrompt] = useState(post ? post.prompt : '');
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [createNewTagButtonVisible, setCreateNewTagButtonVisible] =
    useState(false);
  const updateSearchModalVisible = () => {
    setSearchQuery('');
    setCreateNewTagButtonVisible(false);
    setSearchModalVisible(!searchModalVisible);
  };
  const updateDeleteModalVisible = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };
  const updatePreviewModalVisible = () => {
    setPreviewModalVisible(!previewModalVisible);
  };
  const handleAddTag = (tag: Tag | string) => {
    const tagName = typeof tag === 'string' ? tag : tag.title;
    setSearchQuery('');
    addTag(tagName);
    setCreateNewTagButtonVisible(false);
    if (allTags.length > 5) {
      updateSearchModalVisible();
    }
  };

  // MISC.
  // const currentTime = new Date();
  // const submitByTime = new Date(submissionThemeInfo.submit_by - 3600000);
  // const navigateToEditImage = () => {
  //   if (updateLocalPostList) {
  //     navigation.navigate('EditPostImage', {
  //       post: post,
  //       updateLocalPostList: updateLocalPostList,
  //     });
  //   }
  // };

  // SUBMISSION.
  const updateSubmitted = useDailyThemeStore((state) => state.updateSubmitted);
  const { handleSubmit, handleEdit } = useFileUpload();
  const handlePressSubmit = () => {
    if (handleDeletePost && post && updatePostInGlobalLists) {
      handleEdit(
        post,
        title,
        prompt,
        selectedTechnology,
        allTags,
        setLoading,
        navigation,
        updatePostInGlobalLists,
      );
    } else {
      handleSubmit(
        allTags,
        false,
        daily_theme_id,
        title,
        prompt,
        selectedTechnology,
        myInfo.id,
        myInfo.username,
        undefined,
        file,
        setLoading,
        setTitle,
        setPrompt,
        navigation,
        updateSubmitted,
        resetScreen,
      );
    }
  };
  const resetScreen = () => {
    setTitle('');
    setPrompt('');
    setSelectedTechnology(EMPTYTECHNOLOGY);
    resetTags();
    setFile && setFile(undefined);
    setLoading(false);
    showSuccessToast('Success!');
  };

  return (
    <>
      <View style={{ flexDirection: 'row' }}>
        <View
          style={{
            alignItems: 'center',
            marginHorizontal: spacing.lg,
          }}
        >
          <Icon_ThumbnailPreview
            file={file}
            setFile={
              setFile
                ? (newFile: Blob | undefined) => setFile(newFile)
                : () => null
            }
            imageURL={post?.images[0].image.url} // for "editpost" instance.
            loading={loading}
            isBoingle={false}
          />
          {file && (
            <Button_WebBasic
              callback={() => updatePreviewModalVisible()}
              text={'Preview'}
              loading={false}
              bgColor={colors.primary_accent}
            />
          )}
          {/* {submissionThemeInfo.posts_id === post?.long_id &&
            submitByTime > currentTime && (
              <Button_WebBasic
                callback={navigateToEditImage}
                text={'Edit image'}
                loading={false}
                bgColor={colors.primary_accent}
              />
            )} */}
        </View>
        <View
          style={{
            backgroundColor: 'rgba(27,23,30,0.2)',
            padding: spacing.md,
            borderRadius: radius.md,
          }}
        >
          <InputBox_WithLabel
            placeholderText={'Add a description...'}
            handleInputChange={setTitle}
            autocapitalize={'sentences'}
            label={'Description'}
            maxLength={500}
            multiline
            value={title}
          />
          <InputBox_WithLabel
            placeholderText={'Add your prompt...'}
            handleInputChange={setPrompt}
            autocapitalize={'sentences'}
            maxLength={500}
            multiline
            value={prompt}
            label={'Prompt (optional)'}
          />
          <Layout_CreatePostTags
            allTags={allTags}
            loading={loading}
            handleRemoveTag={removeTag}
            updateSearchModalVisible={updateSearchModalVisible}
          />
          <Layout_TechnologyChips
            technologies={technologies}
            loading={loading}
            selectedTechnology={selectedTechnology}
            setSelectedTechnology={setSelectedTechnology}
          />
          <View style={{ flexDirection: 'row' }}>
            <View style={{ marginRight: spacing.sm }}>
              <Button_WebBasic
                callback={
                  handleDeletePost ? updateDeleteModalVisible : resetScreen
                }
                text={handleDeletePost ? 'Delete post' : 'Discard draft'}
                loading={false}
                bgColor={colors.primary_accent}
              />
            </View>
            <View style={globalstyles.container}>
              <Button_WebBasic
                callback={handlePressSubmit}
                text={'Submit'}
                loading={loading}
              />
            </View>
          </View>
        </View>
      </View>
      <Toast />
      <SearchTagModal
        searchModalVisible={searchModalVisible}
        updateSearchModalVisible={updateSearchModalVisible}
        createNewTagButtonVisible={createNewTagButtonVisible}
        setCreateNewTagButtonVisible={setCreateNewTagButtonVisible}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        allTags={allTags}
        removeTag={removeTag}
        handleAddTag={handleAddTag}
      />
      {handleDeletePost && (
        <Modal_TwoOptions
          cancel={updateDeleteModalVisible}
          isVisible={deleteModalVisible}
          title={'Delete post?'}
          callback1={handleDeletePost}
          callback2={updateDeleteModalVisible}
          text1={'Yes delete  post (no take backs)'}
          icon1={faTrash}
          iconColor1={colors.alert}
          text2={'Nevermind'}
          icon2={faTimes}
        />
      )}
      {file && (
        <Modal_PreviewPost
          user={myInfo}
          title={title}
          tags={allTags}
          modalVisible={previewModalVisible}
          updateModalVisible={updatePreviewModalVisible}
          file={file}
          prompt={prompt}
          navigation={navigation}
        />
      )}
    </>
  );
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ProfileResponse, SocialMediaLink, TagList } from '@/api/zod';
import { Button_SocialIcon } from '../shared/Button_SocialIcon';
import { Button_HotDog } from '../shared/Button_HotDog';
import { spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { TagListLink } from './ProfileHeaderExtras';
import Button_Follow from '../shared/Button_Follow';

interface Props {
  myProfile: boolean;
  user: ProfileResponse;
  sociallinks: SocialMediaLink[];
  openMoreInfo: () => void;
  handleFollow: () => void;
  tagList?: TagList;
  navToTagList?: () => void;
  navToEditProfile: () => void;
}

interface SocialTypeToPlatformKeyMap {
  [key: string]: string;
}

const socialTypeToPlatformKey: SocialTypeToPlatformKeyMap = {
  '1': 'discord',
  '2': 'instagram',
  '3': 'twitter',
  '5': 'threads',
  '6': 'linktree',
};

export const ProfileHeaderButtons = ({
  myProfile,
  user,
  handleFollow,
  sociallinks,
  openMoreInfo,
  tagList,
  navToTagList,
  navToEditProfile,
}: Props) => {
  const socialIcons = sociallinks.map((link) => {
    const platformKey =
      socialTypeToPlatformKey[link.social_media_type_id.toString()];
    if (!platformKey) {
      return null;
    }
    return (
      <Button_SocialIcon
        key={link.id}
        platformKey={platformKey}
        url_ending={link.username}
      />
    );
  });
  return (
    <View style={styles.rowContainer}>
      {!myProfile && (
        <View style={styles.buttonSpace}>
          <Button_Follow
            handleFollow={handleFollow}
            isFollowed={user.isFollowed}
          />
        </View>
      )}
      <View style={styles.buttonSpace}>
        <Button_HotDog
          title="More info"
          callback={openMoreInfo}
          bgColor={colors.primary_offset}
          bgHighlight={colors.primary_accent}
          brightText
        />
      </View>
      {socialIcons}
      <View style={styles.buttonSpace}>
        <TagListLink
          myProfile={myProfile}
          tagList={tagList}
          navToTagList={navToTagList}
          navToEditProfile={navToEditProfile}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: spacing.lg,
  },
  buttonSpace: {
    marginHorizontal: spacing.sm,
  },
});

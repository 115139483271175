import React from 'react';
import { Pressable, Text, View } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { ExtendedBaseTheme } from '@/api/zod';
import { ParamListBase } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { colors } from '@/app/colors';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { StackNavigationProp } from '@react-navigation/stack';

interface ChipProps {
  theme: ExtendedBaseTheme;
  navigation: StackNavigationProp<ParamListBase>;
}

export function Button_PromptListChip({ theme, navigation }: ChipProps) {
  const handleNavigation = () => {
    navigation.push('ViewTagList', {
      tag_list: theme.tag_list_info.tag_list,
    });
  };
  return (
    <Pressable
      style={{ ...globalstyles.chip, marginBottom: spacing.md }}
      onPress={handleNavigation}
    >
      <Text style={globalstyles.smallbody}>
        from @{theme.tag_list_info.tag_list.username} #
        {theme.tag_list_info.tag_list.title}
      </Text>
      <View style={{ marginLeft: spacing.xs }}>
        <FontAwesomeIcon
          icon={faChevronRight}
          color={colors.secondary}
          size={8}
        />
      </View>
    </Pressable>
  );
}

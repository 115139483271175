import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, View, ScrollView, Pressable } from 'react-native';
import { Image } from 'expo-image';
import { colors } from '@/app/colors';
import {
  globalstyles,
  radius,
  spacing,
  webContentWidth,
  font,
  heightWithHeader,
} from '@/app/globalstyles';
import { Button_Submission } from '@/components/Button_Submission';
import {
  authenticatedRequest,
  requestAppleReauthentication,
  requestDiscordReauthentication,
  requestGoogleReauthentication,
} from '@/modules/auth';
// import { useSession } from '@/app/store/session';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import Toast from 'react-native-toast-message';
import { AuthButton } from '@/components/unauthenticated/AuthButton';
import { InputBox_WithSubmit } from '@/components/InputBox_WithSubmit';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useLogout } from '@/api/useLogout';
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey';
import Modal from 'react-native-modal';
import { ModalHeader } from '@/components/ModalHeader';
import { useMyProfileStore } from '@/app/store/store';

const renderLineItem = (text: string) => {
  return (
    <View style={styles.lineItem}>
      <Text style={globalstyles.body}>{text}</Text>
    </View>
  );
};

export function DeleteAccountScreen() {
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'DeleteAccount'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const { handleLogout } = useLogout();
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [finalBossModalVisible, setFinalBossModalVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState<string>('noattempt');
  const [loginMethods, setLoginMethods] = useState<string[]>([]);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [usersEmail, setUsersEmail] = useState('');

  const logoMapping: { [key: string]: any } = {
    discord: require('../../../../assets/login-icons/discord-login-logo.png'),
    google: require('../../../../assets/login-icons/google-login-logo.png'),
    apple: require('../../../../assets/login-icons/apple-login-logo.png'),
  };

  const renderAuthMethods = (method: string) => {
    const capitalizedMethod = method.charAt(0).toUpperCase() + method.slice(1);
    const logo = logoMapping[method.toLowerCase()];
    if (method === 'Email') {
      return (
        <View key={method} style={{ alignItems: 'center' }}>
          <View style={{ marginBottom: spacing.md }}>
            <Text style={globalstyles.body}>
              Enter your password for {usersEmail}
            </Text>
          </View>
          <InputBox_WithSubmit
            placeholderText={'Enter password'}
            handleInputChange={(text) => setPassword(text)}
            autocapitalize={'sentences'}
            isLoading={loading}
            value={password}
            submitCallback={submitPassword}
            submitDisabled={password === '' ? true : false}
            submitIcon={faKey}
            secureTextEntry={true}
            bgColor={colors.primary}
          />
        </View>
      );
    } else if (method === 'discord') {
      return (
        <View key={method}>
          <AuthButton
            logo={logo}
            callback={handleDiscordResponse}
            text={capitalizedMethod}
            sideblanks={false}
          />
        </View>
      );
    } else if (method === 'apple') {
      return (
        <View key={method}>
          <AuthButton
            logo={logo}
            callback={handleAppleResponse}
            text={capitalizedMethod}
            sideblanks={false}
          />
        </View>
      );
    } else if (method === 'google') {
      return (
        <View key={method}>
          <AuthButton
            logo={logo}
            callback={handleGoogleResponse}
            text={capitalizedMethod}
            sideblanks={false}
          />
        </View>
      );
    }
  };

  const handleDiscordResponse = async () => {
    const response = await requestDiscordReauthentication();
    if (response === 'success') {
      setAuthenticated('success');
    } else {
      setAuthenticated('denied');
      showToast('Authentication failed - contact support');
    }
  };
  const handleAppleResponse = async () => {
    const response = await requestAppleReauthentication();
    if (response === 'success') {
      setAuthenticated('success');
    } else {
      setAuthenticated('denied');
      showToast('Authentication failed - contact support');
    }
  };
  const handleGoogleResponse = async () => {
    const response = await requestGoogleReauthentication();
    if (response === 'success') {
      setAuthenticated('success');
    } else {
      setAuthenticated('denied');
      showToast('Authentication failed - contact support');
    }
  };

  const handlePressDeleteButton = async () => {
    if (authenticated === 'success') {
      setFinalBossModalVisible(!finalBossModalVisible);
    } else {
      setAuthModalVisible(!authModalVisible);
    }
  };

  const initiateDataDeletion = async () => {
    const authenticatedAxios = await authenticatedRequest();
    try {
      await authenticatedAxios.post('z_user/permanent_delete', {
        users_id: user?.id,
        email: usersEmail,
      });
    } catch (error: any) {
      console.error('Error response:', error.response);
    }
  };

  const permanentlyDeleteAccount = async () => {
    await initiateDataDeletion();
    handleLogout();
  };

  useEffect(() => {
    const fetchLoginMethods = async () => {
      try {
        const authenticatedAxios = await authenticatedRequest();
        const response = await authenticatedAxios.get(
          `auth/login_methods_and_email/${user?.id}`,
        );
        setLoginMethods(response.data.loginMethods);
        setUsersEmail(response.data.usersEmail);
      } catch (error) {
        showToast('Error updating last notif check time');
      }
    };
    fetchLoginMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitPassword = async () => {
    try {
      setLoading(true);
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.post(
        'auth/validate_user_password',
        {
          users_id: user?.id,
          password: password,
        },
      );
      setAuthenticated(response.data);
      setAuthModalVisible(false);
      if (response.data === 'success') {
        showSuccessToast('Success');
      } else if (response.data === 'denied') {
        showToast('Denied');
      }
    } catch (error) {
      showToast('Error updating last notif check time');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollView
      style={globalstyles.container}
      contentContainerStyle={styles.contentContainer}
    >
      <View>
        <Image
          source={require('../../../../assets/kermit.jpg')}
          style={{
            ...globalstyles.profileBannerImage,
            marginBottom: spacing.md,
          }}
          transition={250}
        />
      </View>
      <View style={{ marginBottom: spacing.md }}>
        <View
          style={{
            padding: spacing.md,
            backgroundColor: colors.primary_accent,
            borderRadius: radius.sm,
            marginBottom: spacing.md,
            marginHorizontal: spacing.sm,
          }}
        >
          <Text style={{ ...globalstyles.label, marginBottom: spacing.md }}>
            Herein lies account deletion. Please review all the information
            below, before making your decision.
          </Text>
          <Text style={globalstyles.label}>
            THIS DECISION IS{' '}
            <Text style={{ color: colors.alert }}>IRREVERSIBLE</Text>. WE
            PERMANENTLY WIPE ALL YOUR DATA FROM OUR SERVERS IMMEDIATELY.
          </Text>
        </View>
        <View style={styles.bullets}>
          {renderLineItem(
            "1. Before proceeding, you must confirm you're you by re-entering your password or re-authenticating via your social login.",
          )}
          {renderLineItem(
            "2. Once authenticated, you'll be able to delete all of your data. We will then log you out automatically.",
          )}
          {renderLineItem(
            "3. You'll receive a confirmation email and final goodbye once your account has been deleted.",
          )}
          {renderLineItem(
            '4. If you have any questions or encounter issues, feel free to reach out to support: team@joinwhirl.com.',
          )}
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
        <View style={{ marginBottom: spacing.md, marginRight: spacing.md }}>
          <Button_Submission
            callback={() => navigation.goBack()}
            title={'Back to safety'}
            loading={false}
          />
        </View>
        <View style={{ marginBottom: spacing.md }}>
          <Button_Submission
            callback={handlePressDeleteButton}
            title={
              authenticated === 'success' ? 'Delete account' : 'Authenticate'
            }
            loading={false}
            bgColor={colors.alert}
          />
        </View>
      </View>
      {authenticated === 'denied' && (
        <Text
          style={{
            ...globalstyles.body,
            color: colors.alert,
            alignSelf: 'center',
          }}
        >
          Authentication Failed
        </Text>
      )}
      {authenticated === 'success' && (
        <View style={{ paddingHorizontal: spacing.lg }}>
          <Text
            style={{
              ...globalstyles.body,
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Authentication Successful 🫡 {'\n'}You can now delete your account
          </Text>
        </View>
      )}

      <Modal
        isVisible={authModalVisible}
        avoidKeyboard={true}
        hideModalContentWhileAnimating={true}
        style={{ margin: 0 }}
      >
        <Pressable
          style={{
            ...globalstyles.container,
            ...globalstyles.centeringContainer,
          }}
        >
          <View style={globalstyles.modalContent}>
            <ModalHeader
              callback={() => setAuthModalVisible(false)}
              title={'Authenticate'}
            />
            {loginMethods.map((method) => renderAuthMethods(method))}
          </View>
        </Pressable>
      </Modal>

      <Modal
        isVisible={finalBossModalVisible}
        avoidKeyboard={true}
        hideModalContentWhileAnimating={true}
        style={{ margin: 0 }}
      >
        <View style={styles.finalBossModalContainer}>
          <View style={styles.modalView}>
            <View style={{ alignItems: 'center', marginBottom: spacing.md }}>
              <Text style={{ ...globalstyles.title, fontSize: font.xxl }}>
                This is good bye
              </Text>
              <Text style={globalstyles.body}>Sure you want to go?</Text>
            </View>
            <View style={{ marginBottom: spacing.md }}>
              <Button_Submission
                callback={() => {
                  setFinalBossModalVisible(false);
                  navigation.goBack();
                }}
                title={'Back to safety'}
                loading={false}
                bgColor={colors.accent}
              />
            </View>
            <Image
              source={require('../../../../assets/goodbye.gif')}
              style={{
                ...globalstyles.profileBannerImage,
                marginBottom: spacing.md,
              }}
              transition={250}
            />
            <View style={{ marginBottom: spacing.md, alignItems: 'center' }}>
              <Button_Submission
                callback={permanentlyDeleteAccount}
                title={'Delete my account forever'}
                loading={false}
                bgColor={colors.alert}
              />
            </View>
            <View
              style={{ paddingHorizontal: spacing.md, alignSelf: 'center' }}
            >
              <Text style={{ ...globalstyles.body, textAlign: 'center' }}>
                Last reminder that this is not reversible. We do not store data
                after this point.
              </Text>
            </View>
          </View>
        </View>
      </Modal>
      <Toast />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: spacing.lg * 4,
    alignSelf: 'center',
    maxWidth: webContentWidth * 2,
    height: heightWithHeader,
  },
  bullets: {
    alignItems: 'flex-start',
    width: '100%',
    paddingHorizontal: spacing.md * 2,
  },
  lineItem: {
    marginBottom: spacing.md,
  },
  modalView: {
    width: webContentWidth * 2,
    backgroundColor: colors.primary,
    borderTopRightRadius: radius.lg,
    borderTopLeftRadius: radius.lg,
    paddingVertical: spacing.xl,
    alignItems: 'center',
    justifyContent: 'center',
  },
  finalBossModalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: colors.primary,
  },
});

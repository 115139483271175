import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import { colors } from '@/app/colors';
import {
  border,
  font,
  globalstyles,
  radius,
  spacing,
} from '@/app/globalstyles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

interface SearchProps {
  toggleModal: () => void;
  searchBarText: string;
}

export const Button_SearchBar = ({
  toggleModal,
  searchBarText,
}: SearchProps) => {
  const [hover, setHover] = useState(false);
  const active = hover || searchBarText !== 'Search';
  return (
    <Pressable
      style={{
        ...styles.searchContainer,
        borderColor: !active ? colors.primary_offset_2 : colors.primary_light,
      }}
      onPress={toggleModal}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <View style={globalstyles.container}>
        <Text
          style={{
            ...globalstyles.usernameText,
            opacity: active ? 1 : 0.2,
          }}
        >
          {searchBarText}
        </Text>
      </View>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        size={font.md}
        color={!active ? colors.primary_light : colors.secondary}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  searchContainer: {
    ...globalstyles.container,
    marginLeft: spacing.md,
    borderRadius: radius.lg,
    backgroundColor: colors.primary_offset,
    borderWidth: border.xs,
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.sm,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

import React, { useEffect, useState } from 'react';
import { Text, View, Pressable } from 'react-native';
import {
  globalstyles,
  headerHeight,
  maxWebContentWidth,
  radius,
  spacing,
} from '@/app/globalstyles';
import ImageBackground from '../ImageBackground';
import { ParamListBase } from '@react-navigation/native';
import { colors } from '@/app/colors';
import { WebNav_DailyThemeheader } from './WebNav_DailyThemeHeader';
import {
  EMPTYTECHNOLOGY,
  ProfileResponse,
  Technology,
  ExtendedBaseTheme,
} from '@/api/zod';
import { countdown } from '../RelativeDate';
import { Text_WebPageHeader } from '../Text_WebPageHeader';
import { Layout_WebUploadDetails } from '../shared/Layout_WebUploadDetails';
import Button_WebUpload from '../Button_WebUpload';
import { Button_PromptListChip } from '../shared/Button_PromptListChip';
import { StackNavigationProp } from '@react-navigation/stack';
import { DEFAULTTECH } from '@/api/posts';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';

interface DailyThemeWebComponentProps {
  submissionThemeInfo: ExtendedBaseTheme;
  navigation: StackNavigationProp<ParamListBase>;
  routeName: string;
  myInfo: ProfileResponse | null;
}

export const DailyThemeWebComponent = ({
  submissionThemeInfo,
  navigation,
  routeName,
  myInfo,
}: DailyThemeWebComponentProps) => {
  const [file, setFile] = useState<Blob | undefined>();
  const [loading, setLoading] = useState(false);
  const loggedInUserHasSubmitted = useDailyThemeStore(
    (state) => state.submissionThemeInfo.loggedInUserHasSubmitted,
  );
  const dailyThemeTags = useDailyThemeStore((state) => state.dailyThemeTags);

  // TECHNOLOGIES.
  const [selectedTechnology, setSelectedTechnology] =
    useState<Technology>(EMPTYTECHNOLOGY);

  useEffect(() => {
    setAllTags(dailyThemeTags);
  }, [dailyThemeTags]);

  // TAGS.
  const [allTags, setAllTags] = useState<string[]>([]);
  const addTag = (tag: string) => {
    setAllTags([...allTags, tag]);
  };
  const handleRemoveTag = (tagToRemove: string) => {
    setAllTags(allTags.filter((tag) => tag !== tagToRemove));
  };
  const resetTags = () => {
    setAllTags(dailyThemeTags);
  };
  return (
    <ImageBackground
      style={{
        ...globalstyles.container,
        alignItems: 'center',
        paddingTop: headerHeight + spacing.lg,
      }}
      source={{ uri: submissionThemeInfo.background_image_landscape.url }}
      imageStyle={{ opacity: 0.2 }}
    >
      <Text_WebPageHeader
        header={
          loggedInUserHasSubmitted
            ? 'Success!'
            : file
            ? 'Draft'
            : `Daily theme: ${submissionThemeInfo.title}`
        }
        subtitle={
          loggedInUserHasSubmitted
            ? `You submitted to the daily theme of "${submissionThemeInfo.title}"`
            : file
            ? `Preparing submission for "${submissionThemeInfo.title}"`
            : ''
        }
      />
      {!file && (
        <View style={{ marginBottom: spacing.lg }}>
          <Button_PromptListChip
            navigation={navigation}
            theme={submissionThemeInfo}
          />
        </View>
      )}

      {!loggedInUserHasSubmitted ? (
        <>
          {file && myInfo ? (
            <Layout_WebUploadDetails
              file={file}
              loading={loading}
              setFile={setFile}
              technologies={DEFAULTTECH}
              selectedTechnology={selectedTechnology}
              setSelectedTechnology={setSelectedTechnology}
              allTags={allTags}
              addTag={addTag}
              removeTag={handleRemoveTag}
              navigation={navigation}
              setLoading={setLoading}
              myInfo={myInfo}
              post={undefined}
              daily_theme_id={submissionThemeInfo.id}
              resetTags={resetTags}
            />
          ) : (
            <Button_WebUpload setFile={setFile} />
          )}
        </>
      ) : (
        <SuccessfulSubmissionDialogue
          submissionThemeInfo={submissionThemeInfo}
          navigation={navigation}
        />
      )}
      {(!file || loggedInUserHasSubmitted) && (
        <WebNav_DailyThemeheader
          activeRoute={routeName}
          navigation={navigation}
          loggedInUserHasSubmitted={loggedInUserHasSubmitted}
        />
      )}
    </ImageBackground>
  );
};

interface SuccessfulSubmissionDialogueProps {
  submissionThemeInfo: ExtendedBaseTheme;
  navigation: StackNavigationProp<ParamListBase>;
}

const SuccessfulSubmissionDialogue = ({
  submissionThemeInfo,
  navigation,
}: SuccessfulSubmissionDialogueProps) => {
  const goToPreview = () => {
    if (submissionThemeInfo.long_id) {
      navigation.push('ViewSinglePost', {
        postId: submissionThemeInfo.long_id,
      });
    }
  };
  return (
    <View
      style={{
        padding: spacing.lg,
        borderRadius: radius.lg,
        backgroundColor: colors.primary_accent,
        width: maxWebContentWidth / 2,
      }}
    >
      <View>
        <View>
          <View style={{ width: '100%' }}>
            <Text
              style={{
                ...globalstyles.body,
                marginBottom: spacing.md,
              }}
            >
              You submitted to the theme of{' '}
              <Text style={{ color: colors.accent }}>
                {submissionThemeInfo.title}.
              </Text>{' '}
              Your post will appear for vote in{' '}
              {countdown(submissionThemeInfo.submit_by)}. Until then, vote and
              explore below!
            </Text>
          </View>
        </View>
        <Pressable
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onPress={goToPreview}
        >
          <Text
            style={{
              ...globalstyles.title,
              alignSelf: 'flex-start',
            }}
          >
            Preview {'->'}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

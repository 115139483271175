import React, { useState } from 'react';
import { View } from 'react-native';
import { colors } from '@/app/colors';

import {
  unauthenticatedRequest,
  login,
  isValidPassword,
  passwordsMatch,
} from '@/modules/auth';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import { InputBox_SignUp } from '../InputBox_SignUp';
import { AuthButton } from '../AuthButton';

interface Props {
  email: string;
}

export const Layout_PWResetStep3 = ({ email }: Props) => {
  const [loading, setLoading] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [secureTextEntry1, setSecureTextEntry1] = useState(true);
  const [secureTextEntry2, setSecureTextEntry2] = useState(true);

  const updateSecureTextEntry1 = () => {
    setSecureTextEntry1(!secureTextEntry1);
  };
  const updateSecureTextEntry2 = () => {
    setSecureTextEntry2(!secureTextEntry2);
  };

  const tryLogin = async () => {
    if (!isValidPassword(password1)) {
      setInvalidPassword(true);
    } else if (!passwordsMatch(password1, password2)) {
      setNoMatch(true);
    } else {
      try {
        setLoading(true);
        const axios = unauthenticatedRequest();
        const response = await axios.post(
          'auth/password_reset/save_new_password',
          {
            email: email,
            password: password1,
          },
        );
        if (response.data) {
          login(response.data);
        }
        showSuccessToast('Success!');
      } catch (error) {
        showToast('Error saving new password');
      } finally {
        setLoading(false);
      }
    }
  };

  const invalidPasswordText = noMatch
    ? 'Passwords do not match.'
    : invalidPassword
    ? 'Invalid password. 8+ characters with at least one letter and one number.'
    : '';

  const disabled =
    !isValidPassword(password1) || !passwordsMatch(password1, password2);

  return (
    <View>
      <InputBox_SignUp
        isPassword={true}
        placeholderText={'Password'}
        value={password1}
        setter={setPassword1}
        secureTextEntry={secureTextEntry1}
        updateSecureTextEntry={updateSecureTextEntry1}
        maxLength={30}
        invalid={false}
        invalidText={''}
      />
      <InputBox_SignUp
        isPassword={true}
        placeholderText={'Confirm password'}
        value={password2}
        setter={setPassword2}
        secureTextEntry={secureTextEntry2}
        updateSecureTextEntry={updateSecureTextEntry2}
        maxLength={30}
        invalid={noMatch || invalidPassword}
        invalidText={invalidPasswordText}
      />
      <AuthButton
        logo={''}
        callback={tryLogin}
        text={'Log in'}
        disabled={disabled}
        bgColor={disabled ? colors.primary_offset : colors.accent}
        bgHover={disabled ? colors.primary_offset_2 : colors.accent_highlight}
        loading={loading}
        sideblanks
      />
    </View>
  );
};

import React, { PropsWithChildren, useCallback } from 'react';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { View } from 'react-native';

SplashScreen.preventAutoHideAsync();

export function LoadAssets({ children }: PropsWithChildren<{}>) {
  const [fontsLoaded] = useFonts({
    PressStart2P: require('../../assets/fonts/PressStart2P-Regular.ttf'),
    Manrope: require('../../assets/fonts/Manrope/static/Manrope-ExtraBold.ttf'),
    Great_Vibes: require('../../assets/fonts/Great_Vibes/GreatVibes-Regular.ttf'),
    Manrope_Light: require('../../assets/fonts/Manrope/static/Manrope-Light.ttf'),
    Manrope_Medium: require('../../assets/fonts/Manrope/static/Manrope-Medium.ttf'),
    Manrope_Bold: require('../../assets/fonts/Manrope/static/Manrope-Bold.ttf'),
  });

  const onLayout = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  return fontsLoaded ? (
    <View style={{ flex: 1 }} onLayout={onLayout}>
      {children}
    </View>
  ) : null;
}

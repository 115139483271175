import React from 'react';
import { View, StyleSheet, Pressable, Text } from 'react-native';
import { ProfileResponse, TagList } from '@/api/zod';
import { globalstyles, spacing } from '@/app/globalstyles';
import { Button_HotDog } from '../shared/Button_HotDog';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { colors } from '@/app/colors';

interface TagListLinkProps {
  myProfile: boolean;
  tagList?: TagList;
  navToTagList?: () => void;
  navToEditProfile: () => void;
}

export const TagListLink = ({
  tagList,
  myProfile,
  navToTagList,
  navToEditProfile,
}: TagListLinkProps) => {
  return (
    <View style={styles.tagListButton}>
      {tagList && navToTagList && (
        <Button_HotDog
          title={`Host: #${tagList.title}`}
          callback={navToTagList}
          brightText
          roundie
        />
      )}
      {myProfile && (
        <Pressable
          style={{ marginLeft: spacing.sm, padding: spacing.sm }}
          onPress={navToEditProfile}
        >
          <FontAwesomeIcon icon={faPencil} size={14} color={colors.secondary} />
        </Pressable>
      )}
    </View>
  );
};

interface LevelProps {
  myProfile: boolean;
  activeUser: ProfileResponse;
  navToLevels: () => void;
  toggleMoreInfoModal: () => void;
  // badge?: BadgeSchema;
}

export const LevelBadge = ({
  activeUser,
  myProfile,
  navToLevels,
  toggleMoreInfoModal,
}: LevelProps) => {
  return (
    <Pressable
      style={{
        ...globalstyles.container,
        alignItems: 'center',
      }}
      onPress={myProfile ? navToLevels : toggleMoreInfoModal}
    >
      <Text style={globalstyles.usernameText}>Level {activeUser.level}</Text>
    </Pressable>
  );
};

interface TotalPostsProps {
  totalPosts: number;
}

export const Total_Posts = ({ totalPosts }: TotalPostsProps) => {
  return (
    <View
      style={{
        ...globalstyles.container,
        alignItems: 'center',
      }}
    >
      <Text style={globalstyles.usernameText}>{totalPosts} posts</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  tagListButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profPicContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    zIndex: 2,
  },
});

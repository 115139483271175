import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { globalstyles, radius, spacing } from '@/app/globalstyles';

type HomeScreenIconProps = {
  callback: () => void;
  icon: IconDefinition;
  subtitle?: string;
  longPressCallback?: () => void;
  activeIcon?: boolean;
  activeDot?: boolean;
  hoverIn?: boolean;
};

export function HomeScreenIcon({
  callback,
  icon,
  subtitle,
  longPressCallback,
  activeIcon,
  activeDot,
  hoverIn,
}: HomeScreenIconProps) {
  return (
    <TouchableOpacity
      onPress={callback}
      onLongPress={longPressCallback ? longPressCallback : () => null}
      delayLongPress={5000}
      activeOpacity={0.9}
      style={{ zIndex: 4, alignItems: 'center' }}
    >
      <View>
        {activeDot && (
          <View
            style={{
              height: spacing.sm,
              width: spacing.sm,
              backgroundColor: colors.high_accent,
              position: 'absolute',
              top: -spacing.xs,
              left: -spacing.xs,
              borderRadius: radius.md,
            }}
          />
        )}
        <FontAwesomeIcon
          icon={icon}
          color={
            activeIcon
              ? colors.high_accent
              : hoverIn
              ? colors.secondary
              : colors.halfwhite
          }
          size={18}
        />
      </View>
      {!!subtitle && (
        <View style={{ margin: spacing.sm }}>
          <Text style={globalstyles.smallbody}>{subtitle}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
}

import React, { useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { globalstyles, border, spacing } from '@/app/globalstyles';
import { useStudioStore } from '@/app/store/studioStore';
import { FlatList } from 'react-native-gesture-handler';
import { formattedDate } from '@/components/RelativeDate';
import { Subtitle } from '@/components/Subtitle';
import { Subscription } from '@/api/zod';
import { colors } from '@/app/colors';
import { useMyProfileStore } from '@/app/store/store';

export function SubscriptionsHistoryScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const subscriptions = useStudioStore((state) => state.subscriptions);
  const fetchSubscriptionHistory = useStudioStore(
    (state) => state.fetchSubscriptionHistory,
  );
  useEffect(() => {
    if (user) {
      fetchSubscriptionHistory(user.id);
    }
  }, [user, fetchSubscriptionHistory]);
  const renderItem = ({ item }: { item: Subscription }) => (
    <View style={styles.itemContainer}>
      <View style={{ marginBottom: spacing.md }}>
        <View style={{ marginBottom: spacing.sm }}>
          <Text style={globalstyles.label}>{item.name}</Text>
        </View>
        <View style={{ marginBottom: spacing.sm, flexDirection: 'row' }}>
          <Text style={globalstyles.body}>{item.subscription_type}</Text>
          <Text style={globalstyles.body}>
            {item.active ? ' - active' : ' - inactive'}
          </Text>
        </View>
        <Text style={globalstyles.tinyfade}>
          Purchased on {formattedDate(item.created_at)}
        </Text>
      </View>
      <Text style={globalstyles.label}>{item.priceString}</Text>
    </View>
  );
  return (
    <View style={globalstyles.container}>
      <Subtitle text={'View past upgrades'} />
      <FlatList
        data={subscriptions}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing.md,
    borderBottomColor: colors.primary_accent,
    borderBottomWidth: border.sm,
  },
});

import { Post } from '@/api/zod';
import { colors } from '@/app/colors';
import { globalstyles, spacing } from '@/app/globalstyles';
import {
  faBookmark,
  faEllipsisV,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { Pressable, Text, StyleSheet } from 'react-native';
import withAuthPopup from '../shared/hoc/withAuthPopup';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  like: () => void;
  save: () => void;
  togglePostSettings: () => void;
  post: Post;
}

const Buttons_PostActions: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  like,
  save,
  togglePostSettings,
  post,
}) => {
  const handlePressLike = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      like();
    }
  };
  const handlePressSave = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      save();
    }
  };
  const handlePressOptions = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      togglePostSettings();
    }
  };
  return (
    <>
      <Pressable style={styles.actionButtonContainer} onPress={handlePressSave}>
        <FontAwesomeIcon
          style={styles.actionButton}
          icon={faBookmark}
          color={post.isSaved ? colors.accent : colors.secondary}
          size={20}
        />
        <Text style={globalstyles.usernameText}>
          {post.totalSaves > 0 ? post.totalSaves : ''}
        </Text>
      </Pressable>
      <Pressable style={styles.actionButtonContainer} onPress={handlePressLike}>
        <FontAwesomeIcon
          style={styles.actionButton}
          icon={faHeart}
          color={post.likedOrVoted ? 'red' : colors.secondary}
          size={20}
        />
        <Text style={globalstyles.usernameText}>
          {post.likeCount > 0 ? post.likeCount : ''}
        </Text>
      </Pressable>
      <Pressable onPress={handlePressOptions}>
        <FontAwesomeIcon
          icon={faEllipsisV}
          color={colors.secondary}
          size={20}
        />
      </Pressable>
    </>
  );
};

export default withAuthPopup(Buttons_PostActions);

const styles = StyleSheet.create({
  actionButtonContainer: {
    alignItems: 'center',
  },
  actionButton: {
    marginHorizontal: spacing.sm,
    marginBottom: spacing.sm,
  },
});

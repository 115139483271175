import { ParamListBase } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useMyProfileStore } from '@/app/store/store';
import { Layout_Profile } from '@/components/profile/Layout_Profile';
import { StackNavigationProp } from '@react-navigation/stack';
import { ProfileResponse } from '@/api/zod';

interface Props {
  navigation: StackNavigationProp<ParamListBase>;
  myInfo: ProfileResponse;
}

export const Layout_MyProfile = ({ navigation }: Props) => {
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const myGalleryData = useMyProfileStore((state) => state.myGalleryData);
  const setMyGalleryData = useMyProfileStore((state) => state.setMyGalleryData);
  const myProfileData = useMyProfileStore((state) => state.myProfileData);
  const myProfileMounted = useMyProfileStore((state) => state.myProfileMounted);

  const hydrateMyGalleryData = useMyProfileStore(
    (state) => state.hydrateMyGalleryData,
  );
  const fetchAllPosts = useMyProfileStore((state) => state.fetchAllPosts);
  const fetchDailyThemePosts = useMyProfileStore(
    (state) => state.fetchDailyThemePosts,
  );

  useEffect(() => {
    hydrateMyGalleryData();
  }, [hydrateMyGalleryData]);

  const setCurrentTab = (tab: string) => {
    setMyGalleryData({ ...myGalleryData, currentTab: tab });
  };

  return (
    <>
      {myInfo && (
        <Layout_Profile
          profileData={myProfileData}
          galleryData={myGalleryData}
          setCurrentTab={setCurrentTab}
          fetchAllPosts={() => fetchAllPosts(myInfo)}
          fetchThemePosts={() => fetchDailyThemePosts(myInfo)}
          myProfile={true}
          navigation={navigation}
          handleFollow={() => null}
          mounted={myProfileMounted}
        />
      )}
    </>
  );
};

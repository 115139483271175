import { mobileCheck } from '@/screens/SignUpSelect';
import React from 'react';
import { Linking, View } from 'react-native';
import { Button_DownloadApp } from './Button_DownloadApp';

export function Buttons_DownloadApp() {
  return (
    <View
      style={{
        flexDirection: mobileCheck() ? 'column' : 'row',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    >
      <Button_DownloadApp
        logo={require('../../../assets/login-icons/google-play-badge.png')}
        callback={() =>
          Linking.openURL(
            'https://play.google.com/store/apps/details?id=com.whirl.android',
          )
        }
      />
      <Button_DownloadApp
        logo={require('../../../assets/login-icons/app-store-badge.png')}
        callback={() =>
          Linking.openURL(
            'https://apps.apple.com/us/app/whirl-ai-art-community/id6448279646',
          )
        }
      />
    </View>
  );
}

import { isValidEmail, isValidPassword } from '@/modules/auth';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { colors } from '@/app/colors';
import {
  border,
  globalstyles,
  maxWebContentWidth,
  radius,
  spacing,
} from '@/app/globalstyles';
import { Text_AuthHeader } from '../Text_AuthHeader';
import { Text_PrivacyAndTerms } from '../Text_PrivacyAndTerms';
import { InputBox_SignUp } from '../InputBox_SignUp';
import { AuthButton } from '../AuthButton';
import { Button_ModalClose } from '../../shared/Button_ModalClose';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Text_ToggleNewUser } from '../Text_ToggleNewUser';
import { useLogin } from '@/api/useLogin';
import { Text_ForgotPassword } from '../Text_ForgotPassword';

interface Props {
  setScreen: (screen: string) => void;
  toggleModal: () => void;
}

export const Layout_EmailSignIn = ({ setScreen, toggleModal }: Props) => {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Explore'>>();
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const updateSecureTextEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };
  const resetValidationState = () => {
    setInvalidEmail(false);
    setInvalidPassword(false);
  };

  const handleInputChange = (setter: any) => (text: string) => {
    const newText = text.replace(/\s+/g, '').toLowerCase();
    setter(newText);
  };

  const { loading, tryLogin } = useLogin({ email, password });

  const handleSignIn = () => {
    resetValidationState();
    if (isValidEmail(email) === false) {
      setInvalidEmail(true);
    }
    if (isValidPassword(password) === false) {
      setInvalidPassword(true);
    }
    if (isValidPassword(password) && isValidEmail(email)) {
      tryLogin();
    }
  };

  return (
    <View style={styles.wrapper}>
      <Text_AuthHeader text={'login'} />
      <Button_ModalClose
        close={() => setScreen('oauth')}
        overrideIcon={faChevronLeft}
        bgColor={colors.primary}
        bgHover={colors.primary_accent}
      />
      <InputBox_SignUp
        value={email}
        handleInputChange={handleInputChange}
        setter={setEmail}
        placeholderText={'Email'}
        keyboardType={'email-address'}
        invalid={invalidEmail}
        invalidText={'Invalid email.'}
      />
      <InputBox_SignUp
        isPassword={true}
        placeholderText={'Password'}
        value={password}
        setter={setPassword}
        secureTextEntry={secureTextEntry}
        updateSecureTextEntry={updateSecureTextEntry}
        maxLength={128}
        invalid={invalidPassword}
        invalidText={'8+ characters and at least one number.'}
      />
      <AuthButton
        logo={''}
        callback={handleSignIn}
        text={'Log in'}
        disabled={password === ''}
        bgColor={colors.accent}
        bgHover={colors.accent_highlight}
        loading={loading}
        sideblanks
      />

      <Text_ToggleNewUser
        text1={'No account yet?'}
        text2={'Sign up'}
        toggleNewUser={() => setScreen('signup')}
      />
      <Text_ForgotPassword callback={() => setScreen('pw-reset')} />
      <Text_PrivacyAndTerms navigation={navigation} toggleModal={toggleModal} />
      <Toast />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...globalstyles.container,
    padding: spacing.xl,
  },
  submitButton: {
    alignItems: 'center',
    width: maxWebContentWidth / 3, // change?
    borderRadius: radius.sm,
    borderWidth: border.xs,
    marginBottom: spacing.lg,
    padding: spacing.sm,
    paddingHorizontal: spacing.md,
  },
  backButton: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

import { ExpRecord } from '@/api/levels';
import { fetchGalleryData, fetchProfileData } from '@/api/posts';
import {
  ProfileResponse,
  EMPTYSTATS,
  MyStats,
  ProfileData,
  GalleryData,
  EMPTYPROFILEDATA,
  EMPTYGALLERYDATA,
} from '@/api/zod';
import { Post } from '@/api/zod';
import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest } from '@/modules/auth';
import { create } from 'zustand';
import { useExploreStore } from './exploreStore';

const initialExpStoreState = {
  expRecords: [],
  page: 1,
  loading: false,
};

type ExpStoreState = {
  expRecords: ExpRecord[];
  page: number;
  loading: boolean;
  reset: () => void;
  setExpRecords: (expRecords: ExpRecord[]) => void;
  setPage: (page: number) => void;
  setLoading: (loading: boolean) => void;
  fetchExpRecords: (user: any) => Promise<void>;
};

export const useExpStore = create<ExpStoreState>((set, get) => ({
  ...initialExpStoreState,
  reset: () => set(() => ({ ...initialExpStoreState })),
  setExpRecords: (expRecords: ExpRecord[]) => set({ expRecords }),
  setPage: (page: number) => set({ page }),
  setLoading: (loading: boolean) => set({ loading }),
  fetchExpRecords: async (user) => {
    const { expRecords, page, setLoading } = get();
    setLoading(true);
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `experience_log/${user?.id}/${page}`,
      );
      set({
        expRecords: [...expRecords, ...response.data],
        page: page + 1,
      });
    } catch (error) {
      console.error('Error fetching experience logs:', error);
    } finally {
      setLoading(false);
    }
  },
}));

const initialMyProfileStoreState = {
  myInfo: null,
  myProfileMounted: false,
  myStats: EMPTYSTATS,
  myProfileData: EMPTYPROFILEDATA,
  myGalleryData: EMPTYGALLERYDATA,
  newNotifications: false,
};

type MyProfileStoreState = {
  myInfo: ProfileResponse | null;
  myProfileMounted: boolean;
  myProfileData: ProfileData;
  myGalleryData: GalleryData;
  myStats: MyStats;
  newNotifications: boolean;

  reset: () => void;
  setMyInfo: (userInfo: ProfileResponse | null) => void;
  setMyProfileMounted: (value: boolean) => void;
  setMyProfileData: (data: ProfileData) => void;
  setMyGalleryData: (data: GalleryData) => void;
  setMyStats: (stats: MyStats) => void;
  setNewNotifications: (isNewNotifications: boolean) => void;

  updatePostInAllPostList: (post: Post) => void;
  updatePostInDailyThemePostList: (updatedPost: Post) => void;
  addNewPostToAllPostList: (post: Post) => void;
  addNewPostToDailyThemePostList: (updatedPost: Post) => void;
  deletePostFromAllPostList: (postId: number) => void;
  deletePostFromDailyThemePostList: (postId: number) => void;

  hydrateMyInfo: (user: ProfileResponse) => void;
  hydrateMyGalleryData: () => void;
  fetchAllPosts: (user: ProfileResponse, reset?: boolean) => Promise<void>;
  fetchDailyThemePosts: (
    user: ProfileResponse,
    reset?: boolean,
  ) => Promise<void>;
  fetchMyStats: (user: ProfileResponse) => void;
};

export const useMyProfileStore = create<MyProfileStoreState>((set, get) => ({
  ...initialMyProfileStoreState,
  reset: () => set(() => ({ ...initialMyProfileStoreState })),
  setMyInfo: (userInfo) => set({ myInfo: userInfo }),
  setMyProfileMounted: (value) => set({ myProfileMounted: value }),
  setMyProfileData: (data) => set({ myProfileData: data }),
  setMyGalleryData: (data) => set({ myGalleryData: data }),
  setMyStats: (stats) => set({ myStats: stats }),
  setNewNotifications: (newNotifications) => set({ newNotifications }),

  updatePostInAllPostList: (updatedPost) => {
    const { setMyGalleryData, myGalleryData: data } = get();
    const postIndex = data.allPostList.findIndex(
      (post) => post.id === updatedPost.id,
    );
    if (postIndex !== -1) {
      data.allPostList[postIndex] = updatedPost;
    }
    setMyGalleryData({
      ...data,
      allPostList: [...data.allPostList],
    });
  },
  addNewPostToAllPostList: (newPost) => {
    const { setMyGalleryData, myGalleryData: data } = get();
    setMyGalleryData({
      ...data,
      allPostList: [
        newPost,
        ...data.allPostList.filter((post) => !post.isPlaceholder),
      ],
    });
  },
  deletePostFromAllPostList: (postId) => {
    const { setMyGalleryData, myGalleryData } = get();
    setMyGalleryData({
      ...myGalleryData,
      allPostList: myGalleryData.allPostList.filter(
        (post) => post.id !== postId,
      ),
    });
  },
  updatePostInDailyThemePostList: (updatedPost) => {
    const { setMyGalleryData, myGalleryData: data } = get();
    const postIndex = data.dailyThemePostList.findIndex(
      (post) => post.id === updatedPost.id,
    );
    if (postIndex !== -1) {
      data.dailyThemePostList[postIndex] = updatedPost;
    }
    setMyGalleryData({
      ...data,
      dailyThemePostList: [...data.dailyThemePostList],
    });
  },
  addNewPostToDailyThemePostList: (newPost) => {
    const { setMyGalleryData, myGalleryData: data } = get();
    setMyGalleryData({
      ...data,
      dailyThemePostList: [
        newPost,
        ...data.dailyThemePostList.filter((post) => !post.isPlaceholder),
      ],
    });
  },
  deletePostFromDailyThemePostList: (postId) => {
    const { setMyGalleryData, myGalleryData } = get();
    setMyGalleryData({
      ...myGalleryData,
      dailyThemePostList: myGalleryData.dailyThemePostList.filter(
        (post) => post.id !== postId,
      ),
    });
  },

  hydrateMyInfo: async (user: ProfileResponse) => {
    const {
      myProfileData: data,
      setMyProfileData,
      setMyProfileMounted,
      fetchMyStats,
    } = get();
    setMyProfileData({ ...data, loading: true });
    const response = await fetchProfileData(user.username, user.id);
    fetchMyStats(user);
    setMyProfileData({ ...response, loading: false });
    setMyProfileMounted(true);
  },
  hydrateMyGalleryData: async () => {
    const { setMyGalleryData, myInfo } = get();
    if (myInfo) {
      const response = await fetchGalleryData(myInfo.username, myInfo.id);
      setMyGalleryData(response);
    }
  },

  fetchMyStats: async (user: ProfileResponse) => {
    const { myInfo, setMyInfo, setMyStats } = get();
    const today = new Date();
    const todayFormatted = today.toISOString().split('T')[0];
    const timezoneOffsetMinutes = new Date().getTimezoneOffset();
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `user_stats/hydrate/${user?.id}/${todayFormatted}/${timezoneOffsetMinutes}`,
      );
      setMyStats({
        total_daily_theme_posts: response.data.total_daily_theme_posts,
        total_likes_received: response.data.total_likes_received,
        total_posts: response.data.total_posts,
        total_theme_votes: response.data.total_theme_votes,
        posts_this_month: response.data.posts_this_month,
        daily_fav_streak: response.data.daily_fav_streak,
      });
      // setBadges(response.data.badges);
      useExploreStore.getState().setTodayTags(response.data.today_tags);
      if (myInfo) {
        setMyInfo({
          ...myInfo,
          level: response.data.user_stats.level,
          experience: response.data.user_stats.experience,
          daily_theme_streak: response.data.user_stats.daily_theme_streak,
          pr_daily_theme_streak: response.data.user_stats.pr_daily_theme_streak,
          vote_streak: response.data.user_stats.vote_streak,
          pr_vote_streak: response.data.user_stats.pr_vote_streak,
        });
      }
    } catch (error) {
      console.log(error);
      showToast('Error checking new notifications');
    }
  },

  fetchAllPosts: async (user, reset = false) => {
    const { myGalleryData: data, setMyGalleryData } = get();
    if (!data.hasMoreAll || data.loadingAll) {
      return;
    }
    const page = reset ? 1 : data.allPage;
    setMyGalleryData({ ...data, loadingAll: true, hasMoreAll: true });
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `post/user-all/${user?.id}/${user?.id}/${page}/0`,
      );
      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !data.allPostList.some(
                (existingPost) => existingPost.id === newPost.id,
              ),
          );
        }
        setMyGalleryData({
          ...data,
          allPostList: reset
            ? [...newPosts]
            : [
                ...data.allPostList.filter((post) => !post.isPlaceholder),
                ...newPosts,
              ],
          hasMoreAll: true,
          loadingAll: false,
          allPage: page + 1,
        });
      } else {
        setMyGalleryData({ ...data, hasMoreAll: false });
      }
    } catch (error) {
      showToast('Error fetching user all posts');
    }
    setMyGalleryData({ ...data, loadingAll: false });
  },
  fetchDailyThemePosts: async (user, reset = false) => {
    const { myGalleryData: data, setMyGalleryData } = get();
    if (!data.hasMoreTheme || data.loadingTheme) {
      return;
    }
    const page = reset ? 1 : data.dailyThemePage;
    setMyGalleryData({ ...data, loadingTheme: true, hasMoreTheme: true });
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `post/user-daily-theme/${user?.id}/${user?.id}/${page}/0`,
      );

      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !data.dailyThemePostList.some(
                (existingPost) => existingPost.id === newPost.id,
              ),
          );
        }
        setMyGalleryData({
          ...data,
          dailyThemePostList: reset
            ? [...newPosts]
            : [
                ...data.dailyThemePostList.filter(
                  (post) => !post.isPlaceholder,
                ),
                ...newPosts,
              ],
          hasMoreTheme: true,
          loadingTheme: false,
          dailyThemePage: page + 1,
        });
      } else {
        setMyGalleryData({ ...data, hasMoreTheme: false });
      }
    } catch (error) {
      showToast('Error fetching user daily theme posts');
    }
    setMyGalleryData({ ...data, loadingTheme: false });
  },
}));

import React, { useState } from 'react';
import {
  View,
  Text,
  Pressable,
  TextInput,
  ActivityIndicator,
} from 'react-native';
import { font, globalstyles, radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { Tag } from '@/api/zod';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons/faMagnifyingGlass';
import { StyleSheet } from 'react-native';

interface ListItemProps {
  item: Tag;
  handleClickTag: (tag: Tag) => void;
}

export function RenderListItem({ item, handleClickTag }: ListItemProps) {
  const [hover, setHover] = useState(false);
  return (
    <Pressable
      style={{
        ...styles.tagListItem,
        backgroundColor: hover ? colors.primary_accent : undefined,
      }}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
      onPress={() => handleClickTag(item)}
    >
      <Text style={globalstyles.usernameText}>
        #{item.title} ({item.usage_count} posts)
      </Text>
    </Pressable>
  );
}

type SearchProps = {
  handleInputChange: (text: string) => void;
  cancelCallback: () => void;
  placeholderText: string;
  value: string;
  editable: boolean;
  loading: boolean;
};

export function Input_WebSearch({
  handleInputChange,
  cancelCallback,
  placeholderText,
  editable = true,
  loading,
  value,
}: SearchProps) {
  return (
    <View style={styles.inputContainer}>
      <View style={{ marginRight: spacing.md }}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          color={colors.secondary}
          size={font.lg}
        />
      </View>
      <TextInput
        placeholder={placeholderText}
        placeholderTextColor={colors.halfwhite}
        onChangeText={handleInputChange}
        style={styles.input}
        autoCorrect={false}
        editable={editable}
        value={value}
        multiline={false}
        numberOfLines={1}
      />
      {loading ? (
        <View style={styles.rightIconContainer}>
          <ActivityIndicator size="small" color={colors.secondary} />
        </View>
      ) : (
        <Pressable
          style={styles.rightIconContainer}
          disabled={value.length === 0}
          onPress={cancelCallback}
        >
          <FontAwesomeIcon
            icon={faXmark}
            color={value.length === 0 ? colors.primary : colors.secondary}
            size={font.lg}
          />
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  leadWrapper: {
    zIndex: 3,
    borderRadius: radius.sm,
    alignSelf: 'center',
    backgroundColor: colors.primary_accent,
  },
  inputContainer: {
    width: '100%',
    flexDirection: 'row',
    marginLeft: spacing.md,
    marginBottom: spacing.sm,
    alignItems: 'center',
    zIndex: 3,
  },
  tagListContainer: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    padding: spacing.md,
    backgroundColor: colors.primary_accent,
    borderRadius: radius.md,
    zIndex: 3,
  },
  tagListItem: {
    ...globalstyles.container,
    padding: spacing.md,
    marginHorizontal: spacing.md,
    marginBottom: spacing.sm,
    borderRadius: radius.sm,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    ...globalstyles.container,
    color: colors.secondary,
    padding: spacing.md,
    fontSize: font.lg,
    borderRadius: radius.xs,
  },
  rightIconContainer: {
    borderRadius: radius.sm,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: spacing.md,
  },
});

import { globalstyles, spacing } from '@/app/globalstyles';
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

interface Props {
  text: string;
  pw_email?: string;
}

export function Text_AuthHeader({ text, pw_email }: Props) {
  const headerText = () => {
    switch (text) {
      case 'oauth':
        return 'Log in to';
      case 'login':
        return 'Log in to';
      case 'signup':
        return 'Sign up for';
      case 'signup-username':
        return 'Sign up for';
      case 'pw-reset-1':
        return 'Reset password';
      case 'pw-reset-2':
        return 'Reset password';
      case 'pw-reset-3':
        return 'Reset password';
      default:
        return 'Log in to';
    }
  };

  const subtitle = () => {
    switch (text) {
      case 'login':
        return 'Welcome back! 🎉';
      case 'signup':
        return 'Welcome!';
      case 'signup-username':
        return 'Last step...';
      case 'pw-reset-1':
        return 'Enter your email below.';
      case 'pw-reset-2':
        return `Code sent to ${pw_email}`;
      case 'pw-reset-3':
        return 'Create new password';
      default:
        return '';
    }
  };

  const is_pw_related = text.includes('pw-reset');

  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <Text style={{ ...globalstyles.title, ...globalstyles._font_lg }}>
          {headerText()}
        </Text>
        {!is_pw_related && (
          <Text style={{ ...globalstyles.logoText }}> Whirl</Text>
        )}
      </View>
      {text !== 'oauth' && <Text style={styles.subtitle}>{subtitle()}</Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    paddingBottom: spacing.xl,
  },
  subtitle: {
    ...globalstyles.usernameText,
    alignSelf: 'center',
  },
});

import React, { useCallback, useEffect, useState } from 'react';
import { Text, StyleSheet, View, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { globalstyles, spacing } from '@/app/globalstyles';
import { authenticatedRequest, isValidWhirlUsername } from '@/modules/auth';
import { debounce } from 'lodash';
import { colors } from '@/app/colors';
import { formattedDate } from '@/components/RelativeDate';
import { InputBox_WithLabel } from '@/components/InputBox_WithLabel';
import { Subtitle } from '@/components/Subtitle';
import { useMyProfileStore } from '@/app/store/store';
import { useEditUsername } from '@/api/useEditUsername';
import { Button_WebBasic } from '@/components/Button_WebBasic';

const debouncedCheckUsername = debounce(
  async (username, setCheckingUsername, setIsTakenUsername, users_id) => {
    if (username !== '') {
      try {
        setCheckingUsername(true);
        const response = await (
          await authenticatedRequest()
        ).get(`auth/check_username_existence/${username}/${users_id}`);
        setIsTakenUsername(response.data);
        setCheckingUsername(false);
      } catch (error) {
        console.log(error);
        setCheckingUsername(false);
      }
    } else {
      setIsTakenUsername(false);
    }
  },
  200,
);

export function EditUsernameScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(user?.username);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [isTakenUsername, setIsTakenUsername] = useState(false);
  const [canChangeUsername, setCanChangeUsername] = useState(false);
  const [nextAvailableNameChange, setNextAvailableNameChange] = useState(0);

  const { updateUsername } = useEditUsername();

  const handleEdit = async () => {
    if (user && username) {
      const body = {
        users_id: user.id,
        username: username,
      };
      try {
        setLoading(true);
        updateUsername(body);
      } catch (error) {
        console.error('Error while updating username:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const checkUsername = useCallback(() => {
    debouncedCheckUsername(
      username,
      setCheckingUsername,
      setIsTakenUsername,
      user?.id,
    );
  }, [username, setCheckingUsername, setIsTakenUsername, user]);

  useEffect(() => {
    if (username && isValidWhirlUsername(username)) {
      checkUsername();
    }
  }, [checkUsername, username]);

  useEffect(() => {
    async function checkIfCanChange() {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `user/check_if_user_can_change_username/${user?.id}`,
      );
      setCanChangeUsername(response.data.canChangeUsername);
      setNextAvailableNameChange(response.data.nextAvailableNameChange);
    }
    checkIfCanChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={globalstyles.container}>
      <KeyboardAwareScrollView
        style={globalstyles.container}
        contentContainerStyle={styles.contentContainer}
        extraScrollHeight={Platform.OS === 'ios' ? 96 : 108}
        enableOnAndroid
      >
        <Subtitle text={'Change your username (once a week)'} />
        <InputBox_WithLabel
          placeholderText={'Username'}
          handleInputChange={setUsername}
          autocapitalize={'none'}
          maxLength={30}
          editable={canChangeUsername}
          value={username}
          label={'Username'}
        />
        {canChangeUsername ? (
          <>
            {username !== user?.username ? (
              <View
                style={{
                  marginHorizontal: spacing.lg,
                  marginBottom: spacing.sm,
                }}
              >
                {username && !isValidWhirlUsername(username) ? (
                  <Text style={{ color: colors.alert }}>
                    {username.startsWith('temp_')
                      ? 'Invalid username.'
                      : 'Invalid username: 3-30 characters, periods and underscores allowed.'}
                  </Text>
                ) : (
                  <>
                    {isTakenUsername && username !== '' ? (
                      <Text style={{ color: colors.alert }}>
                        Username taken - try another.
                      </Text>
                    ) : (
                      <>
                        {!checkingUsername && username !== '' ? (
                          <Text style={{ color: colors.accent }}>
                            That works!
                          </Text>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </View>
            ) : null}
          </>
        ) : (
          <View
            style={{
              marginHorizontal: spacing.lg,
              marginBottom: spacing.sm,
            }}
          >
            <Text style={{ color: colors.alert }}>
              Next available name change:
              {` ${formattedDate(nextAvailableNameChange)}`}
            </Text>
          </View>
        )}

        <View style={{ marginTop: spacing.md, alignSelf: 'center' }}>
          <Button_WebBasic
            callback={handleEdit}
            text={canChangeUsername ? 'Confirm' : 'Come back later'}
            loading={loading}
            disabled={username !== user?.username ? false : true}
          />
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    marginTop: spacing.md,
    marginHorizontal: spacing.md,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'center',
    paddingBottom: 96,
  },
});

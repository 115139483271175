import { Post } from '@/api/zod';
import { toggleSavePost, toggleLikePost } from '@/api/posts';
import { ActivityIndicator, LogBox, SafeAreaView, View } from 'react-native';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { RenderPost } from '@/components/RenderPost';
import { showToast } from '@/components/ErrorToast';
import Toast from 'react-native-toast-message';
import { authenticatedRequest } from '@/modules/auth';
import { colors } from '@/app/colors';
import { useMyProfileStore } from '@/app/store/store';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import {
  globalstyles,
  heightWithHeader,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { useExploreStore } from '@/app/store/exploreStore';

LogBox.ignoreLogs([
  'Non-serializable values were found in the navigation state',
]);

export function ViewEphemeralPostsScreen() {
  const {
    params: { posts, updateRootPostState, queryParams },
  } = useRoute<AuthenticatedNavigatorRouteProp<'ViewEphemeralPosts'>>();
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'ViewEphemeralPosts'>>();

  const user = useMyProfileStore((state) => state.myInfo);
  const [localPostList, setLocalPostList] = useState<Post[]>(posts);
  const [root] = useState(queryParams[0]);
  const [page, setPage] = useState(1);
  const [offset] = useState(queryParams[1]);
  const [loading, setLoading] = useState(false);
  const [hasMorePosts, setHasMorePosts] = useState(true);

  const updatePostInExplorePostList = useExploreStore(
    (state) => state.updatePostInExplorePostList,
  );
  const updatePostInAllPostList = useMyProfileStore(
    (state) => state.updatePostInAllPostList,
  );
  const updatePostInDailyThemePostList = useMyProfileStore(
    (state) => state.updatePostInDailyThemePostList,
  );
  const updatePostInChampPostList = useDailyThemeStore(
    (state) => state.updatePostInChampPostList,
  );

  const [activeDescriptionId, setActiveDescriptionId] = useState<number>();
  const updateActiveDescriptionId = (id: number | undefined) => {
    setActiveDescriptionId(id);
  };

  const fetchPosts = async () => {
    if (!hasMorePosts) {
      return;
    }
    setLoading(true);
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `${root}/${user?.id}/${page}/${offset}`,
      );
      if (response.data.length > 0) {
        setPage(page + 1);
        // setOffset(offset + 6);
        setLocalPostList([...localPostList, ...response.data]);
      } else {
        setHasMorePosts(false);
      }
    } catch (error) {
      // console.log(error);
      showToast('Error fetching posts');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocalPostList = async (updatedPost: Post) => {
    const postIndex = localPostList.findIndex(
      (post) => post.id === updatedPost.id,
    );

    if (postIndex !== -1) {
      const updatedPostsList = [
        ...localPostList.slice(0, postIndex),
        updatedPost,
        ...localPostList.slice(postIndex + 1),
      ];

      if (setLocalPostList) {
        setLocalPostList(updatedPostsList);
      }
      updateRootPostState(updatedPost);
    }
  };

  const handleSavePost = (post: Post) => {
    user && toggleSavePost(user, post);

    // global store update.
    const updatedPost = {
      ...post,
      isSaved: !post.isSaved,
      totalSaves: !post.isSaved ? post.totalSaves + 1 : post.totalSaves - 1,
    };
    updateRootPostState(updatedPost);
    updateLocalPostList(updatedPost);
    updatePostInExplorePostList(updatedPost);
    updatePostInAllPostList(updatedPost);
    updatePostInDailyThemePostList(updatedPost);
    updatePostInChampPostList(updatedPost);
  };

  const handleLike = (post: Post) => {
    user && toggleLikePost(user, post);

    const updatedPost = {
      ...post,
      likedOrVoted: !post.likedOrVoted,
      likeCount: !post.likedOrVoted ? post.likeCount + 1 : post.likeCount - 1,
    };

    // global store update.
    updateRootPostState(updatedPost);
    updateLocalPostList(updatedPost);
    updatePostInExplorePostList(updatedPost);
    updatePostInAllPostList(updatedPost);
    updatePostInDailyThemePostList(updatedPost);
    updatePostInChampPostList(updatedPost);
  };

  const renderPost = ({ item }: { item: Post }) => {
    if (item.isPlaceholder) {
      return null;
    }

    return (
      <RenderPost
        post={item}
        user={user}
        navigation={navigation}
        handleLike={handleLike}
        handleSavePost={handleSavePost}
        voteable={false}
        editable={false}
        activeDescriptionId={activeDescriptionId}
        updateActiveDescriptionId={updateActiveDescriptionId}
        updateLocalPostList={updateLocalPostList}
      />
    );
  };

  const renderFooter = () => {
    return loading && hasMorePosts ? (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="large" color={colors.halfwhite} />
      </View>
    ) : null;
  };

  return (
    <>
      <SafeAreaView style={globalstyles.container}>
        <FlatList
          data={localPostList}
          renderItem={renderPost}
          keyExtractor={(item) => String(item.id)}
          onEndReached={fetchPosts}
          style={{ height: heightWithHeader }}
          onEndReachedThreshold={0.8}
          ListFooterComponent={renderFooter}
          contentContainerStyle={{
            alignSelf: 'center',
            maxWidth: webContentWidth * 2,
          }}
        />
      </SafeAreaView>
      <Toast />
    </>
  );
}

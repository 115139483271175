import { useNavigation, useRoute } from '@react-navigation/native';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import React, { useCallback, useEffect, useState } from 'react';
import { Text, StyleSheet, Pressable, View } from 'react-native';
import { Image } from 'expo-image';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { colors } from '@/app/colors';
import {
  globalstyles,
  heightWithHeader,
  longButtonWidth,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { Button_Submission } from '@/components/Button_Submission';
import { InputBox_WithLabel } from '@/components/InputBox_WithLabel';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Subtitle } from '@/components/Subtitle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { PromptListTagModal } from './promptListTagModal';
import { SimpleTagListTag, TagListTag } from '@/api/zod';
import { authenticatedRequest } from '@/modules/auth';
import { appendFileToFormData, useOpenImagePicker } from '@/api/upload';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import { useMyProfileStore } from '@/app/store/store';
import { Button_Settings } from '@/components/shared/Button_Settings';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';

export function EditTagListScreen() {
  const {
    params: { tag_list, tags },
  } = useRoute<AuthenticatedNavigatorRouteProp<'EditTagList'>>();
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation = useNavigation<AuthenticatedNavigatorProp<'EditTagList'>>();
  const transformToSimpleTagListTag = (
    tagList: TagListTag[],
  ): SimpleTagListTag[] => {
    return tagList.map((tag) => {
      const day = parseInt(tag.date.split('-')[2], 10);

      return {
        tag_str: tag.title,
        day: day,
      };
    });
  };
  const transformedTags: SimpleTagListTag[] = transformToSimpleTagListTag(tags);
  const extractMonthAndYearFromDate = (date: string): [number, number] => {
    const [year, month] = date.split('-').map(Number);
    return [month, year];
  };

  const [description, setDescription] = useState(tag_list.description);
  const [title, setTitle] = useState(tag_list.title);
  const [searchQuery, setSearchQuery] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createNewTagButton, setCreateNewTagButton] = useState(false);
  const [pressedDate, setPressedDate] = useState<number>(0);
  const [allTags, setAllTags] = useState<SimpleTagListTag[]>(transformedTags);

  const [month, year] = extractMonthAndYearFromDate(tags[0].date);

  const [imageFile, setImageFile] = useState<Blob>();

  const headerRight = useCallback(() => {
    return (
      <Pressable
        style={{
          ...globalstyles.chip,
          ...globalstyles._selectedChip,
          marginRight: spacing.md,
        }}
        onPress={() => setDeleteModalVisible(true)}
      >
        <Text style={globalstyles.smallbody}>Delete</Text>
      </Pressable>
    );
  }, []);

  useEffect(() => {
    navigation.setOptions({
      title: tag_list.title,
      headerRight: headerRight,
    });
  }, [headerRight, navigation, tag_list]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (imageFile) {
      appendFileToFormData(formData, 'file', imageFile);
    }

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const filteredTags = allTags.filter((tag) => tag.day >= currentDay);
    const tags_json = JSON.stringify(filteredTags);
    formData.append('tags_json', tags_json);
    user && formData.append('users_id', user.id.toString());
    formData.append('month', month.toString());
    formData.append('year', year.toString());

    try {
      await (
        await authenticatedRequest()
      ).post(`tag_lists/${tag_list.id}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      });

      navigation.navigate('Explore', {
        passedTag: undefined,
      });
      showSuccessToast('Success! List edited.');
    } catch (error) {
      console.error('Error creating tag list:', error);
      showToast('Error creating prompt list');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const authenticatedAxiosInstance = await authenticatedRequest();
      await authenticatedAxiosInstance.delete(`tag_lists/${tag_list.id}`);

      setDeleteModalVisible(false);
      navigation.navigate('Explore', { passedTag: undefined });
      showSuccessToast('Deleted list');
    } catch (error) {
      console.log(error);
      showToast('Error deleting list');
    } finally {
      setLoading(false);
    }
  };

  const addTag = (tag: SimpleTagListTag) => {
    const updatedTags = allTags.filter(
      (existingTag) => existingTag.day !== tag.day,
    );
    updatedTags.push(tag);
    setSearchQuery('');
    setAllTags(updatedTags);
    setCreateNewTagButton(false);
    updateModalVisible();
  };

  const updateModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  // const removeTag = (day: number) => {
  //   const updatedTags = allTags.filter(
  //     (existingTag) => existingTag.day !== day,
  //   );
  //   setAllTags(updatedTags);
  // };

  const detailsComplete = (): boolean => {
    return (
      (!!tag_list.image.url || !!imageFile) &&
      !!title &&
      !!description &&
      allTags.length > 3
    );
  };

  return (
    <>
      <KeyboardAwareScrollView
        style={globalstyles.container}
        contentContainerStyle={styles.contentContainer}
        extraScrollHeight={48}
        enableOnAndroid
      >
        <Subtitle text={`Editing your prompt list: ${tag_list.title}`} />
        <InputBox_WithLabel
          placeholderText={'Enter title'}
          handleInputChange={setTitle}
          autocapitalize={'sentences'}
          maxLength={50}
          value={title}
          label={'Edit event title'}
        />
        <InputBox_WithLabel
          placeholderText={'Prompt list description...'}
          handleInputChange={setDescription}
          autocapitalize={'sentences'}
          maxLength={280}
          multiline
          value={description}
          label={'Edit description'}
        />
        <Pressable
          style={{
            ...styles.image,
            ...globalstyles.centeringContainer,
            marginBottom: spacing.md,
          }}
        >
          <Image
            source={
              !imageFile
                ? { uri: tag_list.image.url }
                : { uri: URL.createObjectURL(imageFile) }
            }
            transition={250}
            style={styles.image}
          />
        </Pressable>
        <View style={{ marginBottom: spacing.md }}>
          <Button_Settings
            callback={useOpenImagePicker(setImageFile)}
            title={'Select a new banner image'}
            icon={imageFile ? faCheck : faImage}
            iconColor={imageFile ? colors.accent : colors.secondary}
          />
        </View>
        <Text
          style={{
            ...globalstyles.label,
            marginBottom: spacing.md,
          }}
        >
          Edit your daily themes (minimum 4)
        </Text>
        <RenderButtonsForMonth
          month={month}
          year={year}
          tagsForMonth={allTags}
          // removeTag={removeTag}
          handlePress={(day: number) => {
            setPressedDate(day);
            setModalVisible(true);
          }}
        />
        <View style={{ marginVertical: spacing.md, alignSelf: 'center' }}>
          <Button_Submission
            callback={handleSubmit}
            title={'Edit prompt list'}
            loading={loading}
            disabled={!detailsComplete()}
            bgColor={detailsComplete() ? colors.accent : colors.primary_accent}
          />
        </View>
        <PromptListTagModal
          visible={modalVisible}
          updateVisible={updateModalVisible}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          year={year}
          month={month}
          day={pressedDate}
          addTag={addTag}
          allTags={allTags}
          createNewTagButton={createNewTagButton}
          setCreateNewTagButton={(bool) => setCreateNewTagButton(bool)}
        />
      </KeyboardAwareScrollView>
      <Modal_TwoOptions
        cancel={() => setDeleteModalVisible(false)}
        isVisible={deleteModalVisible}
        title={'Delete list?'}
        callback1={handleDelete}
        callback2={() => setDeleteModalVisible(false)}
        text1={'Yes delete  list (no take backs)'}
        icon1={faTrash}
        iconColor1={colors.alert}
        text2={'Nevermind'}
        icon2={faTimes}
      />
    </>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    height: heightWithHeader,
    paddingTop: spacing.md,
    paddingBottom: 96,
    marginHorizontal: spacing.md,
    alignSelf: 'center',
    maxWidth: webContentWidth * 2,
  },
  image: {
    height: longButtonWidth,
    width: longButtonWidth,
    backgroundColor: colors.primary_accent,
  },
});

interface DateProps {
  month: number;
  year: number;
  handlePress: (day: number) => void;
  // removeTag: (day: number) => void;
  tagsForMonth: Array<{ day: number; tag_str: string }>;
}

const RenderButtonsForMonth: React.FC<DateProps> = ({
  month,
  year,
  handlePress,
  // removeTag,
  tagsForMonth,
}) => {
  const daysInMonth = getDaysInMonth(month, year);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;

  const buttons = Array.from({ length: daysInMonth }, (_, i) => i + 1).map(
    (day) => {
      const tagForDay = tagsForMonth.find((item) => item.day === day);
      const buttonText = tagForDay
        ? `${monthNames[month - 1]} ${day} -> ${tagForDay.tag_str}`
        : `${monthNames[month - 1]} ${day} ->`;

      const isPastDay = month === currentMonth && day <= currentDay;
      const iconAndTextColor = isPastDay
        ? 'rgba(255,255,255,0.4)'
        : tagForDay
        ? colors.high_accent
        : colors.secondary;

      return (
        <Button_Settings
          key={day}
          callback={() => handlePress(day)}
          // cancelCallback={
          //   tagForDay && !isPastDay ? () => removeTag(day) : undefined
          // }
          title={buttonText}
          icon={tagForDay ? faCheck : faPlus}
          iconColor={iconAndTextColor}
          textColor={iconAndTextColor}
          disabled={isPastDay}
        />
      );
    },
  );

  return <>{buttons}</>;
};

const getDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

import React from 'react';
import { Text, Pressable, ActivityIndicator } from 'react-native';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';

interface Props {
  text: string;
  callback: () => void;
  loading: boolean;
  disabled?: boolean;
  bgColor?: string;
  bigWidth?: boolean;
}

export const Button_WebBasic = ({
  text,
  callback,
  loading,
  disabled,
  bgColor,
  bigWidth,
}: Props) => {
  return (
    <Pressable
      style={{
        ...globalstyles.roundedButtonWeb,
        backgroundColor: bgColor ? bgColor : colors.accent,
        borderRadius: radius.xs,
        paddingHorizontal: bigWidth ? spacing.xl * 2 : spacing.lg,
      }}
      disabled={disabled ? disabled : false}
      onPress={callback}
    >
      {!loading ? (
        <Text style={globalstyles.title}>{text}</Text>
      ) : (
        <ActivityIndicator color={colors.secondary} size={'small'} />
      )}
    </Pressable>
  );
};

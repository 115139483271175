import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { HomeScreenIcon } from '../HomeScreenIcon';
import {
  border,
  globalstyles,
  height,
  radius,
  spacing,
} from '@/app/globalstyles';
import { Image } from 'expo-image';
import placeholderProf from '../../../assets/profile-placeholder.jpg';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { useNavigation } from '@react-navigation/native';
import { useMyProfileStore } from '@/app/store/store';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { colors } from '@/app/colors';
import Buttons_SignupCTA from '../unauthenticated/Buttons_SignupCTA';

interface Props {
  hoverInCallback: () => void;
}

export function Buttons_HomeTopRight({ hoverInCallback }: Props) {
  const myInfo = useMyProfileStore((state) => state.myInfo);

  return (
    <View style={styles.userNavigationContainer}>
      {myInfo?.isVisitor ? (
        <Buttons_SignupCTA />
      ) : (
        <LoggedInUserThumbnail hoverInCallback={hoverInCallback} />
      )}
    </View>
  );
}

interface LoggedInProps {
  hoverInCallback: () => void;
}

const LoggedInUserThumbnail = ({ hoverInCallback }: LoggedInProps) => {
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Explore'>>();
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const isNewNotifications = useMyProfileStore(
    (state) => state.newNotifications,
  );
  const navigateToNotifications = () => {
    navigation.push('Notifications');
  };
  const goToMyProfile = () => {
    myInfo &&
      navigation.navigate('UserProfile', { username: myInfo?.username });
  };

  return (
    <>
      <View
        style={{
          ...globalstyles.chip,
          backgroundColor: colors.invisible,
          marginRight: spacing.md,
          borderWidth: 0,
        }}
      >
        <HomeScreenIcon
          icon={faBell}
          callback={navigateToNotifications}
          hoverIn={true}
          activeIcon={isNewNotifications}
        />
      </View>
      <Pressable
        onPress={goToMyProfile}
        style={{
          borderColor: colors.invisible,
          // borderColor: active ? colors.secondary : colors.invisible,
          borderWidth: border.sm,
          borderRadius: radius.xl,
        }}
        onHoverIn={hoverInCallback}
      >
        <Image
          style={{
            ...globalstyles.profileImageSmall,
          }}
          source={
            myInfo?.Profile_picture
              ? { uri: `${myInfo.Profile_picture.url}?tpl=med` }
              : placeholderProf
          }
        />
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  userNavigationContainer: {
    paddingRight: spacing.md,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 4,
  },
  imageContainer: {
    justifyContent: 'space-between',
    backgroundColor: 'pink',
    height: height,
    width: '100%',
  },
});

import { useHealthCheck } from '@/api/health';
import { useCurrentUser } from '@/api/me';
import { login, logout } from '@/modules/auth';
import { useMemo } from 'react';

export function useSession() {
  const { isSuccess: online, isLoading: healthCheckLoading } = useHealthCheck();
  const { data: user, isLoading: userLoading } = useCurrentUser();

  const loading = useMemo(
    () => healthCheckLoading || userLoading,
    [healthCheckLoading, userLoading],
  );

  return {
    loading,
    online,
    login,
    logout,
    user,
  };
}

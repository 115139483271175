import { authenticatedRequest, isValidSocialUsername } from '@/modules/auth';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { useMyProfileStore } from '@/app/store/store';
import { Button_Submission } from '@/components/Button_Submission';
import { InputBox_WithLabel } from '@/components/InputBox_WithLabel';

export function EditSocialLinksScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'EditSocialLinks'>>();
  const myProfileData = useMyProfileStore((state) => state.myProfileData);
  const setMyProfileData = useMyProfileStore((state) => state.setMyProfileData);
  const [loading, setLoading] = useState(false);
  const [discord_name, setDiscord] = useState('');
  const [instagram_name, setInstagram] = useState('');
  const [twitter_name, setTwitter] = useState('');
  const [threads_name, setThreads] = useState('');
  const [linktree_name, setLinktree] = useState('');

  useEffect(() => {
    myProfileData.links.map((item: any) => {
      if (item.social_media_type_id === 1) {
        setDiscord(item.username);
      } else if (item.social_media_type_id === 2) {
        setInstagram(item.username);
      } else if (item.social_media_type_id === 3) {
        setTwitter(item.username);
      } else if (item.social_media_type_id === 5) {
        setThreads(item.username);
      } else if (item.social_media_type_id === 6) {
        setLinktree(item.username);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = async () => {
    const body = {
      discord_username: discord_name,
      instagram_username: instagram_name,
      twitter_username: twitter_name,
      threads_username: threads_name,
      linktree_username: linktree_name,
    };

    try {
      setLoading(true);
      const axiosInstance = await authenticatedRequest();
      const response = await axiosInstance.post(
        `social_media_links/me/${user?.id}`,
        body,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      setMyProfileData({ ...myProfileData, links: response.data });
      navigation.goBack();
    } catch (error) {
      console.error('Error while updating social media links:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDiscordChange = (text: string) => {
    if (isValidSocialUsername(text) || text === '') {
      setDiscord(text);
    }
  };
  const handleIGChange = (text: string) => {
    if (isValidSocialUsername(text) || text === '') {
      setInstagram(text);
    }
  };
  const handleThreadsChange = (text: string) => {
    if (isValidSocialUsername(text) || text === '') {
      setThreads(text);
    }
  };
  const handleTwitterChange = (text: string) => {
    if (isValidSocialUsername(text) || text === '') {
      setTwitter(text);
    }
  };
  const handleTreeChange = (text: string) => {
    // no validation here.
    setLinktree(text);
  };

  return (
    <ScrollView
      style={globalstyles.container}
      contentContainerStyle={{
        height: heightWithHeader,
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      <InputBox_WithLabel
        placeholderText={'Discord username'}
        handleInputChange={handleDiscordChange}
        autocapitalize={'none'}
        maxLength={33}
        value={discord_name}
        label={'Discord'}
      />
      <InputBox_WithLabel
        placeholderText={'Instagram username'}
        autocapitalize={'none'}
        maxLength={33}
        value={instagram_name.toLowerCase()}
        handleInputChange={handleIGChange}
        label={'Instagram'}
      />

      <InputBox_WithLabel
        placeholderText={'X username'}
        autocapitalize={'none'}
        maxLength={33}
        value={twitter_name}
        handleInputChange={handleTwitterChange}
        label={'X'}
      />
      <InputBox_WithLabel
        placeholderText={'Threads username'}
        autocapitalize={'none'}
        maxLength={33}
        value={threads_name}
        handleInputChange={handleThreadsChange}
        label={'Threads'}
      />
      <InputBox_WithLabel
        placeholderText={'Linktree username'}
        autocapitalize={'none'}
        value={linktree_name}
        handleInputChange={handleTreeChange}
        label={'Linktr.ee'}
      />

      <View style={{ marginTop: spacing.md, alignSelf: 'center' }}>
        <Button_Submission
          callback={handleEdit}
          title={'Confirm'}
          loading={loading}
        />
      </View>
    </ScrollView>
  );
}

import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { Post } from '@/api/zod';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faCircleUp as faCircleUpRegular } from '@fortawesome/free-regular-svg-icons/faCircleUp';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons/faThumbtack';
import { faComment as faCommentRegular } from '@fortawesome/free-regular-svg-icons/faComment';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons/faHeart';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons/faHeart';

type PostActionButtonsProps = {
  post: Post;
  metadataCallback: () => void;
  commentCallback: () => void;
  saveCallback: () => void;
  likeCallback: () => void;
  voteable?: boolean;
  isPreview?: boolean;
};

export function PostActionButtons({
  post,
  metadataCallback,
  commentCallback,
  saveCallback,
  likeCallback,
  voteable,
  isPreview,
}: PostActionButtonsProps) {
  return (
    <View style={styles.actionButtonContainer}>
      {post.prompt !== '' ? (
        <TouchableOpacity
          style={styles.icon}
          onPress={metadataCallback}
          activeOpacity={0.9}
          disabled={isPreview}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            color={colors.secondary}
            style={{ paddingRight: 12 }}
            size={18}
          />
        </TouchableOpacity>
      ) : null}
      <TouchableOpacity
        style={styles.icon}
        onPress={saveCallback}
        activeOpacity={0.9}
        disabled={isPreview}
      >
        <FontAwesomeIcon
          icon={faThumbtack}
          color={post.isSaved ? colors.accent : colors.secondary}
          style={{ paddingRight: spacing.md }}
          size={18}
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.icon}
        onPress={commentCallback}
        activeOpacity={0.9}
        disabled={isPreview}
      >
        <FontAwesomeIcon
          icon={faCommentRegular}
          color={colors.secondary}
          style={{ paddingRight: spacing.md }}
          size={22}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={likeCallback}
        activeOpacity={0.9}
        disabled={isPreview}
      >
        {voteable ? (
          <FontAwesomeIcon
            icon={faCircleUpRegular}
            color={post.likedOrVoted ? colors.accent : colors.secondary}
            size={22}
          />
        ) : (
          <FontAwesomeIcon
            icon={post.likedOrVoted ? faHeartSolid : faHeartRegular}
            color={post.likedOrVoted ? colors.accent : colors.secondary}
            size={22}
          />
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  actionButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary_accent,
    borderRadius: radius.sm,
    padding: spacing.md,
  },
  icon: {
    marginRight: 0,
  },
});

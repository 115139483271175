import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

export const goToUser = (
  username: string,
  navigation: StackNavigationProp<ParamListBase>,
) => {
  navigation.push('UserProfile', {
    username: username,
  });
};

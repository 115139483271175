import { colors } from '@/app/colors';
import { spacing } from '@/app/globalstyles';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { ActivityIndicator, Pressable, StyleSheet } from 'react-native';
import withAuthPopup from '../../shared/hoc/withAuthPopup';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  handleCreateComment: () => void;
  disabled: boolean;
  newCommentLoading: boolean;
  isMineInDailyTheme?: boolean;
}

const Button_CreateComment: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  handleCreateComment,
  disabled,
  newCommentLoading,
  isMineInDailyTheme,
}) => {
  const handlePress = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      handleCreateComment();
    }
  };
  return (
    <Pressable
      style={styles.actionButtonContainer}
      onPress={handlePress}
      disabled={disabled}
    >
      {!newCommentLoading ? (
        <FontAwesomeIcon
          style={styles.actionButton}
          icon={faPaperPlane}
          color={isMineInDailyTheme ? colors.halfwhite : colors.secondary}
          size={20}
        />
      ) : (
        <ActivityIndicator size={'small'} color={colors.secondary} />
      )}
    </Pressable>
  );
};

export default withAuthPopup(Button_CreateComment);

const styles = StyleSheet.create({
  actionButtonContainer: {
    alignItems: 'center',
  },
  actionButton: {
    marginHorizontal: spacing.sm,
    marginBottom: spacing.sm,
  },
});

import React, { useState } from 'react';
import { Text, View, StyleSheet, Pressable, Linking } from 'react-native';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { ParamListBase } from '@react-navigation/native';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { StackNavigationProp } from '@react-navigation/stack';

interface Button_WebFooterProps {
  navigation: StackNavigationProp<ParamListBase>;
}

export const Button_WebFooter = ({ navigation }: Button_WebFooterProps) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const handleSettingsOpen = () => {
    setSettingsOpen(!settingsOpen);
  };
  return (
    <View style={styles.wrapper}>
      {!settingsOpen ? (
        <Pressable
          style={{
            ...styles.button,
            backgroundColor: hover
              ? colors.primary_offset_2
              : colors.primary_offset,
          }}
          onPress={handleSettingsOpen}
          onHoverIn={() => setHover(true)}
          onHoverOut={() => setHover(false)}
        >
          <FontAwesomeIcon icon={faBars} size={20} color={colors.secondary} />
        </Pressable>
      ) : (
        <Pressable
          style={{
            ...styles.button,
            backgroundColor: colors.primary_offset,
          }}
          onPress={handleSettingsOpen}
        >
          <View
            style={{
              paddingHorizontal: spacing.md,
              paddingBottom: spacing.md,
            }}
          >
            <RenderSettingsListItem
              text={'Discord'}
              callback={() =>
                Linking.openURL('https://discord.gg/aiartcommunity')
              }
            />
            <RenderSettingsListItem
              text={'Instagram'}
              callback={() =>
                Linking.openURL('https://instagram.com/joinwhirl')
              }
            />
            <RenderSettingsListItem
              text={'Threads'}
              callback={() => Linking.openURL('https://threads.net/@joinwhirl')}
            />
            <RenderSettingsListItem
              text={'X'}
              callback={() => Linking.openURL('https://x.com/joinwhirl')}
            />
            <RenderSettingsListItem
              text={'Help'}
              callback={() => navigation.push('Help')}
            />
            <RenderSettingsListItem
              text={'Terms'}
              callback={() => navigation.push('TermsOfUse')}
            />
            <RenderSettingsListItem
              text={'Privacy'}
              callback={() => navigation.push('Privacy')}
            />
          </View>
          <View style={{ alignSelf: 'flex-end' }}>
            <FontAwesomeIcon
              icon={faXmark}
              size={20}
              color={colors.secondary}
            />
          </View>
        </Pressable>
      )}
    </View>
  );
};

interface SettingsLinksProps {
  text: string;
  callback: () => void;
}

const RenderSettingsListItem = ({ text, callback }: SettingsLinksProps) => {
  const [hover, setHover] = useState(false);
  return (
    <Pressable
      style={{
        marginVertical: spacing.sm,
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
      onPress={callback}
    >
      <Text style={hover ? globalstyles.body : globalstyles.bodyfade}>
        {text}
      </Text>
      <View
        style={{
          height: 1,
          flex: 1,
          backgroundColor: colors.primary_accent,
          paddingRight: spacing.lg,
          marginLeft: spacing.sm,
        }}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    padding: spacing.md,
    borderRadius: radius.xl,
  },
  wrapper: {
    position: 'absolute',
    zIndex: 4,
    bottom: spacing.lg,
    right: spacing.lg,
  },
});

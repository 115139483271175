import React from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Post } from '@/api/zod';
import { Image } from 'expo-image';
import {
  maxWebContentWidth,
  radius,
  webContentWidth,
  width,
} from '@/app/globalstyles';
import Modal from 'react-native-modal';
import { Button_ModalClose } from '@/components/shared/Button_ModalClose';

interface Props {
  visible: boolean;
  toggleVisible: () => void;
  post?: Post;
  bare_link?: string;
}

export const Modal_FullScreenImage = ({
  visible,
  toggleVisible,
  post,
  bare_link,
}: Props) => {
  return (
    <Modal
      isVisible={visible}
      style={{ margin: 0 }}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      hideModalContentWhileAnimating={true}
    >
      <Button_ModalClose close={toggleVisible} />
      <Pressable onPress={toggleVisible} style={styles.container}>
        {post ? (
          <>
            {post.images[0].image.url && (
              <Image
                source={{ uri: post.images[0].image.url }}
                style={{
                  width: webContentWidth * 3,
                  height: undefined,
                  zIndex: 1,
                  aspectRatio:
                    (post.images[0].image.meta.width || 1) /
                    (post.images[0].image.meta.height || 1),
                }}
                contentFit={'cover'}
                transition={250}
              />
            )}
          </>
        ) : null}
        {bare_link ? (
          <>
            {bare_link && (
              <Image
                source={{ uri: bare_link }}
                style={{
                  width: width,
                  height: width,
                  maxWidth: maxWebContentWidth / 2,
                  maxHeight: maxWebContentWidth / 2,
                  aspectRatio: 1,
                  zIndex: 1,
                  borderRadius: radius.sm,
                }}
                contentFit={'cover'}
                transition={250}
              />
            )}
          </>
        ) : null}
      </Pressable>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    // ...globalstyles.container,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

import { unauthenticatedRequest } from '@/modules/auth';
import { useState } from 'react';

interface Props {
  email: string;
}

export const useEmailVerification = ({ email }: Props) => {
  const [sendingCode, setSendingCode] = useState(false);
  const [verifyingDetails, setVerifyingDetails] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [expiredCode, setExpiredCode] = useState(false);
  const [canSendNewCode, setCanSendNewCode] = useState(false);
  const [timeUntilCanSendCode, setTimeUntilCanSendCode] = useState(0);

  const sendCode = async () => {
    if (canSendNewCode && !emailTaken) {
      setInvalidCode(false);
      setExpiredCode(false);

      try {
        setSendingCode(true);
        const response: boolean = await unauthenticatedRequest().post(
          '/auth_2/email_verification_code',
          {
            email: email,
          },
        );
        if (response) {
          setCanSendNewCode(false);
          setTimeUntilCanSendCode(60);
        } else {
          setEmailTaken(true);
        }
      } catch {
        console.log('error!');
      } finally {
        setSendingCode(false);
      }
    }
  };

  async function verifyCode(code: string, email: string): Promise<string> {
    try {
      setVerifyingDetails(true);
      const response: any = await unauthenticatedRequest().post(
        '/auth_2/verify_code',
        {
          code: code,
          email: email,
        },
      );
      return response.data;
    } catch {
      console.log('error');
      return 'error';
    } finally {
      setVerifyingDetails(false);
    }
  }

  const resetValidationState = () => {
    setInvalidCode(false);
    setEmailTaken(false);
    setExpiredCode(false);
  };

  return {
    resetValidationState,
    sendCode,
    verifyCode,
    setEmailTaken,
    setExpiredCode,
    setInvalidCode,
    setCanSendNewCode,
    setTimeUntilCanSendCode,
    sendingCode,
    verifyingDetails,
    emailTaken,
    invalidCode,
    expiredCode,
    canSendNewCode,
    timeUntilCanSendCode,
  };
};

import React from 'react';
import { View, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';

type SubtitleProps = {
  text: string;
};

export function Subtitle({ text }: SubtitleProps) {
  return (
    <View style={{ marginBottom: spacing.md }}>
      <Text style={globalstyles.title}>{text}</Text>
    </View>
  );
}

import React, { useEffect, useState } from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import {
  globalstyles,
  heightWithHeader,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { EMPTYSTATE_OPENAIIMAGEOBJECT, OpenAIImageObject } from '@/api/zod';
import { useStudioStore } from '@/app/store/studioStore';
import { colors } from '@/app/colors';
import { debounce } from 'lodash';
import { Modal_FullScreenImage } from '../Modal_FullScreenImage';
import { addPlaceholders, toggleStarImage } from '@/api/studio';
import { RenderGalleryImage } from './RenderGalleryImage';
// import { useNavigation } from '@react-navigation/native';
// import { MyGalleryStackProp } from '@/navigators/types';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { useMyProfileStore } from '@/app/store/store';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { InputBox_WithSubmit } from '@/components/InputBox_WithSubmit';
import { ImageGenGalleryPromptHeader } from '@/components/studio/ImageGenGalleryPromptHeader';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';

const numbers = Array.from({ length: 4 }, (_, i) => i + 1);

export function MyStudioGalleryScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  // const navigation = useNavigation<MyGalleryStackProp<'MyStudioGallery'>>();
  const [numColumns, setNumColumns] = useState(4);
  const [filterStarred, setFilterStarred] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [localQueryText, setLocalQueryText] = useState('');
  const [listKey, setListKey] = useState(Math.random().toString());
  const [fullScreenModalVisible, setFullScreenModalVisible] = useState(false);
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const submissionThemeInfo = useDailyThemeStore(
    (state) => state.submissionThemeInfo,
  );
  // const updateSubmitted = useDailyThemeStore((state) => state.updateSubmitted);
  const [pressedItem, setPressedItem] = useState<OpenAIImageObject>(
    EMPTYSTATE_OPENAIIMAGEOBJECT,
  );
  const [isCreatePostModalVisible, setCreatePostModalVisible] = useState(false);
  const toggleCreatePostModal = () => {
    setCreatePostModalVisible(!isCreatePostModalVisible);
  };

  const changeColumns = (newNumColumns: number) => {
    setNumColumns(newNumColumns);
    setListKey(Math.random().toString());
  };

  const myGallery = useStudioStore((state) => state.myGallery);
  const searchImages = useStudioStore((state) => state.searchImages);
  const updateImageInMyGallery = useStudioStore(
    (state) => state.updateImageInMyGallery,
  );
  const updateImageInPublicGallery = useStudioStore(
    (state) => state.updateImageInPublicGallery,
  );
  const loadingMyGallery = useStudioStore((state) => state.loadingMyGallery);
  const hasMoreMyGalleryImages = useStudioStore(
    (state) => state.hasMoreMyGalleryImages,
  );
  const fetchMyGalleryImages = useStudioStore(
    (state) => state.fetchMyGalleryImages,
  );
  const hasRemainingCredits = useStudioStore(
    (state) => state.hasRemainingCredits,
  );

  const handleTextChange = (text: string) => {
    setLocalQueryText(text);
  };

  const updateFullScreenModalVisible = async () => {
    setFullScreenModalVisible(!fullScreenModalVisible);
  };

  const handlePressStar = () => {
    setFilterStarred(!filterStarred);
    if (user) {
      if (localQueryText !== '') {
        try {
          setLoadingSearch(true);
          if (user) {
            searchImages(localQueryText, !filterStarred, user?.id, true);
          }
          setLoadingSearch(false);
        } catch (error) {
          setLoadingSearch(false);
        }
      } else {
        fetchMyGalleryImages(user.id, !filterStarred, true);
      }
    }
  };

  const toggleStar = (image: OpenAIImageObject) => {
    user && toggleStarImage(user.id, image);

    const updatedImage = {
      ...image,
      isStarred: !image.isStarred,
    };
    updateImageInMyGallery(updatedImage);
    if (image.public) {
      updateImageInPublicGallery(updatedImage);
    }
  };

  const handlePressPost = (item: OpenAIImageObject) => {
    setPressedItem(item);
    if (submissionThemeInfo.loggedInUserHasSubmitted) {
      GoToCreatePost(item, null);
    } else {
      toggleCreatePostModal();
    }
  };

  const GoToCreatePost = (item: OpenAIImageObject, theme_id: number | null) => {
    toggleCreatePostModal();
    // need passedTags here though?
    if (theme_id) {
      // Get this right - I changed things up before.
      // navigation.navigate('CreatePost', {
      //   submission_theme_id: theme_id,
      //   generated_image: item,
      //   passed_prompt: item.prompt,
      // });
    }
    // navigation.navigate('CreatePost', {
    //   submission_theme_id: null,
    //   generated_image: item,
    //   passed_prompt: item.prompt,
    // });
  };

  const renderFooter = () => {
    return loadingMyGallery && hasMoreMyGalleryImages ? (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="small" color={colors.halfwhite} />
      </View>
    ) : null;
  };

  const filter = debounce(async () => {
    if (localQueryText !== '') {
      try {
        setLoadingSearch(true);
        if (user) {
          searchImages(localQueryText, filterStarred, user?.id, true);
        }
        setLoadingSearch(false);
      } catch (error) {
        setLoadingSearch(false);
      }
    } else {
      if (user) {
        fetchMyGalleryImages(user.id, filterStarred, true);
      }
    }
  }, 500);

  useEffect(() => {
    filter();
    return () => {
      filter.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQueryText]);

  return (
    <>
      <KeyboardAwareScrollView
        style={globalstyles.container}
        keyboardShouldPersistTaps="handled"
        enableOnAndroid
      >
        {user && (
          <FlatList
            data={addPlaceholders(myGallery, numColumns)}
            numColumns={numColumns}
            contentContainerStyle={{
              alignItems: 'center',
              paddingBottom: webContentWidth,
            }}
            style={{ height: heightWithHeader }}
            ListFooterComponent={renderFooter}
            renderItem={({ index, item }) => (
              <RenderGalleryImage
                item={item}
                index={index}
                numColumns={numColumns}
                setFullScreenUrl={setFullScreenUrl}
                toggleStar={toggleStar}
                navigateToCreatePost={() => handlePressPost(item)}
                updateFullScreenModalVisible={updateFullScreenModalVisible}
                hasRemainingCredits={hasRemainingCredits}
              />
            )}
            onEndReached={() => fetchMyGalleryImages(user.id, false)}
            onEndReachedThreshold={0.8}
            key={listKey}
            keyExtractor={(item) => item.id.toString()}
            showsVerticalScrollIndicator={false}
          />
        )}
        <View style={globalstyles.promptInputContainer}>
          <View style={{ alignItems: 'center', alignSelf: 'center' }}>
            <ImageGenGalleryPromptHeader
              columns={numbers}
              handlePressStar={handlePressStar}
              filterStarred={filterStarred}
              numColumns={numColumns}
              changeColumns={changeColumns}
            />
            <InputBox_WithSubmit
              placeholderText={'Search text prompt'}
              handleInputChange={handleTextChange}
              autocapitalize={'sentences'}
              multiline={false}
              isLoading={loadingSearch}
              maxLength={400}
              value={localQueryText}
              submitCallback={() => null}
              submitDisabled={true}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <Modal_TwoOptions
        cancel={toggleCreatePostModal}
        isVisible={isCreatePostModalVisible}
        title={`For the daily theme ${submissionThemeInfo.title}?`}
        callback1={() => GoToCreatePost(pressedItem, submissionThemeInfo.id)}
        callback2={() => GoToCreatePost(pressedItem, null)}
        text1={'Yes! Daily theme post'}
        icon1={faCheck}
        iconColor1={colors.alert}
        text2={'Nope - gallery post'}
        icon2={faTimes}
      />
      <Modal_FullScreenImage
        visible={fullScreenModalVisible}
        toggleVisible={updateFullScreenModalVisible}
        bare_link={fullScreenUrl}
      />
    </>
  );
}

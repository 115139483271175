import { relativeDate } from '@/components/RelativeDate';
import React from 'react';
import { Text, FlatList, View, SafeAreaView } from 'react-native';
import { globalstyles, heightWithHeader } from '@/app/globalstyles';
import { useExpStore, useMyProfileStore } from '@/app/store/store';
import { Text_ListHeader } from '@/components/Text_ListHeader';

export function ExpLogScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const fetchExpRecords = useExpStore((state) => state.fetchExpRecords);
  const expRecords = useExpStore((state) => state.expRecords);

  const handleLoadMore = () => {
    if (user) {
      fetchExpRecords(user);
    }
  };

  const renderItem = ({ item }: any) => (
    <View style={globalstyles.listItemContainer}>
      <View>
        <Text style={globalstyles.body}>{item.type}</Text>
        <Text style={globalstyles.tinyfade}>
          {relativeDate(item.created_at)}
        </Text>
      </View>
      <Text style={globalstyles.label}>
        {item.amount > 0 ? '+' : ''}
        {item.amount}
      </Text>
    </View>
  );

  const listHeader = () => {
    return <Text_ListHeader text={'Recent Experience points'} />;
  };

  return (
    <SafeAreaView style={globalstyles.container}>
      <FlatList
        data={expRecords}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
        onEndReached={handleLoadMore}
        ListHeaderComponent={listHeader}
        style={{ height: heightWithHeader }}
        onEndReachedThreshold={0.8}
      />
    </SafeAreaView>
  );
}

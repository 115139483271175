import React, { Dispatch, SetStateAction, useState } from 'react';
import { View } from 'react-native';
import { colors } from '@/app/colors';

import { unauthenticatedRequest, isValidResetCode } from '@/modules/auth';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import { InputBox_SignUp } from '../InputBox_SignUp';
import { AuthButton } from '../AuthButton';

interface Props {
  moveToStep: (s: number) => void;
  email: string;
  code: string;
  setCode: Dispatch<SetStateAction<string>>;
}

export const Layout_PWResetStep2 = ({
  moveToStep,
  email,
  code,
  setCode,
}: Props) => {
  const [denied, setDenied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expiredCode, setExpiredCode] = useState(false);

  const handleInputChange = (setter: any) => (text: string) => {
    const newText = text.replace(/\s+/g, '');
    setter(newText);
  };

  const verifyCode = async () => {
    setDenied(false);
    setExpiredCode(false);
    try {
      setLoading(true);
      const axios = unauthenticatedRequest();
      const response = await axios.post('auth/password_reset/verify_code', {
        reset_code: code,
        email: email,
      });
      handleResponse(response.data);
    } catch (error) {
      showToast('Error verifying code');
    } finally {
      setLoading(false);
    }
  };

  const handleResponse = (response: string) => {
    if (response === 'success') {
      showSuccessToast('Verified.');
      moveToStep(3);
    } else if (response === 'denied') {
      setDenied(true);
    } else if (response === 'expired') {
      showToast('Expired code.');
      setExpiredCode(true);
      setCode('');
      setTimeout(() => moveToStep(1), 500);
    }
  };

  const invalidCodeText = denied
    ? 'Wrong code. Try again'
    : expiredCode
    ? 'Expired.'
    : '';

  return (
    <View>
      <InputBox_SignUp
        value={code}
        handleInputChange={handleInputChange}
        setter={setCode}
        placeholderText={'Code'}
        keyboardType={'numeric'}
        maxLength={8}
        invalid={denied || expiredCode}
        invalidText={invalidCodeText}
      />
      <AuthButton
        logo={''}
        callback={verifyCode}
        text={'Verify'}
        disabled={!isValidResetCode(code)}
        bgColor={
          !isValidResetCode(code) ? colors.primary_offset : colors.accent
        }
        bgHover={
          !isValidResetCode(code)
            ? colors.primary_offset_2
            : colors.accent_highlight
        }
        loading={loading}
        sideblanks
      />
    </View>
  );
};

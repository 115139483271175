import {
  authenticatedRequest,
  getFreshAuthToken,
  logout,
} from '@/modules/auth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { queryKeys } from './keys';
import { useMyProfileStore } from '@/app/store/store';
import { ProfileResponse, ProfileResponseParser } from './zod';
import { appendFileToFormData } from './upload';

export function useCurrentUser() {
  return useQuery({
    queryKey: queryKeys.auth.me(),
    queryFn: safelyGetCurrentUser,
  });
}

async function safelyGetCurrentUser(): Promise<ProfileResponse | null> {
  // grab token. if none exists, just go to login page (null).
  const authToken = await getFreshAuthToken();
  if (!authToken) {
    return null;
  }

  // if token does exist, fetch user data.
  const user = await (
    await authenticatedRequest()
  )
    .get<{ data: ProfileResponse }>('auth/me')
    .then((response) => {
      return ProfileResponseParser.parse(response.data);
    })
    .catch((error) => {
      console.error('API request error:', error);
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        logout();
      }
    });
  return user || null;
}

export type UpdateSelfArgs = {
  users_id: number;
  bio: string;
  fullname: string;
  username?: string;
  Profile_picture?: Blob;
  Profile_banner?: Blob;
};

export function useUpdateSelf() {
  const queryClient = useQueryClient();
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  return useMutation({
    mutationFn: updateSelf,
    onSuccess: (result) => {
      if (result) {
        setMyInfo(result);
        queryClient.setQueryData(queryKeys.auth.me(), result);
      }
    },
  });
}

async function updateSelf(args: UpdateSelfArgs) {
  const formData = new FormData();

  if (args.Profile_picture) {
    appendFileToFormData(formData, 'file', args.Profile_picture);
  }

  if (args.Profile_banner) {
    appendFileToFormData(formData, 'banner', args.Profile_banner);
  }

  if (args.username) {
    formData.append('username', args.username);
  }

  formData.append('users_id', args.users_id.toString());
  formData.append('bio', args.bio);
  formData.append('fullname', args.fullname);

  const response = await (
    await authenticatedRequest()
  ).post<ProfileResponse>('user/edit', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return response.data;
}

export type UpdateUsernameArgs = {
  users_id: number;
  username: string;
};

export async function updateUsername(args: UpdateUsernameArgs) {
  const formData = new FormData();

  formData.append('users_id', args.users_id.toString());
  formData.append('username', args.username);

  const response = await (
    await authenticatedRequest()
  ).post<ProfileResponse>('user/edit/username', formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return response.data;
}

export async function permanentlyDeleteUser(args: UpdateUsernameArgs) {
  const response = await (
    await authenticatedRequest()
  ).post<string>('z_user/permanent_delete', args, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return response.data;
}

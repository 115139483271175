import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { useSession } from '@/app/store/session';
import Toast from 'react-native-toast-message';
import { useIsFocused } from '@react-navigation/native';
import { authenticatedRequest } from '@/modules/auth';
import { useMyProfileStore } from '@/app/store/store';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { showToast } from '@/components/ErrorToast';
import { globalstyles } from '@/app/globalstyles';
import LoadingText from '@/components/LoadingText';
import { HomeWebComponent } from '@/components/home/HomeWebComponent';
import { Icon_WhirlVideo } from '@/components/Icon_WhirlVideo';
import { Modal_Subscribed } from '@/components/Modal_Subscribed';
import { useStudioStore } from '@/app/store/studioStore';
import { useExploreStore } from '@/app/store/exploreStore';

export function HomeScreen() {
  const { user } = useSession();
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [subscribedModal, setSubscribedModal] = useState(false);
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Home'>>();
  const isFocused = useIsFocused();

  // const isNewNotifications = useNotificationStore(
  //   (state) => state.isNewNotifications,
  // );
  const setNewNotifications = useMyProfileStore(
    (state) => state.setNewNotifications,
  );
  const fetchVotingThemes = useDailyThemeStore(
    (state) => state.fetchVotingThemes,
  );

  const fetchAllThemeInfo = useDailyThemeStore(
    (state) => state.fetchAllThemeInfo,
  );
  // const submissionThemeInfo = useDailyThemeStore(
  //   (state) => state.submissionThemeInfo,
  // );
  // const navigateToNotifications = () => {
  //   navigation.push('Notifications');
  // };

  const fetchExplorePosts = useExploreStore((state) => state.fetchRecentPosts);
  const fetchCustomerInfo = useStudioStore((state) => state.fetchCustomerInfo);
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  const myInfo = useMyProfileStore((state) => state.myInfo);

  useEffect(() => {
    if (isFocused && myInfo) {
      checkForNewNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const checkForNewNotifications = async () => {
    try {
      const authenticatedAxios = await authenticatedRequest();
      const response = await authenticatedAxios.get(
        `notification/new/${myInfo?.id}`,
      );
      setNewNotifications(response.data.isNewNotifications);
    } catch (error) {
      console.log(error);
      showToast('Error checking new notifications');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        setMyInfo(user);
        await fetchAllThemeInfo(user.id);
        fetchVotingThemes(user, true);
        await fetchExplorePosts(user, true);
        fetchCustomerInfo(user.id);
        setLoading(false);
        setMounted(true);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const msg = urlParams.get('message');

    if (msg === 'subscribed') {
      setSubscribedModal(true);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [mounted]);

  return (
    <>
      {loading ? (
        <View
          style={{
            ...globalstyles.container,
            ...globalstyles.centeringContainer,
          }}
        >
          <Icon_WhirlVideo />
          <LoadingText />
        </View>
      ) : (
        <HomeWebComponent />
      )}
      <Toast />
      <Modal_Subscribed
        visible={subscribedModal}
        callbackCloseModal={() => setSubscribedModal(false)}
        navigation={navigation}
      />
    </>
  );
}

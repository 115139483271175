import React from 'react';
import { View, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';

interface HeaderProps {
  header: string;
  subtitle: string;
}

export function Text_WebPageHeader({ header, subtitle }: HeaderProps) {
  return (
    <View style={{ marginBottom: subtitle ? spacing.lg : 0, zIndex: 2 }}>
      <Text
        style={{
          marginBottom: spacing.md,
          ...globalstyles.title,
          ...globalstyles._font_xl,
        }}
      >
        {header}
      </Text>
      {subtitle !== '' && (
        <Text
          style={{
            ...globalstyles.body,
            ...globalstyles._font_lg,
            alignSelf: 'center',
          }}
        >
          {subtitle}
        </Text>
      )}
    </View>
  );
}

import { ProfileResponse, VISITOR } from '@/api/zod';
import { useDailyThemeStore } from '@/app/store/dailyThemeStore';
import { useExploreStore } from '@/app/store/exploreStore';
import { useSession } from '@/app/store/session';
import { useMyProfileStore } from '@/app/store/store';
import { FullScreenLoading } from '@/screens/FullScreenLoading';
import { Offline } from '@/screens/Offline';
import { mobileCheck } from '@/screens/SignUpSelect';
import React from 'react';
import { AuthenticatedNavigator } from './authenticated-navigator/AuthenticatedNavigator';
import { MobileNavigator } from './mobile-navigator/MobileNavigator';

export function NavigationRoot() {
  const { online, loading, user } = useSession();

  // key hydration buckets.
  const fetchTopPosts = useExploreStore((state) => state.fetchTopPosts);
  const hydrateDailyTheme = useDailyThemeStore(
    (state) => state.hydrateDailyTheme,
  );
  const fetchFinalRoundThemePosts = useDailyThemeStore(
    (state) => state.fetchFinalRoundThemePosts,
  );
  const setMyInfo = useMyProfileStore((state) => state.setMyInfo);
  const hydrateMyInfo = useMyProfileStore((state) => state.hydrateMyInfo);

  const hydrate = async () => {
    if (!user) {
      setMyInfo(VISITOR);
      fetchKeyState(VISITOR);
    } else {
      setMyInfo(user);
      fetchKeyState(user);
      hydrateMyInfo(user);
    }
  };

  const fetchKeyState = async (userObj: ProfileResponse) => {
    hydrateDailyTheme(userObj.id);
    fetchFinalRoundThemePosts(userObj);
    await fetchTopPosts(userObj, true);
  };

  if (loading) {
    return <FullScreenLoading />;
  }

  if (!online) {
    return <Offline />;
  }

  if (mobileCheck()) {
    return <MobileNavigator />;
  } else {
    hydrate();
    return <AuthenticatedNavigator />;
  }
}

import React from 'react';
import { View } from 'react-native';
import { globalstyles } from '@/app/globalstyles';
import { Image } from 'expo-image';

type ProfileImageProps = {
  image_url?: string;
};

export function ProfileImage({ image_url }: ProfileImageProps) {
  return (
    <>
      {image_url ? (
        <View style={globalstyles.profileImageContainerSmall}>
          <Image
            source={{
              uri: image_url,
            }}
            transition={250}
            style={globalstyles.profileImageSmall}
          />
        </View>
      ) : (
        <View style={globalstyles.profileImageContainerSmall}>
          <Image
            transition={250}
            source={require('../../assets/profile-placeholder.jpg')}
            style={globalstyles.profileImageSmall}
          />
        </View>
      )}
    </>
  );
}

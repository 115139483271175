import { colors } from '@/app/colors';
import {
  faArrowUp,
  faEllipsisVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { StyleSheet, Pressable, View, Text } from 'react-native';
import withAuthPopup from '../../shared/hoc/withAuthPopup';
import { Comment } from '@/api/zod';
import { globalstyles, spacing } from '@/app/globalstyles';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  handleLike: () => void;
  handleOptions: () => void;
  item: Comment;
}

const Buttons_CommentActions: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  handleLike,
  handleOptions,
  item,
}) => {
  const handlePressLike = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      handleLike();
    }
  };
  const handlePressOptions = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      handleOptions();
    }
  };
  return (
    <View style={styles.likeButtonContainer}>
      <View style={{ marginRight: spacing.sm }}>
        {item.likeCount > 0 ? (
          <Text style={globalstyles.tinybody}>{item.likeCount}</Text>
        ) : null}
      </View>
      <Pressable onPress={handlePressLike}>
        <FontAwesomeIcon
          icon={faArrowUp}
          color={item.isLiked ? colors.accent : colors.halfwhite}
          size={18}
        />
      </Pressable>
      <Pressable
        onPress={handlePressOptions}
        style={{ paddingLeft: spacing.sm }}
      >
        <FontAwesomeIcon
          icon={faEllipsisVertical}
          color={colors.halfwhite}
          size={18}
        />
      </Pressable>
    </View>
  );
};

export default withAuthPopup(Buttons_CommentActions);

const styles = StyleSheet.create({
  likeButtonContainer: {
    paddingRight: spacing.lg,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

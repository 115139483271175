import React from 'react';
import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import { Post } from '@/api/zod';
import { colors } from '@/app/colors';
import { authenticatedRequest } from '@/modules/auth';
import * as Clipboard from 'expo-clipboard';
import { showSuccessToast, showToast } from '@/components/ErrorToast';
import { globalstyles, spacing } from '@/app/globalstyles';
import { ModalHeader } from '@/components/ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons/faBookmark';
import Modal from 'react-native-modal';

interface MetadataModalProps {
  metadataModalVisible: boolean;
  updateMetadataModalVisible: () => void;
  updatePromptInfo: () => void;
  post: Post;
  userId: number | undefined;
  promptIsSaved: boolean;
  totalPromptSaves: number;
}

export const MetadataModal = ({
  metadataModalVisible,
  updateMetadataModalVisible,
  updatePromptInfo,
  post,
  userId,
  promptIsSaved,
  totalPromptSaves,
}: MetadataModalProps) => {
  const handleSavePrompt = async () => {
    if (userId) {
      if (promptIsSaved) {
        try {
          const authenticatedAxios = await authenticatedRequest();
          await authenticatedAxios.delete(
            `user_saved_prompts/${post.id}/${userId}`,
          );
          updatePromptInfo();
        } catch (error) {
          showToast('Error unsaving prompt');
        }
      } else {
        try {
          const authenticatedAxios = await authenticatedRequest();
          await authenticatedAxios.post('user_saved_prompts', {
            users_id: userId,
            posts_id: post.id,
          });
          updatePromptInfo();
        } catch (error) {
          showToast('Error saving prompt');
        }
      }
    }
  };

  const [copiedText, setCopiedText] = React.useState('');

  const copyToClipboard = async () => {
    setCopiedText(post.prompt);
    await Clipboard.setStringAsync(copiedText);
    showSuccessToast('Copied to clipboard');
  };

  return (
    <Modal
      isVisible={metadataModalVisible}
      hideModalContentWhileAnimating={true}
      style={{ margin: 0 }}
    >
      <Pressable
        style={{ ...globalstyles.container, justifyContent: 'center' }}
        onPress={updateMetadataModalVisible}
      >
        <View style={globalstyles.modalContent}>
          <ModalHeader
            callback={updateMetadataModalVisible}
            title={'Metadata'}
          />
          <View style={styles.labelContainer}>
            <Text style={{ ...globalstyles.label, marginBottom: spacing.md }}>
              Prompt
            </Text>
            <View style={styles.bodyContainer}>
              <TouchableOpacity
                onPress={handleSavePrompt}
                activeOpacity={0.9}
                style={{
                  marginRight: spacing.md,
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon
                  icon={faBookmark}
                  style={{ marginBottom: spacing.sm }}
                  color={promptIsSaved ? colors.accent : colors.secondary}
                  size={16}
                />
                {totalPromptSaves > 0 && (
                  <Text style={globalstyles.tinybody}>{totalPromptSaves}</Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={copyToClipboard}
                style={{ flex: 1 }}
              >
                <Text style={globalstyles.smallbody}>{post.prompt}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.labelContainer}>
            <Text style={{ ...globalstyles.label, marginBottom: spacing.md }}>
              Technology
            </Text>
            <View style={styles.bodyContainer}>
              {post.technology_type !== undefined ? (
                <View
                  style={{
                    ...globalstyles.chip,
                    backgroundColor: colors.primary,
                  }}
                >
                  <Text
                    style={{
                      ...globalstyles.chipText,
                      ...globalstyles._selectedChipText,
                    }}
                  >
                    {post.technology_type.emoji} {post.technology_type.name}
                  </Text>
                </View>
              ) : (
                <Text style={{ ...globalstyles.chipText, opacity: 0.6 }}>
                  🖖 We'll never know...
                </Text>
              )}
            </View>
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};

const styles = StyleSheet.create({
  bodyContainer: {
    paddingHorizontal: spacing.md,
    flexDirection: 'row',
  },
  labelContainer: {
    paddingHorizontal: spacing.lg,
    paddingTop: spacing.lg,
  },
});

import React from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { ProfileResponse } from '@/api/zod';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import ImageBackground from '@/components/ImageBackground';
import { formattedDate } from './RelativeDate';
import { colors } from '@/app/colors';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

interface JoinCardProps {
  bgImageUrl: string;
  title: string;
  submitted: boolean;
  id: number;
  date: number;
  user?: ProfileResponse | null | undefined;
  navigationCallback: (id: number) => void;
}

export function JoinCard({
  bgImageUrl,
  title,
  date,
  id,
  submitted,
  // user,
  navigationCallback,
}: JoinCardProps) {
  return (
    <Pressable onPress={() => navigationCallback(id)} style={styles.container}>
      <ImageBackground
        source={{ uri: bgImageUrl }}
        style={{
          ...globalstyles.profileBannerImage,
          justifyContent: 'space-between',
          paddingLeft: spacing.md,
          paddingBottom: spacing.md,
        }}
        imageStyle={{ borderRadius: radius.lg }}
      >
        <View style={{ alignSelf: 'flex-end' }}>
          {submitted ? (
            <View
              style={{
                padding: spacing.xs,
                backgroundColor: colors.accent,
                borderRadius: spacing.lg,
                margin: spacing.md,
              }}
            >
              <FontAwesomeIcon
                icon={faCheck}
                color={colors.secondary}
                size={20}
              />
            </View>
          ) : (
            <View style={{ height: 1, width: 1 }} />
          )}
        </View>
        <View>
          <View>
            <Text style={globalstyles.label}>{title}</Text>
          </View>
          <View>
            <Text style={globalstyles.tinyfade}>{formattedDate(date)}</Text>
          </View>
        </View>
      </ImageBackground>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: spacing.md,
    paddingBottom: spacing.md,
    borderRadius: radius.lg,
  },
});

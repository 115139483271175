import React from 'react';
import { Text, View, StyleSheet, ActivityIndicator } from 'react-native';
import { ExtendedBaseTheme, ProfileResponse } from '@/api/zod';
import { colors } from '@/app/colors';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { Text_WebPageHeader } from '../Text_WebPageHeader';
import { formattedDate } from '../RelativeDate';
import { Button_PromptListChip } from '../shared/Button_PromptListChip';
import { StackNavigationProp } from '@react-navigation/stack';
import { ParamListBase } from '@react-navigation/native';
import ImageBackground from '../ImageBackground';

export interface Props {
  user: ProfileResponse | null;
  themeInfo: ExtendedBaseTheme;
  navigation: StackNavigationProp<ParamListBase>;
}

export function Layout_ThemeHeader({ user, themeInfo, navigation }: Props) {
  if (user) {
    return (
      <ImageBackground
        source={{
          uri: `${themeInfo.background_image_landscape.url}?tpl=bigger`,
        }}
        style={styles.bg}
        imageStyle={{
          borderTopRightRadius: radius.sm,
          borderTopLeftRadius: radius.sm,
          opacity: 0.4,
        }}
      >
        <Text_WebPageHeader
          header={themeInfo.title}
          subtitle={'Vote for your favorite submissions!'}
        />
        <Button_PromptListChip navigation={navigation} theme={themeInfo} />
        <Text style={globalstyles.bodyfade}>
          {themeInfo.total_posts} posts | {formattedDate(themeInfo.submit_by)}
        </Text>
      </ImageBackground>
    );
  } else {
    return (
      <View style={{ padding: spacing.md }}>
        <ActivityIndicator size="large" color={colors.secondary} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  bg: {
    paddingVertical: spacing.lg,
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: spacing.xxs,
    marginHorizontal: 1,
  },
});

import React from 'react';
import { Text, View, Pressable, Linking } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';

export function HelpScreen() {
  return (
    <View style={{ ...globalstyles.container, alignItems: 'center' }}>
      <View style={{ padding: spacing.md }}>
        <Text
          style={{
            ...globalstyles.title,
            ...globalstyles._font_lg,
            alignSelf: 'flex-start',
            marginBottom: spacing.md,
          }}
        >
          Support
        </Text>
        <View style={{ marginBottom: spacing.xl }}>
          <View style={{ marginBottom: spacing.md }}>
            <Text style={globalstyles.body}>
              Need help? Contact us here: team@joinwhirl.com.
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={globalstyles.body}>
              We are also very active (the community, as well as the developer)
              on{' '}
            </Text>
            <Pressable
              onPress={() =>
                Linking.openURL('https://discord.gg/aiartcommunity')
              }
            >
              <Text
                style={{
                  ...globalstyles.body,
                  color: colors.accent,
                  textDecorationLine: 'underline',
                }}
              >
                Discord.
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
}

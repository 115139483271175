import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Pressable,
  View,
} from 'react-native';
import {
  border,
  globalstyles,
  radius,
  webContentWidth,
  spacing,
} from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { OpenAIImageObject } from '@/api/zod';
import { Image } from 'expo-image';
import { useStudioStore } from '@/app/store/studioStore';
import { useNavigation } from '@react-navigation/native';
import { AuthenticatedNavigatorProp } from '@/navigators/types';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { LinearGradient } from 'expo-linear-gradient';

type RenderGalleryImageProps = {
  item: OpenAIImageObject;
  numColumns: number;
  index: number;
  setFullScreenUrl: (url: string) => void;
  updateFullScreenModalVisible: () => void;
  toggleStar?: (image: OpenAIImageObject) => void;
  navigateToCreatePost?: () => void;
  hasRemainingCredits: boolean;
};

export function RenderGalleryImage({
  item,
  numColumns,
  index,
  hasRemainingCredits,
  setFullScreenUrl,
  toggleStar,
  navigateToCreatePost,
  updateFullScreenModalVisible,
}: RenderGalleryImageProps) {
  console.log(index);
  const [hoverIn, setHoverIn] = useState(false);
  const navigation = useNavigation<AuthenticatedNavigatorProp<'Studio'>>();
  const dynamicStyles = StyleSheet.create({
    textWrapper: {
      width: webContentWidth,
    },
  });
  const setTextPrompt = useStudioStore((state) => state.setTextPrompt);
  const handlePromptPress = (prompt: string) => {
    if (hasRemainingCredits) {
      setTextPrompt(prompt);
      navigation.push('Studio');
    }
  };

  const handleStarPress = () => {
    if (toggleStar) {
      toggleStar(item);
    }
  };

  return (
    <View style={{ padding: spacing.sm }}>
      <Pressable
        style={{
          borderColor: hoverIn ? colors.accent : colors.primary,
          borderWidth: border.sm,
          borderRadius: radius.sm,
          backgroundColor: hoverIn ? colors.accent : colors.primary,
        }}
        // onPress={handleStarPress}
        onLongPress={() => {
          if (!item.isPlaceholder) {
            setFullScreenUrl(item.image.url);
            updateFullScreenModalVisible();
          }
        }}
        onHoverIn={!item.isPlaceholder ? () => setHoverIn(true) : () => null}
        onHoverOut={!item.isPlaceholder ? () => setHoverIn(false) : () => null}
      >
        <View>
          {!item.isPlaceholder ? (
            <Image
              source={item.image.url}
              style={{
                width: webContentWidth,
                height: undefined,
                aspectRatio: 1,
                backgroundColor: colors.primary,
                borderRadius: radius.sm,
              }}
              contentFit="contain"
              transition={250}
            />
          ) : (
            <View
              style={{
                width: webContentWidth,
                height: undefined,
                aspectRatio: 1,
                backgroundColor: colors.primary_accent,
                borderRadius: radius.sm,
                opacity: 1 - index * 0.33,
              }}
            />
          )}
          {!item.isPlaceholder && (
            <>
              {toggleStar ? (
                <View
                  style={
                    numColumns !== 1
                      ? styles.star
                      : { ...styles.star, top: 12, right: 12 }
                  }
                >
                  <TouchableOpacity
                    activeOpacity={0.9}
                    onPress={handleStarPress}
                  >
                    <FontAwesomeIcon
                      icon={faStar}
                      size={numColumns === 5 ? 16 : 20}
                      color={item.isStarred ? colors.accent : colors.secondary}
                    />
                  </TouchableOpacity>
                </View>
              ) : null}
              <View
                style={
                  numColumns !== 1
                    ? styles.newPost
                    : { ...styles.newPost, bottom: 12, right: 12 }
                }
              >
                {navigateToCreatePost && (
                  <TouchableOpacity
                    activeOpacity={0.9}
                    style={styles.newPostContainer}
                    onPress={navigateToCreatePost}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      size={numColumns === 5 ? 8 : 12}
                      color={colors.secondary}
                    />
                  </TouchableOpacity>
                )}
              </View>
            </>
          )}
          {numColumns <= 3 && !item.isPlaceholder && hoverIn ? (
            <TouchableOpacity
              style={{
                ...dynamicStyles.textWrapper,
                ...styles.prompt,
                borderBottomRightRadius: radius.sm,
                borderBottomLeftRadius: radius.sm,
              }}
              activeOpacity={0.9}
              onPress={() => handlePromptPress(item.prompt)}
            >
              <Text
                style={{
                  ...globalstyles.body,
                  color: hoverIn ? colors.secondary : colors.halfwhite,
                  zIndex: 6,
                }}
                numberOfLines={2}
              >
                {item.prompt}
              </Text>
              <LinearGradient
                colors={['rgba(27,23,30,1)', 'rgba(27,23,30,1)']}
                style={{
                  ...globalstyles.homeScreenGradient,
                  borderBottomRightRadius: radius.sm,
                  borderBottomLeftRadius: radius.sm,
                }}
              />
            </TouchableOpacity>
          ) : null}
        </View>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  star: {
    position: 'absolute',
    top: 6,
    right: 6,
    zIndex: 4,
  },
  newPostContainer: {
    padding: spacing.sm,
    backgroundColor: colors.accent,
    borderRadius: radius.lg,
  },
  newPost: {
    position: 'absolute',
    bottom: 6,
    right: 6,
    zIndex: 4,
  },
  prompt: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: spacing.sm,
  },
});

import React from 'react';
import {
  CardStyleInterpolators,
  createStackNavigator,
} from '@react-navigation/stack';
import { AuthenticatedNavigatorParams } from '@/navigators/types';
import { HomeScreen } from '@/screens/Home';
import { EditProfileScreen } from '@/screens/profile/settings/EditProfile';
import { EditSocialLinksScreen } from '@/screens/profile/settings/EditSocialLinks';
import { CreatePostScreen } from '@/screens/CreatePost';
import { DailyThemeScreen } from '@/screens/daily theme/DailyTheme';
import { ViewPostsForVoteScreen } from '@/screens/daily theme/ViewPostsForVote';
import { UserProfileScreen } from '@/screens/profile/UserProfile';
import { NotificationsScreen } from '@/screens/Notifications';
import { ViewSinglePostScreen } from '@/screens/ViewSinglePost';
import { ExploreScreen } from '@/screens/Explore';
import { LevelsScreen } from '@/screens/levels/Levels';
import { DailyThemeArchivesIndexScreen } from '@/screens/daily theme/DailyThemeArchivesIndex';
import { CommunityScreen } from '@/screens/Community';
import { EditPostScreen } from '@/screens/EditPost';
import { ViewEphemeralPostsScreen } from '@/screens/ViewEphemeralPosts';
import { AboutScreen } from '@/screens/About';
import { LeaderboardScreen } from '@/screens/leaderboard/Leaderboard';
import { Leaderboard_DailyThemeStreakScreen } from '@/screens/leaderboard/Leaderboard_DailyThemeStreak';
import { Leaderboard_TotalExpScreen } from '@/screens/leaderboard/Leaderboard_TotalExp';
import { DailyThemeRound1VoteScreen } from '@/screens/daily theme/DailyThemeRound1Vote';
import { StudioScreen } from '@/screens/studio/Studio';
import { PublicGalleryScreen } from '@/screens/studio/PublicGallery';
import { MyStudioGalleryScreen } from '@/screens/studio/MyStudioGallery';
import { ExpLogScreen } from '@/screens/levels/ExpLog';
import { ExpListScreen } from '@/screens/levels/ExpList';
import { BadgesScreen } from '@/screens/levels/Badges';
import { VoteNextThemeScreen } from '@/screens/VoteNextTheme';
import { ViewChampsScreen } from '@/screens/ViewChamps';
import { DailyThemePodiumScreen } from '@/screens/daily theme/DailyThemePodium';
import { MySettingsScreen } from '@/screens/profile/settings/MySettings';
import { TermsOfUseScreen } from '@/screens/TermsOfUse';
import { PrivacyScreen } from '@/screens/Privacy';
import { ReportScreen } from '@/screens/Report';
import { LikesScreen } from '@/screens/Likes';
import { MySavedPromptsScreen } from '@/screens/profile/MySavedPrompts';
import { MySavedPostsScreen } from '@/screens/profile/MySavedPosts';
import { EditCommentScreen } from '@/screens/EditComment';
import { colors } from '@/app/colors';
import { EditPostImageScreen } from '@/screens/EditPostImage';
import { EditBannerScreen } from '@/screens/profile/settings/EditBanner';
import { ReleaseNotesScreen } from '@/screens/ReleaseNotes';
import { ViewBadgeInfoScreen } from '@/screens/profile/ViewBadgeInfo';
import { border, font, headerHeight } from '@/app/globalstyles';
import { EditUsernameScreen } from '@/screens/profile/settings/EditUsername';
import { AccountDetailsScreen } from '@/screens/profile/settings/AccountDetails';
import { DeleteAccountScreen } from '@/screens/profile/settings/DeleteAccount';
import { MyBlockedUsersScreen } from '@/screens/profile/settings/MyBlockedUsers';
import { SubscriptionsHistoryScreen } from '@/screens/profile/settings/SubscriptionsHistory';
import { LevelsListScreen } from '@/screens/levels/LevelsList';
import { Button_HeaderRightRefresh } from '@/components/Button_HeaderRightRefresh';
import { DailyThemeArchivesGalleryScreen } from '@/screens/daily theme/DailyThemeArchivesGallery';
import { TagListIndexScreen } from '@/screens/tag lists/TagListIndex';
import { ViewTagListScreen } from '@/screens/tag lists/ViewTagList';
import { CreateTagListScreen } from '@/screens/tag lists/CreateTagList';
import { EditTagListScreen } from '@/screens/tag lists/EditTagList';
import { TagListGalleryScreen } from '@/screens/tag lists/TagListGallery';
import { HelpScreen } from '@/screens/Help';
import { Layout_WebHeader } from '@/components/Layout_WebHeader';
import SuccessfulOAuthScreen from '@/screens/SuccessfulOAuth';

const AuthenticatedStack = createStackNavigator<AuthenticatedNavigatorParams>();

export function AuthenticatedNavigator() {
  return (
    <AuthenticatedStack.Navigator
      screenOptions={{
        header: Layout_WebHeader,
        headerTransparent: false,
        headerBackTitleVisible: false,
        headerTintColor: colors.secondary,
        headerShadowVisible: true,
        headerTitleStyle: {
          includeFontPadding: false,
          textAlignVertical: 'center',
          fontFamily: 'Manrope',
          fontSize: font.xl,
        },
        headerShown: true,
        headerStyle: {
          backgroundColor: colors.primary,
          borderBottomWidth: border.sm,
          borderBottomColor: colors.primary_accent,
          height: headerHeight,
        },
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
      }}
    >
      <AuthenticatedStack.Screen
        name="Explore"
        component={ExploreScreen}
        options={() => ({
          title: '',
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="SuccessfulOAuth"
        component={SuccessfulOAuthScreen}
        options={() => ({
          title: '',
        })}
      />
      <AuthenticatedStack.Screen
        name="Home"
        component={HomeScreen}
        options={() => ({
          title: '',
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="MySettings"
        component={MySettingsScreen}
        options={() => ({
          title: 'Settings',
        })}
      />
      <AuthenticatedStack.Screen
        name="MyBlockedUsers"
        component={MyBlockedUsersScreen}
        options={() => ({
          title: 'Blocked users',
        })}
      />
      <AuthenticatedStack.Screen
        name="SubscriptionsHistory"
        component={SubscriptionsHistoryScreen}
        options={() => ({
          title: 'Subscriptions History',
        })}
      />
      {/* <AuthenticatedStack.Screen
        name="Subscriptions"
        component={withSideNavigation(SubscriptionsScreen)}
      /> */}
      <AuthenticatedStack.Screen
        name="MySavedPrompts"
        component={MySavedPromptsScreen}
        options={() => ({
          title: 'Saved prompts',
        })}
      />
      <AuthenticatedStack.Screen
        name="MySavedPosts"
        component={MySavedPostsScreen}
        options={() => ({
          title: 'Saved posts',
        })}
      />
      <AuthenticatedStack.Screen
        name="Levels"
        component={LevelsScreen}
        options={() => ({
          headerTitle: 'Levels, Exp, Streaks',
        })}
      />
      <AuthenticatedStack.Screen
        name="LevelsList"
        component={LevelsListScreen}
        options={() => ({
          headerTitle: 'Levels list',
        })}
      />
      <AuthenticatedStack.Screen
        name="ExpLog"
        component={ExpLogScreen}
        options={() => ({
          title: 'Exp log',
        })}
      />
      <AuthenticatedStack.Screen
        name="ExpList"
        component={ExpListScreen}
        options={() => ({
          title: 'Experience points',
        })}
      />
      <AuthenticatedStack.Screen name="Badges" component={BadgesScreen} />
      <AuthenticatedStack.Screen name="Community" component={CommunityScreen} />
      <AuthenticatedStack.Screen
        name="ViewBadgeInfo"
        component={ViewBadgeInfoScreen}
        options={() => ({
          title: 'Badge info',
        })}
      />
      <AuthenticatedStack.Screen
        name="ViewChamps"
        component={ViewChampsScreen}
        options={() => ({
          title: '🎊 Daily Champ! 🎊',
        })}
      />
      <AuthenticatedStack.Screen
        name="UserProfile"
        component={UserProfileScreen}
        options={() => ({
          title: '',
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="DeleteAccount"
        component={DeleteAccountScreen}
        options={() => ({
          headerTitle: 'Delete account',
          headerTintColor: colors.alert,
        })}
      />
      <AuthenticatedStack.Screen
        name="AccountDetails"
        component={AccountDetailsScreen}
        options={() => ({
          headerTitle: 'Account details',
        })}
      />
      <AuthenticatedStack.Screen
        name="EditProfile"
        component={EditProfileScreen}
        options={() => ({
          headerTitle: 'Edit profile',
        })}
      />
      <AuthenticatedStack.Screen
        name="EditBanner"
        component={EditBannerScreen}
        options={() => ({
          headerTitle: 'Edit banner',
        })}
      />
      <AuthenticatedStack.Screen
        name="EditComment"
        component={EditCommentScreen}
        options={() => ({
          title: 'Edit comment',
        })}
      />
      <AuthenticatedStack.Screen
        name="EditSocialLinks"
        component={EditSocialLinksScreen}
        options={() => ({
          title: 'Edit socials',
        })}
      />
      <AuthenticatedStack.Screen
        name="EditUsername"
        component={EditUsernameScreen}
        options={() => ({
          title: 'Edit username',
        })}
      />
      <AuthenticatedStack.Screen
        name="CreatePost"
        component={CreatePostScreen}
        options={() => ({
          title: 'Draft',
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="EditPost"
        component={EditPostScreen}
        options={() => ({
          title: 'Edit post',
        })}
      />
      <AuthenticatedStack.Screen
        name="EditPostImage"
        component={EditPostImageScreen}
        options={() => ({
          title: 'Edit post image',
        })}
      />
      <AuthenticatedStack.Screen
        name="ViewEphemeralPosts"
        component={ViewEphemeralPostsScreen}
        options={() => ({
          title: '',
        })}
      />
      <AuthenticatedStack.Screen
        name="ViewPostsForVote"
        component={ViewPostsForVoteScreen}
        options={() => ({
          title: '',
        })}
      />
      <AuthenticatedStack.Screen
        name="ViewSinglePost"
        component={ViewSinglePostScreen}
        options={() => ({
          title: 'Post',
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="DailyTheme"
        component={DailyThemeScreen}
        options={() => ({
          headerTransparent: true,
          title: '',
        })}
      />
      <AuthenticatedStack.Screen
        name="DailyThemeRound1Vote"
        component={DailyThemeRound1VoteScreen}
        options={() => ({
          title: '',
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="DailyThemeArchivesIndex"
        component={DailyThemeArchivesIndexScreen}
        options={() => ({
          title: 'The Archives',
        })}
      />
      <AuthenticatedStack.Screen
        name="DailyThemeArchivesGallery"
        component={DailyThemeArchivesGalleryScreen}
        options={() => ({
          title: 'The Archives',
        })}
      />
      <AuthenticatedStack.Screen
        name="Notifications"
        component={NotificationsScreen}
      />
      <AuthenticatedStack.Screen
        name="Leaderboard"
        component={LeaderboardScreen}
        options={() => ({
          title: 'Leaderboard',
        })}
      />
      <AuthenticatedStack.Screen
        name="Leaderboard_DailyThemeStreak"
        component={Leaderboard_DailyThemeStreakScreen}
        options={() => ({
          title: 'Leaderboad',
        })}
      />
      <AuthenticatedStack.Screen
        name="Leaderboard_TotalExp"
        component={Leaderboard_TotalExpScreen}
        options={() => ({
          title: 'Leaderboad',
        })}
      />
      <AuthenticatedStack.Screen
        name="VoteNextTheme"
        component={VoteNextThemeScreen}
        options={() => ({
          headerTitle: '👇 Vote next theme 👇',
          headerRight: Button_HeaderRightRefresh,
          headerTintColor: colors.secondary,
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="DailyThemePodium"
        component={DailyThemePodiumScreen}
        options={() => ({
          title: 'Champ vote',
          headerRight: Button_HeaderRightRefresh,
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen name="About" component={AboutScreen} />
      <AuthenticatedStack.Screen name="Help" component={HelpScreen} />
      <AuthenticatedStack.Screen
        name="ReleaseNotes"
        component={ReleaseNotesScreen}
        options={() => ({
          title: 'Release Notes',
        })}
      />
      <AuthenticatedStack.Screen
        name="Studio"
        component={StudioScreen}
        options={() => ({
          title: 'Studio',
        })}
      />
      <AuthenticatedStack.Screen
        name="PublicGallery"
        component={PublicGalleryScreen}
        options={() => ({
          title: 'Community',
        })}
      />
      <AuthenticatedStack.Screen
        name="MyStudioGallery"
        component={MyStudioGalleryScreen}
        options={() => ({
          title: 'My Gallery',
        })}
      />
      <AuthenticatedStack.Screen
        name="Privacy"
        component={PrivacyScreen}
        options={() => ({
          title: '',
        })}
      />
      <AuthenticatedStack.Screen
        name="TermsOfUse"
        component={TermsOfUseScreen}
        options={() => ({
          title: '',
        })}
      />
      <AuthenticatedStack.Screen name="Likes" component={LikesScreen} />
      <AuthenticatedStack.Screen name="Report" component={ReportScreen} />
      <AuthenticatedStack.Screen
        name="TagListIndex"
        component={TagListIndexScreen}
        options={() => ({
          title: 'Prompt lists',
        })}
      />
      <AuthenticatedStack.Screen
        name="ViewTagList"
        component={ViewTagListScreen}
        options={() => ({
          title: '',
          headerTransparent: true,
        })}
      />
      <AuthenticatedStack.Screen
        name="CreateTagList"
        component={CreateTagListScreen}
        options={() => ({
          title: 'Create Prompt List',
        })}
      />
      <AuthenticatedStack.Screen
        name="EditTagList"
        component={EditTagListScreen}
        options={() => ({
          title: 'Edit Prompt List',
        })}
      />
      <AuthenticatedStack.Screen
        name="TagListGallery"
        component={TagListGalleryScreen}
      />
    </AuthenticatedStack.Navigator>
  );
}

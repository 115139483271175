import React from 'react';
import { Pressable, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { Technology } from '@/api/zod';

interface ChipProps {
  item: Technology;
  isSelected: boolean;
  loading: boolean;
  callback: (item: Technology) => void;
}

export const Button_TechnologyChip = ({
  item,
  isSelected,
  loading,
  callback,
}: ChipProps) => {
  return (
    <Pressable
      key={item.id}
      disabled={loading}
      style={
        !isSelected
          ? {
              ...globalstyles.chip,
              marginRight: spacing.xs,
              marginBottom: spacing.xs,
            }
          : {
              ...globalstyles.chip,
              ...globalstyles._selectedChip,
              marginRight: spacing.xs,
              marginBottom: spacing.xs,
            }
      }
      onPress={() => callback(item)}
    >
      <Text
        style={[
          globalstyles.chipText,
          isSelected && globalstyles._selectedChipText,
        ]}
      >
        {`${item.emoji} ${item.name}`}
      </Text>
    </Pressable>
  );
};

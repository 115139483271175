import { Post } from '@/api/zod';
import { colors } from '@/app/colors';
import { useMyProfileStore } from '@/app/store/store';
import { unauthenticatedRequest } from '@/modules/auth';
import {
  AuthenticatedNavigatorProp,
  AuthenticatedNavigatorRouteProp,
} from '@/navigators/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { globalstyles } from '@/app/globalstyles';
import { Layout_Post } from '@/components/shared/Layout_Post';
import { goToUser } from '@/api/navigation';
import usePostActions from '@/api/usePostActions';
import { useComment } from '@/api/useComment';
import Modal_TwoOptions from '@/components/shared/Modal_TwoOptions';
import { faFlag, faPencil, faTimes } from '@fortawesome/free-solid-svg-icons';
import ImageBackground from '@/components/ImageBackground';
import { Layout_PageNotFound } from '@/components/shared/Layout_PageNotFound';

export function ViewSinglePostScreen() {
  const {
    params: { postId, updateRootPostState },
  } = useRoute<AuthenticatedNavigatorRouteProp<'ViewSinglePost'>>();
  const navigation =
    useNavigation<AuthenticatedNavigatorProp<'ViewSinglePost'>>();
  const myInfo = useMyProfileStore((state) => state.myInfo);
  const [post, setPost] = useState<Post>();
  const [loading, setLoading] = useState(true);
  const [notAvailable, setNotAvailable] = useState(false);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const editable = post?.users_id === myInfo?.id;

  // HANDLERS.
  const togglePostSettings = () => {
    setSettingsModalVisible(!settingsModalVisible);
  };
  const { handleLike, handleSave } = usePostActions(
    updateRootPostState,
    setPost,
  );
  const like = () => {
    post && handleLike(post);
  };
  const save = () => {
    post && handleSave(post);
  };
  const {
    fetchComments,
    comments,
    loading: commentsLoading,
    newCommentLoading,
    hasMoreComments,
    createComment,
    deleteComment,
    createReply,
    likeCommentOrReply,
  } = useComment();
  const toggleComment = () => {
    if (!commentsVisible) {
      myInfo && post && fetchComments(post, myInfo.id);
    }
    setCommentsVisible(!commentsVisible);
  };
  const handlePressSettings = () => {
    setSettingsModalVisible(false);
    if (post && myInfo) {
      if (!editable) {
        navigation.push('Report', {
          postId: post.id,
          reporting_user_id: myInfo?.id,
          reported_user_id: post.users_id,
        });
      } else {
        navigation.navigate('EditPost', {
          post: post,
          updateLocalPostList: setPost,
        });
      }
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const axios = unauthenticatedRequest();
        const response = await axios.get(
          `post/single_long/${postId}/${myInfo?.id}`,
        );
        if (!response.data.isDeleted) {
          setPost(response.data);
        } else {
          setNotAvailable(true);
        }
      } catch (error) {
        setNotAvailable(true);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RENDERERS.
  const renderNoPost = () => {
    if (loading) {
      return <ActivityIndicator size="small" color={colors.secondary} />;
    } else if (notAvailable) {
      return <Layout_PageNotFound navigation={navigation} />;
    }
  };

  const imageSource = post
    ? { uri: `${post.images[0].image.url}?tpl=bigger` }
    : require('../../assets/bg-splotch.jpg');

  return (
    <ImageBackground
      style={styles.page}
      source={imageSource}
      blurRadius={50}
      imageStyle={{
        opacity: 0.2,
      }}
    >
      {post ? (
        <View style={styles.contentContainer}>
          <Layout_Post
            post={post}
            goToUser={() => goToUser(post.username, navigation)}
            like={like}
            save={save}
            isAnonymous={post.isDailyThemePost && !post.isVisible}
            navigation={navigation}
            toggleComment={toggleComment}
            fetchComments={fetchComments}
            likeCommentOrReply={likeCommentOrReply}
            commentsVisible={commentsVisible}
            comments={comments}
            loading={commentsLoading}
            hasMoreComments={hasMoreComments}
            createComment={createComment}
            deleteComment={deleteComment}
            createReply={createReply}
            setPressedItem={setPost}
            newCommentLoading={newCommentLoading}
            togglePostSettings={togglePostSettings}
            handleClose={() => null}
            fadeAnim={1}
          />
        </View>
      ) : (
        <>{renderNoPost()}</>
      )}
      <Modal_TwoOptions
        cancel={() => setSettingsModalVisible(false)}
        isVisible={settingsModalVisible}
        callback1={handlePressSettings}
        text1={editable ? 'Edit post' : 'Report post'}
        icon1={editable ? faPencil : faFlag}
        iconColor1={colors.high_accent}
        callback2={() => setSettingsModalVisible(false)}
        text2={'Back'}
        icon2={faTimes}
      />
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  page: {
    ...globalstyles.container,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

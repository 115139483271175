import { getExpList } from '@/api/levels';
import { colors } from '@/app/colors';
import { globalstyles, heightWithHeader, spacing } from '@/app/globalstyles';
import { useMyProfileStore } from '@/app/store/store';
import { Text_ListHeader } from '@/components/Text_ListHeader';
import React from 'react';
import { Text, View, FlatList, SafeAreaView } from 'react-native';

export function ExpListScreen() {
  const user = useMyProfileStore((state) => state.myInfo);
  const renderItem = ({ item, index }: { item: any; index: number }) => (
    <View style={globalstyles.listItemContainer}>
      <View>
        <Text style={globalstyles.body}>{item.type}</Text>
        {item.caveat ? (
          <Text style={globalstyles.tinyfade}>{item.caveat}</Text>
        ) : null}
      </View>
      {user &&
      index === getExpList(user.daily_theme_streak.toString()).length - 1 ? (
        <View
          style={{ backgroundColor: colors.secondary, padding: spacing.sm }}
        >
          <Text style={{ ...globalstyles.label, color: colors.primary }}>
            +{item.amount}
          </Text>
        </View>
      ) : (
        <Text style={globalstyles.label}>+{item.amount}</Text>
      )}
    </View>
  );

  const listHeader = () => {
    return (
      <>
        <Text_ListHeader text={'Multiplied by Level.'} />
      </>
    );
  };

  return (
    <SafeAreaView style={globalstyles.container}>
      {user && (
        <FlatList
          data={getExpList(user?.daily_theme_streak.toString())}
          renderItem={renderItem}
          style={{ height: heightWithHeader }}
          ListHeaderComponent={listHeader}
          keyExtractor={(item, index) => index.toString()}
        />
      )}
    </SafeAreaView>
  );
}

import { colors } from '@/app/colors';

export function getExpList(daily_theme_streak: string) {
  return [
    {
      id: 1,
      type: 'Daily champ',
      amount: '500',
      color: 'rgba(100,100,100,0.8)',
    },
    {
      id: 2,
      type: 'Daily winner',
      amount: '100',
      color: 'rgba(100,100,100,0.8)',
    },
    {
      id: 3,
      type: 'Top post',
      amount: '50',
      color: colors.primary_accent,
    },
    {
      id: 4,
      type: 'New post',
      amount: '10',
      caveat: 'max 50 Exp. / day',
      color: colors.primary_accent,
    },
    {
      id: 5,
      type: 'Vote on Champ',
      amount: '3',
      caveat: '3 votes per day count toward Exp.',
      color: 'rgba(100,100,100,0.4)',
    },
    {
      id: 6,
      type: 'Receive Champ vote',
      amount: '2',
      color: 'rgba(100,100,100,0.4)',
    },
    {
      id: 7,
      type: 'Receive first round vote',
      amount: '1',
      color: 'rgba(100,100,100,0.2)',
    },
    {
      id: 8,
      type: 'Current daily theme streak',
      amount: daily_theme_streak,
      caveat: 'Accumulates - very powerful!',
      color: '#f29e4c',
    },
  ];
}

export const LVLS = [
  { level: 1, exp: 0 },
  { level: 2, exp: 100 },
  { level: 3, exp: 500 },
  { level: 4, exp: 1000 },
  { level: 5, exp: 2000 },
  { level: 6, exp: 4000 },
  { level: 7, exp: 8000 },
  { level: 8, exp: 16000 },
  { level: 9, exp: 32000 },
  { level: 10, exp: 64000 },
  { level: 11, exp: 128000 },
  { level: 12, exp: 256000 },
  { level: 13, exp: 512000 },
];

export type ExpRecord = {
  id: number;
  amount: number;
  created_at: number;
  type: string;
};

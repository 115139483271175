import React from 'react';
import { View, Pressable } from 'react-native';
import { Image } from 'expo-image';
import { ProfileResponse } from '@/api/zod';
import { globalstyles, radius, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import { StyleSheet } from 'react-native';

interface ProfPicProps {
  handleFullScreen: () => void;
  activeUser: ProfileResponse;
}

export const ProfilePicture = ({
  handleFullScreen,
  activeUser,
}: ProfPicProps) => {
  const imageSource = activeUser.Profile_picture
    ? { uri: `${activeUser.Profile_picture.url}?tpl=large` }
    : require('../../../assets/profile-placeholder.jpg');
  return (
    <View style={styles.profContainer}>
      <Pressable onPress={handleFullScreen} style={globalstyles.profileImage}>
        <Image
          source={imageSource}
          style={globalstyles.profileImage}
          transition={250}
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  profContainer: {
    // bottom: -spacing.xl,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: radius.xxl,
    zIndex: 2,
    padding: spacing.xs,
    backgroundColor: colors.primary,
  },
});

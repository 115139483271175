import React from 'react';
import { View, Text } from 'react-native';
import { globalstyles, spacing } from '@/app/globalstyles';
import { colors } from '@/app/colors';
import ModalHOC from '../shared/hoc/ModalHOC';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

interface Props {
  username: string;
}

const Modal_Content = ({ username }: Props) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <FontAwesomeIcon
        style={{ marginRight: spacing.md }}
        icon={faDiscord}
        size={20}
        color={colors.secondary}
      />
      <Text style={globalstyles.title}>{username}</Text>
    </View>
  );
};

const Modal_DiscordUsername = ModalHOC(Modal_Content);

export default Modal_DiscordUsername;

import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  View,
  StyleSheet,
  TextInput,
  Text,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import { colors } from '@/app/colors';
import {
  font,
  radius,
  spacing,
  border,
  maxWebContentWidth,
  globalstyles,
} from '@/app/globalstyles';

type Props = {
  setter: Dispatch<SetStateAction<string>>;
  value: string;
  handleInputChange?: (
    setter: Dispatch<SetStateAction<string>>,
  ) => (text: string) => void;
  sendCodeText: string;
  sendCode: () => void;
  validEmail: boolean;
  canSendNewCode: boolean;
  invalid: boolean;
  invalidText: string;
  loading: boolean;
};

export function InputBox_SendCode({
  handleInputChange,
  setter,
  value,
  sendCodeText,
  sendCode,
  validEmail,
  canSendNewCode,
  invalid,
  invalidText,
  loading,
}: Props) {
  const [inputHover, setInputHover] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);
  return (
    <>
      <Pressable
        style={{
          ...styles.inputWrapper,
          marginBottom: spacing.sm,
          backgroundColor: !validEmail
            ? colors.primary_offset
            : inputHover
            ? colors.primary_offset_2
            : colors.primary_offset,
          borderColor: !validEmail
            ? colors.primary_offset_2
            : inputHover
            ? colors.primary_light
            : colors.primary_offset_2,
        }}
        onHoverIn={() => setInputHover(true)}
        onHoverOut={() => setInputHover(false)}
      >
        <TextInput
          placeholder={'Code'}
          placeholderTextColor={
            validEmail ? colors.halfwhite : colors.primary_accent
          }
          keyboardType={'numeric'}
          autoCapitalize="none"
          autoCorrect={false}
          style={styles.input}
          maxLength={6}
          editable={validEmail}
          value={value}
          onChangeText={handleInputChange ? handleInputChange(setter) : setter}
        />
        <Pressable
          style={{
            ...styles.codeSubmit,
            backgroundColor:
              validEmail && buttonHover && canSendNewCode
                ? colors.primary_light
                : validEmail
                ? colors.primary_accent
                : colors.primary_offset_2,
          }}
          onPress={sendCode}
          onHoverIn={() => setButtonHover(true)}
          onHoverOut={() => setButtonHover(false)}
        >
          {!loading ? (
            <Text
              style={{
                ...globalstyles.title,
                marginRight: spacing.sm,
                color:
                  validEmail && canSendNewCode
                    ? colors.secondary
                    : colors.halfwhite,
              }}
            >
              {sendCodeText}
            </Text>
          ) : (
            <View style={{ alignSelf: 'center' }}>
              <ActivityIndicator size={'small'} color={colors.secondary} />
            </View>
          )}
        </Pressable>
      </Pressable>
      {invalid && invalidText ? (
        <View style={styles.invalidWrapper}>
          <Text style={styles.invalidText}>{invalidText}</Text>
        </View>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  inputWrapper: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: maxWebContentWidth / 3, // change?
    borderRadius: radius.sm,
    borderWidth: border.xs,
  },
  input: {
    color: colors.secondary,
    padding: spacing.md,
    width: '100%',
    fontSize: font.lg,
    borderTopLeftRadius: radius.md,
    borderBottomLeftRadius: radius.md,
  },
  invalidWrapper: {
    paddingBottom: spacing.sm,
    alignSelf: 'center',
  },
  invalidText: {
    color: colors.high_accent,
  },
  codeSubmit: {
    borderLeftWidth: border.xs,
    borderLeftColor: colors.primary_accent,
    justifyContent: 'center',
    paddingHorizontal: spacing.lg,
    height: '100%',
    borderTopRightRadius: radius.sm,
    borderBottomRightRadius: radius.sm,
  },
});

import { ProfileResponse, TagListTag } from '@/api/zod';
import { EMPTYTECHNOLOGY, Post, Tag, Technology } from '@/api/zod';
import { showToast } from '@/components/ErrorToast';
import { authenticatedRequest, unauthenticatedRequest } from '@/modules/auth';
import { create } from 'zustand';

const initialExploreStoreState = {
  postList: [],
  hasMorePosts: true,
  loading: false,
  page: 1,
  searchedTag: null,
  selectedTech: EMPTYTECHNOLOGY,
  todayTags: [],
};

type ExploreStore = {
  reset: () => void;
  activeTab: string;
  setActiveTab: (tag: string) => void;
  searchedTag: Tag | null;
  setSearchedTag: (tag: Tag) => void;
  selectedTech: Technology;
  setSelectedTech: (tag: Technology) => void;
  todayTags: TagListTag[];
  setTodayTags: (tags: TagListTag[]) => void;

  postList: Post[];
  hasMorePosts: boolean;
  loading: boolean;
  page: number;
  fetchRecentPosts: (user: ProfileResponse, reset: boolean) => Promise<void>;
  fetchRandomPosts: (user: ProfileResponse) => Promise<void>;
  fetchTopTodayPosts: (user: ProfileResponse, reset: boolean) => Promise<void>;
  fetchTopPosts: (user: ProfileResponse, reset: boolean) => Promise<void>;
  fetchFollowingPosts: (user: ProfileResponse, reset: boolean) => Promise<void>;
  fetchHasPromptPosts: (user: ProfileResponse, reset: boolean) => Promise<void>;
  fetchSearchPosts: (
    tag: Tag | null,
    user: ProfileResponse,
    reset: boolean,
  ) => Promise<void>;
  fetchSearchTechPosts: (
    technology: Technology,
    user: ProfileResponse,
    reset: boolean,
  ) => Promise<void>;
  removeReaction: (post: Post, postUserReactionId: number) => void;
  addReaction: (post: Post, reactionId: number, userId: number) => void;
  updatePostInExplorePostList: (post: Post) => void;
  deletePostFromExplorePostList: (postId: number) => void;
  handleBlock: (blocked_user_id: number) => void;
};

export const useExploreStore = create<ExploreStore>((set, get) => ({
  ...initialExploreStoreState,
  activeTab: 'topweekly',
  reset: () => set(() => ({ ...initialExploreStoreState })),
  setActiveTab: (tag) => set({ activeTab: tag }),
  setSearchedTag: (tag) => set({ searchedTag: tag }),
  setSelectedTech: (tech) => set({ selectedTech: tech }),
  setTodayTags: (tags) => set({ todayTags: tags }),

  fetchTopPosts: async (user, reset = false) => {
    const { loading, page: currentPage, postList } = get();
    if (loading) {
      return;
    }

    const page = reset ? 1 : currentPage;
    set({ loading: true });

    try {
      const unauthenticatedAxios = unauthenticatedRequest();
      const response = await unauthenticatedAxios.get(
        `home/top/${user?.id}/${page}`,
      );

      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !postList.some((existingPost) => existingPost.id === newPost.id),
          );
        }
        set({
          postList: reset
            ? [...newPosts]
            : [...postList.filter((post) => !post.isPlaceholder), ...newPosts],
          hasMorePosts: true,
          loading: false,
          page: page + 1,
        });
      } else {
        set({ hasMorePosts: false, loading: false });
      }
    } catch (error) {
      console.log(error);
      set({ loading: false });
    }
  },
  fetchRecentPosts: async (user, reset = false) => {
    const { loading, page: currentPage, postList } = get();
    if (loading) {
      return;
    }

    const page = reset ? 1 : currentPage;
    set({ loading: true });

    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(`post/all/${user?.id}/${page}/0`);

      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !postList.some((existingPost) => existingPost.id === newPost.id),
          );
        }
        set({
          postList: reset
            ? [...newPosts]
            : [...postList.filter((post) => !post.isPlaceholder), ...newPosts],
          hasMorePosts: true,
          loading: false,
          page: page + 1,
        });
      } else {
        set({ hasMorePosts: false, loading: false });
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching recent posts');
      set({ loading: false });
    }
  },
  fetchRandomPosts: async (user) => {
    const { loading } = get();
    if (loading) {
      return;
    }

    set({ loading: true });

    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(`post/random/${user?.id}`);
      set({
        postList: response.data,
        hasMorePosts: false,
        loading: false,
        page: 1,
      });
    } catch (error) {
      showToast('Error fetching random');
      set({ loading: false });
    }
  },
  fetchTopTodayPosts: async (user, reset = false) => {
    const { loading, page: currentPage, postList } = get();
    if (loading) {
      return;
    }

    const page = reset ? 1 : currentPage;
    set({ loading: true });

    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(`post/top-today/${user?.id}/${page}`);

      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !postList.some((existingPost) => existingPost.id === newPost.id),
          );
        }
        set({
          postList: reset
            ? [...newPosts]
            : [...postList.filter((post) => !post.isPlaceholder), ...newPosts],
          hasMorePosts: true,
          loading: false,
          page: page + 1,
        });
      } else {
        set({ hasMorePosts: false, loading: false });
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching top posts');
      set({ loading: false });
    }
  },
  fetchFollowingPosts: async (user: ProfileResponse, reset = false) => {
    const { loading, page: currentPage, postList } = get();
    if (loading) {
      return;
    }
    const page = reset ? 1 : currentPage;
    set({ loading: true });
    try {
      const axios = await authenticatedRequest();
      const response = await axios.get(`post/following/${user?.id}/${page}`);
      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !postList.some((existingPost) => existingPost.id === newPost.id),
          );
        }
        set({
          postList: reset
            ? [...newPosts]
            : [...postList.filter((post) => !post.isPlaceholder), ...newPosts],
          hasMorePosts: true,
          loading: false,
          page: page + 1,
        });
      } else {
        set({ hasMorePosts: false, loading: false });
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching posts');
      set({ loading: false });
    }
  },
  fetchHasPromptPosts: async (user: ProfileResponse, reset = false) => {
    const { loading, page: currentPage, postList } = get();
    if (loading) {
      return;
    }
    const page = reset ? 1 : currentPage;
    set({ loading: true });
    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(`post/has_prompt/${user?.id}/${page}`);
      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !postList.some((existingPost) => existingPost.id === newPost.id),
          );
        }
        set({
          postList: reset
            ? [...newPosts]
            : [...postList.filter((post) => !post.isPlaceholder), ...newPosts],
          hasMorePosts: true,
          loading: false,
          page: page + 1,
        });
      } else {
        set({ hasMorePosts: false, loading: false });
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching posts with prompts');
      set({ loading: false });
    }
  },
  fetchSearchPosts: async (
    searchedTag,
    user: ProfileResponse,
    reset = false,
  ) => {
    const { loading, page: currentPage, postList } = get();
    if (loading) {
      return;
    }
    const page = reset ? 1 : currentPage;
    set({ loading: true });

    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `post_tags/${searchedTag?.id || searchedTag?.tags_id}/${
          user?.id
        }/${page}`,
      );

      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !postList.some((existingPost) => existingPost.id === newPost.id),
          );
        }
        set({
          postList: reset
            ? [...newPosts]
            : [...postList.filter((post) => !post.isPlaceholder), ...newPosts],
          hasMorePosts: true,
          loading: false,
          page: page + 1,
        });
      } else {
        set({ hasMorePosts: false, loading: false });
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching searched posts');
      set({ loading: false });
    }
  },
  fetchSearchTechPosts: async (
    technology,
    user: ProfileResponse,
    reset = false,
  ) => {
    const { loading, page: currentPage, postList } = get();
    if (loading) {
      return;
    }
    const page = reset ? 1 : currentPage;
    set({ loading: true });

    try {
      const axios = unauthenticatedRequest();
      const response = await axios.get(
        `post/technology_type/${technology.id}/${user?.id}/${page}`,
      );

      if (response.data.length > 0) {
        let newPosts = response.data;
        if (!reset) {
          newPosts = response.data.filter(
            (newPost: Post) =>
              !postList.some((existingPost) => existingPost.id === newPost.id),
          );
        }
        set({
          postList: reset
            ? [...newPosts]
            : [...postList.filter((post) => !post.isPlaceholder), ...newPosts],
          hasMorePosts: true,
          loading: false,
          page: page + 1,
        });
      } else {
        set({ hasMorePosts: false, loading: false });
      }
    } catch (error) {
      console.log(error);
      showToast('Error fetching technology posts');
      set({ loading: false });
    }
  },
  removeReaction: async (post: Post, postUserReactionId: number) => {
    const postList = get().postList;
    const index = postList?.findIndex((p) => p.id === post.id);
    const updatedPost = {
      ...post,
      reactions: post.reactions.filter(
        (reaction) => reaction.id !== postUserReactionId,
      ),
    };
    set((state) => ({
      ...state,
      postList: state.postList.map((p, i) => (i === index ? updatedPost : p)),
    }));
  },
  addReaction: async (post: Post, reactionId: number, userId: number) => {
    const postList = get().postList;
    const index = postList?.findIndex((p) => p.id === post.id);
    const authenticatedAxios = await authenticatedRequest();
    const response = await authenticatedAxios.post('post_user_reactions', {
      users_id: userId,
      posts_id: post.id,
      reactions_id: reactionId,
    });

    if (index !== undefined && index > -1) {
      const updatedPost = {
        ...post,
        reactions: [...post.reactions, response.data],
      };

      set((state) => ({
        ...state,
        postList: state.postList.map((p, i) => (i === index ? updatedPost : p)),
      }));
    }
  },
  updatePostInExplorePostList: (updatedPost) =>
    set((state) => {
      const postIndex = state.postList.findIndex(
        (post) => post.id === updatedPost.id,
      );

      if (postIndex !== -1) {
        state.postList[postIndex] = updatedPost;
      }
      return { postList: [...state.postList] };
    }),
  deletePostFromExplorePostList: (postId) =>
    set((state) => ({
      postList: state.postList.filter((post) => post.id !== postId),
    })),
  handleBlock: (blocked_user_id: number) => {
    set((state) => ({
      postList: state.postList.filter(
        (post) => post.users_id !== blocked_user_id,
      ),
    }));
  },
}));

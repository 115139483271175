import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { colors } from '@/app/colors';

interface Props {
  loading: boolean;
  hasMorePosts: boolean;
}

export const renderFooter = (loading: boolean, hasMorePosts: boolean) => {
  return (
    <Layout_FlatListFooter loading={loading} hasMorePosts={hasMorePosts} />
  );
};

const Layout_FlatListFooter = ({ loading, hasMorePosts }: Props) => {
  return loading && hasMorePosts ? (
    <View style={{ padding: 20 }}>
      <ActivityIndicator size="large" color={colors.halfwhite} />
    </View>
  ) : null;
};

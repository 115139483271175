import React from 'react';
import withAuthPopup from '../shared/hoc/withAuthPopup';
import { Button_HeaderNavLink } from './Button_HeaderNavLink';

interface Props {
  toggleModal?: () => void;
  isVisitor?: boolean;
  hoverInCallback: () => void;
  callback: () => void;
}

const Button_NavLinkPost: React.FC<Props> = ({
  toggleModal,
  isVisitor,
  callback,
  hoverInCallback,
}) => {
  const handlePress = () => {
    if (isVisitor && toggleModal) {
      toggleModal();
    } else {
      callback();
    }
  };
  return (
    <Button_HeaderNavLink
      text={'Post'}
      hoverInCallback={hoverInCallback}
      callback={handlePress}
      activeRoute="CreatePost"
    />
  );
};

export default withAuthPopup(Button_NavLinkPost);
